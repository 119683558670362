import React, { Fragment, useEffect, useState } from "react";
import { MobileView } from "react-device-detect";
import { Dropdown } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link } from "react-router-dom";
import logofull from "../../../../assets/admin/images/logo-full.png";
import logo from "../../../../assets/admin/images/logo.png";
import Notifications from "../notifications/Notifications";

const NavHader = () => {
  const [toggle, setToggle] = useState(false);
  const [sideBarStyle, setSideBarStyle] = useState({
    value: "full",
    label: "Full",
  });
  const [navToggle, setNavToggle] = useState(false);

  const [menuToggle, setMenuToggle] = useState(false);
  const [notification, setNotification] = useState({
    count: 0,
    records: [],
  });
  const history = useHistory();
  const openMenuToggle = () => {
    sideBarStyle.value === "overly"
      ? setMenuToggle(true)
      : setMenuToggle(false);
  };

  useEffect(() => {
    const checkDeviceType = () => {
      // debugger;
      const isMobileDevice = window.matchMedia("(max-width: 768px)").matches;
      setNavToggle(isMobileDevice);
    };

    checkDeviceType();

    // Add event listener to detect device type changes on window resize
    window.addEventListener("resize", checkDeviceType);

    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", checkDeviceType);
    };
  }, []);

  return (
    <div className="nav-header head-mob">
      <Link to="/dashboard" className="brand-logo">
        <Fragment>
          <div className="justlogo">
            <img src={logo} />
          </div>
          <div className="brand-title">
            <img src={logofull} />
          </div>
        </Fragment>
      </Link>
      <Dropdown as="li" className="nav-item dropdown notification_dropdown ">
        <Dropdown.Toggle
          className="nav-link i-false c-pointer"
          variant=""
          as="a"
          data-toggle="dropdown"
          aria-expanded="false"
        >
          <div className="header-icon">
            <i className="fa fa-bell " aria-hidden="true"></i>
          </div>

          <span className="badge light text-white bg-warning rounded-circle">
            {notification.count > 0 ? notification.count : ""}
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu
          align="right"
          className="mt-2 dropdown-menu dropdown-menu-end"
        >
          <PerfectScrollbar className="widget-media dlab-scroll p-3 height380">
            <Notifications recrods={notification?.records} />
          </PerfectScrollbar>
          <a
            className="all-notification"
            onClick={() => {
              history.push("/detail-notification");
            }}
          >
            See all notifications <i className="ti-arrow-right" />
          </a>
        </Dropdown.Menu>
      </Dropdown>
      <MobileView>
        <div
          className="nav-control"
          onClick={() => {
            setToggle(!toggle);
            openMenuToggle();
          }}
        >
          <div className={`hamburger ${toggle ? "is-active" : ""}`}>
            <span className="line"></span>
            <span className="line"></span>
            <span className="line"></span>
          </div>
        </div>
      </MobileView>
    </div>
  );
};

export default NavHader;
