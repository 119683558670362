import React from "react";
import CommonHero from "../Helper/CommonHero";
import FooterOne from "../Partials/Footers/FooterOne";
import HeaderFive from "../Partials/Headers/HeaderFive";
import CommingSoon from "../Partials/CommingSoon";

function Contact() {
  return (
    <>
      <HeaderFive />
      <CommonHero title="Contact Us" breadcrumb={["Home", "Contact"]} />
     <CommingSoon />
      <FooterOne />
    </>
  );
}

export default Contact;
