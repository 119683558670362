import React, { useState } from "react";
import "../../../assets/qrscanpages/css/style.css";

import "./qrstyle.css";
import { resturantActions } from "../../../Store/Resturant/resturantAction";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Common/Loader";
import { toast } from "react-toastify";
import { right } from "@popperjs/core";

function RestaurantProductPage({ selectedRecord, back, price, cartItemId }) {
  const dispatch = useDispatch();
  const { menuData } = useSelector((state) => state.MenuReducer);
  const [isLoading, setLoading] = useState(false);
  const [numberProduct, setNumberProduct] = useState(0);
  // const [selectedCartItems, setSelectedCartItems] = useState(0);
  var resId = parseInt(window.location.href.split("res=")[1]);
  var crt = window.location.href.split("&crt=")[1];
  const handleCartplus = () => {
    setNumberProduct(numberProduct + 1);
    // dispatch(
    //   resturantActions.onAddCart(parseInt(resId), crt, selectedCartItems)
    // ).then((result) => {});
  };
  const handleCartMinus = () => {
    if (numberProduct > 0) {
      setNumberProduct(numberProduct - 1);
      // dispatch(
      //   resturantActions.onSubCart(parseInt(resId), 1, selectedCartItems)
      // ).then((result) => {});
    }
  };
  const addToCart = () => {
    setLoading(true);
    var data = {
      item: selectedRecord?.id,
      quantity: numberProduct,
    };
    dispatch(resturantActions.onResturantAddCart(data, resId, crt)).then(
      (res) => {
        setLoading(false);
        if (res?.id) {
          // setSelectedCartItems(res?.items[0]?.id);
          price(selectedRecord?.price * numberProduct);
          cartItemId(res?.items[0]?.id);
          back();
        } else if (
          res?.response?.data?.message === "Item already added to Cart"
        ) {
        } else {
          toast.error("Something went wrong, Please try later.");
        }
      }
    );
  };
  return (
    <>
      {isLoading ? <Loader /> : null}
      <div className="fixed">
        <div className="main-wrapper">
          <div
            className="inner-wrapper1 white-bg"
            style={{ backgroundColor: `${menuData?.popup_background_color}` }}
          >
            <div className="top1">
              {/* <Link to="/restaurant-menu-categories/"> */}
              <i
                class="fa fa-chevron-left p-3"
                onClick={() => {
                  back();
                }}
              ></i>
              {/* </Link> */}
              <hr />
              <div
                style={{
                  padding: "0 10px",
                  // backgroundColor: `${menuData?.item_product_background_color}`,
                  color: `${menuData?.item_product_text_color}`,
                }}
              >
                <table width={"100%"}>
                  <tr>
                    <td width={"20%"}>
                      <img
                        src={selectedRecord?.featured}
                        className="card-img img-responsive pl-2"
                        alt="featured"
                      />
                    </td>
                    <td width={"80%"}>
                      <p
                        className="cad-title"
                        style={{
                          color: `${menuData?.item_product_text_color}`,
                        }}
                      >
                        {selectedRecord?.name}
                      </p>
                      <p
                        className="dim-text mb-4"
                        style={{
                          color: `${menuData?.item_product_text_color}`,
                        }}
                      >
                        {selectedRecord?.description}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="quilaity-lable">
                        <span className="font-weight-bold">Quantity</span>
                      </div>
                      <div className="cart-qualitity-btn"></div>
                    </td>
                    <td>
                      <table width="100" style={{ float: right }}>
                        <tr>
                          <td>
                            <button
                              className="preview-quality-btn"
                              onClick={handleCartMinus}
                            >
                              -
                            </button>
                          </td>
                          <td>
                            <span className="preview-quality-number">
                              {numberProduct}
                            </span>
                          </td>
                          <td>
                            <button
                              className="preview-quality-btn"
                              onClick={handleCartplus}
                            >
                              +
                            </button>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={3}>
                      <div
                        className="comment-form-group"
                        style={{ marginBottom: "100px" }}
                      >
                        {/*<lable>Any Special Requests?</lable>
                        <textarea className="form-control mb-5"></textarea>*/}
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
              {numberProduct > 0 ? (
                <div>
                  <div className="row m-0 p-0 py-4 black-bg cart-ribbon">
                    <div className="col-6">
                      <span className="total-dim-text">Total Bill</span>
                      <p
                        className="footer-total-text white-text"
                        style={{
                          color: `${menuData?.item_product_price}`,
                        }}
                      >
                        ₦ {selectedRecord?.price * numberProduct}
                      </p>
                    </div>
                    <div className="col-6 text-right">
                      <button
                        className="preview-button-theme"
                        onClick={() => {
                          addToCart();
                        }}
                        style={{
                          backgroundColor: `${menuData?.popup_cart_btn_color}`,
                          color: `${menuData?.popup_cart_text_color}`,
                        }}
                      >
                        <span className="btn-cart-ison">
                          <i class="fa fa-shopping-cart" aria-hidden="true"></i>
                        </span>
                        Add to Cart({numberProduct})
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>

      {/* <div className="popup-product-area">
        <div className="wrapper">
        <div className="row"></div>
        <div className="popup-header">
        <i class="fa fa-chevron-left"></i>
        </div>
       <div className="products-area-screen-3">

       <div className="row my-3 rounded p-2">
            <div className="col-2 p-0">
              <img src = {pizza} className="card-img img-responsive"/>  
            </div>
            <div className="col-9">
              <p className="cad-title">Cheese Pizza</p>
              <p className="dim-text mb-4">Onion, Capsicum, Corn, Cheese ( 100gm ), Spanish tomato, Coriander</p>
              <p className="dim-text pb-o mb-0" ><i className="fa fa-clock orange-text"></i> 40 min <i className="fa fa-star orange-text ml-2"></i> 4.7</p>
            </div>
            <div className="col-1 text-right">
            <p><i className="far fa-heart"></i></p>
            </div>
          </div>
          

        
       </div>
       <div className="quality-area">
        <div className="quilaity-lable">
          <span>Quantity</span>
        </div>
        <div className="cart-qualitity-btn">
          <table>
            <tr>
              <td><button className="preview-quality-btn"
              onClick={handleCartMinus}
              >-</button></td>
              <td><span className="preview-quality-number">{numberProduct}</span></td>
              <td><button className="preview-quality-btn"
              onClick={handleCartplus}
              >+</button></td>

            </tr>
          </table>
        </div>
       </div>


       <div className="comment-area">
        <div className="comment-form-group">
        <lable>Any Special Requests?</lable>   
        <textarea className="form-control"></textarea>       
        </div>
          </div>
          </div>
      </div> 
      {/* end of popup */}

      {/* {numberProduct>0 ? (<RestaurantCartArea />) : ""} */}
    </>
  );
}

export default RestaurantProductPage;
