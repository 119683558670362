import React from "react";
import OnlineBookingAnimations from "./OnlineBookingAnimations";

function OnlineBooking() {
  return (
    <section className="about-2-area about-5-area">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <OnlineBookingAnimations />
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="about-5-content">
              <h3 className="title">Online Booking System for Salons</h3>
              <div className="text">
                <p>
                  Most hair salon owners struggle keeping in touch with their
                  customers and managing appointments at the same time.
                  Customers want to have salons that are reachable 24/7,
                  especially with the growing digital industry. Salon Management
                  App is a software that will solve all worries. With our
                  software, your clients can choose what service they want and
                  book their appointments instantly. With an automatic payment
                  system, organizing and managing your hair salon will be much
                  easier.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default OnlineBooking;
