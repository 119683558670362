import React, { useState } from "react";

import { FiShoppingCart } from "react-icons/fi";
import { useSelector } from "react-redux";

function Screen2({ template }) {
  const { menuData } = useSelector((state) => state.MenuReducer);
  return (
    <>
      <div
        className="screen-content"
        style={{
          backgroundColor: `${menuData?.menu_header_background_color}`,
        }}
      >
        <div className="screen2-header">
          <div className="back-icon-area">
            <span>
              <i className="fa fa-chevron-left"></i>
            </span>
          </div>
          <div className="back-icon-area">
            <span>{/* <i className="fa fa-heart"></i> */}</span>
          </div>
        </div>
        <div className="screen-search-area">
          <div className="input-group search-area menu-item-search-area">
            <span className="input-group-text">
              <i className="flaticon-381-search-2"></i>
            </span>
            <input
              type="text"
              className={`form-control`}
              placeholder="Search here..."
              disabled
            />
          </div>
        </div>
        {/* <div className="categories-area">
          <p
            className="top-categories-lable"
            style={{
              color: `${menuData?.menu_header_text}`,
            }}
          >
            Top Categories
          </p>
          <div className="categories-list">
            <div className="category-block">
              <div className="category-image-preview-area">
                <img
                  src="https://i.pinimg.com/736x/7a/6f/50/7a6f50ad3dcf43f63f6e0658c1dd3031.jpg"
                  className="category-image-preview"
                ></img>
              </div>
              <div className="preview-category-overlay"> </div>
              <div className="category-title-preview">Hair Saloon</div>
            </div>
          </div>
        </div> */}
        <div
          className="categories-tabs"
          style={{
            color: `${menuData?.menu_category_tab_text_color}`,
          }}
        >
          <ul>
            <li
              className="active-category-tab"
              style={{
                color: `${menuData?.menu_category_tab_text_color} !important`,
              }}
            >
              <span>Hair Styles</span>
            </li>
            <li>
              <span>Facial</span>
            </li>
          </ul>
        </div>
        <div className="products-area-preview">
          <div
            className="product-block"
            style={{
              backgroundColor: `${menuData?.menu_product_backgroud_color}`,
              color: `${menuData?.menu_product_text}`,
            }}
          >
            <div className="product-block-left">
              <img
                src="https://i.pinimg.com/736x/7a/6f/50/7a6f50ad3dcf43f63f6e0658c1dd3031.jpg"
                className="preview-product-image"
              ></img>
            </div>
            <div className="product-block-right">
              <div className="product-block-row1">
                <div className="produtc-block-title-area">
                  <p
                    className="product-name-block"
                    style={{
                      color: `${menuData?.menu_product_text}`,
                    }}
                  >
                    Cornrows
                  </p>
                  <p
                    className="product-category-name-block"
                    style={{
                      color: `${menuData?.menu_product_text}`,
                    }}
                  >
                    looks more striking{" "}
                  </p>
                </div>
                <div className="product-block-fav-area">
                  <div className="fav-btn-product">
                    {/* <i className="fa fa-heart"></i> */}
                  </div>
                </div>
              </div>
              <div className="product-block-row2">
                <div className="row2-left-about-product">
                  <ul>
                    <li>
                      <span className="product-time-icon">
                        <i className="fa fa-clock" aria-hidden="true"></i>
                      </span>
                      <span
                        className="product-time-number"
                        style={{
                          color: `${menuData?.menu_product_text}`,
                        }}
                      >
                        40min
                      </span>
                    </li>
                    <li>
                      <span
                        className="discount-product-info"
                        style={{
                          color: `${menuData?.menu_product_text}`,
                        }}
                      >
                        20%
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="row2-right-product-price">
                  <span
                    className="product-price"
                    style={{
                      color: `${menuData?.menu_product_price}`,
                    }}
                  >
                    ₦75.12
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            className="product-block"
            style={{
              backgroundColor: `${menuData?.menu_product_backgroud_color}`,
              color: `${menuData?.menu_product_text}`,
            }}
          >
            <div className="product-block-left">
              <img
                src="https://i.pinimg.com/736x/7a/6f/50/7a6f50ad3dcf43f63f6e0658c1dd3031.jpg"
                className="preview-product-image"
              ></img>
            </div>
            <div className="product-block-right">
              <div className="product-block-row1">
                <div className="produtc-block-title-area">
                  <p
                    className="product-name-block"
                    style={{
                      color: `${menuData?.menu_product_text}`,
                    }}
                  >
                    Box Braids
                  </p>
                  <p
                    className="product-category-name-block"
                    style={{
                      color: `${menuData?.menu_product_text}`,
                    }}
                  >
                    looks more striking{" "}
                  </p>
                </div>
                <div className="product-block-fav-area">
                  <div className="fav-btn-product">
                    {/* <i className="fa fa-heart"></i> */}
                  </div>
                </div>
              </div>
              <div className="product-block-row2">
                <div className="row2-left-about-product">
                  <ul>
                    <li>
                      <span className="product-time-icon">
                        <i className="fa fa-clock" aria-hidden="true"></i>
                      </span>
                      <span
                        className="product-time-number"
                        style={{
                          color: `${menuData?.menu_product_text}`,
                        }}
                      >
                        40min
                      </span>
                    </li>
                    <li>
                      <span
                        className="discount-product-info"
                        style={{
                          color: `${menuData?.menu_product_text}`,
                        }}
                      >
                        20%
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="row2-right-product-price">
                  <span
                    className="product-price"
                    style={{
                      color: `${menuData?.menu_product_price}`,
                    }}
                  >
                    ₦75.12
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            className="product-block"
            style={{
              backgroundColor: `${menuData?.menu_product_backgroud_color}`,
              color: `${menuData?.menu_product_text}`,
            }}
          >
            <div className="product-block-left">
              <img
                src="https://i.pinimg.com/736x/7a/6f/50/7a6f50ad3dcf43f63f6e0658c1dd3031.jpg"
                className="preview-product-image"
              ></img>
            </div>
            <div className="product-block-right">
              <div className="product-block-row1">
                <div className="produtc-block-title-area">
                  <p
                    className="product-name-block"
                    style={{
                      color: `${menuData?.menu_product_text}`,
                    }}
                  >
                    Cornrows
                  </p>
                  <p
                    className="product-category-name-block"
                    style={{
                      color: `${menuData?.menu_product_text}`,
                    }}
                  >
                    looks more striking{" "}
                  </p>
                </div>
                <div className="product-block-fav-area">
                  <div className="fav-btn-product">
                    {/* <i className="fa fa-heart"></i> */}
                  </div>
                </div>
              </div>
              <div className="product-block-row2">
                <div className="row2-left-about-product">
                  <ul>
                    <li>
                      <span className="product-time-icon">
                        <i className="fa fa-clock" aria-hidden="true"></i>
                      </span>
                      <span
                        className="product-time-number"
                        style={{
                          color: `${menuData?.menu_product_text}`,
                        }}
                      >
                        40min
                      </span>
                    </li>
                    <li>
                      <span
                        className="discount-product-info"
                        style={{
                          color: `${menuData?.menu_product_text}`,
                        }}
                      >
                        20%
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="row2-right-product-price">
                  <span
                    className="product-price"
                    style={{
                      color: `${menuData?.menu_product_price}`,
                    }}
                  >
                    ₦75.12
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="checkout-cart-area-preview"
          style={{
            backgroundColor: `${menuData?.menu_cart_background}`,
            //color: `${menuData?.menu_cart_text_color}`,
          }}
        >
          <div className="left-total-area">
            <span
              className="total-dim-text"
              style={{
                color: `${menuData?.menu_cart_text_color}`,
              }}
            >
              Total Bill
            </span>
            <p
              className="footer-total-text"
              style={{
                color: `${menuData?.menu_cart_price_color}`,
              }}
            >
              ₦75.12
            </p>
          </div>
          <div className="right-side-btn">
            <button
              className="preview-button-theme"
              disabled
              style={{
                backgroundColor: `${menuData?.menu_delivery_text_background}`,
                color: `${menuData?.menu_delivery_btn_color}`,
              }}
            >
              <span className="btn-cart-ison">
                <FiShoppingCart
                  style={{
                    color: `${menuData?.menu_delivery_icon_color}`,
                  }}
                />
              </span>
              Checkout(3)
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Screen2;
