import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import StickyMenu from "../../../Lib/StickyMenu";
import Navigation from "../../Helper/Navigation";
/* import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';*/
import Dialog from "@material-ui/core/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import { useDispatch } from "react-redux";
/*
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions"; */
import DialogContent from "@material-ui/core/DialogContent";
import { accountActions } from "../../../Store/Accounts/accountAction";
import VideoPopUp from "../../Helper/VideoPopUp";
import "../../../assets/css/login.css";
import { RoutesObj } from "../../../Routers";
import Loader from "../../Common/Loader";
import { resturantActions } from "../../../Store/Resturant/resturantAction";
import BaseModel from "./BaseModel";
import { w3cwebsocket as W3CWebSocket } from "websocket";
// import { color } from "@mui/system";
/* import { Text, View } from 'react'; */
// import Styles from './Styles';
import { MdClose } from "react-icons/md";
import { loginSocket } from "../../../Config/apiConfig";

function HeaderFive() {
  const [socket, setSocket] = useState(null);
  const [qrData, setQrData] = useState("");
  // const [show, setShow] = useState(false);

  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  const [open, setOpen] = React.useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [fullWidth, setFullWidth] = React.useState(false);
  const [remeberMe, setRemeberMe] = React.useState(false);
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const isToken = localStorage.getItem("access_token");
  const handleClickToOpen = () => {
    setOpen(true);
    setError("");
    setFullWidth(true);
  };
  const [popupvideo, setPValue] = useState(false);
  const toggleHandlerv = (e) => {
    e.preventDefault();
    setPValue(!popupvideo);
  };
  const handleQRScan = () => {
    setLoading(true);
    const newSocket = new W3CWebSocket(loginSocket);

    newSocket.onopen = () => {
      // console.log("Connected to the server");
      setSocket(newSocket);
    };

    newSocket.onmessage = (message) => {
      const data = JSON.parse(message.data);

      // console.log("Message Receive");
      // console.log(data);
      if (data.type === "session_id") {
        setQrData(data.token);
        setIsOpen(true);
      } else if (data.type === "token") {
        // console.log(data.token);
        localStorage.setItem("access_token", data.token);
        dispatch(resturantActions.onGetLoggedUserDetail()).then((res) => {
          if (res?.id) {
            dispatch(accountActions.onChange("type", res?.type));
            localStorage.setItem("venu_name", res?.venu_name);
            localStorage.setItem("user_id", res?.user_id);
            localStorage.setItem("visit", res?.visit);
            localStorage.setItem("type", res?.type);
            localStorage.setItem("name", userName);
            if (res?.type === "restaurant") {
              localStorage.setItem("res", res?.id);
              localStorage.setItem("bran", res?.branch?.id);
            } else {
              localStorage.setItem("bus", res?.id);
            }
            history.push(RoutesObj.Dashboard.path);
            setOpen(false);
          }
        });
        // setQrData(data.token);
        setIsOpen(false);
      } else if (data.type === "login_success") {
        // Handle successful login here.
        // console.log("User logged in successfully:", data);
        // Store the JWT token and redirect to the dashboard or perform other actions.
      }
      setLoading(false);
    };

    newSocket.onclose = () => {
      setLoading(false);
      // console.log("Disconnected from the server");
    };

    return () => {
      newSocket.close();
    };
  };

  const handleToClose = () => {
    setOpen(false);
  };
  const [toggleMenu, setValue] = useState(false);
  const toggleHandler = (e) => {
    e.preventDefault();
    setValue(!toggleMenu);
  };
  useEffect(() => {
    StickyMenu(".header-nav");
  }, []);

  const onSubmitLogin = () => {
    setLoading(true);
    dispatch(accountActions.onSubmit(userName, password)).then((data) => {
      if (data?.access) {
        dispatch(resturantActions.onGetLoggedUserDetail()).then((res) => {
          if (res?.id) {
            localStorage.setItem("user_id", res?.user_id);
            //  localStorage.setItem("menuItem", res?.menuItem); //comment
            dispatch(accountActions.onChange("type", res?.type));
            localStorage.setItem("type", res?.type);
            localStorage.setItem("name", userName);
            localStorage.setItem("venu_name", res?.venu_name);
            localStorage.setItem("email", res?.email);
            localStorage.setItem("setting", res?.setting);
            if (res?.type === "restaurant") {
              localStorage.setItem("res", res?.id);
              localStorage.setItem("bran", res?.branch?.id);
            } else {
              localStorage.setItem("bus", res?.id);
            }
            history.push(RoutesObj.Dashboard.path);
            setOpen(false);
          }
        });
      } else if (data?.response?.data?.detail) {
        setError(data?.response?.data?.detail);
      }
      setLoading(false);
    });
  };
  return (
    <>
      {popupvideo && (
        <VideoPopUp
          videoSrc="//www.youtube.com/embed/8iqRPBrJQtk?autoplay=1"
          handler={toggleHandlerv}
        />
      )}
      {isLoading ? <Loader /> : null}
      <Dialog
        open={open}
        //onClose={handleToClose}
        style={{ borderRadius: "20px" }}
        fullWidth={fullWidth}
      >
        {/* <DialogTitle>{"Login"}</DialogTitle> */}
        <div
          style={{
            backgroundColor: "#1b2227",
            maxWidth: "777px",
            maxHeight: "700px",
          }}
        >
          <DialogContent>
            <div
              style={{ display: "flex", justifyContent: "flex-end" }}
              onClick={() => {
                setOpen(false);
              }}
            >
              <MdClose
                style={{
                  color: "#F9A05F",
                  fontSize: "30px",
                }}
              />
            </div>

            <DialogContentText>
              <div className="row">
                <div class="col-12">
                  <div className="text-center">
                    <h2 className="login">
                      {" "}
                      Login<span>&#9996;</span>
                    </h2>
                    <h6 className="text-light">
                      Enter your email & password to login our dashboard
                    </h6>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <label for="input1" class="col-sm-2" className="col-sm-2">
                      <span
                        class="h6 small label pl-2 pr-2 "
                        style={{ color: "white" }}
                      >
                        UserName
                      </span>
                    </label>
                    <input
                      type="text"
                      class="form-control mt-n3"
                      id="input1"
                      value={userName}
                      onChange={(e) => {
                        setUserName(e.target.value);
                        setError("");
                      }}
                    />
                    <label for="input2" class="col-sm-2">
                      <span
                        class="h6 small label pl-2 pr-2"
                        style={{ color: "white" }}
                      >
                        Password
                      </span>
                    </label>
                    <input
                      type="password"
                      class="form-control mt-n3"
                      id="input2"
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                        setError("");
                      }}
                    />
                  </div>
                </div>
                <div class="col-12">
                  <div className="form-group">
                    <div class="row">
                      <div className="col-6">
                        <div class="form-check">
                          {/* <input
                            class="form-check-input"
                            type="checkbox"
                            value={remeberMe}
                            id="form1Example3"
                            checked={remeberMe}
                            onClick={() => setRemeberMe(!remeberMe)}
                          />
                          <label
                            class="form-check-label text-light mt-1"
                            for="form1Example3"
                          >
                            &nbsp;Remember me{" "}
                          </label> */}
                        </div>
                      </div>
                      {/* <div className="col-6 text-right">
                        <a href="#!" className="forget-pass">
                          Forgot password?
                        </a>
                      </div> */}
                    </div>
                  </div>
                  <div className="form-group">
                    <button
                      type="submit"
                      class="login-button button btn-width"
                      onClick={() => {
                        onSubmitLogin();
                      }}
                    >
                      Login
                    </button>
                    <div className="text-center m-1">
                      <a style={{ color: "white" }} className="forget-pass">
                        --OR--
                      </a>
                    </div>
                    <div className="text-center">
                      <a
                        className="forget-pass giveaway-login"
                        onClick={() => {
                          handleQRScan();
                          // setIsOpen(true);
                        }}
                      >
                        Giveaway QR Login
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <p style={{ color: "red" }}>{error}</p>
                </div>
                <div class="col-12">
                  <table className="qr-login">
                    <tr>
                      <td>
                        <div className="imgScan">
                          <img
                            src={
                              require(`../../../assets/images/QR.png`).default
                            }
                            alt=""
                          />
                        </div>
                      </td>
                      <td>
                        <p class="text">Don't have an account?</p>
                      </td>
                    </tr>
                  </table>
                  <p
                    style={{
                      "text-align": "center",
                      "margin-top": "20px",
                      color: "white",
                    }}
                  >
                    Scan here and download the app on your phone to signup
                  </p>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
          {/* <DialogActions>
            <Button onClick={handleToClose}
              color="secondary" autoFocus>
              Close
            </Button>
          </DialogActions> */}
        </div>
      </Dialog>
      <BaseModel qrCodeLink={qrData} isOpen={isOpen} setIsOpen={setIsOpen} />

      {/* {<Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Login</Modal.Title>
        </Modal.Header>
        <Modal.Body >


          <form class="bg-dark">

            <div class="form-group">
              <label for="input1" class="col-sm-2">
                <span class="h6 small bg-white text-muted pt-1 pl-2 pr-2 ">Username</span>
              </label>
              <input type="text" class="form-control mt-n3" id="input1" value="sample" />
              <label for="input2" class="col-sm-2">
                <span class="h6 small bg-white text-muted pt-1 pl-2 pr-2">Password</span>
              </label>
              <input type="password" class="form-control mt-n3" id="input2" value="sample" />
            </div>

            <button type="submit" class="btn btn-primary btn-block">Login</button>
          </form>


        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer> }
      </Modal>} */}

      <header className="header-area header-5-area">
        <div className="header-nav">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="navigation">
                  <nav className="navbar navbar-expand-lg navbar-light ">
                    <a className="navbar-brand" href="/">
                      <img
                        src={
                          require(`../../../assets/images/logo-2.png`).default
                        }
                        alt=""
                      />
                    </a>

                    <button
                      onClick={(e) => toggleHandler(e)}
                      className={`navbar-toggler ${toggleMenu ? "active" : ""}`}
                      type="button"
                      data-toggle="collapse"
                      data-target="#navbarSupportedContent"
                      aria-controls="navbarSupportedContent"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    >
                      <span className="toggler-icon"></span>
                      <span className="toggler-icon"></span>
                      <span className="toggler-icon"></span>
                    </button>

                    <div
                      className={`collapse navbar-collapse sub-menu-bar ${
                        toggleMenu ? "show" : ""
                      }`}
                      id="navbarSupportedContent"
                    >
                      <Navigation />
                    </div>

                    <div className="navbar-btn d-sm-block">
                      {isToken ? (
                        <a
                          className="main-btn"
                          onClick={() => {
                            history.push(RoutesObj.Dashboard.path);
                          }}
                        >
                          Dashboard
                        </a>
                      ) : (
                        <a className="main-btn" onClick={handleClickToOpen}>
                          Login
                        </a>
                      )}
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="hero-video">
        <a
          className="video-popup-btn"
          href="/#"
          onClick={(e) => toggleHandlerv(e)}
        >
          <img
            src={require(`../../../assets/images/video-icon.svg`).default}
            alt=""
          />{" "}
          Watch Video
        </a>
      </div>
    </>
  );
}

export default HeaderFive;
