import React, { useCallback, useEffect, useState } from "react";
import DeshboardLayout from "../layouts/DeshboardLayout";
import Dheader from "./dheaer";
import Filter from "./filter";
import Upcoming from "./upcoming";
import Past from "./past";
import "./orders.css";
import { useDispatch } from "react-redux";
import { resturantActions } from "../../../Store/Resturant/resturantAction";
import Loader from "../../Common/Loader";
import { catalogActions } from "../../../Store/Catalogs/catalogAction";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import QRCode from "react-qr-code";
import { toast } from "react-toastify";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { debounce } from "@mui/material";
import question from "../../../assets/images/question.png";
import qr_guide from "../../../assets/images/qr_scan_guide.jpg";
import GuideModel from "../partials/visitModel";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  alignItem: "center",
  display: "flex",
  flexDirection: "column",
};

function Orders() {
  const [totalPay, setTotalPay] = useState(0);
  const [toggle, setToggle] = useState(1);
  const [upCommingData, setUpCommingData] = useState([]);
  const [upCommingDataBackup, setUpCommingDataBackup] = useState([]);
  const [pastData, setPastData] = useState([]);
  const [pastDataBackup, setPastDataBackup] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [isDataReload, setIsDataReload] = useState(false);
  const [orderDetail, setOrderDetail] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [qrCodeLink, setQrCodeLink] = useState("");
  const [appointmentData, setAppointmentData] = useState([]);
  const [isDetailOpen, setIsDetailOpen] = useState(false);
  const [upCommingPageSize, setUpCommingPageSize] = useState(0);
  const [pastPageSize, setPastPageSize] = useState(0);
  const history = useHistory();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    loadPastData();
  }, []);

  useEffect(() => {
    loadUpcomingData();
  }, []);

  const redirectTo = (id) => {
    history.push(`/order-detail?id=${id}`);
  };

  const loadPastData = (page) => {
    setLoading(true);
    let date = new Date();
    // const pastTime = `?placed_at_lt=${date.toISOString().split("T")[0]} ${
    //   date.toISOString().split("T")[1].split(".")[0]
    // }`;
    let pastTime = "";
    if (page) {
      pastTime = `?page=${page}&booking_slot_from__gte=${
        date.toISOString().split("T")[0]
      }`;
    } else {
      pastTime = `?booking_slot_from__lte=${date.toISOString().split("T")[0]}`;
    }
    dispatch(resturantActions.onGetAllOrder(pastTime)).then((res) => {
      if (res?.results?.length > 0) {
        setPastData(res?.results);
        setPastDataBackup(res?.results);
        setPastPageSize(Math.ceil(res?.count / 10));
      }
      setLoading(false);
    });
  };

  const loadUpcomingData = (page) => {
    setLoading(true);
    let date = new Date();
    // const upCommingTime = `?placed_at_gt=${date.toISOString().split("T")[0]} ${
    //   date.toISOString().split("T")[1].split(".")[0]
    // }`;
    let upCommingTime = "";
    if (page) {
      upCommingTime = `?page=${page}&booking_slot_from__gte=${
        date.toISOString().split("T")[0]
      }`;
    } else {
      upCommingTime = `?booking_slot_from__gte=${
        date.toISOString().split("T")[0]
      }`;
    }
    dispatch(resturantActions.onGetAllOrder(upCommingTime)).then((res) => {
      setLoading(false);
      if (res?.results?.length > 0) {
        setUpCommingData(res?.results);
        setUpCommingDataBackup(res?.results);
        setUpCommingPageSize(Math.ceil(res?.count / 10));
      }
    });
  };

  function updateToggle(id) {
    setToggle(id);
  }
  const filterOrderStatus = (link) => {
    setLoading(true);

    dispatch(resturantActions.onGetAllOrder(link)).then((res) => {
      setLoading(false);
      if (res?.results?.length > 0) {
        setUpCommingData(res?.results);
        setUpCommingPageSize(Math.ceil(res?.count / 10));
      }
    });
  };

  const debouncedChangeHandler = useCallback(
    debounce(filterOrderStatus, 500),
    []
  );

  const filter = (keyword) => {
    if (toggle === 1) {
      // let link = `?resPerPage=50&search=${keyword}`;
      let link = `?search=${keyword}`;
      // filterOrderStatus(link);
      debouncedChangeHandler(link);
      // if (keyword !== "") {
      //   const results = upCommingDataBackup.filter((x) =>
      //     x?.order_id.includes(keyword)
      //   );
      //   setUpCommingData(results);
      // } else {
      //   setUpCommingData(upCommingDataBackup);
      // }
    } else {
      // let link = `?resPerPage=50&search=${keyword}`;
      let link = `?search=${keyword}`;
      // filterOrderStatus(link);
      debouncedChangeHandler(link);
      // if (keyword !== "") {
      //   const results = pastDataBackup.filter((x) =>
      //     x?.order_id?.includes(keyword)
      //   );
      //   setPastData(results);
      // } else {
      //   setPastData(pastDataBackup);
      // }
    }

    setSearch(keyword);
  };

  const orderStatus = (keyword) => {
    if (toggle === 1) {
      filterOrderStatus(`?resPerPage=50&order_status=${keyword}`);
      // const results = upCommingDataBackup.filter(
      //   (x) => x?.order_status.toLowerCase() == keyword.toLowerCase()
      // );
      // setUpCommingData(results);
    } else {
      filterOrderStatus(`?resPerPage=50&order_status=${keyword}`);
      // const results = pastDataBackup.filter(
      //   (x) => x?.order_status.toLowerCase() == keyword.toLowerCase()
      // );
      // setPastData(results);
    }
  };

  const sortBy = (keyword) => {
    if (toggle === 1) {
      if (keyword.toLowerCase() === "orderid") {
        const results = upCommingData.sort((a, b) => {
          let fa = a.order_id.toLowerCase(),
            fb = b.order_id.toLowerCase();
          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
          return 0;
        });
        const val = results.filter((x) => x);
        setUpCommingData(val);
      }
      if (keyword.toLowerCase() === "date") {
        const results = upCommingData.sort((a, b) => {
          let da = new Date(a.placed_at),
            db = new Date(b.placed_at);
          return da - db;
        });
        const val = results.filter((x) => x);
        setUpCommingData(val);
      }
      if (keyword.toLowerCase() === "amount") {
        const results = upCommingData.sort((a, b) => {
          return a.total_price - b.total_price;
        });
        const val = results.filter((x) => x);
        setUpCommingData(val);
      }
      if (keyword.toLowerCase() === "orderstatus") {
        const results = upCommingData.sort((a, b) => {
          let fa = a.order_status.toLowerCase(),
            fb = b.order_status.toLowerCase();
          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
          return 0;
        });
        const val = results.filter((x) => x);
        setUpCommingData(val);
      }
      if (keyword.toLowerCase() === "type") {
        const results = upCommingData.sort((a, b) => {
          let fa = a.order_type.toLowerCase(),
            fb = b.order_type.toLowerCase();
          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
          return 0;
        });
        const val = results.filter((x) => x);
        setUpCommingData(val);
      }
      if (keyword.toLowerCase() === "orderdetails") {
        const results = upCommingData.sort((a, b) => {
          let fa = a.items[0].item.name.toLowerCase(),
            fb = b.items[0].item.name.toLowerCase();
          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
          return 0;
        });
        const val = results.filter((x) => x);
        setUpCommingData(val);
      }
    } else {
      if (keyword.toLowerCase() === "orderid") {
        const results = pastDataBackup.sort((a, b) => {
          let fa = a.order_id.toLowerCase(),
            fb = b.order_id.toLowerCase();
          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
          return 0;
        });
        const val = results.filter((x) => x);
        setPastData(val);
      }
      if (keyword.toLowerCase() === "date") {
        const results = pastDataBackup.sort((a, b) => {
          let da = new Date(a.placed_at),
            db = new Date(b.placed_at);
          return da - db;
        });
        const val = results.filter((x) => x);
        setPastData(val);
      }
      if (keyword.toLowerCase() === "amount") {
        const results = pastDataBackup.sort((a, b) => {
          return a.total_price - b.total_price;
        });
        const val = results.filter((x) => x);
        setPastData(val);
      }
      if (keyword.toLowerCase() === "orderstatus") {
        const results = pastDataBackup.sort((a, b) => {
          let fa = a.order_status.toLowerCase(),
            fb = b.order_status.toLowerCase();
          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
          return 0;
        });
        const val = results.filter((x) => x);
        setPastData(val);
      }
      if (keyword.toLowerCase() === "type") {
        const results = pastDataBackup.sort((a, b) => {
          let fa = a.order_type.toLowerCase(),
            fb = b.order_type.toLowerCase();
          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
          return 0;
        });
        const val = results.filter((x) => x);
        setPastData(val);
      }
      if (keyword.toLowerCase() === "orderdetails") {
        const results = pastDataBackup.sort((a, b) => {
          let fa = a.items[0].item.name.toLowerCase(),
            fb = b.items[0].item.name.toLowerCase();
          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
          return 0;
        });
        const val = results.filter((x) => x);
        setPastData(val);
      }
    }
  };

  const clearFilter = () => {
    setPastData(pastDataBackup);
    setUpCommingData(upCommingDataBackup);
  };

  const orderStatusChange = (orderId, orderStatus) => {
    setLoading(true);
    dispatch(resturantActions.onChangeOrderStatus(orderId, orderStatus)).then(
      (res) => {
        loadPastData(null);
        loadUpcomingData(null);
      }
    );
  };

  const upComminghandleChange = (page) => {
    setUpCommingPageSize(page);
    loadUpcomingData(page);
  };

  const pastHandleChange = (page) => {
    setPastPageSize(page);
    loadPastData(page);
  };

  const closePopup = () => {
    setIsOpen(false);
  };

  const orderPaywithQRCode = (orderId) => {
    setLoading(true);
    dispatch(catalogActions.onGetOrderQRCode(orderId)).then((res) => {
      setLoading(false);
      if (res?.link != "completed") {
        setIsOpen(true);
        setQrCodeLink(res?.link);
        setOrderDetail(res?.detail);
      } else {
        toast.warn("Payment Completed!");
      }
    });
  };

  const getOrderDetail = (orderId) => {
    setLoading(true);
    dispatch(catalogActions.onGetOrderDetail(orderId)).then((res) => {
      setLoading(false);
      if (res?.payment_status == "completed") {
        toast.warning("Payment Completed!");
        return;
      }
      let amount = res?.total_price;
      let discountSum = res?.items.reduce(
        (total, item) => total + (item.discount * item.quantity || 0),
        0
      );
      amount -= discountSum;
      setTotalPay(amount);
      setAppointmentData(res);
      setIsDetailOpen(true);
      dispatch(resturantActions.onChange("orderDetail", res));
    });
  };

  const onCloseModel = () => {
    setOpen(false);
  };

  const payOrder = () => {
    if (
      (appointmentData?.total_tip !== "") &
      (appointmentData?.total_price !== "")
    ) {
      setLoading(true);
      const data = {
        transaction_id: "11111542",
        transaction_type: "purchase",
        payment_status: "success",
        tip: appointmentData?.total_tip,
        amount: appointmentData?.total_price,
        currency: "NGN",
        description: "",
        username: "Munawar",
        customer_id: "123",
        customer: appointmentData?.customer,
        payment_method: "giveaway",
      };
      dispatch(
        catalogActions.onRestaurantTransactionWayCash(
          data,
          appointmentData?.order_id
        )
      ).then((res) => {
        if (res?.id) {
          setIsDetailOpen(false);
          loadUpcomingData(null);
          toast.success("Transaction Succuss");
        } else {
          toast.error("Transaction Failed");
        }
        setLoading(false);
      });
    } else {
      toast.error("Amount and tip is not null");
    }
  };

  return (
    <>
      {isLoading ? <Loader /> : null}
      <div id="main-wrapper" class="show over-flow-block">
        <DeshboardLayout />
        <div
          class={`content-body dashboard-conainer ${
            isLoading ? "hide-custom" : "show"
          }`}
        >
          <Dheader />
          <Filter
            search={search}
            onChange={(e) => filter(e)}
            orderStatus={(e) => orderStatus(e)}
            clearFilter={() => clearFilter()}
            sortBy={(e) => {
              sortBy(e);
            }}
          />

          <div className="row white-bg   mx-3 p-1 mt-3 rounded">
            {/* demo */}
            <ul className="dashbaord-edit-menu-nav upcoming-past-tab">
              <li
                className={toggle === 1 ? "active-menu-nav" : ""}
                onClick={() => updateToggle(1)}
              >
                <span>Upcoming Orders</span>
              </li>
              <li
                className={toggle === 2 ? "active-toggle" : ""}
                onClick={() => updateToggle(2)}
              >
                <span>Past Orders</span>
              </li>
            </ul>
            {/* end demo */}
            {/* <ul className="toggle-list">
              <li
                className={toggle === 1 ? "active-toggle" : ""}
                onClick={() => updateToggle(1)}
              >
                Upcoming Orders
              </li>
              <li
                className={toggle === 2 ? "active-toggle" : ""}
                onClick={() => updateToggle(2)}
              >
                Past Order
              </li>
            </ul> */}

            {toggle === 1 && (
              <div className={toggle === 1 ? "show-content" : "toggle-content"}>
                <Upcoming
                  redirectTo={(r) => redirectTo(r)}
                  upCommingData={upCommingData}
                  onOrderStatusChange={(orderId, orderStatus) => {
                    orderStatusChange(orderId, orderStatus);
                  }}
                  handleChange={(a, b) => {
                    upComminghandleChange(b);
                  }}
                  upCommingPageSize={upCommingPageSize}
                  orderPaywithQRCode={(e) => orderPaywithQRCode(e)}
                  orderPaywithCash={(e) => getOrderDetail(e)}
                />
              </div>
            )}
            {toggle === 2 && (
              <div className={toggle === 2 ? "show-content" : "toggle-content"}>
                <Past
                  redirectTo={(r) => redirectTo(r)}
                  pastData={pastData}
                  onOrderStatusChange={(orderId, orderStatus) => {
                    orderStatusChange(orderId, orderStatus);
                  }}
                  pastHandleChange={(a, b) => {
                    pastHandleChange(b);
                  }}
                  pastPageSize={pastPageSize}
                />
              </div>
            )}
          </div>
          <GuideModel
            url={qr_guide}
            open={open}
            onCloseCallback={onCloseModel}
          />
          <Modal
            open={isOpen}
            onClose={() => closePopup()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="qr-slip"
          >
            <Box sx={style} style={{ position: "relative" }}>
              <img
                className="question-mark"
                width={25}
                src={question}
                onClick={() => {
                  setOpen(true);
                }}
                style={{ position: "absolute", right: "8px", top: "8px" }}
              />

              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                style={{ alignSelf: "center" }}
              >
                Pay Via Giveaway
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ mt: 2 }}
                style={{ alignSelf: "center" }}
              >
                <QRCode value={qrCodeLink} />
              </Typography>
              <div className="row">
                <div className="col-12">
                  {orderDetail?.order_id ? (
                    <table width={"250px"} style={{ margin: "auto" }}>
                      <tr>
                        <td colSpan={2}>
                          <table className="items-table" width={"100%"}>
                            <tr>
                              <th>Item</th>
                              <th>Qty</th>
                              <th>Price {orderDetail?.currency}</th>
                            </tr>
                            {orderDetail?.items.map((obj) => (
                              <tr>
                                <td>{obj?.item?.name}</td>
                                <td>{obj?.quantity}</td>
                                <td>
                                  {orderDetail?.currency +
                                    " " +
                                    obj?.unit_price}
                                </td>
                              </tr>
                            ))}
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <th>Order ID : </th>
                        <td>{orderDetail?.order_id}</td>
                      </tr>
                      <tr>
                        <th>Total Price : </th>
                        <td>
                          {orderDetail?.currency +
                            " " +
                            orderDetail?.total_price}
                        </td>
                      </tr>
                      <tr>
                        <th>Discount : </th>
                        <td>
                          {orderDetail?.currency +
                            " " +
                            orderDetail?.total_discounted}
                        </td>
                      </tr>
                      <tr>
                        <th>Total Tip : </th>
                        <td>
                          {orderDetail?.currency + " " + orderDetail?.total_tip}
                        </td>
                      </tr>
                      <tr>
                        <th>Total : </th>
                        <th>
                          {orderDetail?.currency +
                            " " +
                            orderDetail?.total_bill_amount}
                        </th>
                      </tr>
                    </table>
                  ) : (
                    <p className="text-center">Loading</p>
                  )}
                </div>
              </div>
              <button
                className="btn btn-primary"
                style={{ alignSelf: "center", marginTop: "20px" }}
                onClick={() => {
                  closePopup();
                }}
              >
                Close
              </button>
            </Box>
          </Modal>
          <Modal
            open={isDetailOpen}
            onClose={() => {
              setIsDetailOpen(false);
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                style={{ alignSelf: "center" }}
              >
                Payment Detail
              </Typography>
              <div
                // className="col-md-6 col-xs-12 px-5 py-2"
                style={{ width: "100%", marginTop: "20px" }}
              >
                <Link
                  className="form-label"
                  onClick={() => {
                    history.push(
                      `/order-detail?id=${appointmentData?.order_id}`
                    );
                  }}
                >{`Order # ${appointmentData?.order_id}`}</Link>
              </div>
              <div
                // className="col-md-6 col-xs-12 px-5 py-2"
                style={{ width: "100%" }}
              >
                <lable className="form-label">Amount</lable>
                <input
                  type="text"
                  name="Amount"
                  class="form-control input-filed"
                  placeholder="Amount"
                  value={totalPay}
                  onChange={(e) => {
                    setAppointmentData({
                      ...appointmentData,
                      total_price: e.target.value,
                    });
                  }}
                />
              </div>
              <div style={{ width: "100%" }}>
                <lable className="form-label">Tip</lable>
                <input
                  type="text"
                  name="Tip"
                  class="form-control input-filed"
                  placeholder="Tip"
                  value={appointmentData.total_tip}
                  onChange={(e) => {
                    setAppointmentData({
                      ...appointmentData,
                      total_tip: e.target.value,
                    });
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <button
                  className="btn btn-primary"
                  style={{
                    alignSelf: "center",
                    marginTop: "20px",
                    width: "100px",
                  }}
                  onClick={() => {
                    payOrder();
                  }}
                >
                  Submit
                </button>
                <button
                  className="btn btn-primary"
                  style={{
                    alignSelf: "center",
                    marginTop: "20px",
                    width: "100px",
                  }}
                  onClick={() => {
                    setIsDetailOpen(false);
                  }}
                >
                  Close
                </button>
              </div>
            </Box>
          </Modal>
        </div>
      </div>
    </>
  );
}

export default Orders;
