function Dheader() {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <h3 className="inner-header">
              Appointments
              <span className="heading-link">
                <i className="fa fa-chevron-right bread-icon"></i>
                <span className="subheading-link">Appointments List</span>
              </span>
            </h3>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dheader;
