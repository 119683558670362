import React from "react";

function FooterOne() {
  return (
    <footer className="footer-area">
      <div className="container">
        <div className="footer-item">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="footer-about mt-30">
                <div className="footer-logo">
                  <a href="/#">
                    <img
                      src={require(`../../../assets/images/logo-2.png`).default}
                      alt=""
                    />
                  </a>
                </div>
                <p className="mt-2">
                  Grow your Revenue & <br></br>Increase Profits with Us
                </p>
                <div className="footer-social">
                  <ul className="social">
                    <li>
                      <a href="/#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <i className="fab fa-linkedin"></i>
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <i className="fab fa-youtube"></i>
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="footer-list mt-30 ml-95">
                <h4 className="footer-title">Product</h4>
                <ul>
                  <li>
                    <a href="/#">Features</a>
                  </li>
                  <li>
                    <a href="/#">Tablet Menu</a>
                  </li>
                  <li>
                    <a href="/#">Dine-in QR Menu</a>
                  </li>
                  <li>
                    <a href="/#">Delivery & Pick-Up Menu</a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="footer-info mt-30">
                <h3 className="footer-title">Contact Us</h3>
                <ul>
                <li className="mr-2">
                  {/*  <i class="fa fa-location"></i>&nbsp;&nbsp;&nbsp; */}
                   380 vistacourt drive 4307 Plano Texas 75074{" "}
                  </li>
                  <li>
                  <i class="fa fa-envelope"></i>&nbsp;&nbsp;&nbsp;&nbsp;
                  <span className="pl-3">info@yawaeig.com</span>
                    </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 text-center">
            <div className="footer-copyright">
              <p>© 2022 Yawaevig . All rights reserved</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default FooterOne;
