import React, { useState } from "react";
import DeshboardLayout from "../layouts/DeshboardLayout";
import MainQR from "./mainQr";

function QrCode() {
  return (
    <>
      <div id="main-wrapper" class="show over-flow-block">
        <DeshboardLayout />
        <div class="content-body">
          <MainQR />
        </div>
      </div>
    </>
  );
}

export default QrCode;
