import React from "react";

function ShowCaseFood() {

  return (
    <section className="about-2-area about-5-area">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
          <div className="animation-area-main">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
                <div className="animation-block-area">
                <img
                src={require(`../../assets/images/showcase1.png`).default}
                alt=""
                className="up-area"
              />
                <img
                src={require(`../../assets/images/showcase2.png`).default}
                alt=""
                className="bottom-area"
              />
                </div>
              </div>
              </div>
            </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="about-5-content">
              <h3 className="title">Showcase your food with beautiful photos & videos</h3>
              <div className="text">
                <p>
                My Menu allows you to showcase your food by video, which will help increase your sales because your guest is mesmerized by the food videos and not looking at the price anymore.
                </p>
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ShowCaseFood;
