import React from "react";

function SliderAnimation() {

  return (
    <>
      <div className="animation-test">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="animation-block">
                <span className="triangle-area">
                </span>
                <span className="triangle-area-1">
                </span>
                <span className="triangle-area-2">
                </span>
                <span className="triangle-area-3">
                </span>
                <span className="circle-area2"></span>
                <img
                src={require(`../../assets/images/slidermageonly.png`).default}
                alt=""
                className="slider-img-main"
              />
              <span className="circle-area"></span>
              <span className="circle-area1"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SliderAnimation;
