import React from "react";
import TutorialsData  from "./TutorialsData";

const Tutorials = ({ toggleTab, PerfectScrollbar, toggleChatBox }) => {
   return (
      <div
         className={`tab-pane fade  ${
            toggleTab === "tutorials" ? "active show" : ""
         }`}
         id="tutorials"
         role="tabpanel"
      >
         <div className="card mb-sm-3 mb-md-0 contacts_card">

            <PerfectScrollbar
               className={`card-body contacts_body p-0 dlab-scroll  ${
                  toggleChatBox ? "ps ps--active-y" : ""
               }`}
               id="DZ_W_Contacts_Body1"
            >
           <TutorialsData />
            </PerfectScrollbar>
         </div>
      </div>
   );
};

export default Tutorials;
