import React from "react";
import { Link } from "react-router-dom";

const MenuNav = () => {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <ul className="dashbaord-header-nav">
              <li className="active-menu-nav">
                <span>Themes</span>
              </li>
              {/* <li><Link to="/edit-menu-template" className="secondary-color-link">Build Menu</Link></li> */}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};
export default MenuNav;
