import React, { useEffect, useState } from "react";
import DeshboardLayout from "../layouts/DeshboardLayout";
import Switch from "react-switch";
import "./pricing.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import { accountActions } from "../../../Store/Accounts/accountAction";
import Loader from "../../Common/Loader";
import BaseModel from "./BaseModel";

function Pricing() {
  const [checked, setChecked] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [qrCodeLink, setQrCodeLink] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [packages, setPackages] = useState([]);
  const [selectedpackages, setSelectedPackages] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    setLoading(true);
    dispatch(accountActions.onGetBusinessPackages()).then((data) => {
      setLoading(false);
      if (data?.length > 0) {
        setPackages(data);
      }
    });
  }, []);

  const onSubscribeClick = (item) => {
    let id = 0;
    let res = localStorage.getItem("res");
    let bus = localStorage.getItem("bus");
    if (res) {
      id = res;
    }
    if (bus) {
      id = bus;
    }
    if (id > 0) {
      const modal = {
        package_id: item?.id,
        business_id: id,
        plan: checked ? "yearly" : "monthly",
      };
      setLoading(true);
      dispatch(accountActions.onSubscribePackages(modal)).then((data) => {
        setLoading(false);
        if (data?.link) {
          setIsOpen(true);
          setQrCodeLink(data?.link);
        }
      });
    }
  };

  return (
    <div id="main-wrapper" class="show">
      {isLoading ? <Loader /> : null}
      <DeshboardLayout />
      <div class="content-body">
        <div className="row">
          <div className="col-md-12 text-center">
            <h2 className="heading">Choose your plan</h2>
            <p className="dim-text">
              15 days unlimited free trial. No contract or credit card required
            </p>
            <p className="subheading">
              Montly{" "}
              <span class="price-switch">
                <Switch
                  className="react-switch"
                  onChange={() => {
                    setChecked(!checked);
                  }}
                  checked={checked}
                  aria-label="super secret label that is not visible"
                />
              </span>
              Yearly <span className="price-sale">20% off</span>
            </p>
          </div>
        </div>

        <div className="row m-4">
          {packages?.map((item) => {
            return (
              <div className="col-md-4 col-12 text-center">
                <div className="white-bg package-container rounded p-3 mx-4">
                  <p className="subheading mt-3">{item?.package?.name}</p>
                  <p className="dim-text">{item?.package?.tag_line}</p>
                  <h2 className="orange-text">
                    ₦{" "}
                    {checked
                      ? item?.package?.yearly_price
                      : item?.package?.price}
                  </h2>
                  <p>{checked ? "/ per Year" : "/ per Month"}</p>
                  <p className="dim-text">{item?.package?.discount_message}</p>
                  {item?.package_type == "business" ? (
                    <>
                      <p className="my-0">
                        {item?.no_of_professional} Professional
                      </p>
                      <p className="my-0"> {item?.no_of_service} Service</p>
                      <p className="my-0">Online Ordering</p>
                    </>
                  ) : (
                    <>
                      <p className="my-0"> {item?.no_of_worker} Worker</p>
                      <p className="my-0">{item?.no_of_item} Product</p>
                      <p className="my-0">Online Ordering</p>
                    </>
                  )}
                  <button
                    className="btn btn-primary my-4"
                    onClick={() => {
                      onSubscribeClick(item);
                    }}
                  >
                    Subscribe
                  </button>
                </div>
              </div>
            );
          })}

          {/* <div className="col-md-4 col-12 text-center">
            <div className="white-bg rounded p-3 mx-4">
              <p className="subheading mt-3">STANDARD</p>
              <p className="dim-text">Ideal for medium Business</p>
              <h2 className="orange-text">₦ 2500</h2>
              <p>/ per Month</p>
              <p className="dim-text">Save 20% per month with yearly plan</p>
              <p className="my-0">7 to 12 Tablets</p>
              <p className="my-0">1 to 30 QR Codes</p>
              <p className="my-0">Online Ordering</p>
              <button className="btn btn-primary my-4">Start Free Trail</button>
            </div>
          </div>

          <div className="col-md-4 col-12 text-center">
            <div className="white-bg rounded p-3 mx-4">
              <p className="subheading mt-3">PREMIUM</p>
              <p className="dim-text">Ideal for large Business</p>
              <h2 className="orange-text">₦ 3500</h2>
              <p>/ per Month</p>
              <p className="dim-text">Save 20% per month with yearly plan</p>
              <p className="my-0">30 to 60 Tablets</p>
              <p className="my-0">1 to 200 QR Codes</p>
              <p className="my-0">Online Ordering</p>
              <button className="btn btn-primary my-4">Start Free Trail</button>
            </div>
          </div> */}
        </div>
        {/* <div className="row m-4">
          <div className="col-md-12 mt-4 col-12">
            <div className="white-bg rounded p-3 mx-4">
              <div className="row">
                <div className="col-md-12 text-center py-4">
                  <h2 className="heading">All Plans Include</h2>
                </div>
                <div className="col-md-6">
                  <p>
                    <input type="checkbox" checked /> Tablet Menu
                  </p>
                  <p>
                    <input type="checkbox" checked /> QR Menu
                  </p>
                  <p>
                    <input type="checkbox" checked /> Unlimited Menus & Menu
                    Items{" "}
                  </p>
                  <p>
                    <input type="checkbox" checked /> Unlimited Orders{" "}
                  </p>
                  <p>
                    <input type="checkbox" checked /> Social Login to capture
                    guest information
                  </p>
                  <p>
                    <input type="checkbox" checked /> Online Delivery & Pick-up
                    Ordering with 0% Commission
                  </p>
                </div>
                <div className="col-md-6">
                  <p>
                    <input type="checkbox" checked /> Enable Guest Self-Ordering
                  </p>
                  <p>
                    <input type="checkbox" checked /> Manage Orders via Order
                    Panel
                  </p>
                  <p>
                    <input type="checkbox" checked /> POS Integration
                  </p>
                  <p>
                    <input type="checkbox" checked /> Payment Integration
                  </p>
                  <p>
                    <input type="checkbox" checked /> Auto-play food videos
                  </p>
                  <p>
                    <input type="checkbox" checked /> FREE SETUp
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <BaseModel
          qrCodeLink={qrCodeLink}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      </div>
    </div>
  );
}

export default Pricing;
