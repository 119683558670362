import { useEffect, useState } from "react";
import GeneralForm from "./generalform";
import Hours from "./businessHours";
import Notification from "./notification";
import "./setting.css";
import { useDispatch, useSelector } from "react-redux";
import { resturantActions } from "../../../Store/Resturant/resturantAction";
import Loader from "../../Common/Loader";
import { toast } from "react-toastify";

const General = () => {
  const [toggle, setToggle] = useState(1);
  const [isLoading, setLoading] = useState(false);
  const [profile, setProfile] = useState();
  const [feature, setFeature] = useState();
  const [businessTypes, setBusinessTypes] = useState([]);
  const dispatch = useDispatch();
  const [restaurantId, setRestaurantId] = useState(0);
  const { resturantSettingNotification, resturantSetting } = useSelector(
    (state) => state.ResturantReducer
  );
  const [socailLink, setSocailLink] = useState([
    {
      name: "facebook",
      link: "",
    },
    {
      name: "instagram",
      link: "",
    },
    {
      name: "snapchat",
      link: "",
    },
    {
      name: "tripadvisor",
      link: "",
    },
    {
      name: "twitter",
      link: "",
    },
    {
      name: "tiktok",
      link: "",
    },
    {
      name: "youtube",
      link: "",
    },
    {
      name: "website",
      link: "",
    },
    {
      name: "privacyURL",
      link: "",
    },
    {
      name: "Linktree",
      link: "",
    },
  ]);
  const [businessHours, setBusinessHours] = useState([
    {
      weekday: "monday",
      start_time: "09:00:00",
      end_time: "17:00:00",
      active: true,
    },
    {
      weekday: "tuesday",
      start_time: "09:00:00",
      end_time: "17:00:00",
      active: false,
    },
    {
      weekday: "wednesday",
      start_time: "09:00:00",
      end_time: "17:00:00",
      active: false,
    },
    {
      weekday: "thursday",
      start_time: "09:00:00",
      end_time: "17:00:00",
      active: false,
    },
    {
      weekday: "friday",
      start_time: "09:00:00",
      end_time: "17:00:00",
      active: false,
    },
    {
      weekday: "saturday",
      start_time: "09:00:00",
      end_time: "17:00:00",
      active: false,
    },
    {
      weekday: "sunday",
      start_time: "09:00:00",
      end_time: "17:00:00",
      active: false,
    },
  ]);
  useEffect(() => {
    setLoading(true);
    dispatch(resturantActions.onGetLoggedUserDetail()).then((res) => {
      if (res?.id) {
        setRestaurantId(res?.id);
        dispatch(resturantActions.onChange("resturantId", res?.id));
        if (res?.social_media?.length === 0) {
          res.social_media = socailLink;
          dispatch(
            resturantActions.onCreateSocialMedia(socailLink, res?.id)
          ).then((res) => {});
        }
        if (res?.branch?.schedule?.length === 0) {
          res.branch.schedule = businessHours;
        }
        dispatch(resturantActions.onChange("resturantSetting", res));
        dispatch(resturantActions.onGetNotificationRestaurant(res?.id)).then(
          (result) => {
            if (result?.id) {
              dispatch(
                resturantActions.onChange(
                  "resturantSettingNotification",
                  result
                )
              );
            }
            dispatch(resturantActions.onGetSoundClips()).then((resp) => {
              if (resp?.results) {
                dispatch(resturantActions.onChange("sounds", resp?.results));
              }
              setLoading(false);
            });
          }
        );
      } else {
        setLoading(false);
      }
    });
  }, []);

  useEffect(() => {
    let getType = localStorage.getItem("type");
    dispatch(resturantActions.onGetBusinessTypes(getType)).then((res) => {
      if (res?.results) {
        setBusinessTypes(res?.results);
      }
    });
  }, []);

  // useEffect(() => {
  //   if (restaurantId > 0) {
  //     dispatch(resturantActions.onGetNotificationRestaurant(restaurantId)).then(
  //       (res) => {
  //         if (res?.id) {
  //           dispatch(
  //             resturantActions.onChange("resturantSettingNotification", res)
  //           );
  //           dispatch(resturantActions.onGetSoundClips()).then((res) => {
  //             if (res?.results) {
  //               dispatch(resturantActions.onChange("sounds", res?.results));
  //             }
  //           });
  //         }
  //         setLoading(false);
  //       }
  //     );
  //   }
  // }, [restaurantId]);

  function updateToggle(id) {
    setToggle(id);
  }
  const saveClick = () => {
    if (restaurantId > 0) {
      setLoading(true);
      dispatch(
        resturantActions.onUpdateSchedules(
          resturantSetting?.branch?.schedule,
          restaurantId
        )
      ).then((res) => {});
      let data = new FormData();
      data.append("restaurant[name]", resturantSetting?.name);
      data.append("restaurant[display_name]", resturantSetting?.display_name);
      data.append("restaurant[tagline]", resturantSetting?.tagline);
      data.append("restaurant[description]", resturantSetting?.description);
      data.append("restaurant[branding_color_primary]", "#ffff");
      data.append("restaurant[branding_color_secondary]", "#ffff");
      data.append("restaurant[branding_color_extra]", "#ffff");
      profile && data.append("restaurant[logo]", profile);
      feature && data.append("restaurant[featured]", feature);
      data.append(
        "restaurant[business_type]",
        resturantSetting?.businessType ? resturantSetting?.businessType : 1
      );
      data.append(
        "restaurant[primary_contact_no]",
        resturantSetting?.primary_contact_no
      );
      data.append(
        "restaurant[secondary_phone_no]",
        resturantSetting?.secondary_phone_no
          ? resturantSetting?.secondary_phone_no
          : resturantSetting?.primary_contact_no
      );
      data.append(
        "restaurant[whatsapp]",
        resturantSetting?.secondary_phone_no
          ? resturantSetting?.secondary_phone_no
          : resturantSetting?.primary_contact_no
      );
      data.append("restaurant[email]", resturantSetting?.email);
      data.append("branch[address]", resturantSetting?.branch?.address);
      data.append("branch[longitude]", resturantSetting?.latitude?.long);
      data.append("branch[latitude]", resturantSetting?.latitude?.lat);
      resturantSetting?.social_media?.map((item, index) => {
        data.append(`social[${index}][name]`, item.name);
        data.append(`social[${index}][link]`, item.link);
      });
      // data.append("branch[city]", resturantSetting?.branch?.city?.id);
      dispatch(resturantActions.onUpdateRestaurant(data)).then((res) => {
        setLoading(false);
        if (res?.id) {
          toast.success("Setting updated");
        } else {
          toast.error("Setting not updated");
        }
      });
      const notificationData = {
        order_notification_sound:
          resturantSettingNotification?.order_notification_sound?.id,
        order_notification_sound_on:
          resturantSettingNotification?.order_notification_sound_on,
        order_notification_sound_repeat:
          resturantSettingNotification?.order_notification_sound_repeat,
        service_notification_sound:
          resturantSettingNotification?.service_notification_sound?.id,
        service_notification_sound_on:
          resturantSettingNotification?.service_notification_sound_on,
        service_notification_sound_repeat:
          resturantSettingNotification?.service_notification_sound_repeat,
        panel_notification_sound:
          resturantSettingNotification?.panel_notification_sound?.id,
        panel_notification_sound_on:
          resturantSettingNotification?.panel_notification_sound_on,
        panel_notification_sound_repeat:
          resturantSettingNotification?.panel_notification_sound_repeat,
        chat_notification_sound:
          resturantSettingNotification?.chat_notification_sound?.id,
        chat_notification_sound_on:
          resturantSettingNotification?.chat_notification_sound_on,
        chat_notification_sound_repeat:
          resturantSettingNotification?.chat_notification_sound_repeat,
      };
      dispatch(
        resturantActions.onSaveResturantNotificationSetting(
          restaurantId,
          notificationData
        )
      ).then((res) => {
        setLoading(false);
      });
    }
  };

  return (
    <>
      {isLoading ? <Loader /> : null}
      <div className={`${isLoading ? "hide-custom" : "show"}`}>
        <div className="row m-4">
          <div className="col-md-7 col-xs-12">
            <h2 className="heading">Settings</h2>
          </div>

          <div className="col-md-5 col-xs-12 text-right">
            {/* <button className="btn btn-info mr-3">Cancel</button> */}
            <button
              className="btn btn-primary"
              onClick={() => {
                saveClick();
              }}
            >
              Save
            </button>
          </div>
        </div>
        <div className="row rounded bg-white m-4">
          <div className="col-md-12 py-3">
            <ul className="menu-toggle-list1 setting-tab">
              <li
                className={
                  toggle === 1 ? "active-toggle setting-tab" : "setting-tab"
                }
                onClick={() => updateToggle(1)}
              >
                General
              </li>
              <li
                className={
                  toggle === 2 ? "active-toggle setting-tab" : "setting-tab"
                }
                onClick={() => updateToggle(2)}
              >
                Business Hours
              </li>
              <li
                className={
                  toggle === 3 ? "active-toggle setting-tab" : "setting-tab"
                }
                onClick={() => updateToggle(3)}
              >
                Notifications
              </li>
            </ul>
            {toggle === 1 && (
              <div className={toggle === 1 ? "show-content" : "toggle-content"}>
                <GeneralForm
                  resturantId={restaurantId}
                  profile={profile}
                  setProfile={(e) => {
                    setProfile(e);
                  }}
                  feature={feature}
                  setFeature={(e) => {
                    setFeature(e);
                  }}
                  businessTypes={businessTypes}
                />
              </div>
            )}

            {toggle === 2 && (
              <div className={toggle === 2 ? "show-content" : "toggle-content"}>
                <Hours resturantId={restaurantId} />
              </div>
            )}

            {toggle === 3 && (
              <div className={toggle === 3 ? "show-content" : "toggle-content"}>
                <Notification />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default General;
