import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import QRCode from "react-qr-code";
import question from "../../assets/images/question.png";
import qr_guide from "../../assets/images/qr_scan_guide.jpg";
import GuideModel from "../Dashboard/partials/visitModel";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  alignItem: "center",
  display: "flex",
  flexDirection: "column",
};

const BaseModel = ({ isOpen, qrCodeLink = null, setIsOpen }) => {
  // const [isOpen, setIsOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const closePopup = () => {
    setIsOpen(false);
  };

  const onCloseModel = () => {
    setOpen(false);
  };

  return (
    <>
      <GuideModel url={qr_guide} open={open} onCloseCallback={onCloseModel} />
      <Modal
        open={isOpen}
        onClose={() => closePopup()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <img
            alt="question"
            className="question-mark"
            width={25}
            src={question}
            onClick={() => {
              setOpen(true);
            }}
            style={{ position: "absolute", right: "8px", top: "8px" }}
          />
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{ alignSelf: "center" }}
          >
            QR Code
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2 }}
            style={{ alignSelf: "center" }}
          >
            {qrCodeLink && (
              <>
                <div id="qrcode" style={{ width: "250px", height: "250px" }}>
                  <QRCode size={250} value={qrCodeLink} />
                </div>
              </>
            )}
          </Typography>
          <div className="row mt-2 text-center">
            {/* <div className="col">
            {qrCodeLink && (
              <button
                id="button"
                onClick={DownloadQr}
                class="btn btn-primary small"
              >
                Download
              </button>
            )}
          </div> */}
            <div className="col">
              <button
                className="btn btn-warning small"
                style={{ width: "150px" }}
                onClick={() => {
                  closePopup();
                }}
              >
                Close
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default BaseModel;
