import React from "react";
import SaveFavoriteAnimation from "./SaveFavoriteAnimation";

function SaveFavorites() {

  return (
    <section className="about-2-area about-5-area">
      <div className="container">
        <div className="row align-items-center">


          <div className="col-lg-6">
            <SaveFavoriteAnimation />
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="about-5-content">
              <h3 className="title">Save your favorites!</h3>
              <div className="text">
                <p>Your guests can now ‘Like’ your menu items which will be saved into their favorites! So next time they visit your restaurant, they don’t need to go looking for their favorites again. It is saved as a preference even if the guest has not logged in!
                </p>
              </div>

            </div>
          </div>

        </div>
      </div>
    </section>
  );
}

export default SaveFavorites;
