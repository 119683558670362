import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import image from "../../../assets/images/avatar.jpg";
import { resturantActions } from "../../../Store/Resturant/resturantAction";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

const Members = ({ results, isReload, pageSize, handlePageChange }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  return (
    <div className="my-4">
      <div className="row">
        {results?.map((x) => {
          return (
            <div className="col-md-3">
              <div className="text-center pb-15 card radius-12">
                <div className="text-right px-3 py-2">
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="member-option"
                      id="dropdown-basic"
                    >
                      <i className="fa fa-ellipsis-h"></i>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => {
                          history.push(`add-staff-member?id=${x.id}`);
                        }}
                      >
                        Edit Profile
                      </Dropdown.Item>
                      {/* <Dropdown.Item
                        onClick={() => {
                          history.push(`view-staff-member?id=${x.id}`);
                        }}
                      >
                        View Profile
                      </Dropdown.Item> */}
                      <Dropdown.Item
                        onClick={() => {
                          dispatch(
                            resturantActions.onDeleteWorkers(null, x.id)
                          ).then((res) => {
                            isReload(res);
                          });
                        }}
                      >
                        Delete
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="pb-3">
                  <img
                    style={{
                      width: "60px",
                      height: "60px",
                      borderRadius: "100px",
                    }}
                    src={x.profile_image ? `${x?.profile_image}` : image}
                    alt=""
                    width={"60px"}
                    height={"60px"}
                  />
                </div>
                <div className="">
                  <h5 className="card-name">{x.name}</h5>
                </div>
                <div className="">
                  <h6 className="orange-text designation">
                    {x?.position?.name}
                  </h6>
                </div>
                <div className="text-center mob-remove">
                  <p className="card-id">{`ID:${x.id_no}`}</p>
                </div>
                {/* <div>
                  <button
                    className="btn btn-primary card-btn"
                    onClick={() => {
                      history.push(`add-staff-member?id=${x.id}`);
                    }}
                  >
                    Edit Member
                  </button>
                </div> */}
              </div>
            </div>
          );
        })}
      </div>
      {pageSize > 1 && (
        <Stack spacing={2}>
          <Pagination
            count={pageSize}
            onChange={(e, p) => handlePageChange(e, p)}
          />
        </Stack>
      )}
    </div>
  );
};
export default Members;
