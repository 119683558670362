import React, { useEffect } from "react";
import CommonHero from "../Helper/CommonHero";
import FooterOne from "../Partials/Footers/FooterOne";
import HeaderFive from "../Partials/Headers/HeaderFive";
import ShowCaseFood from "./ShowCaseFood";
import Branding from "./Branding";
import SaveFavorites from "./SaveFavorites";
import SeamlessPayment from "./SeamlessPayment";
import DiffrentWays from "./DifferentWays";
import Benefits from "./Benefits";
import { useDispatch } from "react-redux";
import { accountActions } from "../../Store/Accounts/accountAction";
import { useHistory } from "react-router";
import { RoutesObj } from "../../Routers";

function QrCodeMenu() {
  // const dispatch = useDispatch();
  // const history = useHistory();
  // useEffect(() => {
  //   dispatch(accountActions.onSubmit("faizan", "Tp@12345")).then((data) => {
  //     if (data?.access) {
  //       history.push(RoutesObj.DashboardHome.path);
  //     }
  //     alert(data?.access);
  //   });
  // }, []);
  return (
    <>
      <HeaderFive />
      <CommonHero title="Qr Code Menu" />
      <ShowCaseFood />
      <Branding />
      <SaveFavorites />
      <SeamlessPayment />
      <DiffrentWays />
      <Benefits />
      <FooterOne />
    </>
  );
}

export default QrCodeMenu;
