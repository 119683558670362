import React, { useEffect, useState } from "react";
import TemplatePreview from "./TemplatePreview";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { menuActions } from "../../../../Store/Menu/menuAction";
import Loader from "../../../Common/Loader";
import MenuTemplateheader from "./MenuTemplateheader";
import MenuNav from "./MenuNav";
import Menuheader from "./Menuheader";
const templateDetails = [
  {
    id: 1,
    title: "Cafe Shop",
    img: "https://images.pexels.com/photos/1624487/pexels-photo-1624487.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    date: "20 JAN 2022",
    category: 1,
    number: "ID 234335",
  },
  {
    id: 2,
    title: "Korean BBQ Beef",
    img: "https://images.pexels.com/photos/842571/pexels-photo-842571.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    date: "20 JAN 2022",
    category: 2,
    number: "ID 234335",
  },
  {
    id: 3,
    title: "BBQ Beef",
    img: "https://images.pexels.com/photos/1624487/pexels-photo-1624487.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    date: "20 JAN 2022",
    category: 1,
    number: "ID 234335",
  },
  {
    id: 4,
    title: "Beef",
    img: "https://images.pexels.com/photos/1633525/pexels-photo-1633525.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    date: "20 JAN 2022",
    category: 3,
    number: "ID 234335",
  },
];

const MenuTemplates = () => {
  const [tempcategories, setTempCategories] = useState([]);
  const [temptItems, setTempItems] = useState(templateDetails);
  const [backuptemptItems, setBackupTempItems] = useState(templateDetails);
  const [curentcategory, setCurrentCategory] = useState(-1);
  const [activeItem, setActiveItem] = useState(temptItems[0]);
  const [previewActive, setPreviewActive] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const { searchTheme } = useSelector((state) => state.MenuReducer);

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    dispatch(menuActions.onGetTheme("business")).then((res) => {
      if (res?.results) {
        setTempItems(res?.results);
        setBackupTempItems(res?.results);
      }
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (searchTheme === "") {
      setTempItems(backuptemptItems);
    } else {
      setTempItems(
        backuptemptItems.filter((x) =>
          x.name.toLowerCase().includes(searchTheme.toLowerCase())
        )
      );
    }
  }, [searchTheme]);

  useEffect(() => {
    dispatch(menuActions.onGetMenuThemesCategory("business")).then((res) => {
      if (res?.results) {
        setTempCategories(res?.results);
      }
    });
  }, []);

  const makeItemActive = (selectedItem) => {
    setActiveItem(selectedItem);
  };
  const makeCategoryActive = (cateogry_id) => {
    setCurrentCategory(cateogry_id);
    if (cateogry_id === -1) {
      setTempItems(backuptemptItems);
    } else {
      setTempItems(
        backuptemptItems.filter((items) => items.category === cateogry_id)
      );
    }
  };

  const makeItemPreview = (selectedItem) => {
    setActiveItem(selectedItem);
    setPreviewActive(!previewActive);
  };

  return (
    <>
      {isLoading ? <Loader /> : null}
      {previewActive ? (
        <TemplatePreview
          toggle={previewActive}
          template={activeItem}
          closePreview={setPreviewActive}
          templateId={activeItem?.id}
        />
      ) : (
        <></>
      )}
      <div class={` ${isLoading ? "hide-custom" : "show"}`}>
        <Menuheader />
        <MenuNav />
        <MenuTemplateheader />
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <ul className="template-filter-menu">
                <li>
                  <button
                    className={`${
                      curentcategory === -1
                        ? "active-template-filter template-filter"
                        : "template-filter"
                    }`}
                    onClick={() => makeCategoryActive(-1)}
                  >
                    All
                  </button>
                </li>
                {tempcategories.map((item) => (
                  <>
                    <li>
                      <button
                        className={`${
                          curentcategory === item.id
                            ? "active-template-filter template-filter"
                            : "template-filter"
                        }`}
                        onClick={() => makeCategoryActive(item.id)}
                      >
                        {item.name}
                      </button>
                    </li>
                  </>
                ))}
              </ul>
            </div>
          </div>
          <div className="row">
            {temptItems.map((item) => (
              <>
                <div className="col-md-3" style={{ flex: "0 0 23%" }}>
                  <div
                    className={`${
                      activeItem.id === item.id
                        ? "active-item-template template-area-block "
                        : "template-area-block"
                    }`}
                    onClick={() => makeItemActive(item)}
                  >
                    <div className="template-image">
                      <img src={item?.home_background_image} />
                    </div>
                    <div className="template-details">
                      <div className="template-content">
                        <p>{item?.name}</p>
                        <p>
                          {item?.id} - {"20 JAN 2022"}
                        </p>
                      </div>
                      <div className="template-buttons">
                        <ul className="dashbaord-themplate-button">
                          <li>
                            <button
                              className="themplate-btn"
                              onClick={() => {
                                history.push(`edit-cat-template?id=${item.id}`);
                              }}
                            >
                              Import
                            </button>
                          </li>
                          <li>
                            <button
                              className="themplate-btn"
                              onClick={() => makeItemPreview(item)}
                            >
                              <i class="fa fa-eye" aria-hidden="true"></i>
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>{" "}
              </>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
export default MenuTemplates;
