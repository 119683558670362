import React from "react";
import deliveryicon from "../../../assets/images/dashboard/deliveryicon.png";
import dineinicon from "../../../assets/images/dashboard/dineinicon.png";
import giveaway from "../../../assets/images/qrscan/giveaway.png";
import cash from "../../../assets/images/qrscan/cash.png";

function getStatus(status) {
  switch (status) {
    case "giveaway":
      return (
        <span style={{ color: "green" }}>
          <img alt="img" src={giveaway} className="pl-2" />
          Give Away
        </span>
      );
    case "cash":
      return (
        <span style={{ color: "green" }}>
          <img alt="img" src={cash} className="pl-2" />
          Cash
        </span>
      );
    case "dine-in":
      return (
        <span style={{ color: "green" }}>
          <img alt="img" src={dineinicon} className="pl-2" /> Dine-In
        </span>
      );
    case "Paid":
      return <span style={{ color: "green" }}>Paid</span>;
    case "paid":
      return <span style={{ color: "green" }}>Paid</span>;
    case "accepted":
      return <span style={{ color: "green" }}>Accepted</span>;
    case "rejected":
      return <span style={{ color: "red" }}>Rejected</span>;
    case "success":
      return <span style={{ color: "green" }}>Paid</span>;
    case "completed":
      return <span style={{ color: "green" }}>Success</span>;
    case "delivery":
      return (
        <span style={{ color: "#F9A05F" }}>
          <img alt="img" src={deliveryicon} className="pl-2" />
          Delivery
        </span>
      );
    case "decline":
      return <span style={{ color: "red" }}>Decline</span>;
    case "pending":
      return <span style={{ color: "#F9A05F" }}>Pending</span>;
    case "cancel":
      return <span style={{ color: "red" }}>Cancel</span>;
    default:
      return status;
  }
}

function getServices(service) {
  return <span className="service">{service}</span>;
}

function toCamelCase(str) {
  return str
    .split(" ")
    .map((word, index) => {
      if (index === 0) {
        return word.toLowerCase();
      }
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join("");
}

function toTitleCase(str) {
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
}

const formatDate = (dateString) => {
  let dateTime = new Date(dateString);

  let options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    timeZone: "UTC",
  };

  let humanReadableDateTime = dateTime.toLocaleString("en-US", options);

  return humanReadableDateTime;
};

const getTime = (timeString) => {
  const [hour, minute] = timeString.split(":").map(Number);
  const period = hour < 12 ? "AM" : "PM";

  let hourIn12Hour = hour % 12;
  if (hourIn12Hour === 0) {
    hourIn12Hour = 12;
  }

  const timeIn12Hour = `${hourIn12Hour}:${
    minute < 10 ? "0" + minute : minute
  } ${period}`;

  return timeIn12Hour;
};

const getFormatedDate = (timeString) => {
  const [date, time] = formatDate(timeString).split(" at ");

  return `${date}`;
};

const getFormatedTime = (timeString) => {
  const [date, time] = formatDate(timeString).split(" at ");

  return `${time}`;
};

export {
  getStatus,
  getServices,
  toCamelCase,
  toTitleCase,
  formatDate,
  getTime,
  getFormatedDate,
  getFormatedTime,
};
