import React, { useState } from "react";
import DropZone from "./DropZone";
import Switch from "react-switch";
import ColorPicker from "./ColorPicker";
import ColorPickerFont from "./ColorPickerFont";
import ColorPickerIcon from "./ColorPickerIcon";

import Screen1 from "../previewscreenscat/Screen1";
import { useDispatch, useSelector } from "react-redux";
import { menuActions } from "../../../../Store/Menu/menuAction";
function MenuHomePage({ templeteDetail }) {
  const { menuData } = useSelector((state) => state.MenuReducer);
  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="form-area-menu">
              <div className="form-group-yawaevig">
                <label>Home Page Background Image</label>
                {menuData?.home_background_image &&
                !menuData?.home_background_image.includes("blob") ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <img
                      className="mouse-pointer"
                      style={{
                        width: "200px",
                        height: "200px",
                      }}
                      src={menuData?.home_background_image}
                    />
                  </div>
                ) : (
                  <DropZone
                    imag={(file, img) => {
                      dispatch(
                        menuActions.onChange("menuData", {
                          ...menuData,
                          home_background_image: img,
                        })
                      );
                      dispatch(
                        menuActions.onChange("menuData", {
                          ...menuData,
                          home_background_image_file: file,
                        })
                      );
                    }}
                    files={files}
                    setFiles={(e) => {
                      setFiles(e);
                    }}
                  />
                )}

                <span
                  style={{
                    color: "red",
                    alignSelf: "center",
                    cursor: "pointer",
                    fontWeight: "bold",
                  }}
                  onClick={() => {
                    setFiles([]);
                    dispatch(
                      menuActions.onChange("menuData", {
                        ...menuData,
                        home_background_image: "",
                      })
                    );
                  }}
                >
                  Remove
                </span>
              </div>
              <div className="form-group-grid">
                <label>Display logo on home page</label>
                <div className="form-right">
                  <Switch
                    onChange={() => {
                      dispatch(
                        menuActions.onChange("menuData", {
                          ...menuData,
                          home_display_logo: !menuData?.home_display_logo,
                        })
                      );
                    }}
                    checked={menuData?.home_display_logo}
                  />
                </div>
              </div>
              <div className="form-group-grid">
                <label>Display Image for home page background</label>
                <div className="form-right">
                  <Switch
                    onChange={() => {
                      dispatch(
                        menuActions.onChange("menuData", {
                          ...menuData,
                          home_display_image_background:
                            !menuData?.home_display_image_background,
                        })
                      );
                    }}
                    checked={menuData?.home_display_image_background}
                  />
                </div>
              </div>

              <div className="form-group-grid">
                <label>Background Color</label>
                <div className="form-right">
                  <div className="color-picker-bk">
                    <ColorPicker
                      color={menuData?.home_background_color}
                      setColor={(e) => {
                        dispatch(
                          menuActions.onChange("menuData", {
                            ...menuData,
                            home_background_color: e,
                          })
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
              {/* <div className="form-group-grid">
                <label>Restaurant Name Color</label>
                <div className="form-right">
                  <div className="color-picker-font">
                    <ColorPickerFont
                      color={menuData?.home_restaurant_name_color}
                      setColor={(e) => {
                        dispatch(
                          menuActions.onChange("menuData", {
                            ...menuData,
                            home_restaurant_name_color: e,
                          })
                        );
                      }}
                    />
                  </div>
                </div>
              </div> */}
              <div className="form-group-grid">
                <label>Main Headings Color</label>
                <div className="form-right">
                  <div className="color-picker-font">
                    <ColorPickerFont
                      color={menuData?.home_main_heading_color}
                      setColor={(e) => {
                        dispatch(
                          menuActions.onChange("menuData", {
                            ...menuData,
                            home_main_heading_color: e,
                          })
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group-grid">
                <label>Sub Headings Color</label>
                <div className="form-right">
                  <div className="color-picker-font">
                    <ColorPickerFont
                      color={menuData?.home_sub_heading_color}
                      setColor={(e) => {
                        dispatch(
                          menuActions.onChange("menuData", {
                            ...menuData,
                            home_sub_heading_color: e,
                          })
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group-grid">
                <label>Text Color</label>
                <div className="form-right">
                  <div className="color-picker-font">
                    <ColorPickerFont
                      color={menuData?.home_text_color}
                      setColor={(e) => {
                        dispatch(
                          menuActions.onChange("menuData", {
                            ...menuData,
                            home_text_color: e,
                          })
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group-grid">
                <label>Walk-In Button</label>
                <div className="form-right">
                  <div className="color-picker-bk">
                    <ColorPicker
                      color={menuData?.home_dine_in_btn_color}
                      setColor={(e) => {
                        dispatch(
                          menuActions.onChange("menuData", {
                            ...menuData,
                            home_dine_in_btn_color: e,
                          })
                        );
                      }}
                    />
                  </div>
                  <div className="color-picker-font">
                    <ColorPickerFont
                      color={menuData?.home_dine_in_text_color}
                      setColor={(e) => {
                        dispatch(
                          menuActions.onChange("menuData", {
                            ...menuData,
                            home_dine_in_text_color: e,
                          })
                        );
                      }}
                    />
                  </div>
                  <div className="color-picker-icon">
                    <ColorPickerIcon
                      btntype="dinein"
                      color={menuData?.home_dine_in_logo_color}
                      setColor={(e) => {
                        dispatch(
                          menuActions.onChange("menuData", {
                            ...menuData,
                            home_dine_in_logo_color: e,
                          })
                        );
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="form-group-grid">
                <label>Call-Out Button</label>
                <div className="form-right">
                  <div className="color-picker-bk">
                    <ColorPicker
                      color={menuData?.home_delivery_in_btn_color}
                      setColor={(e) => {
                        dispatch(
                          menuActions.onChange("menuData", {
                            ...menuData,
                            home_delivery_in_btn_color: e,
                          })
                        );
                      }}
                    />
                  </div>
                  <div className="color-picker-font">
                    <ColorPickerFont
                      color={menuData?.home_delivery_in_text_color}
                      setColor={(e) => {
                        dispatch(
                          menuActions.onChange("menuData", {
                            ...menuData,
                            home_delivery_in_text_color: e,
                          })
                        );
                      }}
                    />
                  </div>
                  <div className="color-picker-icon">
                    <ColorPickerIcon
                      btntype="delivery"
                      color={menuData?.home_delivery_in_logo_color}
                      setColor={(e) => {
                        dispatch(
                          menuActions.onChange("menuData", {
                            ...menuData,
                            home_delivery_in_logo_color: e,
                          })
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="screen-area inner-screen-area">
              <Screen1 />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MenuHomePage;
