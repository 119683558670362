const data = [
  {
    id: Math.random(),
    title: "QR Code Menu",
    link: "/qr-code-menu",
    submenu: false,
  },
  {
    id: Math.random(),
    title: "Scan to Pay",
    link: "/scan-to-pay",
    submenu: false,
  },
  {
    id: Math.random(),
    title: "Book Appointment",
    link: "/book-appointment",
    submenu: false,
  },

  {
    id: Math.random(),
    title: "More",
    link: "/#",
    submenu: true,
    items: [
     /* {
        id: `${Math.random()}sub`,
        title: "Contact us",
        link: "/contact",
      },
      {
        id: `${Math.random()}sub`,
        title: "Privacy Policy",
        link: "/privacy-policy",
      }, */
      {
        id: `${Math.random()}sub`,
        title: "Terms & Conditions",
        link: "/terms-and-conditions",
      },
    ],
  }
];
export default data;
