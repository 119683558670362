import React, { useState } from "react";
import Header from "./nav/Header";
import NavHader from "./nav/NavHader";
import Sidebar from "./nav/SideBar";
import Support from "./Support";
import "../../../assets/admin//vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "../../../assets/admin/css/style.css";
import "bootstrap/dist/css/bootstrap.min.css";

const DeshboardLayout = () => {
  const [toggle, setToggle] = useState("");
  const onClick = (name) => setToggle(toggle === name ? "" : name);
  return (
    <>
      <Support onClick={() => onClick("supportbox")} toggle={toggle} />
      <NavHader />
      <Header onSupport={() => onClick("supportbox")} />
      <Sidebar />
    </>
  );
};
export default DeshboardLayout;
