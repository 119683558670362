import React, { useState } from "react";
import { Link } from "react-router-dom";
import pizza from "../../../assets/images/qrscan/pizza.png";
import giveaway from "../../../assets/images/qrscan/giveaway.png";
import cash from "../../../assets/images/qrscan/cash.png";
import "./qrstyle.css";

function Tip() {
  const [numberProduct, setNumberProduct] = useState("10%");
  const handleCartplus = () => {
    setNumberProduct(numberProduct + 1);
  };
  const handleCartMinus = () => {
    if (numberProduct > 0) {
      setNumberProduct(numberProduct - 1);
    }
  };

  return (
    <>
      <div className="row pt-5 black-bg px-2">
        <div className="col-md-9 col-3">
          <Link to="restaurant-menu-homepage">
            <i class="fa fa-chevron-left orange-text"></i>
          </Link>
        </div>
        <div className="col-6 text-center">
          <p className="text-white">Checkout</p>
        </div>
        <div className="col-md-3 col-3 d-flex text-right"></div>
      </div>

      <div className="row p-2">
        <div className="col-2 text-center p-0">
          <img src={pizza} alt="img" className="card-img img-responsive" />
        </div>
        <div className="col-8">
          <p className="cad-title">Cheese Pizza</p>
          <p className="dim-text mb-4">
            Onion, Capsicum, Corn, Cheese ( 100gm ), Spanish tomato, Coriander
          </p>
          <div className="cart-qualitity-btn">
            <table>
              <tr>
                <td>
                  <button
                    className="preview-quality-btn"
                    onClick={handleCartMinus}
                  >
                    -
                  </button>
                </td>
                <td>
                  <span className="preview-quality-number">
                    {numberProduct}
                  </span>
                </td>
                <td>
                  <button
                    className="preview-quality-btn"
                    onClick={handleCartplus}
                  >
                    +
                  </button>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div className="col-2 text-right">
          {/* <p><i className="far fa-heart"></i></p> */}
          <p className="font-weight-bold orange-text">₦75.12</p>
        </div>
      </div>

      <div className="col-12">
        <h3 className="mobile-heading">Add a Tip</h3>
        <span className="tip-amoun orange-bg">10%</span>{" "}
        <span className="tip-amoun">15%</span>{" "}
        <span className="tip-amoun">20%</span>{" "}
        <span className="tip-amoun">None</span>
      </div>

      <div className="col-12 mt-4">
        <h3 className="mobile-heading">Payment Summary</h3>
      </div>

      <div className="col-12">
        <table className="table table-borderless">
          <tr>
            <td>Basket Total</td>
            <td className="text-right">₦75.12</td>
          </tr>
          <tr>
            <td className="text-danger">Discount</td>
            <td className="text-danger text-right">-₦10.12</td>
          </tr>
          <tr>
            <td>Service Fee</td>
            <td className="text-right">₦5.00</td>
          </tr>
          <tr>
            <td className="font-weight-bold">Total Amount</td>
            <td className="text-right">₦70.12</td>
          </tr>
        </table>
      </div>

      <div className="col-12 mt-4">
        <h3 className="mobile-heading">Pay With</h3>
        <p>
          <input type="radio" name="paywith" value="giveawayapp" />{" "}
          <span>
            {" "}
            <img src={giveaway} alt="logo" /> Giveaway App
          </span>
        </p>
        <p>
          <input type="radio" name="paywith" value="giveawayapp" />{" "}
          <span>
            {" "}
            <img src={cash} alt="cash" /> Cash
          </span>
        </p>
      </div>

      <div className="checkout-cart-area">
        <div className="row">
          <div className="col-12 text-center">
            <div className="text-center">
              <button className="preview-button-theme" disabled>
                <Link to="/pay">Place an Order</Link>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Tip;
