import QRCode from "react-qr-code";
import "./qrcode.css";
import { useState } from "react";
import html2canvas from "html2canvas";
import { useEffect } from "react";
import { resturantActions } from "../../../Store/Resturant/resturantAction";
import { useDispatch } from "react-redux";
import Loader from "../../Common/Loader";
import { toast } from "react-toastify";
import { Link } from "@material-ui/core";

const MainQr = () => {
  const [venu_name, setVenuName] = useState("");
  const [qrvenue, setQrvenue] = useState("");
  const [qrLink, setQrLink] = useState("");
  const [qrCodeLink, setQrCodeLink] = useState("");
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  let subscription = JSON.parse(localStorage.getItem("subscription"));
  // const link = "https://yawaevig.com/" + qrvenue;
  const base_link = `https://${window.location.hostname}`;
  let getType = localStorage.getItem("type");
  let typeId = 0;

  if (getType === "restaurant") {
    typeId = localStorage.getItem("res");
  } else {
    typeId = localStorage.getItem("bus");
  }
  useEffect(() => {
    if (localStorage.getItem("venu_name")) {
      let temp = localStorage.getItem("venu_name");
      setVenuName(temp);
    }
    loadQRCode();
  }, []);

  const loadQRCode = () => {
    // setLoading(true);
    let id = "0";
    let res = localStorage.getItem("res");
    let bus = localStorage.getItem("bus");
    if (res) {
      id = res;
    }
    if (bus) {
      id = bus;
    }
    if (id !== "0") {
      // let data = { venu_name: qrvenue };
      dispatch(resturantActions.onGetRestaurants(id)).then((data) => {
        if (data?.id) {
          if (data?.venu_name) {
            localStorage.setItem("venu_name", data?.venu_name);
            setVenuName(data?.venu_name);
            let temp_link = base_link + data.venu_name;
            setQrvenue(temp_link);
            setQrLink(temp_link);
            setQrCodeLink(temp_link);
          }
        }
        setLoading(false);
      });
      // dispatch(resturantActions.onGetQRCode(id)).then((data) => {
      //   if (data?.id) {
      //     setQrLink(data?.qr_data);
      //     setQrvenue(data?.qr_data);
      //     if (data?.qr_code_link) {
      //       setQrCodeLink(data?.qr_data);
      //     }
      //   }
      //   setLoading(false);
      // });
    } else {
      setLoading(false);
    }
  };

  const generateQRCode = () => {
    setLoading(true);
    var data = {
      "restaurant[venu_name]": `${venu_name}`,
    };
    // var data = {
    //   link:
    //     getType === "restaurant"
    //       ? `https://yawaevig.com/restaurant-menu-homepage?res=${typeId}`
    //       : `https://yawaevig.com/business-menu-homepage?bus=${typeId}`,
    // };
    // if (qrCodeLink) {
    //   dispatch(resturantActions.onUpdateQRCode(data, id)).then((res) => {
    //     if (res?.id) {
    //       setQrLink(res?.qr_data);
    //       let imag = res?.qr_code_link.includes("http");
    //       setQrCodeLink(
    //         imag ? res?.qr_code_link : rootURL + res?.qr_code_link
    //       );
    //     }
    //     setLoading(false);
    //   });
    // } else {
    // dispatch(resturantActions.onGenerateQRCode(data)).then((res) => {
    //   if (res?.id) {
    //     setQrLink(res?.qr_data);
    //     let imag = res?.qr_code_link.includes("https");
    //     setQrCodeLink(imag ? res?.qr_data : res?.qr_data);
    //     toast.success("QR Generated");
    //   }
    //   setLoading(false);
    // });
    dispatch(resturantActions.onUpdateRestaurant(data)).then((data) => {
      if (data?.id) {
        if (data?.venu_name) {
          let temp_link = "";
          if (getType === "restaurant") {
            temp_link = `${base_link}/restaurant/${data.venu_name}`;
          } else {
            temp_link = `${base_link}/business/${data.venu_name}`;
          }
          setQrvenue(temp_link);
          setQrLink(temp_link);
          setQrCodeLink(temp_link);
        }
      }
      setLoading(false);
    });
    //}
  };

  const copyToClipboard = (e) => {
    if (getType === "restaurant") {
      navigator.clipboard.writeText(
        `${base_link}/restaurant/${venu_name}`
      );
    } else {
      navigator.clipboard.writeText(
        `${base_link}/business/${venu_name}`
      );
    }
  };

  const DownloadQr = (e) => {
    html2canvas(document.getElementById("qrcode")).then(function (canvas) {
      // document.body.appendChild(canvas);
      var a = document.createElement("a");
      a.href = canvas
        .toDataURL("..assets/image/jpeg")
        .replace("image/jpeg", "image/octet-stream");
      a.download = "QRCode.jpeg";
      a.click();
    });
  };
  return (
    <>
      {isLoading ? <Loader /> : null}
      {subscription !== null && subscription?.plan !== "trial" ? (
        <div className="bg-white m-2 p-4 rounded-lg">
          <h2 class="heading">QR Code</h2>
          <p>
            This where you can set you venue URL & download your QR that your
            customers can scan
          </p>

          {/* Form */}
          <div className="row">
            <div className="col-md-8 col-xs-12">
              <div>
                <label className="qrlabel">Venue URL *</label>
                <input
                  type="text"
                  value={venu_name}
                  onChange={(e) => {
                    let temp = e.target.value;
                    temp = temp.replace(" ", "-");
                    setVenuName(temp);
                  }}
                  name="qrvenue"
                  className="form-control qr-input"
                  placeholder="enter link"
                  required
                />
              </div>

              <div className="py-3">
                <p className="dim-text">
                  This will be part of the URL that your customers use to access
                  your Venue. It has to be unique and must be formatted as
                  letters. digits. ‘’-” or “_”. and with no spaces
                </p>
                <>
                  <p>
                    This where you can set you venue URL & download your QR that
                    your customers can scan
                  </p>
                  <p className="font-weight-bolder qr-heading-text">
                    <span id="linktext">
                      {getType === "restaurant"
                        ? `${base_link}/restaurant/${venu_name}`
                        : `${base_link}/business/${venu_name}`}
                    </span>
                  </p>
                  <div
                    className="btn btn-primary btn-copy"
                    id="copydiv"
                    onClick={copyToClipboard}
                  >
                    {" "}
                    Copy{" "}
                  </div>
                </>
                {/* {setCopied==true ? "Copied" : "Copy"} */}
              </div>
            </div>
            {qrCodeLink && (
              <div className="col-md-4 p-4 col-xs-12">
                <div id="qrcode" className="p-2">
                  <QRCode className="qr-mob" value={qrCodeLink} />
                </div>
                <button
                  id="button"
                  onClick={DownloadQr}
                  class="btn btn-primary mt-3"
                >
                  Download QR Code
                </button>
              </div>
            )}
            <div className="col-md-8">
              <button
                type="submit"
                class="btn btn-primary btn-block"
                onClick={() => {
                  generateQRCode();
                }}
              >
                Generate QR Code
              </button>
            </div>
          </div>
          {/* end of form */}
        </div>
      ) : (
        <div>
          {" "}
          <h2 class="heading">QR Code</h2>
          <p
            className="text-center"
            style={{ marginLeft: "20px", fontSize: "18px" }}
          >
            To access QR Feature, Please subscribe to{" "}
            <Link to={`/subscription`}>Package</Link>.
          </p>
        </div>
      )}
    </>
  );
};

export default MainQr;
