import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dineinicon from "../../../assets/images/dashboard/dineinicon.png";
import "./qrstyle.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Loader from "../../Common/Loader";
import { resturantActions } from "../../../Store/Resturant/resturantAction";
import { catalogActions } from "../../../Store/Catalogs/catalogAction";
import { menuActions } from "../../../Store/Menu/menuAction";
import { MdDirectionsWalk } from "react-icons/md";
import { useParams } from "react-router-dom";

function BusinessHomePage() {
  const { slug } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [facebook, setFacebook] = useState("");
  const [instgram, setInstgram] = useState("");
  const [temptItems, setTempItems] = useState([]);
  const { menuData } = useSelector((state) => state.MenuReducer);
  const [branchId, setBranchId] = useState(0);
  const [bus, setBus] = useState(null);
  // var bus = parseInt(window.location.href.split("?bus=")[1]);
  var st = parseInt(
    window.location.href.split("&st=")[1]
      ? window.location.href.split("&st=")[1]
      : 0
  );
  useEffect(() => {
    setLoading(true);
    dispatch(
      resturantActions.onGetRestaurantsByVenu(slug.split("&st=")[0])
    ).then((res) => {
      if (res?.id) {
        setBus(res?.id);
        setTempItems(res);
        getMenu(res?.id);
        setBranchId(res?.branch?.id);
        setFacebook(
          res?.social_media.filter((x) => x.name === "facebook")[0]?.link
        );
        setInstgram(
          res?.social_media.filter((x) => x.name === "instagram")[0]?.link
        );
      }
      setLoading(false);
    });
  }, []);

  const getMenu = (buss) => {
    setLoading(true);

    dispatch(catalogActions.onGetDefaultBusiness(buss)).then((res) => {
      if (res?.id) {
        const data = {
          name: res?.name,
          description: res?.description,
          menu_type: res?.menu_type,
          home_background_image: res?.home_background_image,
          home_display_logo: res?.home_display_logo,
          home_display_image_background: res?.home_display_image_background,
          home_background_image_file: null,
          home_background_color: res?.home_background_color,
          home_restaurant_name_color: res?.home_restaurant_name_color,
          home_main_heading_color: res?.home_main_heading_color,
          home_sub_heading_color: res?.home_sub_heading_color,
          home_text_color: res?.home_text_color,
          home_dine_in_btn_color: res?.home_dine_in_btn_color,
          home_dine_in_text_color: res?.home_dine_in_text_color,
          home_dine_in_logo_color: res?.home_dine_in_logo_color,
          home_delivery_in_btn_color: res?.home_delivery_in_btn_color,
          home_delivery_in_text_color: res?.home_delivery_in_text_color,
          home_delivery_in_logo_color: res?.home_delivery_in_logo_color,
          menu_header_background_color: res?.menu_header_background_color,
          menu_header_text: res?.menu_header_text,
          menu_product_backgroud_color: res?.menu_product_backgroud_color,
          menu_product_text: res?.menu_product_text,
          menu_product_price: res?.menu_product_price,
          menu_product_icons: res?.menu_product_icons,
          menu_cart_background: res?.menu_cart_background,
          menu_cart_text_color: res?.menu_cart_text_color,
          menu_cart_price_color: res?.menu_cart_price_color,
          menu_category_tab_text_color: res?.menu_category_tab_text_color,
          menu_category_tab_color: res?.menu_category_tab_color,
          menu_delivery_text_background: res?.menu_delivery_text_background,
          menu_delivery_btn_color: res?.menu_delivery_btn_color,
          menu_delivery_icon_color: res?.menu_delivery_icon_color,
          item_product_background_color: res?.item_product_background_color,
          item_product_text_color: res?.item_product_text_color,
          item_product_price: res?.item_product_price,
          item_tip_text_color: res?.item_tip_text_color,
          item_tip_btn_color: res?.item_tip_btn_color,
          item_cart_text_color: res?.item_cart_text_color,
          item_cart_btn_color: res?.item_cart_btn_color,
          item_place_order_text_color: res?.item_place_order_text_color,
          item_place_order_btn_color: res?.item_place_order_btn_color,
          popup_background_color: res?.popup_background_color,
          popup_text_color: res?.popup_text_color,
          popup_product_bg_color: res?.popup_product_bg_color,
          popup_product_text_color: res?.popup_product_text_color,
          popup_cart_btn_color: res?.popup_cart_btn_color,
          popup_cart_text_color: res?.popup_cart_text_color,
        };
        dispatch(menuActions.onChange("menuData", data));
      }
      setLoading(false);
    });
  };

  const onCreateWalkInButtonPress = async () => {
    history.push(
      `/business-menu-professional?bus=${bus}&st=${st}&ordertype=121&na=${
        slug.split("&st=")[0]
      }`
    );
  };

  const onCreateCallOutButtonPress = async () => {
    history.push(
      `/business-menu-professional?bus=${bus}&st=${st}&ordertype=122&na=${
        slug.split("&st=")[0]
      }`
    );
  };

  return (
    <>
      {isLoading ? <Loader /> : null}
      <div
        className="qr-mobile-screen"
        style={{ backgroundColor: `${menuData?.home_background_color}` }}
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 p-0">
              <div
                className="head-img"
                style={{ backgroundImage: `url(${temptItems.featured})` }}
              >
                {/* <img
                  src={temptItems.featured}
                  className="topimg"
                  style={{ width: "100%" }}
                /> */}
              </div>
              <div className="header2">
                <div className="qr-logo-main">
                  <img src={temptItems.logo} className="" />
                </div>
                <div className="qr-social-media icon-social">
                  <div className="social-media-icons">
                    <ul>
                      {facebook !== "" && (
                        <li>
                          <i
                            class="fab fa-facebook-f"
                            onClick={() => {
                              if (
                                temptItems?.social_media.filter(
                                  (x) => x.name === "facebook"
                                )[0]?.link
                              ) {
                                window.open(
                                  temptItems?.social_media.filter(
                                    (x) => x.name === "facebook"
                                  )[0]?.link
                                );
                              }
                            }}
                          ></i>
                        </li>
                      )}
                      {instgram !== "" && (
                        <li>
                          <i
                            class="fab fa-instagram"
                            onClick={() => {
                              if (
                                temptItems?.social_media.filter(
                                  (x) => x.name === "instagram"
                                )[0]?.link
                              ) {
                                window.open(
                                  temptItems?.social_media.filter(
                                    (x) => x.name === "instagram"
                                  )[0]?.link
                                );
                              }
                            }}
                          ></i>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {/* <div className="col-md-7">
					<div className="qr-scan-title">
						<h3>Burger & Pizza kitchen</h3>
					</div>
					<div className="qr-scan-location">
						<span>
						<i class="fa fa-map-marker" aria-hidden="true"></i> </span>
						<span className='preview-address'>
						1001 Street, Nigera
						</span>
					</div>						  
				</div>
					  <div className="col-md-5">
						  <span>Open</span>
				</div> */}

            <div className="inline-content">
              <table width={"100%"}>
                <tr>
                  <td width={"80%"}>
                    <div
                      className="qr-scan-title mt-2"
                      style={{ color: `${menuData?.home_main_heading_color}` }}
                    >
                      <h4>{temptItems?.name}</h4>
                      <div className="qr-scan-location p-0">
                        <span className="orange-text">
                          <i class="fas fa-map-marker-alt"></i>{" "}
                        </span>
                        <span className="preview-address">
                          {temptItems?.branch?.address}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td align="right" valign="top">
                    {temptItems?.branch?.open ? (
                      <span className="hour-btn open-hour">OPEN</span>
                    ) : (
                      <span className="hour-btn close-hour">CLOSE</span>
                    )}
                  </td>
                </tr>
              </table>

              <div className="">
                {/* <p className="green-text">
                  <i className="far fa-scrubber"></i> Open
                </p> */}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="qr-scan-description">
              <h5 style={{ color: `${menuData?.home_sub_heading_color}` }}>
                Description
              </h5>
              <p style={{ color: `${menuData?.home_text_color}` }}>
                {temptItems?.description}
              </p>
            </div>
          </div>
          <div
            className="order-container"
            style={{ color: `${menuData?.home_sub_heading_color}` }}
          >
            <h6>Ordering</h6>
            <div className="qr-ordering-area">
              {/* <Link
                className="qr-scan-ordering-btn"
                to="restaurant-menu-categories"
              > */}
              <div
                className="qr-scan-ordering-btn"
                style={{
                  backgroundColor: menuData?.home_dine_in_btn_color,
                }}
                onClick={() => {
                  onCreateWalkInButtonPress();
                }}
              >
                <div className="left-btn-content">
                  {/* <img
                    src={dineinicon}
                    className="pl-2"
                    style={{ color: `${menuData?.home_dine_in_logo_color}` }}
                  /> */}
                  <MdDirectionsWalk
                    style={{
                      color: `${menuData?.home_dine_in_logo_color}`,
                      fontSize: "26px",
                      marginLeft: "10px",
                    }}
                  />
                  <span
                    style={{
                      paddingLeft: "10px",
                      color: `${menuData?.home_dine_in_text_color}`,
                    }}
                  >
                    Walk in
                  </span>
                </div>
                <div className="right-side-btn-content">
                  <i
                    class="fa fa fa-chevron-right pr-3"
                    style={{ color: `${menuData?.home_dine_in_logo_color}` }}
                  ></i>
                </div>
              </div>
              {/* </Link>
              <Link
                className="qr-scan-ordering-btn"
                to="restaurant-menu-categories"
              > */}
              {/* <div
                className="qr-scan-ordering-btn"
                onClick={() => {
                  onCreateCallOutButtonPress();
                }}
              >
                <div className="left-btn-content">
                  <img src={deliveryicon} className="pl-2" />
                  <span style={{ paddingLeft: "10px" }}>Out-Call</span>
                </div>
                <div className="right-side-btn-content">
                  <i class="fa fa fa-chevron-right pr-3"></i>
                </div>
              </div> */}
              {/* </Link> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BusinessHomePage;
