import { useState, useEffect } from "react";
import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import close from "../../../assets/images/close.jpg";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "background.paper",
  //   border: "2px solid #000",
  //   boxShadow: 24,
  //   p: 4,
};

const GuideModel = ({ url, onCloseCallback, open, setOpen }) => {
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [link, setLink] = useState(null);

  useEffect(() => {
    // setOpen(qr_model);
  }, []);

  useEffect(() => {
    // handleQRModel(open);
  }, [open]);

  return (
    <div>
      <Modal
        open={open}
        onClose={onCloseCallback}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-description"
            style={{ position: "relative" }}
          >
            <img src={url} width={"100%"} />
            <a onClick={onCloseCallback}>
              <img
                className="question-mark"
                src={close}
                style={{ position: "absolute", top: 5, right: 5 }}
              />
            </a>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default GuideModel;
