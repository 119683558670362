import React from "react";

function CommingSoon() {

  return (
    <>
     <section className="comming-soon-section">
        <div className="container">
            <div className="row">
                <div className="col-md-12 text-center">
                    <h1><span><i class="fa fa-spinner fa-spin"></i></span></h1>
                    <h1 className="comming-soon-title">Coming Soon</h1>
                </div>
            </div>
        </div>
     </section>
    </>
  );
}

export default CommingSoon;
