import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import { menuActions } from "../../../../Store/Menu/menuAction";

function AddSection({ setSetcionClose, selectedSection }) {
  const [show, setShow] = useState(true);
  const [name, setName] = useState(
    selectedSection?.name ? selectedSection.name : ""
  );
  const [discription, setDiscription] = useState(
    selectedSection?.description ? selectedSection.description : ""
  );
  const dispatch = useDispatch();
  var MenuId = parseInt(window.location.href.split("?id=")[1]);

  const handleClose = () => {
    setShow(false);
    setSetcionClose(false);
  };
  const addUpdateSection = () => {
    var data = {
      name: name,
      description: discription,
      active: true,
    };
    if (selectedSection?.id) {
      dispatch(
        menuActions.onUpdateSection(MenuId, data, selectedSection?.id)
      ).then((res) => {
        if (res) {
          handleClose();
        }
      });
    } else {
      dispatch(menuActions.onCreateSection(MenuId, data)).then((res) => {
        if (res) {
          handleClose();
        }
      });
    }
  };
  return (
    <>
      <div className="modalAreaCentered text-center">
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Add New Section</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-area">
              <div className="form-group-yawaevig">
                <lable>Name</lable>
                <input
                  type="text"
                  className="form-control input-filed"
                  placeholder="Add Section Name"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </div>
              <div className="form-group-yawaevig">
                <lable>Description</lable>
                <textarea
                  className="form-control input-filed"
                  placeholder="Add Description"
                  value={discription}
                  onChange={(e) => {
                    setDiscription(e.target.value);
                  }}
                ></textarea>
              </div>
            </div>
            <ul className="modal-buttons-list">
              <li>
                <button
                  onClick={handleClose}
                  className="btn-theme-secondary slim-button-theme "
                >
                  Cancel
                </button>
              </li>
              <li>
                <button
                  className="btn-theme-main slim-button-theme"
                  onClick={() => {
                    addUpdateSection();
                  }}
                >
                  Save Changes
                </button>
              </li>
            </ul>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

export default AddSection;
