import React, { useState } from "react";
import DeshboardLayout from "../layouts/DeshboardLayout";
import Dheader from "./dheader";
import Users from "./users";
import Roles from "./roles"

import "./user.css";

function UserManagement() {
  return (
		<>
		    <div id="main-wrapper" class="show">
		    <DeshboardLayout />
			 <div class="content-body">	
			 	<Dheader />
				<Users />

				<Roles />
			  </div>	
			 </div>
		</>
  );
}

export default UserManagement;
