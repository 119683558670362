import React, { useState } from "react";
import PreviewScreen from "./PreviewScreen";
import ColorPicker from "./ColorPicker";
import ColorPickerFont from "./ColorPickerFont";
import Screen3 from "../previewscreenscat/Screen3";
import { menuActions } from "../../../../Store/Menu/menuAction";
import { useDispatch, useSelector } from "react-redux";
function PopupPage({ templeteDetail }) {
  const { menuData } = useSelector((state) => state.MenuReducer);
  const dispatch = useDispatch();
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="form-area-menu">
              <div className="form-group-grid">
                <label>Popup Background Color</label>
                <div className="form-right">
                  <div className="color-picker-bk">
                    <ColorPicker
                      color={menuData?.popup_background_color}
                      setColor={(e) => {
                        dispatch(
                          menuActions.onChange("menuData", {
                            ...menuData,
                            popup_background_color: e,
                          })
                        );
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="form-group-grid">
                <label>Text Color</label>
                <div className="form-right">
                  <div className="color-picker-font">
                    <ColorPickerFont
                      color={menuData?.popup_text_color}
                      setColor={(e) => {
                        dispatch(
                          menuActions.onChange("menuData", {
                            ...menuData,
                            popup_text_color: e,
                          })
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group-grid">
                <label>Product Background</label>
                <div className="form-right">
                  <div className="color-picker-bk">
                    <ColorPicker
                      color={menuData?.popup_product_bg_color}
                      setColor={(e) => {
                        dispatch(
                          menuActions.onChange("menuData", {
                            ...menuData,
                            popup_product_bg_color: e,
                          })
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group-grid">
                <label>Product Text Color</label>
                <div className="form-right">
                  <div className="color-picker-font">
                    <ColorPickerFont
                      color={menuData?.popup_product_text_color}
                      setColor={(e) => {
                        dispatch(
                          menuActions.onChange("menuData", {
                            ...menuData,
                            popup_product_text_color: e,
                          })
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group-grid">
                <label>Cart Button</label>
                <div className="form-right">
                  <div className="color-picker-bk">
                    <ColorPicker
                      color={menuData?.popup_cart_btn_color}
                      setColor={(e) => {
                        dispatch(
                          menuActions.onChange("menuData", {
                            ...menuData,
                            popup_cart_btn_color: e,
                          })
                        );
                      }}
                    />
                  </div>
                  <div className="color-picker-font">
                    <ColorPickerFont
                      color={menuData?.popup_cart_text_color}
                      setColor={(e) => {
                        dispatch(
                          menuActions.onChange("menuData", {
                            ...menuData,
                            popup_cart_text_color: e,
                          })
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="screen-area inner-screen-area">
              <Screen3 />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PopupPage;
