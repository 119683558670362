import React from "react";
import DeshboardLayout from "../layouts/DeshboardLayout";
import General from "./general";

function Settings() {
  return (
    <>
      <div id="main-wrapper" className="show over-flow-block">
        <DeshboardLayout />
        <div className="content-body setting-mob">
          <General />
        </div>
      </div>
    </>
  );
}

export default Settings;
