import React from "react";
import ElectronicTipsAnimations from "./ElectronicTipsAnimations";

function ElectronicTips() {

  return (
    <section className="about-2-area about-5-area">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="about-5-content">
              <h3 className="title">Electronic TIPS</h3>
              <div className="text">
                <p>
                Your employees manage their own tips and withdraw tips themselves. You only need to activate a tip to an employee by number once. withdrawals can be made to any bank card.
                </p>
              </div>

            </div>
          </div>
          <div className="col-lg-6">
             <ElectronicTipsAnimations />
          </div>

        </div>
      </div>
    </section>
  );
}

export default ElectronicTips;
