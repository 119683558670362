import React, { useState } from "react";
import DeshboardLayout from "./layouts/DeshboardLayout";
import Dheader from "./main/Dheader";
import InfoBlocks from "./main/InfoBlocks";
import SalesChart from "./main/SalesChart";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { resturantActions } from "../../Store/Resturant/resturantAction";
import Loader from "../Common/Loader";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { useHistory } from "react-router-dom";
import { MdShoppingCartCheckout } from "react-icons/md";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid transparent",
  boxShadow: 24,
  p: 4,
  alignItem: "center",
  display: "flex",
  flexDirection: "column",
};

function Dashboard() {
  const [isLoading, setLoading] = useState(false);
  const [navToggle, setNavToggle] = useState(false);
  const [subscription, setSubscription] = useState(false);
  const [summary, setSummary] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    let id = "0";
    let res = localStorage.getItem("res");
    let bus = localStorage.getItem("bus");
    if (res) {
      id = res;
    }
    if (bus) {
      id = bus;
    }
    if (id !== "0") {
      setLoading(true);
      dispatch(resturantActions.onGetDashboardSummary(id)).then((res) => {
        setSummary(res);
        setLoading(false);
      });
    }
  }, []);

  useEffect(() => {
    dispatch(resturantActions.onGetLoggedUserDetail()).then((res) => {
      if (res?.id) {
        localStorage.setItem("subscription", JSON.stringify(res?.subscription));
        localStorage.setItem("setting", JSON.stringify(res?.setting));
        setSubscription(res?.subscription);
      }
    });
  }, []);
  useEffect(() => {
    const checkDeviceType = () => {
      // debugger;
      const isMobileDevice = window.matchMedia("(max-width: 768px)").matches;
      
      setNavToggle(isMobileDevice);
    };

    checkDeviceType();

    // Add event listener to detect device type changes on window resize
    window.addEventListener("resize", checkDeviceType);

    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", checkDeviceType);
    };
  }, []);

  return (
    <>
      {isLoading ? <Loader /> : null}
      <div
        id="main-wrapper"
        className={
          navToggle
            ? "show menu-toggle over-flow-block"
            : "show over-flow-block"
        }
      >
        <DeshboardLayout />
        <div
          className={`content-body dashboard-conainer ${
            isLoading ? "hide-custom" : "show"
          }`}
        >
          <Dheader />
          <div className="container">
            <div className="row">
              <div className="col-md-9">
                <InfoBlocks summary={summary} />
                <SalesChart salesGraph={summary} />
              </div>
              {/* <div className="col-md-4">
					<Alert />
					</div> */}
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={
          subscription === null || subscription?.plan === "trial" ? true : false
        }
        // onClose={() => closePopup()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <MdShoppingCartCheckout
            style={{ color: "#F9A05F", fontSize: "50px", alignSelf: "center" }}
          />
          <span
            style={{ color: "#00000", fontWeight: "bold", alignSelf: "center" }}
          >
            Online Booking Subscription
          </span>
          <span
            style={{
              color: "#AEAEB2",
              fontSize: "12px",
              alignSelf: "center",
            }}
          >
            Client are unable to book you at the moment.
          </span>
          <span
            style={{
              color: "#AEAEB2",
              fontSize: "12px",
              alignSelf: "center",
            }}
          >
            To enable online booking, Please subscribe to our services.
          </span>
          <button
            className="btn btn-primary"
            style={{ alignSelf: "center", marginTop: "20px" }}
            onClick={() => {
              history.push("/subscription");
            }}
          >
            Subscribe
          </button>
        </Box>
      </Modal>
    </>
  );
}

export default Dashboard;
