import "./trans.css";

const Dheader = ({ search, setSearch }) => {
  return (
    <>
      <div className="row mx-2">
        <div className="col-md-7">
          <h2 classsName="heading">Transaction</h2>
        </div>
        <div className="col-md-5 inline">
          <input
            type="email"
            className="form-control mr-3 searchbtn search"
            placeholder="Search by Order no."
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />

          <div className="mob-remove">
            <button className="btn btn-primary btn-filter">
              <i className="fa fa-filter"></i> Filter
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dheader;
