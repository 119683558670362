import React, { useEffect, useState } from "react";
import Switch from "react-switch";
import Loader from "../../Common/Loader";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { accountActions } from "../../../Store/Accounts/accountAction";
import { toast } from "react-toastify";
import { formatDate } from "../partials/status";

function Details() {
  const [checked, setChecked] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [packages, setPackages] = useState([]);
  const [transactionspackages, setTransactionsPackages] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  let subscription = JSON.parse(localStorage.getItem("subscription"));
  useEffect(() => {
    setLoading(true);
    dispatch(accountActions.onGetBusinessPackages()).then((data) => {
      setLoading(false);
      if (data?.length > 0) {
        setPackages(data);
      }
    });
  }, []);

  useEffect(() => {
    dispatch(accountActions.onGetTransactionsPackages()).then((data) => {
      setLoading(false);
      if (data?.results?.length > 0) {
        setTransactionsPackages(data?.results);
      }
    });
  }, []);
  return (
    <div className="row mb-2 mx-1 white-bg rounded">
      {isLoading ? <Loader /> : null}
      <div className="col-md-12 py-2 mt-2">
        <h2 className="heading">My Plan</h2>
        {subscription === null && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p
              style={{
                alignSelf: "center",
              }}
            >
              15 days unlimited free trial. No contract or credit card required
            </p>
            {/* <p
              className="btn btn-premium"
              style={{
                alignSelf: "center",
                color: "#F9A05F",
              }}
              onClick={() => {
                setLoading(true);
                dispatch(accountActions.onGetTrialPackages()).then((data) => {
                  setLoading(false);
                  if (data?.subscription_status === "active") {
                    toast.success("Your trial pacakge has been activated");
                    localStorage.setItem(
                      "subscription",
                      JSON.stringify({
                        active: data?.subscription_status,
                        plan_type: data?.plan_type,
                      })
                    );
                    subscription = { active: "active" };
                  }
                });
              }}
            >
              Activate Free Trial
            </p> */}
          </div>
        )}
      </div>

      {/* STANDARD SUBSCRIPTION CARD */}
      {packages?.map((item) => {
        return (
          <>
            {item?.package?.name === "Premium" ? (
              <div className="col-md-6 col-xs-12 my-2 py-1">
                <div className="mr-3 p-3 premium">
                  <div className="row">
                    <div className="col-md-7 col-xs-6">
                      <h2 className="heading py-0 my-0 text-white">
                        {item?.package?.name}
                      </h2>
                      <p className="dim-text">{item?.package?.tag_line}</p>
                    </div>

                    <div className="col-md-5 col-xs-6">
                      <p className="sub-premium-price">
                        <span className="text-white">
                          {item?.pakcage?.prefer_currency}{" "}
                          {item?.package?.price}
                        </span>{" "}
                        / per Month
                      </p>
                    </div>

                    <div className="col-md-3 col-xs-12">
                      <button
                        className="btn btn-premium"
                        onClick={() => {
                          if (
                            item.id === subscription?.package_id &&
                            subscription?.plan != "trial"
                          ) {
                          } else {
                            history.push("/pricing");
                          }
                        }}
                      >
                        {item.id === subscription?.package_id
                          ? "Active Subscription"
                          : "Upgrade"}
                      </button>
                    </div>

                    <div className="col-md-9">
                      <p className="sub-premium-price">
                        Learn more about this plan
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="col-md-6 col-xs-12 my-2 py-1">
                <div className="mr-3 p-3 standard">
                  <div className="row">
                    <div className="col-md-7 col-xs-6">
                      <h2 className="heading py-0 my-0">
                        {item?.package?.name}
                      </h2>
                      <p className="dim-text">{item?.package?.tag_line}</p>
                    </div>

                    <div className="col-md-5 col-xs-6">
                      <p className="sub-price">
                        <span className="orange-text">
                          {item?.pakcage?.prefer_currency}{" "}
                          {item?.package?.price}
                        </span>{" "}
                        / per Month
                      </p>
                    </div>

                    <div className="col-md-12 col-xs-12">
                      <button
                        className="btn btn-standard"
                        onClick={() => {
                          if (
                            item.id === subscription?.package_id &&
                            subscription?.plan !== "trial"
                          ) {
                          } else {
                            history.push("/pricing");
                          }
                        }}
                      >
                        {item.id === subscription?.package_id &&
                        subscription?.plan !== "trial"
                          ? "Active Subscription"
                          : "Upgrade"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* PREMIUM SUBSCRIPTION CARD */}
          </>
        );
      })}
      {/* <div className="col-md-6 col-xs-12 my-2 py-1">
        <div className="mr-3 p-3 standard">
          <div className="row">
            <div className="col-md-7 col-xs-6">
              <h2 className="heading py-0 my-0">Standard</h2>
              <p className="dim-text">Ideal for medium Business</p>
            </div>

            <div className="col-md-5 col-xs-6">
              <p className="sub-price">
                <span className="orange-text">₦ 2500</span> / per Month
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <button className="btn btn-standard">Cancel Subscription</button>
            </div>
          </div>
        </div>
      </div> */}

      {/* PREMIUM SUBSCRIPTION CARD */}
      {/* <div className="col-md-6 col-xs-12 my-2 py-1">
        <div className="ml-3 p-3 premium">
          <div className="row">
            <div className="col-md-7 col-xs-6">
              <h2 className="heading py-0 my-0 text-white">Premium</h2>
              <p className="dim-text">Ideal for large Business</p>
            </div>

            <div className="col-md-5 col-xs-6">
              <p className="sub-premium-price">
                <span className="text-white">₦ 3500</span> / per Month
              </p>
            </div>

            <div className="col-md-3 col-xs-12">
              <button className="btn btn-premium">Upgrade</button>
            </div>

            <div className="col-md-9">
              <p className="sub-premium-price">Learn more about this plan</p>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className="col-md-10 pt-4">
        <h2 className="heading">Enable Auto Renew</h2>
      </div>
      <div className="col-md-2 text-right pt-4">
        <Switch
          className="react-switch"
          onChange={() => {
            setChecked(!checked);
          }}
          checked={checked}
          aria-label="super secret label that is not visible"
        />
      </div>

      <div className="col-md-12">
        <p>
          This option; if checked, will renew your productive subscription, if
          the current plan expires, However, this might prevent you from
        </p>
      </div>

      <div className="col-md-4 py-3">
        <h2 className="heading">Giveaway Wallet</h2>
        <input
          type="text"
          className="form-control input-filed"
          placeholder="12124523453mefhjew23"
        />
      </div>
      <div className="col-md-8"></div> */}

      <div className="col-md-12 pt-4">
        <h2 className="heading">Billing History</h2>
      </div>

      <div className="col-md-12">
        <table className="table table-borderless">
          <thead>
            <tr>
              <th scope="col"> Date </th>
              <th scope="col">Details</th>
              <th scope="col">Wallet Address</th>
              <th scope="col">Amount</th>
              {/* <th scope="col">Download</th> */}
            </tr>
          </thead>
          <tbody>
            {transactionspackages?.map((x) => {
              return (
                <tr>
                  <td>{formatDate(x.timestamp)}</td>
                  <td>{x?.detail}</td>
                  <td>{x?.transaction_id}</td>
                  <td>
                    {x?.currency} {x?.amount}
                  </td>
                  {/* <td>
                    <span className="orange-text">Invoice 26 July 2020</span>
                  </td> */}
                </tr>
              );
            })}
            {/* <tr>
              <td>26 July 2020</td>
              <td>Standard plan, monthly</td>
              <td>12124523453mefhjew23</td>
              <td>₦ 2500</td>
              <td>
                <span className="orange-text">Invoice 26 July 2020</span>
              </td>
            </tr>
            */}
          </tbody>
        </table>
        {transactionspackages?.length === 0 && (
          <span
            style={{
              display: "flex",
              justifyContent: "center",
              fontSize: "20px",
              marginBottom: "50px",
            }}
          >
            You haven’t any Billing History.
          </span>
        )}
      </div>
    </div>
  );
}

export default Details;
