import React, { useState } from "react";
import DeshboardLayout from "../layouts/DeshboardLayout";
import Dheader from "./dheader";
import Profile from "./profile";

function MyAccount() {
  return (
    <>
      <div id="main-wrapper" class="show">
        <DeshboardLayout />
        <div class="content-body">
          <Profile />
        </div>
      </div>
    </>
  );
}

export default MyAccount;
