import Dropdown from "react-bootstrap/Dropdown";

const Filter = ({ search, onChange, orderStatus, clearFilter, sortBy }) => {
  return (
    <>
      <div className="row bg-white rounded mx-3 px-0 py-1 mt-3">
        <div className="col-md-8 mob-remove">
          <ul className="filter">
            <li>
              <div className="pt-2 mr-4">
                <Dropdown>
                  <Dropdown.Toggle variant="filter" id="dropdown-basic">
                    <span className="filteroption">
                      <i class="far fa-filter"></i> Filter By
                    </span>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => {
                        sortBy("Date");
                      }}
                    >
                      Date
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        sortBy("OrderId");
                      }}
                    >
                      Appointment ID
                    </Dropdown.Item>
                    {/* <Dropdown.Item
                      onClick={() => {
                        sortBy("OrderDetails");
                      }}
                    >
                      Order Details
                    </Dropdown.Item> */}
                    {/* <Dropdown.Item
                      onClick={() => {
                        sortBy("Type");
                      }}
                    >
                      Type
                    </Dropdown.Item>*/}
                    <Dropdown.Item
                      onClick={() => {
                        sortBy("amount");
                      }}
                    >
                      Amount
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        sortBy("OrderStatus");
                      }}
                    >
                      Appointment Status
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </li>

            <li>
              <div className="pt-2 mr-4">
                <Dropdown>
                  {/* <Dropdown.Toggle variant="filter" id="dropdown-basic">
                    <span className="filteroption">
                      14 Feb 2019 <i className="fa fa-chevron-down px-1"></i>
                    </span>
                  </Dropdown.Toggle> */}

                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">14 Feb 2019</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">15 Feb 2019</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </li>

            <li>
              <div className="pt-2 mr-4">
                <Dropdown>
                  <Dropdown.Toggle variant="filter" id="dropdown-basic">
                    <span className="filteroption">
                      Order Status <i className="fa fa-chevron-down px-1"></i>
                    </span>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => {
                        orderStatus("pending");
                      }}
                    >
                      Pending
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        orderStatus("accepted");
                      }}
                    >
                      Accepted
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        orderStatus("complete");
                      }}
                    >
                      Completed
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        orderStatus("decline");
                      }}
                    >
                      Decline
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        orderStatus("cancel");
                      }}
                    >
                      Cancel
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </li>

            <li className="">
              <p
                className="orange-text"
                onClick={() => {
                  clearFilter();
                }}
              >
                <i className="fa fa-undo px-2"></i> Clear Filter
              </p>
            </li>

            <li className="text-right"></li>
          </ul>
        </div>
        <div className="col-md-4 text-right1">
          {/* <input
            type="text"
            className="form control order-search"
            placeholder="Search by Order #"
            value={search}
            onChange={(e) => {
              onChange(e.target.value);
            }}
          /> */}
          {/* search field */}
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text search">
                <i class="fas fa-search"></i>
              </span>
            </div>
            <input
              type="text"
              class="form-control order-search search"
              placeholder="Search by Appointment #"
              value={search}
              onChange={(e) => {
                onChange(e.target.value);
              }}
            />
          </div>
          {/* end of search field */}
        </div>
      </div>
    </>
  );
};

export default Filter;
