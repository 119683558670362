// import { TimePickerComponent } from "@syncfusion/ej2-react-calendars"
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { resturantActions } from "../../../Store/Resturant/resturantAction";

const BusinessHours = ({ resturantId }) => {
  const { resturantSetting } = useSelector((state) => state.ResturantReducer);
  const dispatch = useDispatch();
  const scheduleUpdate = (res) => {
    dispatch(
      resturantActions.onChange("resturantSetting", {
        ...resturantSetting,
        branch: {
          ...resturantSetting.branch,
          schedule: res,
        },
      })
    );
  };
  return (
    <>
      <div className="row">
        <div className="col-md-7">
          <table className="table table-borderless">
            <thead>
              <tr>
                <th scope="col">Working Hours</th>
                <th scope="col">Opening Hours</th>
                <th scope="col">Closing Hours</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    checked={
                      resturantSetting?.branch?.schedule?.filter(
                        (x) => x?.weekday?.toLowerCase() === "sunday"
                      )[0]?.active
                    }
                    onChange={() => {
                      var res = resturantSetting?.branch?.schedule?.map((x) => {
                        if (x.weekday === "sunday") {
                          x.active =
                            !resturantSetting?.branch?.schedule?.filter(
                              (x) => x?.weekday?.toLowerCase() === "sunday"
                            )[0]?.active;
                        }
                        return x;
                      });
                      scheduleUpdate(res);
                    }}
                  />
                  Sunday
                </td>
                <td>
                  <input
                    type="time"
                    className="form-control input-filed time-select"
                    value={
                      resturantSetting?.branch?.schedule?.filter(
                        (x) => x?.weekday?.toLowerCase() === "sunday"
                      )[0]?.start_time
                    }
                    onChange={(e) => {
                      var res = resturantSetting?.branch?.schedule?.map((x) => {
                        if (x.weekday === "sunday") {
                          x.start_time = e.target.value;
                        }
                        return x;
                      });
                      scheduleUpdate(res);
                    }}
                  />
                </td>
                <td>
                  <input
                    type="time"
                    className="form-control input-filed time-select"
                    value={
                      resturantSetting?.branch?.schedule?.filter(
                        (x) => x?.weekday?.toLowerCase() === "sunday"
                      )[0]?.end_time
                    }
                    onChange={(e) => {
                      var res = resturantSetting?.branch?.schedule?.map((x) => {
                        if (x.weekday === "sunday") {
                          x.end_time = e.target.value;
                        }
                        return x;
                      });
                      scheduleUpdate(res);
                    }}
                  />
                </td>
              </tr>

              <tr>
                <td>
                  <input
                    type="checkbox"
                    checked={
                      resturantSetting?.branch?.schedule?.filter(
                        (x) => x?.weekday?.toLowerCase() === "monday"
                      )[0]?.active
                    }
                    onChange={() => {
                      var res = resturantSetting?.branch?.schedule?.map((x) => {
                        if (x.weekday === "monday") {
                          x.active =
                            !resturantSetting?.branch?.schedule?.filter(
                              (x) => x?.weekday?.toLowerCase() === "monday"
                            )[0]?.active;
                        }
                        return x;
                      });
                      scheduleUpdate(res);
                    }}
                  />{" "}
                  <span className="day">Monday</span>
                </td>
                <td>
                  <input
                    type="time"
                    className="form-control input-filed time-select"
                    value={
                      resturantSetting?.branch?.schedule?.filter(
                        (x) => x?.weekday?.toLowerCase() === "monday"
                      )[0]?.start_time
                    }
                    onChange={(e) => {
                      var res = resturantSetting?.branch?.schedule?.map((x) => {
                        if (x.weekday === "monday") {
                          x.start_time = e.target.value;
                        }
                        return x;
                      });
                      scheduleUpdate(res);
                    }}
                  />
                </td>
                <td>
                  <input
                    type="time"
                    className="form-control input-filed time-select"
                    value={
                      resturantSetting?.branch?.schedule?.filter(
                        (x) => x?.weekday?.toLowerCase() === "monday"
                      )[0]?.end_time
                    }
                    onChange={(e) => {
                      var res = resturantSetting?.branch?.schedule?.map((x) => {
                        if (x.weekday === "monday") {
                          x.end_time = e.target.value;
                        }
                        return x;
                      });
                      scheduleUpdate(res);
                    }}
                  />
                </td>
              </tr>

              <tr>
                <td>
                  <input
                    type="checkbox"
                    checked={
                      resturantSetting?.branch?.schedule?.filter(
                        (x) => x?.weekday?.toLowerCase() === "tuesday"
                      )[0]?.active
                    }
                    onChange={() => {
                      var res = resturantSetting?.branch?.schedule?.map((x) => {
                        if (x.weekday === "tuesday") {
                          x.active =
                            !resturantSetting?.branch?.schedule?.filter(
                              (x) => x?.weekday?.toLowerCase() === "tuesday"
                            )[0]?.active;
                        }
                        return x;
                      });
                      scheduleUpdate(res);
                    }}
                  />{" "}
                  <span className="day">Tuesday</span>
                </td>
                <td>
                  <input
                    type="time"
                    className="form-control input-filed time-select"
                    value={
                      resturantSetting?.branch?.schedule?.filter(
                        (x) => x?.weekday?.toLowerCase() === "tuesday"
                      )[0]?.start_time
                    }
                    onChange={(e) => {
                      var res = resturantSetting?.branch?.schedule?.map((x) => {
                        if (x.weekday === "tuesday") {
                          x.start_time = e.target.value;
                        }
                        return x;
                      });
                      scheduleUpdate(res);
                    }}
                  />
                </td>
                <td>
                  <input
                    type="time"
                    className="form-control input-filed time-select"
                    value={
                      resturantSetting?.branch?.schedule?.filter(
                        (x) => x?.weekday?.toLowerCase() === "tuesday"
                      )[0]?.end_time
                    }
                    onChange={(e) => {
                      var res = resturantSetting?.branch?.schedule?.map((x) => {
                        if (x.weekday === "tuesday") {
                          x.end_time = e.target.value;
                        }
                        return x;
                      });
                      scheduleUpdate(res);
                    }}
                  />
                </td>
              </tr>

              <tr>
                <td>
                  <input
                    type="checkbox"
                    checked={
                      resturantSetting?.branch?.schedule?.filter(
                        (x) => x?.weekday?.toLowerCase() === "wednesday"
                      )[0]?.active
                    }
                    onChange={() => {
                      var res = resturantSetting?.branch?.schedule?.map((x) => {
                        if (x.weekday === "wednesday") {
                          x.active =
                            !resturantSetting?.branch?.schedule?.filter(
                              (x) => x?.weekday?.toLowerCase() === "wednesday"
                            )[0]?.active;
                        }
                        return x;
                      });
                      scheduleUpdate(res);
                    }}
                  />{" "}
                  <span className="day">Wednesday</span>
                </td>
                <td>
                  <input
                    type="time"
                    className="form-control input-filed time-select"
                    value={
                      resturantSetting?.branch?.schedule?.filter(
                        (x) => x?.weekday?.toLowerCase() === "wednesday"
                      )[0]?.start_time
                    }
                    onChange={(e) => {
                      var res = resturantSetting?.branch?.schedule?.map((x) => {
                        if (x.weekday === "wednesday") {
                          x.start_time = e.target.value;
                        }
                        return x;
                      });
                      scheduleUpdate(res);
                    }}
                  />
                </td>
                <td>
                  <input
                    type="time"
                    className="form-control input-filed time-select"
                    value={
                      resturantSetting?.branch?.schedule?.filter(
                        (x) => x?.weekday?.toLowerCase() === "wednesday"
                      )[0]?.end_time
                    }
                    onChange={(e) => {
                      var res = resturantSetting?.branch?.schedule?.map((x) => {
                        if (x.weekday === "wednesday") {
                          x.end_time = e.target.value;
                        }
                        return x;
                      });
                      scheduleUpdate(res);
                    }}
                  />
                </td>
              </tr>

              <tr>
                <td>
                  <input
                    type="checkbox"
                    checked={
                      resturantSetting?.branch?.schedule?.filter(
                        (x) => x?.weekday?.toLowerCase() === "thursday"
                      )[0]?.active
                    }
                    onChange={() => {
                      var res = resturantSetting?.branch?.schedule?.map((x) => {
                        if (x.weekday === "thursday") {
                          x.active =
                            !resturantSetting?.branch?.schedule?.filter(
                              (x) => x?.weekday?.toLowerCase() === "thursday"
                            )[0]?.active;
                        }
                        return x;
                      });
                      scheduleUpdate(res);
                    }}
                  />{" "}
                  <span className="day">Thursday</span>
                </td>
                <td>
                  <input
                    type="time"
                    className="form-control input-filed time-select"
                    value={
                      resturantSetting?.branch?.schedule?.filter(
                        (x) => x?.weekday?.toLowerCase() === "thursday"
                      )[0]?.start_time
                    }
                    onChange={(e) => {
                      var res = resturantSetting?.branch?.schedule?.map((x) => {
                        if (x.weekday === "thursday") {
                          x.start_time = e.target.value;
                        }
                        return x;
                      });
                      scheduleUpdate(res);
                    }}
                  />
                </td>
                <td>
                  <input
                    type="time"
                    className="form-control input-filed time-select"
                    value={
                      resturantSetting?.branch?.schedule?.filter(
                        (x) => x?.weekday?.toLowerCase() === "thursday"
                      )[0]?.end_time
                    }
                    onChange={(e) => {
                      var res = resturantSetting?.branch?.schedule?.map((x) => {
                        if (x.weekday === "thursday") {
                          x.end_time = e.target.value;
                        }
                        return x;
                      });
                      scheduleUpdate(res);
                    }}
                  />
                </td>
              </tr>

              <tr>
                <td>
                  <input
                    type="checkbox"
                    className=""
                    checked={
                      resturantSetting?.branch?.schedule?.filter(
                        (x) => x?.weekday?.toLowerCase() === "friday"
                      )[0]?.active
                    }
                    onChange={() => {
                      var res = resturantSetting?.branch?.schedule?.map((x) => {
                        if (x.weekday === "friday") {
                          x.active =
                            !resturantSetting?.branch?.schedule?.filter(
                              (x) => x?.weekday?.toLowerCase() === "friday"
                            )[0]?.active;
                        }
                        return x;
                      });
                      scheduleUpdate(res);
                    }}
                  />{" "}
                  <span className="day">Friday</span>
                </td>
                <td>
                  <input
                    type="time"
                    className="form-control input-filed time-select"
                    value={
                      resturantSetting?.branch?.schedule?.filter(
                        (x) => x?.weekday?.toLowerCase() === "friday"
                      )[0]?.start_time
                    }
                    onChange={(e) => {
                      var res = resturantSetting?.branch?.schedule?.map((x) => {
                        if (x.weekday === "friday") {
                          x.start_time = e.target.value;
                        }
                        return x;
                      });
                      scheduleUpdate(res);
                    }}
                  />
                </td>
                <td>
                  <input
                    type="time"
                    className="form-control input-filed time-select"
                    value={
                      resturantSetting?.branch?.schedule?.filter(
                        (x) => x?.weekday?.toLowerCase() === "friday"
                      )[0]?.end_time
                    }
                    onChange={(e) => {
                      var res = resturantSetting?.branch?.schedule?.map((x) => {
                        if (x.weekday === "friday") {
                          x.end_time = e.target.value;
                        }
                        return x;
                      });
                      scheduleUpdate(res);
                    }}
                  />
                </td>
              </tr>

              <tr>
                <td>
                  <input
                    type="checkbox"
                    checked={
                      resturantSetting?.branch?.schedule?.filter(
                        (x) => x?.weekday?.toLowerCase() === "saturday"
                      )[0]?.active
                    }
                    onChange={() => {
                      var res = resturantSetting?.branch?.schedule?.map((x) => {
                        if (x.weekday === "saturday") {
                          x.active =
                            !resturantSetting?.branch?.schedule?.filter(
                              (x) => x?.weekday?.toLowerCase() === "saturday"
                            )[0]?.active;
                        }
                        return x;
                      });
                      scheduleUpdate(res);
                    }}
                  />{" "}
                  <span className="day">Saturday</span>
                </td>
                <td>
                  <input
                    type="time"
                    className="form-control input-filed time-select"
                    value={
                      resturantSetting?.branch?.schedule?.filter(
                        (x) => x?.weekday?.toLowerCase() === "saturday"
                      )[0]?.start_time
                    }
                    onChange={(e) => {
                      var res = resturantSetting?.branch?.schedule?.map((x) => {
                        if (x.weekday === "saturday") {
                          x.start_time = e.target.value;
                        }
                        return x;
                      });
                      scheduleUpdate(res);
                    }}
                  />
                </td>
                <td>
                  <input
                    type="time"
                    className="form-control input-filed time-select"
                    value={
                      resturantSetting?.branch?.schedule?.filter(
                        (x) => x?.weekday?.toLowerCase() === "saturday"
                      )[0]?.end_time
                    }
                    onChange={(e) => {
                      var res = resturantSetting?.branch?.schedule?.map((x) => {
                        if (x.weekday === "saturday") {
                          x.end_time = e.target.value;
                        }
                        return x;
                      });
                      scheduleUpdate(res);
                    }}
                  />
                </td>
              </tr>

              {/* <tr>
                <td>Serving time Range</td>
                <td>
                  <input
                    type="time"
                    className="form-control input-filed time-select"
                    placeholder="8:00 AM"
                  />
                </td>
                <td>
                  <input
                    type="time"
                    className="form-control input-filed time-select"
                  />
                </td>
              </tr> */}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default BusinessHours;
