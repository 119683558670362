import DeshboardLayout from "../layouts/DeshboardLayout";
import Details from "./details";
import "./subscription.css";

function Subscription() {
  return (
    <>
      <div id="main-wrapper" class="show">
        <DeshboardLayout />
        <div class="content-body">
          <div className="row  mb-2 mx-1">
            <div className="col-md-12">
              <h2 className="heading">Subscription</h2>
            </div>
            <Details />
          </div>
        </div>
      </div>
    </>
  );
}

export default Subscription;
