import React from "react";
import BrandPageAnimations from "./BrandPageAnimations";

function BrandPage() {

  return (
    <section className="about-2-area about-5-area">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="about-5-content">
              <h3 className="title">Brand Page</h3>
              <div className="text">
                <p>
                We will personally create, design and place your business page in the Yawaevig system.
                </p>
                <ul>
                <li><span>&#10146;</span> Electronic gift certificates</li>
                <li><span>&#10146;</span> Online booking</li>
                <li><span>&#10146;</span> Messenger</li>
                <li><span>&#10146;</span> Price</li>
                <li><span>&#10146;</span> Rating and Reviews</li>
                </ul>
              </div>

            </div>
          </div>
          <div className="col-lg-6">
          <BrandPageAnimations />
          </div>

        </div>
      </div>
    </section>
  );
}

export default BrandPage;
