import React, { useState } from "react";
import { MdOutlineBrunchDining, MdOutlineDeliveryDining } from "react-icons/md";
import { useSelector } from "react-redux";

function Screen1({ template }) {
  const { menuData } = useSelector((state) => state.MenuReducer);
  return (
    <>
      <div
        className="screen-content"
        style={{ backgroundColor: `${menuData?.home_background_color}` }}
      >
        <div className="preview-header-img">
          {menuData?.home_display_image_background ? (
            <img src={menuData?.home_background_image} />
          ) : (
            menuData?.home_display_logo && (
              <div style={{ marginTop: "70px" }}></div>
            )
          )}
        </div>
        <div className="logo-social-area">
          {menuData?.home_display_logo ? (
            <img
              src="https://media.istockphoto.com/id/1306631068/vector/saloon-chair.jpg?s=612x612&amp;w=0&amp;k=20&amp;c=ANEI5YQE6ky32kS8WNsR7y6zWhZiSzLZXlfW8nxQEpc="
              className="scree-logo-area"
            />
          ) : (
            <div className="scree-logo-area"></div>
          )}
          <div className="social-media-icons">
            <ul>
              <li>
                <i class="fab fa-facebook-f"></i>
              </li>
              <li>
                <i class="fab fa-instagram"></i>
              </li>
            </ul>
          </div>
        </div>
        <div className="resturnet-title-area">
          <h6 style={{ color: `${menuData?.home_main_heading_color}` }}>
            Barber Shop
          </h6>
        </div>
        <div className="resturnet-location">
          <div className="resturent-location-area">
            <span>
              <i class="fa fa-map-marker" aria-hidden="true"></i>{" "}
            </span>
            <span
              className="preview-address"
              style={{ color: `${menuData?.home_text_color}` }}
            >
              1001 Street, Nigera
            </span>
          </div>
          <div className="open-or-close">
            <p>
              <span className="before-open"></span>Open
            </p>
          </div>
        </div>
        <div className="description-area">
          <p
            className="description-text"
            style={{ color: `${menuData?.home_sub_heading_color}` }}
          >
            Description
          </p>
          <p
            className="description-details"
            style={{ color: `${menuData?.home_text_color}` }}
          >
            Our Stylists are Experienced, Talented & Professional. Book your
            appointment.
          </p>
        </div>
        <div className="ordering">
          <p
            className="description-text"
            style={{ color: `${menuData?.home_main_heading_color}` }}
          >
            Ordering
          </p>
          <button
            className="preview-menu-order-btn"
            style={{
              backgroundColor: menuData?.home_dine_in_btn_color,
            }}
          >
            <div className="left-btn-content">
              <MdOutlineBrunchDining
                style={{ color: `${menuData?.home_dine_in_logo_color}` }}
              />
              <span style={{ color: `${menuData?.home_dine_in_text_color}` }}>
                {" "}
                Walk in
              </span>
            </div>
            <div className="right-side-btn-content">
              <i
                class="fa fa fa-chevron-right"
                style={{ color: `${menuData?.home_dine_in_logo_color}` }}
              ></i>
            </div>
          </button>
          <button
            className="preview-menu-order-btn"
            style={{
              backgroundColor: `${menuData?.home_delivery_in_btn_color}`,
            }}
          >
            <div className="left-btn-content">
              <MdOutlineDeliveryDining
                style={{ color: `${menuData?.home_delivery_in_logo_color}` }}
              />
              <span
                style={{ color: `${menuData?.home_delivery_in_text_color}` }}
              >
                Out Call
              </span>
            </div>
            <div className="right-side-btn-content">
              <i
                class="fa fa fa-chevron-right"
                style={{ color: `${menuData?.home_delivery_in_logo_color}` }}
              ></i>
            </div>
          </button>
        </div>
      </div>
    </>
  );
}

export default Screen1;
