import React from "react";

function FastPayment() {

  return (
    <section className="about-2-area about-5-area">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div
              className="about-2-thumb mt-30 animated wow fadeInLeft"
              data-wow-duration="3000ms"
              data-wow-delay="0ms"
            >
              <div className="thumb">
                <img
                  src={require(`../../assets/images/fastpayment.png`).default}
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="about-5-content">
              <h3 className="title">Fast Payment by QR CODE</h3>
              <div className="text">
                <p>
                Provide your customers with the most convenient and profitable way to pay by QR Code, Payment is made on a screen designed in your corporate style. The payment already has a tip system, brand evaluation and a cashback system the increases your average check.
                </p>
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FastPayment;
