import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { Dropdown } from "react-bootstrap";
import {
  getStatus,
  getServices,
  getFormatedDate,
  getFormatedTime,
} from "../partials/status";

const Upcoming = ({
  redirectTo,
  upCommingData,
  onOrderStatusChange,
  handleChange,
  upCommingPageSize,
  orderPaywithQRCode,
  orderPaywithCash,
  orderPaywithPush,
}) => {
  return (
    <>
      <div className="row">
        <table className="table table-borderless">
          <thead>
            <tr>
              <th className="mob-remove" scope="col">
                Appointment
              </th>
              <th className="mob-remove" scope="col">
                Professional
              </th>
              <th scope="col" className="order-detail">
                Order ID
              </th>
              {/* <th scope="col">Order Detail</th> */}
              <th className="mob-remove" scope="col">
                Order Status
              </th>
              <th className="mob-remove" scope="col">
                Payment Status
              </th>
              <th className="mob-remove" scope="col">
                Payment Type
              </th>
              <th className="mob-remove" scope="col">
                Amount
              </th>
              <th scope="col" className="text-center order-detail">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {upCommingData.map((x, i) => {
              return (
                <tr key={i}>
                  <td scope="row" className="text-center mob-remove">
                    {getFormatedDate(x.placed_at)}
                    <br></br>
                    {getFormatedTime(x.placed_at)}
                  </td>
                  <td className="mob-remove">
                    {x?.services[0]?.professional?.name}
                  </td>
                  <td>
                    <span
                      className="form-label"
                      style={{ cursor: "pointer", color: "#F9A05F" }}
                      onClick={() => {
                        redirectTo(x?.appointment_id);
                      }}
                    >
                      {x?.appointment_id}
                    </span>
                  </td>
                  {/* <td>{x?.services.map((x) => getServices(x.service))}</td> */}
                  <td className="mob-remove">{getStatus(x?.order_status)}</td>
                  <td className="mob-remove">{getStatus(x?.payment_status)}</td>
                  <td className="mob-remove">{getStatus(x?.payment_mode)}</td>
                  <td className="mob-remove">{`NGN ${x?.total_bill_amount}`}</td>
                  <td className="text-center">
                    {/* drop down */}
                    <Dropdown>
                      <Dropdown.Toggle variant="abbs" id="dropdown-basic">
                        <span>
                          <i class="far fa-ellipsis-h"></i>
                        </span>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => {
                            onOrderStatusChange(x?.id, "accepted");
                          }}
                          disabled={
                            x?.order_status === "accepted" ||
                            x?.order_status === "decline"
                              ? true
                              : false
                          }
                          className="order-detail"
                        >
                          Accepted
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            onOrderStatusChange(x?.id, "decline");
                          }}
                          disabled={
                            x?.order_status === "accepted" ||
                            x?.order_status === "decline"
                              ? true
                              : false
                          }
                          className="order-detail"
                        >
                          Decline
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            orderPaywithQRCode(x?.appointment_id);
                          }}
                          disabled={
                            x?.payment_status === "success" ? true : false
                          }
                          className="order-detail"
                        >
                          Pay Via QR
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            orderPaywithCash(x?.appointment_id);
                          }}
                          disabled={
                            x?.payment_status === "success" ? true : false
                          }
                          className="order-detail"
                        >
                          Pay Via Cash
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            orderPaywithPush(x?.appointment_id);
                          }}
                          disabled={
                            x?.payment_status === "success" ? true : false
                          }
                          className="order-detail"
                        >
                          Pay Via Push
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    {/* end of dropdown */}
                  </td>
                  {/* <td className="text-center">
                    <button
                      className="btn btn-danger small"
                      onClick={() => {
                        onOrderStatusChange(x?.id, "decline");
                      }}
                      disabled={x?.order_status === "accepted" ? true : false}
                    >
                      Decline
                    </button>
                    <button
                      className="btn btn-success small"
                      onClick={() => {
                        onOrderStatusChange(x?.id, "accepted");
                      }}
                      disabled={x?.order_status === "accepted" ? true : false}
                    >
                      Accept
                    </button>
                  </td> */}
                </tr>
              );
            })}
          </tbody>
        </table>
        {upCommingData?.length === 0 && (
          <span
            style={{
              display: "flex",
              justifyContent: "center",
              fontSize: "20px",
              marginBottom: "50px",
            }}
            className="order-detail"
          >
            You haven’t any Appointment.
          </span>
        )}
      </div>
      {upCommingPageSize > 1 && (
        <Stack spacing={2}>
          <Pagination count={upCommingPageSize} onChange={handleChange} />
        </Stack>
      )}
    </>
  );
};

export default Upcoming;
