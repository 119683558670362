import React from "react";

function SeamlessPaymentAnimations() {

  return (
    <div className="animation-area-main">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
            <div className="seamless-animation-block-area">
              <div className="seamless-left">
              <img
            src={require(`../../assets/images/seamless1.png`).default}
            alt=""
            className="seamless-mainimage"
          />
              </div>
          <div className="seamless-right">
            <img
            src={require(`../../assets/images/seamless2.png`).default}
            alt=""
            className="seamless-right-image"
          />
          </div>

            </div>
          </div>
          </div>
        </div>
        </div>
  );
}

export default SeamlessPaymentAnimations;
