import React, { useState } from "react";
import RestaurantCartArea from "./restaurantCartArea";
import pizza from "../../../assets/images/qrscan/pizza.png";
import "../../../assets/qrscanpages/css/style.css";
import { Link } from "react-router-dom";

import "./qrstyle.css";
import { rootURL } from "../../../Config/apiConfig";
import { resturantActions } from "../../../Store/Resturant/resturantAction";
import { useDispatch } from "react-redux";
import Loader from "../../Common/Loader";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function OrderPlaced({
  title,
  msg,
  firstName,
  lastName,
  contact,
  email,
  booking,
  appointmentId,
  link,
}) {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [numberProduct, setNumberProduct] = useState(0);
  const [selectedCartItems, setSelectedCartItems] = useState(0);
  var resId = parseInt(window.location.href.split("res=")[1]);
  var crt = window.location.href.split("&crt=")[1];
  const history = useHistory();
  return (
    <>
      {isLoading ? <Loader /> : null}
      <div className="fixed px-0">
        <div className="mx-0">
          <div className="main-wrapper">
            <div className="inner-wrapper1 white-bg">
              <div
                className="top1"
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <p
                  style={{
                    margin: "15px",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  {title}
                </p>
                <div
                  style={{
                    margin: "20px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <p
                      style={{
                        fontSize: "14px",
                        marginBottom: "10px",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      {msg}
                    </p>
                    {/* <p className="cad-title">{`Last Name ${lastName}`}</p>
                    <p className="cad-title">{`Contact ${contact}`}</p>
                    <p className="cad-title">{`Email ${email}`}</p>
                    <p className="cad-title">{`Booking Slot ${booking}`}</p> */}
                  </div>
                  <button
                    className="preview-button-theme"
                    style={{ width: "100px" }}
                    onClick={() => {
                      history.push(link);
                    }}
                  >
                    Done
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OrderPlaced;
