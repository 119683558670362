function Dheader() {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <h3 className="inner-header">
              Orders
              <span class="heading-link">
                <i class="fa fa-chevron-right bread-icon"></i>
                <span class="subheading-link">Order List</span>
              </span>
            </h3>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dheader;
