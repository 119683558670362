import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import { menuActions } from "../../../../Store/Menu/menuAction";
import Loader from "../../../Common/Loader";
import Switch from "react-switch";
import { rootURL } from "../../../../Config/apiConfig";
import { toast } from "react-toastify";

function AddItem({ setSetcionClose, itemCategory, selectedItem, gallery }) {
  let imag = selectedItem?.featured?.includes("http");
  const [show, setShow] = useState(true);
  const [featuredImg, setFeaturedImg] = useState(null);
  const [selectFeaturedImg, setSelectFeaturedImg] = useState(
    selectedItem?.featured
      ? imag
        ? selectedItem.featured
        : selectedItem.featured
      : null
  );
  const [isLoading, setLoading] = useState(false);
  const [menuItem, setMenuItem] = useState({
    name: selectedItem?.name ? selectedItem.name : "",
    description: selectedItem?.description ? selectedItem.description : "",
    featured: selectedItem?.featured
      ? imag
        ? selectedItem.featured
        : selectedItem.featured
      : "",
    price: selectedItem?.price ? selectedItem.price : 0,
    preparation_time: selectedItem?.preparation_time
      ? selectedItem.preparation_time
      : 0,
    item: selectedItem?.section?.id ? selectedItem?.section?.id : 0,
    inStock: selectedItem?.instock != null ? selectedItem.instock : true,
    deliveryEnable:
      selectedItem?.delivery_enable != null
        ? selectedItem.delivery_enable
        : true,
    roomService:
      selectedItem?.room_service != null ? selectedItem.room_service : true,
    dineIn: selectedItem?.dine_in != null ? selectedItem.dine_in : true,
    discount: selectedItem?.discount ? selectedItem.discount : 0,
  });
  const dispatch = useDispatch();
  const handleClose = () => {
    setShow(false);
    setSetcionClose(false);
  };
  var secId = parseInt(window.location.href.split("?id=")[1]);
  const addUpdateData = () => {
    if (
      menuItem?.name !== "" &&
      menuItem?.price !== 0 &&
      (featuredImg !== null ||
        selectFeaturedImg !== null ||
        menuItem?.feature_image)
    ) {
      setLoading(true);
      let resturantItemData = {};
      if (selectFeaturedImg) {
        resturantItemData = {
          name: menuItem?.name,
          description: menuItem?.description,
          featured: menuItem?.featured,
          price: parseInt(menuItem?.price),
          discount: parseInt(menuItem?.discount),
          preparation_time: parseInt(menuItem?.preparation_time),
          instock: menuItem?.inStock,
          delivery_enable: menuItem?.deliveryEnable,
          room_service: menuItem?.roomService,
          dine_in: menuItem?.dineIn,
        };
      } else {
        resturantItemData = new FormData();
        resturantItemData.append("name", menuItem.name);
        resturantItemData.append("description", menuItem.description);
        featuredImg && resturantItemData.append("featured", featuredImg);
        resturantItemData.append(
          "preparation_time",
          parseInt(menuItem.preparation_time)
        );
        resturantItemData.append("price", parseInt(menuItem.price));
        resturantItemData.append("discount", parseInt(menuItem.discount));
        resturantItemData.append("instock", menuItem.inStock);
        resturantItemData.append("delivery_enable", menuItem.deliveryEnable);
        resturantItemData.append("room_service", menuItem.roomService);
        resturantItemData.append("dine_in", menuItem.dineIn);
      }
      let sectionId = parseInt(
        menuItem?.item === "" || menuItem?.item === 0
          ? itemCategory[0]?.id
          : menuItem.item
      );
      if (selectedItem?.id) {
        dispatch(
          menuActions.onUpdateSectionItem(
            sectionId,
            resturantItemData,
            selectedItem?.id
          )
        ).then((res) => {
          if (res?.id) {
            setLoading(false);
            handleClose();
          } else {
            setLoading(false);
          }
        });
      } else {
        dispatch(
          menuActions.onCreateSectionItem(sectionId, resturantItemData)
        ).then((res) => {
          if (res?.id) {
            setLoading(false);
            handleClose();
          } else {
            setLoading(false);
          }
        });
      }
    } else {
      toast.error("Please fill name or price or Item Image");
    }
  };
  return (
    <>
      {isLoading ? <Loader /> : null}
      <div className="modalAreaCentered text-center">
        <Modal show={show} onHide={handleClose} size="lg" centered>
          <Modal.Header closeButton>
            <Modal.Title>Item Settings</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-area item-setting-form">
              <form>
                <div className="yawaevig-form-row">
                  <div className="form-group-yawaevig">
                    <lable>Product Name</lable>
                    <input
                      type="text"
                      className="form-control input-filed"
                      placeholder="e.g Our Classes Burger"
                      value={menuItem.name}
                      onChange={(e) => {
                        setMenuItem({
                          ...menuItem,
                          name: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="form-group-yawaevig">
                    <lable>Product Category</lable>
                    <select
                      name="position"
                      class="form-control input-filed"
                      placeholder="e.g 3142121"
                      value={menuItem.item}
                      onChange={(e) => {
                        setMenuItem({
                          ...menuItem,
                          item: e.target.value,
                        });
                      }}
                    >
                      {itemCategory?.map((x, i) => {
                        return (
                          <option key={i} value={x.id}>
                            {x.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="form-group-yawaevig">
                  <lable>Description</lable>
                  <textarea
                    className="form-control input-filed"
                    placeholder="Add Description"
                    value={menuItem.description}
                    onChange={(e) => {
                      setMenuItem({
                        ...menuItem,
                        description: e.target.value,
                      });
                    }}
                  ></textarea>
                </div>

                <div className="item-image-area">
                  <div className="right-image-preview">
                    <lable>Preview</lable>
                    {menuItem?.featured && (
                      <img
                        src={menuItem.featured}
                        className="item-setting-preview"
                      />
                    )}
                  </div>
                  <div className="left-image-upload">
                    <div className="upload-file-field">
                      <lable>Item Image</lable>
                      <input
                        type="file"
                        name="myImage"
                        accept="image/png,image/jpeg"
                        onChange={(e) => {
                          if (e.target.files && e.target.files[0]) {
                            let img = e.target.files[0];
                            setFeaturedImg(e.target.files[0]);
                            const ava = URL.createObjectURL(img);
                            setMenuItem({
                              ...menuItem,
                              featured: ava,
                            });
                            setSelectFeaturedImg(null);
                          }
                        }}
                      />
                      <span>Remove Image</span>
                    </div>
                    <div className="suggested-images-area">
                      <lable>Suggest Image</lable>
                      <ul className="images-list-suggestion">
                        {gallery?.map((x) => (
                          <li
                            onClick={() => {
                              setSelectFeaturedImg(x?.image);
                              setMenuItem({
                                ...menuItem,
                                featured: x?.image,
                              });
                            }}
                          >
                            <img src={x?.image} />
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="switch-btns-area">
                  <div className="left-btns-menu">
                    <div className="instock div-flex-andspaceb">
                      <lable>In Stock</lable>
                      <Switch
                        onChange={() => {
                          setMenuItem({
                            ...menuItem,
                            inStock: !menuItem?.inStock,
                          });
                        }}
                        checked={menuItem?.inStock}
                      />
                    </div>
                    <div style={{ margin: "10px" }}></div>
                    <div className="roomservice div-flex-andspaceb">
                      <label>Room Service</label>
                      <Switch
                        onChange={() => {
                          setMenuItem({
                            ...menuItem,
                            roomService: !menuItem?.roomService,
                          });
                        }}
                        checked={menuItem?.roomService}
                      />
                    </div>
                  </div>
                  <div className="right-btns-menu">
                    <div className="deliveryenable div-flex-andspaceb">
                      <lable> Delivery Enable </lable>
                      <Switch
                        onChange={() => {
                          setMenuItem({
                            ...menuItem,
                            deliveryEnable: !menuItem?.deliveryEnable,
                          });
                        }}
                        checked={menuItem?.deliveryEnable}
                      />
                    </div>
                    <div style={{ margin: "10px" }}></div>
                    <div className="dinein div-flex-andspaceb">
                      <label>Dine-In</label>
                      <Switch
                        onChange={() => {
                          setMenuItem({
                            ...menuItem,
                            dineIn: !menuItem?.dineIn,
                          });
                        }}
                        checked={menuItem?.dineIn}
                      />
                    </div>
                  </div>
                </div>
                <div className="yawaevig-form-row">
                  <div className="form-group-yawaevig">
                    <lable>Preparation Minutes</lable>
                    <input
                      type="text"
                      className="form-control input-filed"
                      placeholder="Minutes"
                      value={menuItem.preparation_time}
                      onChange={(e) => {
                        setMenuItem({
                          ...menuItem,
                          preparation_time: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="yawaevig-form-row">
                  <div className="form-group-yawaevig">
                    <lable>Price</lable>
                    <input
                      type="text"
                      className="form-control input-filed"
                      placeholder="e.g Our Classes Burger"
                      value={menuItem.price}
                      onChange={(e) => {
                        setMenuItem({
                          ...menuItem,
                          price: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="form-group-yawaevig">
                    <lable>Discount</lable>
                    <input
                      type="text"
                      className="form-control input-filed"
                      placeholder="e.g Our Classes Burger"
                      value={menuItem.discount}
                      onChange={(e) => {
                        setMenuItem({
                          ...menuItem,
                          discount: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
              </form>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <ul className="modal-buttons-list">
              <li>
                <button
                  onClick={handleClose}
                  className="btn-theme-secondary slim-button-theme "
                >
                  Cancel
                </button>
              </li>
              <li>
                <button
                  className="btn-theme-main slim-button-theme"
                  onClick={() => {
                    addUpdateData();
                  }}
                >
                  Save Changes
                </button>
              </li>
            </ul>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default AddItem;
