import React from "react";
import { Link, useHistory } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import Notifications from "../notifications/Notifications";
import profile from "../../../../assets/admin/images/logo.png";
import { Dropdown } from "react-bootstrap";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { accountActions } from "../../../../Store/Accounts/accountAction";
import { useState } from "react";
import { notificationSocket } from "../../../../Config/apiConfig";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { toast } from "react-toastify";

const Header = ({ onSupport }) => {
  const [memberData, setMemberData] = useState({
    avatar: "",
    first_name: "",
    email: "",
  });
  const [notification, setNotification] = useState({
    count: 0,
    records: [],
  });
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(accountActions.onGetProfile()).then((data) => {
      if (data?.id) {
        setMemberData({
          avatar: data?.avatar ? data?.avatar : "",
          first_name: data?.first_name ? data?.first_name : "",
          email: data?.email ? data?.email : "",
        });
      }
    });
  }, []);

  useEffect(() => {
    loadNotification();
  }, []);

  const [socket, setSocket] = useState(null);
  useEffect(() => {
    const newSocket = new W3CWebSocket(notificationSocket, null, null, {
      Authorization: `JWT ${localStorage.getItem("access_token")}`,
    });

    // console.log(`JWT ${localStorage.getItem("access_token")}`);
    newSocket.onopen = () => {
      // console.log("Connected to the server");
      setSocket(newSocket);
    };

    newSocket.onmessage = async (message) => {
      const data = JSON.parse(message.data);
      // console.log("Message Receive");
      // debugger;
      // console.log(data);
      if (data?.data) {
        toast.success(data?.data?.message);
        let setting = await JSON.parse(localStorage.getItem("setting"));
        if (data?.data?.metadata?.type === "appointment") {
          if (setting?.order_notification_sound_on) {
            // console.log(setting?.service_notification_sound?.audio_file);
            const audio = new Audio(
              setting?.service_notification_sound?.audio_file
            );
            audio.play();
            for (
              let i = 0;
              i < setting?.service_notification_sound_repeat;
              i++
            ) {
              audio.play();
            }
          }
        }
        if (data?.data?.metadata?.type === "order") {
          if (setting?.service_notification_sound_on) {
            const audio = new Audio(
              setting?.order_notification_sound?.audio_file
            );
            audio.play();
            for (let i = 0; i < setting?.order_notification_sound_repeat; i++) {
              audio.play();
            }
          }
        }
        if (data?.data?.metadata?.type === "business_subscription") {
        } else {
          const audio = new Audio(
            setting?.service_notification_sound?.audio_file
          );
          audio.play();
        }
      }
      loadNotification();
    };

    newSocket.onclose = () => {
      // console.log("Disconnected from the server");
    };

    return () => {
      newSocket.close();
    };
  }, []);

  const loadNotification = () => {
    dispatch(accountActions.onGetNotification("/?is_read=false")).then(
      (data) => {
        if (data?.results) {
          setNotification({
            count: data?.count,
            records: data?.results,
          });
        }
      }
    );
  };
  return (
    <div className="header">
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left"></div>
            <ul className="navbar-nav header-right mob-remove">
              <Dropdown
                as="li"
                className="nav-item dropdown notification_dropdown "
              >
                <Dropdown.Toggle
                  variant=""
                  as="a"
                  className="nav-link bell bell-link i-false c-pointer"
                  onClick={() => onSupport()}
                >
                  <div className="header-icon">
                    <i className="fa fa-question-circle" aria-hidden="true"></i>
                  </div>
                </Dropdown.Toggle>
              </Dropdown>
              <Dropdown
                as="li"
                className="nav-item dropdown notification_dropdown "
              >
                <Dropdown.Toggle
                  className="nav-link i-false c-pointer"
                  variant=""
                  as="a"
                  data-toggle="dropdown"
                  aria-expanded="false"
                >
                  <div className="header-icon">
                    <i className="fa fa-bell " aria-hidden="true"></i>
                  </div>

                  <span className="badge light text-white bg-warning rounded-circle">
                    {notification.count > 0 ? notification.count : ""}
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu
                  align="right"
                  className="mt-2 dropdown-menu dropdown-menu-end"
                >
                  <PerfectScrollbar className="widget-media dlab-scroll p-3 height380">
                    <Notifications recrods={notification?.records} />
                  </PerfectScrollbar>
                  <a
                    className="all-notification"
                    onClick={() => {
                      history.push("/detail-notification");
                    }}
                  >
                    See all notifications <i className="ti-arrow-right" />
                  </a>
                </Dropdown.Menu>
              </Dropdown>

              <Dropdown as="li" className="nav-item dropdown header-profile">
                <Dropdown.Toggle
                  variant=""
                  as="a"
                  className="nav-link i-false c-pointer"
                  role="button"
                  data-toggle="dropdown"
                >
                  <div className="header-profile-area">
                    <span className="person-name">
                      {`Hi ${memberData?.first_name}`}
                    </span>
                    <div
                      className="image-area"
                      style={{ border: "3px solid orange" }}
                    >
                      <img
                        src={memberData.avatar ? memberData.avatar : profile}
                        alt=""
                      />
                    </div>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu
                  align="right"
                  className="mt-3 dropdown-menu dropdown-menu-end header-drop-down"
                >
                  <div className="drop-down-profile">
                    <div className="porifle-picture">
                      <img
                        src={memberData.avatar ? memberData.avatar : profile}
                        alt=""
                      />
                    </div>
                    <div className="profile-details">
                      <p className="profile-user-name">
                        {`Hi ${memberData?.first_name}`}
                      </p>
                      <p className="profile-user-email">
                        {memberData?.email !== "null" ? memberData?.email : ""}
                      </p>
                    </div>
                  </div>
                  <hr className="drop-down-hr" />
                  <Link to="/my-account" className="dropdown-item ai-icon">
                    <i className="fa fa-user" aria-hidden="true"></i>
                    <span className="ms-2">My Account </span>
                  </Link>
                  {/* <Link to="/user-management" className="dropdown-item ai-icon">
                    <i className="fa fa-users" aria-hidden="true"></i>
                    <span className="ms-2">User Managment </span>
                  </Link> */}
                  {/* subscription */}
                  <Link to="/subscription" className="dropdown-item ai-icon">
                    <i className="fas fa-usd-circle" aria-hidden="true"></i>
                    <span className="ms-2">Subscription </span>
                  </Link>
                  {/* end of subscription */}
                  <div className="dropdown-item ai-icon">
                    <i className="fa fa-sign-out" aria-hidden="true"></i>
                    <span
                      className="ms-2"
                      onClick={() => {
                        localStorage.removeItem("refresh_token");
                        localStorage.removeItem("res");
                        localStorage.removeItem("bran");
                        localStorage.removeItem("access_token");
                        localStorage.removeItem("name");
                        localStorage.removeItem("email");
                        localStorage.removeItem("type");
                        localStorage.removeItem("bus");
                        localStorage.removeItem("avatar");
                        localStorage.removeItem("subscription");
                        history.push("/");
                      }}
                    >
                      Log Out{" "}
                    </span>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
