import React from "react";


const Dheader = () => {
	return(
		<>
        <div className="container">
            <div className="row mb-2">
            <div className="col-md-9">
             <h3 className="inner-header">Dashboard</h3>
            </div>
                    <div className="col-md-3 text-right">
                        
            {/* <ul className="dashbaord-date-filter">
                <li><input className="filter-date" type="date" /></li>
                <li><input className="filter-date" type="date" /></li>
                <li><i class="fa fa-calendar" aria-hidden="true"></i></li>

            </ul> */}
            </div>
            </div>
        </div>
		</>
	)
}
export default Dheader;