import React, { useState } from "react";
import { Link } from "react-router-dom";
import giveawayapp from "../../../assets/images/qrscan/giveawayapp.png";
import "./qrstyle.css"


function Giveaway() {

    const [numberProduct,setNumberProduct] = useState(0);
  const handleCartplus = () => {
    setNumberProduct(numberProduct+1);
  }
  const handleCartMinus = () => {
    if(numberProduct>0){
      setNumberProduct(numberProduct-1); 
    }
  }
    

    return (<>    
    
    <div className="row pt-5 black-bg px-2">
        <div className="col-md-9 col-3">
          <Link to="restaurant-menu-homepage"><i class="fa fa-chevron-left orange-text"></i></Link>
            </div>
            <div className="col-6 text-center">
                <p className="text-white">Checkout</p>
        </div>
        <div className="col-md-3 col-3 d-flex text-right">
        </div>
        </div>
        <div className="row white-bg full-height">
            <div className="col-12 py-3 text-center">
                <img src={giveawayapp} />
            </div>

            <div className="col-12">
                <label htmlFor="paybill" className="pl-2 font-weight-bold mt-2">Pay Bill</label>
                <input type="text" className="form-control mobile-field pl-2" placeholder="₦70.12" />
                
                <label htmlFor="paybill" className="pl-2 font-weight-bold mt-4">Wallet Address</label>
                <input type="text" className="form-control mobile-field pl-2" placeholder="Add Wallet Address" />
            </div>
            <div className="col-12 text-center py-4">
                <button className="btn btn-primary btn-lg">Continue</button>
            </div>

        <div className="col-12 py-0 my-0">
        <label htmlFor="" className="font-weight-bold pl-2">Click URL if you Dont have the app</label>
        </div>
        <div className="col-12 py-0 my-0">
          <div class="input-group py-0 my-0 move-up">
            <input type="text" class="form-control mobile-field pl-2" placeholder="www.giveaway.io/paymenlink" />
            <button class="btn btn-primary from-btn" type="button">Open Link</button>
        </div>  
        </div>
        </div>
        
    </>)
}

export default Giveaway;