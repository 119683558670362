import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import { menuActions } from "../../../../Store/Menu/menuAction";
import Loader from "../../../Common/Loader";
import Switch from "react-switch";
import { rootURL } from "../../../../Config/apiConfig";
import { catalogActions } from "../../../../Store/Catalogs/catalogAction";
import { toast } from "react-toastify";
const timeSlot = [
  {
    time: "15",
  },
  {
    time: "30",
  },
  {
    time: "45",
  },
  {
    time: "60",
  },
  {
    time: "75",
  },
  {
    time: "90",
  },
  {
    time: "105",
  },
  {
    time: "120",
  },
  {
    time: "135",
  },
  {
    time: "150",
  },
  {
    time: "165",
  },
  {
    time: "180",
  },
  {
    time: "195",
  },
  {
    time: "210",
  },
  {
    time: "225",
  },
  {
    time: "240",
  },
  {
    time: "255",
  },
  {
    time: "270",
  },
  {
    time: "285",
  },
  {
    time: "300",
  },
];

function AddItem({
  setSetcionClose,
  itemCategory,
  selectedItem,
  selectedSectionId,
  gallery,
}) {
  let imag = selectedItem?.feature_image?.includes("http");
  const [show, setShow] = useState(true);
  const [featuredImg, setFeaturedImg] = useState(null);
  const [selectFeaturedImg, setSelectFeaturedImg] = useState(
    selectedItem?.feature_image
      ? imag
        ? selectedItem.feature_image
        : selectedItem.feature_image
      : null
  );
  const [isLoading, setLoading] = useState(false);
  const [menuItem, setMenuItem] = useState({
    name: selectedItem?.service?.name ? selectedItem.service.name : "",
    description: selectedItem?.service?.description
      ? selectedItem.service.description
      : "",
    feature_image: selectedItem?.feature_image
      ? imag
        ? selectedItem.feature_image
        : selectedItem.feature_image
      : "",
    price: selectedItem?.price ? selectedItem.price : 0,
    service_time: selectedItem?.service_time ? selectedItem.service_time : 0,
    item: selectedItem?.section?.id ? selectedItem?.section?.id : 0,
    available: selectedItem?.available != null ? selectedItem.available : true,
    service_time:
      selectedItem?.service_time != null ? selectedItem.service_time : 30,
    walk_in: selectedItem?.walk_in != null ? selectedItem.walk_in : true,
    call_out: selectedItem?.call_out != null ? selectedItem.call_out : true,
    discount: selectedItem?.discount ? selectedItem.discount : 0,
  });
  const dispatch = useDispatch();
  const handleClose = () => {
    setShow(false);
    setSetcionClose(false);
  };
  var secId = parseInt(window.location.href.split("?id=")[1]);
  const addUpdateData = () => {
    if (
      menuItem?.name !== "" &&
      menuItem?.price !== 0 &&
      (featuredImg !== null ||
        selectFeaturedImg !== null ||
        menuItem?.feature_image)
    ) {
      setLoading(true);
      let resturantItemData = {};
      if (selectFeaturedImg) {
        resturantItemData = {
          name: menuItem?.name,
          description: menuItem?.description,
          feature_image: menuItem?.feature_image,
          price: parseInt(menuItem?.price),
          service_time: parseInt(menuItem?.service_time),
          discount: parseInt(menuItem?.discount),
          available: menuItem?.available,
          walk_in: menuItem?.walk_in,
          call_out: menuItem?.call_out,
        };
      } else {
        resturantItemData = new FormData();
        resturantItemData.append("name", menuItem.name);
        resturantItemData.append("description", menuItem.description);
        featuredImg && resturantItemData.append("feature_image", featuredImg);
        resturantItemData.append("price", parseInt(menuItem.price));
        resturantItemData.append(
          "service_time",
          parseInt(menuItem.service_time)
        );
        resturantItemData.append("discount", parseInt(menuItem.discount));
        resturantItemData.append("available", menuItem.available);
        resturantItemData.append("service_time", menuItem.service_time);
        resturantItemData.append("walk_in", menuItem.walk_in);
        resturantItemData.append("call_out", menuItem.call_out);
      }
      let sectionId = parseInt(
        menuItem?.item === "" || menuItem?.item === 0
          ? itemCategory[0]?.id
          : menuItem.item
      );
      if (selectedItem?.id) {
        dispatch(
          catalogActions.onUpdateSectionServices(
            sectionId,
            resturantItemData,
            selectedItem?.id
          )
        ).then((res) => {
          if (res?.id) {
            setLoading(false);
            handleClose();
          } else {
            setLoading(false);
          }
        });
      } else {
        dispatch(
          catalogActions.onCreateSectionServices(sectionId, resturantItemData)
        ).then((res) => {
          if (res?.id) {
            setLoading(false);
            handleClose();
          } else {
            setLoading(false);
          }
        });
      }
    } else {
      toast.error("Please fill name, price or Item Image");
    }
  };
  return (
    <>
      {isLoading ? <Loader /> : null}
      <div className="modalAreaCentered text-center">
        <Modal show={show} onHide={handleClose} size="lg" centered>
          <Modal.Header closeButton>
            <Modal.Title>Item Settings</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-area item-setting-form">
              <form>
                <div className="yawaevig-form-row">
                  <div className="form-group-yawaevig">
                    <lable>Product Name</lable>
                    <input
                      type="text"
                      className="form-control input-filed"
                      placeholder="e.g Our Classes Burger"
                      value={menuItem.name}
                      onChange={(e) => {
                        setMenuItem({
                          ...menuItem,
                          name: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="form-group-yawaevig">
                    <lable>Product Category</lable>
                    <select
                      name="position"
                      class="form-control input-filed"
                      placeholder="e.g 3142121"
                      value={menuItem.item}
                      onChange={(e) => {
                        setMenuItem({
                          ...menuItem,
                          item: e.target.value,
                        });
                      }}
                    >
                      {itemCategory?.map((x, i) => {
                        return (
                          <option key={i} value={x.id}>
                            {x.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="form-group-yawaevig">
                  <lable>Description</lable>
                  <textarea
                    className="form-control input-filed"
                    placeholder="Add Description"
                    value={menuItem.description}
                    onChange={(e) => {
                      setMenuItem({
                        ...menuItem,
                        description: e.target.value,
                      });
                    }}
                  ></textarea>
                </div>

                <div className="item-image-area">
                  <div className="right-image-preview">
                    <lable>Preview</lable>
                    {menuItem?.feature_image && (
                      <img
                        src={menuItem.feature_image}
                        className="item-setting-preview"
                      />
                    )}
                  </div>
                  <div className="left-image-upload">
                    <div className="upload-file-field">
                      <lable>Item Image</lable>
                      <input
                        type="file"
                        name="myImage"
                        accept="image/png,image/jpeg"
                        onChange={(e) => {
                          if (e.target.files && e.target.files[0]) {
                            let img = e.target.files[0];
                            setFeaturedImg(e.target.files[0]);
                            const ava = URL.createObjectURL(img);
                            setMenuItem({
                              ...menuItem,
                              feature_image: ava,
                            });
                            setSelectFeaturedImg(null);
                          }
                        }}
                      />
                      <span>Remove Image</span>
                    </div>
                    <div className="suggested-images-area">
                      <lable>Suggest Image</lable>
                      <ul className="images-list-suggestion">
                        {gallery?.map((x) => (
                          <li
                            onClick={() => {
                              setSelectFeaturedImg(x?.image);
                              setMenuItem({
                                ...menuItem,
                                feature_image: x?.image,
                              });
                            }}
                          >
                            <img src={x?.image} />
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="switch-btns-area">
                  <div className="left-btns-menu">
                    <div className="instock div-flex-andspaceb">
                      <lable>Availablility</lable>
                      <Switch
                        onChange={() => {
                          setMenuItem({
                            ...menuItem,
                            available: !menuItem?.available,
                          });
                        }}
                        checked={menuItem?.available}
                      />
                    </div>
                    {/* <div className="roomservice div-flex-andspaceb">
                      <label>Walk-In</label>
                      <Switch
                        onChange={() => {
                          setMenuItem({
                            ...menuItem,
                            walk_in: !menuItem?.walk_in,
                          });
                        }}
                        checked={menuItem?.walk_in}
                      />
                    </div> */}
                  </div>
                  <div className="right-btns-menu">
                    <div className="deliveryenable div-flex-andspaceb">
                      <lable> </lable>
                      {/* <Switch
                        onChange={() => {
                          setMenuItem({
                            ...menuItem,
                            service_time: !menuItem?.service_time,
                          });
                        }}
                        checked={menuItem?.service_time}
                      /> */}
                    </div>
                    {/* <div className="dinein div-flex-andspaceb">
                      <label>Call-Out</label>
                      <Switch
                        onChange={() => {
                          setMenuItem({
                            ...menuItem,
                            call_out: !menuItem?.call_out,
                          });
                        }}
                        checked={menuItem?.call_out}
                      />
                    </div> */}
                  </div>
                </div>
                <div className="yawaevig-form-row">
                  <div className="form-group-yawaevig">
                    <lable>Service Time (Minutes)</lable>
                    {/* <input
                      type="number"
                      className="form-control input-filed"
                      placeholder="Minutes"
                      min="1"
                      max="240"
                      value={menuItem.service_time}
                      onChange={(e) => {
                        setMenuItem({
                          ...menuItem,
                          service_time: e.target.value,
                        });
                      }}
                    /> */}
                    <select
                      className="form-control input-filed"
                      value={menuItem?.service_time}
                      onChange={(e) => {
                        setMenuItem({
                          ...menuItem,
                          service_time: e.target.value,
                        });
                      }}
                    >
                      {timeSlot?.map((x, i) => {
                        return (
                          <option key={i} value={x?.time}>
                            {x?.time}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="yawaevig-form-row">
                  <div className="form-group-yawaevig">
                    <lable>Price</lable>
                    <input
                      type="text"
                      className="form-control input-filed"
                      placeholder="e.g Our Classes Burger"
                      value={menuItem.price}
                      onChange={(e) => {
                        setMenuItem({
                          ...menuItem,
                          price: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="form-group-yawaevig">
                    <lable>Discount</lable>
                    <input
                      type="text"
                      className="form-control input-filed"
                      placeholder="e.g Our Classes Burger"
                      value={menuItem.discount}
                      onChange={(e) => {
                        setMenuItem({
                          ...menuItem,
                          discount: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
              </form>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <ul className="modal-buttons-list">
              <li>
                <button
                  onClick={handleClose}
                  className="btn-theme-secondary slim-button-theme "
                >
                  Cancel
                </button>
              </li>
              <li>
                <button
                  className="btn-theme-main slim-button-theme"
                  onClick={() => {
                    addUpdateData();
                  }}
                >
                  Save Changes
                </button>
              </li>
            </ul>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default AddItem;
