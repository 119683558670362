import { resturantTypes } from "./resturantTypes";
import { endpoints } from "../../Config/apiConfig";
import { axiosInstance } from "../../Config/axiosInstance";

const onChange = (field, value) => {
  return (dispatch) => {
    dispatch({
      type: resturantTypes.RESTURANT_ONCHANGE,
      payload: { prop: field, value: value },
    });
  };
};

const onCreateRestaurants = (data, access) => async (dispatch) => {
  let data = new FormData();
  data.append("restaurant[name]", "Food Lab");
  data.append("restaurant[tagline]", "We deliver success story");
  data.append("restaurant[description]", "We deliver your desire tast");
  data.append("restaurant[branding_color_primary]", "#ffff");
  data.append("restaurant[branding_color_secondary]", "#ffff");
  data.append("restaurant[primary_contact_no]", "+92333");
  data.append("restaurant[secondary_phone_no]", "+92333");
  data.append("restaurant[whatsapp]", "+92333");
  data.append("restaurant[email]", "faizan1@gmail.com");
  data.append("branch[address]", "Some address123");
  data.append("branch[city]", "2");
  var response = await axiosInstance.postApi(
    data,
    endpoints.resturantApi.createRestaurant
  );
  return response;
};

const onGetRestaurants = (res) => async (dispatch) => {
  var response = await axiosInstance.getApiWithoutToken(
    `${endpoints.resturantApi.createRestaurant}${res}/`
  );
  return response;
};

const onGetRestaurantsByVenu = (venu_name) => async (dispatch) => {
  var response = await axiosInstance.getApiWithoutToken(
    `${endpoints.resturantApi.restaurantByVenu}${venu_name}`
  );
  return response;
};

const onGetWorkers = (page) => async (dispatch) => {
  var bran = await localStorage.getItem("bran");
  var response = await axiosInstance.getApi(
    page !== null
      ? `${endpoints.resturantApi.getWorkers(bran)}${page}`
      : endpoints.resturantApi.getWorkers(bran)
  );
  return response;
};
const onGetWorkersById = (id) => async (dispatch) => {
  var bran = await localStorage.getItem("bran");
  var response = await axiosInstance.getApi(
    `${endpoints.resturantApi.getWorkers(bran)}/${id}`
  );
  return response;
};
const onCreateWorkers = (data) => async (dispatch) => {
  var bran = await localStorage.getItem("bran");
  var response = await axiosInstance.postApi(
    data,
    endpoints.resturantApi.createWorkers(bran)
  );
  return response;
};
const onUpdateWorkers = (data, id) => async (dispatch) => {
  var bran = await localStorage.getItem("bran");
  var response = await axiosInstance.updateApi(
    data,
    `${endpoints.resturantApi.updateWorkers(bran)}${id}/`
  );
  return response;
};
const onDeleteWorkers = (data, id) => async (dispatch) => {
  var bran = await localStorage.getItem("bran");
  var response = await axiosInstance.deleteApi(
    data,
    `${endpoints.resturantApi.deleteWorkers(bran)}${id}/`
  );
  return response;
};

const onCreateScheduleWorkers = (data, workerid, id) => async (dispatch) => {
  var response = await axiosInstance.updateApi(
    data,
    `${endpoints.resturantApi.scheduleWorkers}${workerid}/schedules/${id}/`
  );
  return response;
};

const onGetWorkersPosition = (type) => async (dispatch) => {
  var response = await axiosInstance.getApi(
    `${endpoints.resturantApi.workersPositions}?type=${type}`
  );
  return response;
};

const onGetProfessionalServices = () => async (dispatch) => {
  var bus = await localStorage.getItem("bus");
  var response = await axiosInstance.getApi(
    `${endpoints.businessApi.getCatalogServices(bus)}`
  );
  return response;
};

const onGetAllOrder = (date) => async (dispatch) => {
  var res = await localStorage.getItem("res");
  var response = await axiosInstance.getApi(
    `${endpoints.orderApi.getAllOrder(res)}${date}`
  );
  return response;
};

const onGetFilterOrder = (link) => async (dispatch) => {
  var res = await localStorage.getItem("res");
  var response = await axiosInstance.getApi(
    `${endpoints.orderApi.getAllOrder(res)}${link}`
  );
  return response;
};

const onChangeOrderStatus = (orderId, orderStatus) => async (dispatch) => {
  var response = await axiosInstance.postApiWithoutData(
    `${endpoints.orderApi.orderStatus}${orderId}/status/${orderStatus}`
  );
  return response;
};

const onGetLoggedUserResturant = () => async (dispatch) => {
  var response = await axiosInstance.getApi(
    `${endpoints.resturantApi.getLoggedUserResturant}`
  );
  return response;
};

const onGetLoggedUserDetail = () => async (dispatch) => {
  var response = await axiosInstance.getApi(
    `${endpoints.resturantApi.getLoggedUserDetail}`
  );
  return response;
};

const onGetNotificationRestaurant = (restaurantId) => async (dispatch) => {
  var response = await axiosInstance.getApi(
    `${endpoints.settingApi.notificationRestaurant(restaurantId)}`
  );
  return response;
};

const onGetSoundClips = () => async (dispatch) => {
  var response = await axiosInstance.getApi(
    `${endpoints.settingApi.soundClips}`
  );
  return response;
};

const onSaveResturantNotificationSetting =
  (restaurantId, notificationData) => async (dispatch) => {
    var response = await axiosInstance.updateApi(
      notificationData,
      `${endpoints.settingApi.notificationRestaurant(restaurantId)}`
    );
    return response;
  };

const onCreateSocialMedia = (data, restaurantId) => async (dispatch) => {
  var response = await axiosInstance.postApi(
    data,
    `${endpoints.settingApi.createSocialMedia(restaurantId)}`
  );
  return response;
};

const onUpdateSocialMedia =
  (data, restaurantId, updateId) => async (dispatch) => {
    var response = await axiosInstance.updateApi(
      data,
      `${endpoints.settingApi.updateSocialMedia(restaurantId, updateId)}`
    );
    return response;
  };
const onUpdateSchedules = (data, restaurantId) => async (dispatch) => {
  var response = await axiosInstance.updateApi(
    data,
    `${endpoints.settingApi.schedules(restaurantId)}`
  );
  return response;
};

const onCreateSchedules = (data, restaurantId) => async (dispatch) => {
  var response = await axiosInstance.postApi(
    data,
    `${endpoints.settingApi.schedules(restaurantId)}`
  );
  return response;
};

const onUpdateRestaurant = (data) => async (dispatch) => {
  var response = await axiosInstance.updateApi(
    data,
    `${endpoints.resturantApi.updateRestaurant}`
  );
  return response;
};

const onGenerateQRCode = (data) => async (dispatch) => {
  var response = await axiosInstance.postApi(
    data,
    `${endpoints.qrCode.getQRCode}`
  );
  return response;
};

const onGetQRCode = () => async (dispatch) => {
  var response = await axiosInstance.getApi(`${endpoints.qrCode.getQRCode}`);
  return response;
};

const onUpdateQRCode = (data) => async (dispatch) => {
  var response = await axiosInstance.updateApi(
    data,
    `${endpoints.qrCode.updateQRCode}`
  );
  return response;
};

const onGetDashboardSummary = (id) => async (dispatch) => {
  var response = await axiosInstance.getApi(
    `${endpoints.dashboard.getSummary(id)}`
  );
  return response;
};

const onGetCustomerCart = (id, customer) => async (dispatch) => {
  var response = await axiosInstance.getApi(
    `${endpoints.cart.getCart(id, customer)}`
  );
  return response;
};

const onAddCart = (id, cart, item) => async (dispatch) => {
  var response = await axiosInstance.updateApiWithoutToken(
    null,
    `${endpoints.cart.addCart(id, cart)}${item}/add`
  );
  return response;
};

const onSubCart = (id, cart, item) => async (dispatch) => {
  var response = await axiosInstance.updateApiWithoutToken(
    null,
    `${endpoints.cart.addCart(id, cart)}${item}/sub`
  );
  return response;
};

const onCheckoutCart = (id, customer, data) => async (dispatch) => {
  var response = await axiosInstance.postApi(
    data,
    `${endpoints.cart.subCart(id, customer)}`
  );
  return response;
};

const onAssignProfessionalToService = (serviceId, data) => async (dispatch) => {
  var response = await axiosInstance.postApi(
    data,
    `${endpoints.businessApi.assignProfessionalToService(serviceId)}`
  );
  return response;
};

const onDeAssignProfessionalToService = (serviceId, id) => async (dispatch) => {
  var response = await axiosInstance.deleteApiWithoutData(
    `${endpoints.businessApi.assignProfessionalToService(serviceId)}${id}/`
  );
  return response;
};
const onGetAssignProfessionalToService =
  (professionalId) => async (dispatch) => {
    var response = await axiosInstance.getApiWithoutToken(
      `${endpoints.businessApi.getAssignProfessionalToService(
        professionalId
      )}?resPerPage=500`
    );
    return response;
  };

const onGetGalleries = (type) => async (dispatch) => {
  var response = await axiosInstance.getApi(
    `${endpoints.gallery.getGallery(type)}`
  );
  return response;
};

const onGenerateStaffQRCode = (data, id) => async (dispatch) => {
  var response = await axiosInstance.postApi(
    data,
    `${endpoints.qrCode.staffQRCode(id)}`
  );
  return response;
};

const onGetStaffQRCode = (id) => async (dispatch) => {
  var response = await axiosInstance.getApi(
    `${endpoints.qrCode.staffQRCode(id)}`
  );
  return response;
};

const onUpdateStaffQRCode = (data, id) => async (dispatch) => {
  var response = await axiosInstance.updateApi(
    data,
    `${endpoints.qrCode.staffQRCode(id)}/update`
  );
  return response;
};

const onGetDineIn = (id) => async (dispatch) => {
  var response = await axiosInstance.getApiWithoutToken(
    `${endpoints.resturantApi.dineIn(id)}`
  );
  return response;
};

const onGetDelivery = (id) => async (dispatch) => {
  var response = await axiosInstance.getApiWithoutToken(
    `${endpoints.resturantApi.delivery(id)}`
  );
  return response;
};

const onCreateStaffDineIn = (res, staff) => async (dispatch) => {
  var response = await axiosInstance.getApiWithoutToken(
    `${
      staff > 0
        ? `${endpoints.resturantApi.createStaffDineIn(res)}${staff}/dine-in`
        : `${endpoints.resturantApi.createStaffDineIn(res)}dine-in`
    }`
  );
  return response;
};

const onCreateStaffDelivery = (res, staff) => async (dispatch) => {
  var response = await axiosInstance.getApiWithoutToken(
    `${
      staff > 0
        ? `${endpoints.resturantApi.createStaffDelivery(res)}${staff}/delivery`
        : `${endpoints.resturantApi.createStaffDelivery(res)}delivery`
    }`
  );
  return response;
};

const onResturantAddCart = (data, id, cart) => async (dispatch) => {
  var response = await axiosInstance.postApiWithoutToken(
    data,
    `${endpoints.cart.returentAddCart(id, cart)}`
  );
  return response;
};

const onResturantRemoveCart = (data, id, cart, cartid) => async (dispatch) => {
  var response = await axiosInstance.deleteApiWithoutToken(
    data,
    `${endpoints.cart.returentRemoveCart(id, cart)}${cartid}`
  );
  return response;
};

const onGetResturantCart = (id, cart) => async (dispatch) => {
  var response = await axiosInstance.getApiWithoutToken(
    `${endpoints.cart.returentGetCart(id, cart)}`
  );
  return response;
};

const onResturantCartCheckout = (data, id, cart) => async (dispatch) => {
  var response = await axiosInstance.postApiWithoutToken(
    data,
    `${endpoints.cart.returentGetCart(id, cart)}/checkout`
  );
  return response;
};

const onGetBusinessTypes = (name) => async (dispatch) => {
  var response = await axiosInstance.getApi(
    `${endpoints.settingApi.getBusinessTypes}?type=${name}`
  );
  return response;
};

const onBusinessCheckout = (data, id) => async (dispatch) => {
  var response = await axiosInstance.postApiWithoutToken(
    data,
    `${endpoints.businessApi.appointments(id)}`
  );
  return response;
};

const onGetTip = (type) => async (dispatch) => {
  var response = await axiosInstance.getApiWithoutToken(
    `${endpoints.cart.retusrentGetTip(type)}`
  );
  return response;
};

export const resturantActions = {
  onChange: onChange,
  onCreateRestaurants: onCreateRestaurants,
  onGetRestaurants: onGetRestaurants,
  onGetRestaurantsByVenu: onGetRestaurantsByVenu,
  onGetWorkers: onGetWorkers,
  onCreateWorkers: onCreateWorkers,
  onUpdateWorkers: onUpdateWorkers,
  onDeleteWorkers: onDeleteWorkers,
  onGetWorkersById: onGetWorkersById,
  onCreateScheduleWorkers: onCreateScheduleWorkers,
  onGetWorkersPosition: onGetWorkersPosition,
  onGetAllOrder: onGetAllOrder,
  onChangeOrderStatus: onChangeOrderStatus,
  onGetLoggedUserResturant: onGetLoggedUserResturant,
  onGetNotificationRestaurant: onGetNotificationRestaurant,
  onGetSoundClips: onGetSoundClips,
  onSaveResturantNotificationSetting: onSaveResturantNotificationSetting,
  onUpdateSchedules: onUpdateSchedules,
  onCreateSchedules: onCreateSchedules,
  onUpdateSocialMedia: onUpdateSocialMedia,
  onUpdateRestaurant: onUpdateRestaurant,
  onCreateSocialMedia: onCreateSocialMedia,
  onGenerateQRCode: onGenerateQRCode,
  onGetQRCode: onGetQRCode,
  onUpdateQRCode: onUpdateQRCode,
  onGetLoggedUserDetail: onGetLoggedUserDetail,
  onGetDashboardSummary: onGetDashboardSummary,
  onGetCustomerCart: onGetCustomerCart,
  onAddCart: onAddCart,
  onSubCart: onSubCart,
  onCheckoutCart: onCheckoutCart,
  onGetProfessionalServices: onGetProfessionalServices,
  onAssignProfessionalToService: onAssignProfessionalToService,
  onDeAssignProfessionalToService: onDeAssignProfessionalToService,
  onGetAssignProfessionalToService: onGetAssignProfessionalToService,
  onGetGalleries: onGetGalleries,
  onGenerateStaffQRCode: onGenerateStaffQRCode,
  onGetStaffQRCode: onGetStaffQRCode,
  onUpdateStaffQRCode: onUpdateStaffQRCode,
  onGetDineIn: onGetDineIn,
  onGetDelivery: onGetDelivery,
  onCreateStaffDineIn: onCreateStaffDineIn,
  onCreateStaffDelivery: onCreateStaffDelivery,
  onResturantAddCart: onResturantAddCart,
  onResturantRemoveCart: onResturantRemoveCart,
  onGetResturantCart: onGetResturantCart,
  onResturantCartCheckout: onResturantCartCheckout,
  onGetBusinessTypes: onGetBusinessTypes,
  onBusinessCheckout: onBusinessCheckout,
  onGetFilterOrder: onGetFilterOrder,
  onGetTip: onGetTip,
};
