import { useState } from "react";
import member from "../../../assets/images/dashboard/staff/memberimg.png";
import { accountActions } from "../../../Store/Accounts/accountAction";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import Dheader from "./dheader";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Loader from "../../Common/Loader";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";

function Profile() {
  const [memberData, setMemberData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: 0,
    busnissId: 0,
    position: "",
    walletaddress: "",
    avatar: "",
    profile_image: "",
    user_app_id: "",
  });
  const [profile, setProfile] = useState("");
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    setLoading(true);
    dispatch(accountActions.onGetProfile()).then((data) => {
      setLoading(false);
      if (data) {
        setMemberData({
          firstName: data?.first_name,
          lastName: data?.last_name,
          walletaddress: data?.wallet,
          mobileNumber: data?.contact_no,
          avatar: data?.avatar,
          user_app_id: data?.user_app_id,
        });
      }
    });
  }, []);
  const saveBtnPress = () => {
    setLoading(true);
    let data = new FormData();
    data.append("first_name", memberData.firstName);
    data.append("last_name", memberData.lastName);
    profile && data.append("avatar", profile);
    data.append("contact_no", memberData.mobileNumber);
    data.append("wallet", memberData.walletaddress);
    data.append("user_app_id", memberData.user_app_id);
    dispatch(accountActions.onUpdateProfile(data)).then((res) => {
      if (res?.id) {
        toast.success("Account information updated");
      } else {
        // toast.error("Some thing went wrong. please try leter.");
      }
      setLoading(false);
    });
  };
  return (
    <>
      {isLoading ? <Loader /> : null}
      <div className="row m-1 pb-3">
        <div className="col-md-6">
          <h2 className="heading">My Account</h2>
        </div>
        <div className="col-md-6 text-right">
          <button
            className="btn btn-info mr-3"
            onClick={() => {
              history.push("/dashboard");
            }}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary"
            onClick={() => {
              saveBtnPress();
            }}
          >
            Save
          </button>
        </div>
      </div>

      <div className="row m-2">
        <div className="white-bg rounded py-3">
          <div className="col-md-12 col-12 py-3 text-center">
            {/* <img src={member} />
            <p className="orange-text">Upload Photo</p> */}
            <label>
              <input
                style={{ display: "none" }}
                type="file"
                name="myImage"
                accept="image/png, image/jpeg, image/jpg, image/png"
                onChange={(e) => {
                  if (e.target.files && e.target.files[0]) {
                    let img = e.target.files[0];
                    setProfile(e.target.files[0]);
                    const ava = URL.createObjectURL(img);
                    setMemberData({
                      ...memberData,
                      avatar: ava,
                    });
                  }
                }}
              />
              {memberData?.avatar ? (
                <img
                  className="mouse-pointer"
                  style={{
                    width: "100px",
                    height: "100px",
                    borderRadius: "100px",
                  }}
                  src={memberData.avatar}
                />
              ) : (
                <span className="fa fa-camera staff-img" />
              )}
            </label>
          </div>
          <div className="row px-4">
            <div className="col-md-6 col-xs-12 px-4 py-2">
              <lable className="form-label">First Name</lable>
              <input
                type="text"
                name="firstname"
                class="form-control input-filed"
                placeholder="Jason"
                value={memberData.firstName}
                onChange={(e) => {
                  setMemberData({
                    ...memberData,
                    firstName: e.target.value,
                  });
                }}
              />
            </div>
            <div className="col-md-6 col-xs-12 px-4 py-2">
              <lable className="form-label">Last Name</lable>
              <input
                type="text"
                name="lastname"
                class="form-control input-filed"
                placeholder="Reese"
                value={memberData.lastName}
                onChange={(e) => {
                  setMemberData({
                    ...memberData,
                    lastName: e.target.value,
                  });
                }}
              />
            </div>
            <div className="col-md-6 col-xs-12 px-4 py-2">
              <lable className="form-label">Mobile Number</lable>
              <PhoneInput
                country={"ng"}
                value={memberData.mobileNumber}
                onChange={(phone) => {
                  let value = null;
                  if (phone != "0" && phone != "") {
                    value = phone;
                  }
                  setMemberData({
                    ...memberData,
                    mobileNumber: value,
                  });
                }}
              />
            </div>
            {/* <div className="col-md-6 col-xs-12 px-4 py-2">
              <lable className="form-label">Business ID</lable>
              <input
                type="text"
                name="lastname"
                class="form-control input-filed"
                placeholder="81923478913"
              />
            </div>
            <div className="col-md-6 col-xs-12 px-4 py-2">
              <lable className="form-label">Position</lable>
              <input
                type="text"
                name="mobile"
                class="form-control input-filed"
                placeholder="Super Admin"
              />
            </div>*/}
            {/* <div className="col-md-6 col-xs-12 px-4 py-2">
              <lable className="form-label">Wallet Address</lable>
              <input
                type="text"
                name="lastname"
                class="form-control input-filed"
                placeholder="12124523453mefhjew23"
                value={memberData.walletaddress}
                onChange={(e) => {
                  let value = ""
                  if(e.target.value!="0"&&e.target.value!=""){
                    value = e.target.value
                  }
                  setMemberData({
                    ...memberData,
                    walletaddress: value,
                  });
                }}
              />
            </div> */}

            <div className="col-md-6 col-xs-12 px-4 py-2">
              <lable className="form-label">Wallet</lable>
              <input
                type="text"
                name="lastname"
                class="form-control input-filed"
                placeholder="Give Away Wallet"
                value={memberData.user_app_id}
                onChange={(e) => {
                  console.log(e.target.value);
                  setMemberData({
                    ...memberData,
                    user_app_id: e.target.value,
                  });
                }}
              />
            </div>

            <div className="col-md-12 col-xs-12 px-4 py-2">&nbsp;</div>
            <div className="col-md-12 col-xs-12 px-4 py-2">&nbsp;</div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Profile;
