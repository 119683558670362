import React from "react";

function CommonHero({ title }) {
  return (
    <>
      <div className="page-title-area">
        <div className="section__bg"></div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 py-5">
              <div className="page-title-content text-center">
                <h3 className="title">{title}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CommonHero;
