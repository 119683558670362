import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import DeshboardLayout from "../layouts/DeshboardLayout";
import Table from "./table";
import "./trans.css";

function Transactions() {
  const history = useHistory();

  const redirectTo = (id) => {
    history.push(`/order-detail?id=${id}`);
  };

  return (
    <>
      <div id="main-wrapper" class="show over-flow-block">
        <DeshboardLayout />
        <div className="content-body">
          <Table redirectTo={(id) => redirectTo(id)} />
        </div>
      </div>
    </>
  );
}

export default Transactions;
