import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Dropdown from "react-bootstrap/Dropdown";
import {
  getFormatedDate,
  getFormatedTime,
  getStatus,
} from "../partials/status";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

const past = ({
  pastData,
  onOrderStatusChange,
  redirectTo,
  pastHandleChange,
  pastPageSize,
}) => {
  return (
    <>
      <div className="row">
        <table className="table table-borderless">
          <thead>
            <tr>
              <th scope="col" className="mob-remove">
                Date{" "}
              </th>
              <th scope="col" className="order-detail">
                Order ID
              </th>
              <th scope="col" className="mob-remove">
                Order Type
              </th>
              <th scope="col" className="mob-remove">
                Order Status
              </th>
              <th scope="col" className="mob-remove">
                Payment Status
              </th>
              <th scope="col" className="mob-remove">
                Payment Type
              </th>
              <th scope="col" className="mob-remove">
                Amount
              </th>
              <th scope="col" className="text-center order-detail">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {pastData.map((x) => {
              return (
                <tr>
                  <td scope="row" className="text-center mob-remove">
                    {getFormatedDate(x.placed_at)}
                    <br></br>
                    {getFormatedTime(x.placed_at)}
                  </td>
                  <td>
                    <span
                      className="form-label order-detail"
                      style={{ cursor: "pointer", color: "#F9A05F" }}
                      onClick={() => {
                        redirectTo(x?.order_id);
                      }}
                    >
                      {x?.order_id}
                    </span>
                  </td>
                  <td className="mob-remove">{getStatus(x?.order_type)}</td>
                  <td className="mob-remove">{getStatus(x?.order_status)}</td>
                  <td className="mob-remove">{getStatus(x?.payment_status)}</td>
                  <td className="mob-remove">{getStatus(x?.payment_mode)}</td>
                  <td className="mob-remove">{`${x?.currency} ${x?.total_bill_amount}`}</td>
                  <td className="text-center">
                    {/* drop down */}
                    <Dropdown>
                      <Dropdown.Toggle variant="abbs" id="dropdown-basic">
                        <span>
                          <i class="far fa-ellipsis-h"></i>
                        </span>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => {
                            onOrderStatusChange(x?.order_id, "Delivered");
                          }}
                        >
                          Mark As Delivered
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            onOrderStatusChange(x?.order_id, "Complete");
                          }}
                        >
                          Mark As Complete
                        </Dropdown.Item>
                        <Dropdown.Item href="#/action-3">
                          Remove Order
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    {/* end of dropdown */}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {pastData?.length === 0 && (
          <span
            style={{
              display: "flex",
              justifyContent: "center",
              fontSize: "20px",
              marginBottom: "50px",
            }}
          >
            You haven’t any Order.
          </span>
        )}
      </div>
      {pastPageSize > 1 && (
        <Stack spacing={2}>
          <Pagination count={pastPageSize} onChange={pastHandleChange} />
        </Stack>
      )}
    </>
  );
};

export default past;
