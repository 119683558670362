import React, { useState } from 'react'
import { Link } from 'react-router-dom'

/// Images

import MsgBox from './MsgBox'

const FAQs = ({ PerfectScrollbar, toggleChatBox, toggleTab }) => {
  const [openMsg, setOpenMsg] = useState(false)
  return (
    <div
      className={`tab-pane fade  ${toggleTab === 'faqs' ? 'active show' : ''}`}
      id='faqs'
      role='tabpanel'
    >
      <div
        className={`card mb-sm-3 mb-md-0 contacts_card dlab-chat-user-box ${
          openMsg ? 'd-none' : ''
        }`}
      >
        
        <PerfectScrollbar
          className={`card-body contacts_body p-0 dlab-scroll  ${
            toggleChatBox ? 'ps ps--active-y' : ''
          }`}
          id='DZ_W_Contacts_Body'
        >
          <ul className='contacts faq-content-area'>
           
            <li
              className='active dlab-chat-user'
              onClick={() => setOpenMsg(true)}
            >
              <div className='d-flex bd-highlight'>
              <p className="tutorial-question">
                            How to Upgrate your personal account to business account?
                        </p>
              </div>
            </li>
          </ul>
        </PerfectScrollbar>
      </div>
      <MsgBox
       openMsg={openMsg}
        PerfectScrollbar={PerfectScrollbar}
        offMsg={() => setOpenMsg(false)}
      />
    </div>
  )
}

export default FAQs
