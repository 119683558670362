import { menuTypes } from "./menuTypes";
import { endpoints } from "../../Config/apiConfig";
import { axiosInstance } from "../../Config/axiosInstance";

const onChange = (field, value) => {
  return (dispatch) => {
    dispatch({
      type: menuTypes.MENU_ONCHANGE,
      payload: { prop: field, value: value },
    });
  };
};

const onGetTheme = (type) => async (dispatch) => {
  var response = await axiosInstance.getApi(
    endpoints.menuTemplateApi.getAllTheme(type)
  );
  return response;
};

const onGetBranchMenu = () => async (dispatch) => {
  var bran = await localStorage.getItem("bran");
  var response = await axiosInstance.getApi(
    endpoints.menuTemplateApi.getBranchMenu(bran)
  );
  return response;
};

const onGetThemeById = (id) => async (dispatch) => {
  var response = await axiosInstance.getApi(
    `${endpoints.menuTemplateApi.getThemeById}/${id}`
  );
  return response;
};
const onCreateMenus = (data) => async (dispatch) => {
  var bran = await localStorage.getItem("bran");
  var response = await axiosInstance.postApi(
    data,
    `${endpoints.MenuApi.createMenu(bran)}`
  );
  return response;
};

const onGetMenus = (bran) => async (dispatch) => {
  var response = await axiosInstance.getApiWithoutToken(
    `${endpoints.MenuApi.createMenu(bran)}default`
  );
  return response;
};
const onUpdateMenus = (data, id) => async (dispatch) => {
  var bran = await localStorage.getItem("bran");
  var response = await axiosInstance.updateApi(
    data,
    `${endpoints.MenuApi.UpdateMenu(id, bran)}`
  );
  return response;
};

const onGetMenuThemesCategory = (type) => async (dispatch) => {
  var response = await axiosInstance.getApi(
    `${endpoints.menuTemplateApi.menuThemesCategory}${type}`
  );
  return response;
};

const onGetSection = (sectionId, page) => async (dispatch) => {
  var response = await axiosInstance.getApi(
    page !== null
      ? `${endpoints.MenuApi.section(sectionId)}?page=${page}`
      : endpoints.MenuApi.section(sectionId)
  );
  return response;
};
const onGetSectionById = (sectionId, id) => async (dispatch) => {
  var response = await axiosInstance.getApi(
    `${endpoints.MenuApi.section(sectionId)}/${id}`
  );
  return response;
};
const onCreateSection = (sectionId, data) => async (dispatch) => {
  var response = await axiosInstance.postApi(
    data,
    `${endpoints.MenuApi.section(sectionId)}`
  );
  return response;
};
const onUpdateSection = (sectionId, data, id) => async (dispatch) => {
  var response = await axiosInstance.updateApi(
    data,
    `${endpoints.MenuApi.section(sectionId)}${id}/`
  );
  return response;
};
const onDeleteSection = (sectionId, id) => async (dispatch) => {
  var response = await axiosInstance.deleteApi(
    null,
    `${endpoints.MenuApi.section(sectionId)}${id}/`
  );
  return response;
};
const onGetSectionItem = (sectionId) => async (dispatch) => {
  var response = await axiosInstance.getApi(endpoints.MenuApi.item(sectionId));
  return response;
};

const onGetAllProducts =
  (data = "") =>
  async (dispatch) => {
    var response = await axiosInstance.getApi(
      endpoints.MenuApi.getAllProducts + data
    );
    return response;
  };

const onGetSectionItemById = (sectionId, id) => async (dispatch) => {
  var response = await axiosInstance.getApi(
    `${endpoints.MenuApi.item(sectionId)}/${id}`
  );
  return response;
};
const onCreateSectionItem = (sectionId, data) => async (dispatch) => {
  var response = await axiosInstance.postApi(
    data,
    `${endpoints.MenuApi.item(sectionId)}/`
  );
  return response;
};

const onUpdateSectionItem = (sectionId, data, itemid) => async (dispatch) => {
  var response = await axiosInstance.updateApi(
    data,
    `${endpoints.MenuApi.item(sectionId)}/${itemid}/`
  );
  return response;
};

const onDeleteSectionItem = (sectionId, itemid) => async (dispatch) => {
  var response = await axiosInstance.deleteApi(
    null,
    `${endpoints.MenuApi.item(sectionId)}/${itemid}/`
  );
  return response;
};

const onGetItemCategory = () => async (dispatch) => {
  var response = await axiosInstance.getApi(endpoints.MenuApi.itemCategory);
  return response;
};

const onCreateResturnantItem = (data) => async (dispatch) => {
  var res = await localStorage.getItem("res");
  var response = await axiosInstance.postApi(
    data,
    `${endpoints.resturantApi.restaurantItem(res)}`
  );
  return response;
};

const onDeleteMenu = (menuId) => async (dispatch) => {
  var bran = await localStorage.getItem("bran");
  var response = await axiosInstance.deleteApi(
    null,
    `${endpoints.MenuApi.deleteMenu(menuId, bran)}`
  );
  return response;
};

const onGetMenuById = (id) => async (dispatch) => {
  var bran = await localStorage.getItem("bran");
  var response = await axiosInstance.getApi(
    endpoints.MenuApi.getMenuById(id, bran)
  );
  return response;
};

const onAppGuideOpen = (acroynm) => async (dispatch) => {
  var response = await axiosInstance.getApi(
    endpoints.MenuApi.isFirstTimeMenuOpen(acroynm)
  );
  return response;
};
export const menuActions = {
  onChange: onChange,
  onGetTheme: onGetTheme,
  onGetThemeById: onGetThemeById,
  onCreateMenus: onCreateMenus,
  onUpdateMenus: onUpdateMenus,
  onGetMenuThemesCategory: onGetMenuThemesCategory,
  onGetSection: onGetSection,
  onGetSectionById: onGetSectionById,
  onCreateSection: onCreateSection,
  onUpdateSection: onUpdateSection,
  onDeleteSection: onDeleteSection,
  onGetSectionItem: onGetSectionItem,
  onGetSectionItemById: onGetSectionItemById,
  onCreateSectionItem: onCreateSectionItem,
  onGetItemCategory: onGetItemCategory,
  onCreateResturnantItem: onCreateResturnantItem,
  onDeleteSectionItem: onDeleteSectionItem,
  onUpdateSectionItem: onUpdateSectionItem,
  onGetBranchMenu: onGetBranchMenu,
  onGetAllProducts: onGetAllProducts,
  onDeleteMenu: onDeleteMenu,
  onGetMenuById: onGetMenuById,
  onGetMenus: onGetMenus,
  onAppGuideOpen: onAppGuideOpen,
};
