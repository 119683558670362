
import React, { useState } from "react";
import Dheader from "./DheaderMembers";
import Members from "./Members";
import DeshboardLayout from "../layouts/DeshboardLayout";

import "../../../assets/admin/vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "../../../assets/admin/css/style.css";


const AllStaffMembers = () => {
	const [toggle, setToggle] = useState("");
	const onClick = (name) => setToggle(toggle === name ? "" : name);
	return(
		<>
		    <div id="main-wrapper" class="show">
			<DeshboardLayout />
             
           
            <div className="container">
				<div className="row">
					<div className="col-md-12 py-4 pt-4">
								<Members />
						
					</div>
				</div>
			</div>
			
			  </div>	
			
		</>
	)
}
export default AllStaffMembers;