import React from "react";
import CommonHero from "../Helper/CommonHero";
import FooterOne from "../Partials/Footers/FooterOne";
import HeaderFive from "../Partials/Headers/HeaderFive";
import OnlineBooking from "./OnlineBooking";
import HairSaloon from "./HairSaloon";
import Crm from "./Crm";
// import FastPayment from "./FastPayment";
// import ElectronicTips from "./ElectronicTips";
// import RatingReviews from "./RatingReviews";
// import BrandPage from "./BrandPage";
// import { useSelector } from "react-redux";

function BookAppointment() {
  // const {} = useSelector((state) => state.AccountReducer);
  return (
    <>
      <HeaderFive />
      <CommonHero title="Book Appointment" />
      <OnlineBooking />
      <HairSaloon />
      <Crm />
      {/* <FastPayment />
      <ElectronicTips />
      <RatingReviews />
      <BrandPage /> */}
      <FooterOne />
    </>
  );
}

export default BookAppointment;
