import React, { useState } from "react";
import paymentgapp from "../../../../assets/images/dashboard/gicon.png";
import paymentcash from "../../../../assets/images/dashboard/cashicon.png";
import { useSelector } from "react-redux";

function Screen4({ template }) {
  const { menuData } = useSelector((state) => state.MenuReducer);
  return (
    <>
      <div className="screen-content">
        <div className="screen2-header">
          <div className="back-icon-area">
            <span>
              <i class="fa fa-chevron-left"></i>
            </span>
          </div>
          <div className="back-icon-area">
            <span>
              <i class="fa fa-heart"></i>
            </span>
          </div>
        </div>
        <div className="content-padding-area">
          <div className="products-area-screen5">
            <div
              className="product-block"
              style={{
                backgroundColor: `${menuData?.item_product_background_color}`,
                color: `${menuData?.item_product_text_color}`,
              }}
            >
              <div className="product-block-left">
                <img
                  src="https://i.pinimg.com/736x/7a/6f/50/7a6f50ad3dcf43f63f6e0658c1dd3031.jpg"
                  className="preview-product-image"
                ></img>
              </div>
              <div className="product-block-right">
                <div className="product-block-row1">
                  <div className="produtc-block-title-area">
                    <p className="product-name-block">Pixie Cut</p>
                    <p className="product-category-name-block">Hair</p>
                  </div>
                  <div className="product-block-fav-area">
                    <div className="fav-btn-product">
                      <i class="fa fa-heart"></i>
                    </div>
                  </div>
                </div>
                <div className="product-block-row2">
                  <div className="row2-left-about-product">
                    <div className="cart-qualitity-btn">
                      <table>
                        <tr>
                          <td>
                            <button
                              className="preview-quality-btn"
                              style={{
                                backgroundColor: `${menuData?.item_product_text_color}`,
                              }}
                            >
                              -
                            </button>
                          </td>
                          <td>
                            <span className="preview-quality-number">1</span>
                          </td>
                          <td>
                            <button
                              className="preview-quality-btn"
                              style={{
                                backgroundColor: `${menuData?.item_product_text_color}`,
                              }}
                            >
                              +
                            </button>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div className="row2-right-product-price">
                    <span
                      className="product-price"
                      style={{
                        color: `${menuData?.item_product_price}`,
                      }}
                    >
                      ₦75.12
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="categories-tabs">
            <p className="title-tip preview-main-title">Add a tip</p>
            <ul
              style={{
                color: `${menuData?.item_tip_text_color}`,
              }}
            >
              <li className="active-category-tab">
                <span>10%</span>
              </li>
              <li>
                <span>15%</span>
              </li>
              <li>
                <span>20%</span>
              </li>
              <li>
                <span>None</span>
              </li>
            </ul>
          </div>
          <div className="payment-summery-area">
            <p className="preview-main-title">Payment Summery</p>
            <table>
              <tr>
                <td>Basket Total</td>
                <td className="text-right">₦75.12</td>
              </tr>
              <tr>
                <td>Discount</td>
                <td className="text-right">₦75.12</td>
              </tr>
              <tr>
                <td>Service fee</td>
                <td className="text-right">₦75.12</td>
              </tr>
              <tr>
                <td>Total Amount</td>
                <td className="text-right">₦75.12</td>
              </tr>
            </table>
          </div>
          <div className="pay-with-area">
            <p className="preview-main-title">Pay with</p>
            <div className="giveaway-payment">
              <div className="payment-form-group">
                <table>
                  <tr>
                    <td>
                      <input type="radio" />
                    </td>
                    <td className="text-center">
                      <img src={paymentgapp} className="preview-payment-logo" />
                    </td>
                    <td>
                      <p className="preview-main-title">Giveaway App</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input type="radio" />
                    </td>
                    <td className="text-center">
                      <img src={paymentcash} className="preview-payment-logo" />
                    </td>
                    <td>
                      <p className="preview-main-title">Cash</p>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="checkout-cart-area-preview display-center">
          <div className="right-side-btn text-center">
            <button
              className="preview-button-theme place-oder-preview-btn"
              style={{
                backgroundColor: `${menuData?.item_place_order_btn_color}`,
                color: `${menuData?.item_place_order_text_color}`,
              }}
            >
              Place A Order
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Screen4;
