

function role (){
    return(<>

        <div className="row m-3">
            <div className="col-md-12">
                <h2 className="heading">Roles & Permissions </h2>
            </div>
        </div>

        
        <div className="row white-bg rounded m-3 p-2">
            <div className="col-md-12">
            <table className="table table-borderless">
            <thead>
                <tr>
                    <th  scope="col"> Functionalities/Role </th>
                    <th className="text-center" scope="col">Owner</th>
                    <th className="text-center" scope="col"> Super Admin </th>
                    <th className="text-center" scope="col"> Admin </th>
                    <th className="text-center" scope="col">Editor</th>
                    <th className="text-center" scope="col">Staff</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Dashboard</td>
                    <td className="text-center"><input type="checkbox" className="green" checked/></td>
                    <td className="text-center"><input type="checkbox" className="green" checked/></td>
                    <td className="text-center"><input type="checkbox" className="green" checked/></td>
                    <td className="text-center"><input type="checkbox" className="green" checked/></td>
                    <td className="text-center"><input type="checkbox" className="green" checked/></td>
                </tr>

                <tr>
                    <td>Menu</td>
                    <td className="text-center"><input type="checkbox" className="green" checked/></td>
                    <td className="text-center"><input type="checkbox" className="green" checked/></td>
                    <td className="text-center"><input type="checkbox" className="green" checked/></td>
                    <td className="text-center"><input type="checkbox" className="green" checked/></td>
                    <td className="text-center"><input type="checkbox" className="green" /></td>
                </tr>

                <tr>
                    <td>Tablet Configuration</td>
                    <td className="text-center"><input type="checkbox" className="green" checked/></td>
                    <td className="text-center"><input type="checkbox" className="green" checked/></td>
                    <td className="text-center"><input type="checkbox" className="green" checked/></td>
                    <td className="text-center"><input type="checkbox" className="green"/></td>
                    <td className="text-center"><input type="checkbox" className="green" /></td>
                </tr>

                <tr>
                    <td>QR Menu Configuration</td>
                    <td className="text-center"><input type="checkbox" className="green" checked/></td>
                    <td className="text-center"><input type="checkbox" className="green" checked/></td>
                    <td className="text-center"><input type="checkbox" className="green" checked/></td>
                    <td className="text-center"><input type="checkbox" className="green"/></td>
                    <td className="text-center"><input type="checkbox" className="green" /></td>
                </tr>

                <tr>
                    <td>Orders</td>
                    <td className="text-center"><input type="checkbox" className="green" checked/></td>
                    <td className="text-center"><input type="checkbox" className="green" checked/></td>
                    <td className="text-center"><input type="checkbox" className="green"/></td>
                    <td className="text-center"><input type="checkbox" className="green"/></td>
                    <td className="text-center"><input type="checkbox" className="green" checked/></td>
                </tr>

                <tr>
                    <td>Info Page</td>
                    <td className="text-center"><input type="checkbox" className="green" checked/></td>
                    <td className="text-center"><input type="checkbox" className="green" checked/></td>
                    <td className="text-center"><input type="checkbox" className="green" checked/></td>
                    <td className="text-center"><input type="checkbox" className="green"/></td>
                    <td className="text-center"><input type="checkbox" className="green" /></td>
                </tr>

                <tr>
                    <td>App Config</td>
                    <td className="text-center"><input type="checkbox" className="green" checked/></td>
                    <td className="text-center"><input type="checkbox" className="green" checked/></td>
                    <td className="text-center"><input type="checkbox" className="green"checked /></td>
                    <td className="text-center"><input type="checkbox" className="green"/></td>
                    <td className="text-center"><input type="checkbox" className="green" /></td>
                </tr>

                <tr>
                    <td>Integrations</td>
                    <td className="text-center"><input type="checkbox" className="green" checked/></td>
                    <td className="text-center"><input type="checkbox" className="green" checked/></td>
                    <td className="text-center"><input type="checkbox" className="green" checked/></td>
                    <td className="text-center"><input type="checkbox" className="green"/></td>
                    <td className="text-center"><input type="checkbox" className="green" /></td>
                </tr>

                <tr>
                    <td>User Management</td>
                    <td className="text-center"><input type="checkbox" className="green" checked/></td>
                    <td className="text-center"><input type="checkbox" className="green" checked/></td>
                    <td className="text-center"><input type="checkbox" className="green" checked/></td>
                    <td className="text-center"><input type="checkbox" className="green"/></td>
                    <td className="text-center"><input type="checkbox" className="green" /></td>
                </tr>

                <tr>
                    <td>Profile Management</td>
                    <td className="text-center"><input type="checkbox" className="green" checked/></td>
                    <td className="text-center"><input type="checkbox" className="green" checked/></td>
                    <td className="text-center"><input type="checkbox" className="green" checked/></td>
                    <td className="text-center"><input type="checkbox" className="green" checked/></td>
                    <td className="text-center"><input type="checkbox" className="green" /></td>
                </tr>

                <tr>
                    <td>Subscription & Billing</td>
                    <td className="text-center"><input type="checkbox" className="green" checked/></td>
                    <td className="text-center"><input type="checkbox" className="green" checked/></td>
                    <td className="text-center"><input type="checkbox" className="green"/></td>
                    <td className="text-center"><input type="checkbox" className="green"/></td>
                    <td className="text-center"><input type="checkbox" className="green" /></td>
                </tr>
            </tbody>
            <tbody>

            </tbody>
        </table>
            </div>
        </div>
    </>)
}

export default role;