import React, { useEffect, useState } from "react";
import TemplatePreview from "./TemplatePreview";
import { Link, useHistory } from "react-router-dom";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Dropdown } from "react-bootstrap";
import { menuActions } from "../../../../../Store/Menu/menuAction";
import { useDispatch } from "react-redux";
import Loader from "../../../../Common/Loader";
import { rootURL } from "../../../../../Config/apiConfig";
import { MdCheckCircle } from "react-icons/md";
import { toast } from "react-toastify";

const templateDetails = [
  {
    id: 1,
    title: "Cafe Shop",
    img: "https://images.pexels.com/photos/1624487/pexels-photo-1624487.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    date: "20 JAN 2022",
    category: 1,
    number: "ID 234335",
  },
  {
    id: 2,
    title: "Korean BBQ Beef",
    img: "https://images.pexels.com/photos/842571/pexels-photo-842571.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    date: "20 JAN 2022",
    category: 2,
    number: "ID 234335",
  },
  {
    id: 3,
    title: "BBQ Beef",
    img: "https://images.pexels.com/photos/1624487/pexels-photo-1624487.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    date: "20 JAN 2022",
    category: 1,
    number: "ID 234335",
  },
  {
    id: 4,
    title: "Beef",
    img: "https://images.pexels.com/photos/1633525/pexels-photo-1633525.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    date: "20 JAN 2022",
    category: 3,
    number: "ID 234335",
  },
];

const MenuTemplates = () => {
  const [temptItems, setTempItems] = useState([]);
  const [activeItem, setActiveItem] = useState(temptItems[0]);
  const [previewActive, setPreviewActive] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    loadMenu();
  }, []);

  const loadMenu = () => {
    setLoading(true);
    dispatch(menuActions.onGetBranchMenu()).then((res) => {
      if (res?.results) {
        setTempItems(res?.results);
      }
      setLoading(false);
    });
  };

  const makeItemActive = (selectedItem) => {
    setActiveItem(selectedItem);
  };

  const makeItemPreview = (selectedItem) => {
    setActiveItem(selectedItem);
    setPreviewActive(!previewActive);
  };

  return (
    <>
      {isLoading ? <Loader /> : null}
      {previewActive ? (
        <TemplatePreview
          toggle={previewActive}
          template={activeItem}
          closePreview={setPreviewActive}
          templateId={activeItem?.id}
        />
      ) : (
        <></>
      )}
      <div class={` ${isLoading ? "hide-custom" : "show"}`}>
        <div className="container">
          <div className="row">
            {temptItems?.map((item) => (
              <>
                <div className="col-md-3">
                  {item?.default && (
                    <MdCheckCircle
                      style={{
                        color: "#F9A05F",
                        fontSize: "30px",
                        zIndex: "9999",
                        position: "absolute",
                        top: "+10px",
                        right: "40",
                      }}
                    />
                  )}
                  <div
                    className={"active-item-template template-area-block "}
                    onClick={() => makeItemActive(item)}
                  >
                    <div className="template-image">
                      <img src={item?.home_background_image} />
                    </div>
                    <div className="template-details">
                      <div className="template-content template-menu-content">
                        <div className="content-letf text-left">
                          <p>{item?.name}</p>
                          <p>
                            {item?.description}
                            <br />
                            {item?.date}
                          </p>
                        </div>
                        <div className="content-right">
                          <Dropdown className="dropdown">
                            <Dropdown.Toggle
                              as="div"
                              className="btn-link i-false"
                            >
                              <div className="del-icon">
                                <MoreHorizIcon />
                              </div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                              <Dropdown.Item
                                className="dropdown-item"
                                onClick={() => {
                                  history.push(
                                    `edit-menu-template?id=${item.id}-u`
                                  );
                                }}
                              >
                                Edit Theme
                              </Dropdown.Item>
                              <Dropdown.Item
                                className="dropdown-item"
                                onClick={() => {
                                  setLoading(true);
                                  let data = new FormData();
                                  data.append("default", "true");
                                  dispatch(
                                    menuActions.onUpdateMenus(data, item?.id)
                                  ).then((res) => {
                                    setLoading(false);
                                    loadMenu();
                                  });
                                }}
                              >
                                Make Default
                              </Dropdown.Item>
                              <Dropdown.Item
                                className="dropdown-item"
                                onClick={() => {
                                  dispatch(
                                    menuActions.onDeleteMenu(item?.id)
                                  ).then((res) => {
                                    if (res?.response?.data?.message) {
                                      toast.error(res?.response?.data?.message);
                                    } else {
                                      loadMenu();
                                    }
                                  });
                                }}
                              >
                                Delete
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                      <div className="template-buttons">
                        <ul className="dashbaord-themplate-button">
                          <li>
                            <button
                              className="themplate-btn"
                              onClick={() => {
                                history.push(`menu?id=${item?.id}`);
                              }}
                            >
                              Edit Menu
                            </button>
                          </li>
                          <li>
                            <button
                              className="themplate-btn"
                              onClick={() => makeItemPreview(item)}
                            >
                              <i class="fa fa-eye" aria-hidden="true"></i>
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>{" "}
              </>
            ))}
            {temptItems?.length === 0 && (
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "20px",
                  marginTop: "50px",
                }}
              >
                You haven’t created Menu.
              </span>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default MenuTemplates;
