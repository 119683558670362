import React, { useState, useCallback, useRef } from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import DeshboardLayout from "../../layouts/DeshboardLayout";
import { Dropdown } from "react-bootstrap";
import { debounce } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import Switch from "react-switch";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import ItemDetail from "../ItemDetail";
import LeftItemDetail from "../LeftItemDetail";
import AddSection from "../buildmenu/AddSection";
import AddItem from "../buildmenu/AddItem";
import { menuActions } from "../../../../Store/Menu/menuAction";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { rootURL } from "../../../../Config/apiConfig";
import Loader from "../../../Common/Loader";
import { resturantActions } from "../../../../Store/Resturant/resturantAction";
import { toast } from "react-toastify";

const itemsList = [
  {
    id: 1,
    title: "Grilled Korean BBQ Beef",
    img: "https://images.pexels.com/photos/1552635/pexels-photo-1552635.jpeg",
    price: "75.2",
    describtion: "Boneless short ribs, jasmine rice",
  },
  {
    id: 2,
    title: "Korean BBQ Beef",
    img: "https://images.pexels.com/photos/1552635/pexels-photo-1552635.jpeg",
    price: "75.2",
    describtion: "Boneless short ribs, jasmine rice",
  },
  {
    id: 3,
    title: "BBQ Beef",
    img: "https://images.pexels.com/photos/1552635/pexels-photo-1552635.jpeg",
    price: "75.2",
    describtion: "Boneless short ribs, jasmine rice",
  },
  {
    id: 4,
    title: "Beef",
    img: "https://images.pexels.com/photos/1552635/pexels-photo-1552635.jpeg",
    price: "75.2",
    describtion: "Boneless short ribs, jasmine rice",
  },
];

// const sectionNames = [
//   {
//     id: 1,
//     title: "Entrees",
//     describtion: "Ask your server for today’s specials!",
//     products: [
//       {
//         id: 1,
//         title: "Grilled Korean BBQ Beef",
//         img: "https://images.pexels.com/photos/1552635/pexels-photo-1552635.jpeg",
//         price: "75.2",
//         describtion: "Boneless short ribs, jasmine rice",
//       },
//       {
//         id: 2,
//         title: "Korean BBQ Beef",
//         img: "https://images.pexels.com/photos/1552635/pexels-photo-1552635.jpeg",
//         price: "75.2",
//         describtion: "Boneless short ribs, jasmine rice",
//       },
//     ],
//   },
//   {
//     id: 2,
//     title: "Soup",
//     describtion: "Add a piping hot cup of soup to your meal!",
//     products: [
//       {
//         id: 3,
//         title: "BBQ Beef",
//         img: "https://images.pexels.com/photos/1552635/pexels-photo-1552635.jpeg",
//         price: "75.2",
//         describtion: "Boneless short ribs, jasmine rice",
//       },
//     ],
//   },
//   {
//     id: 3,
//     title: "Salad",
//     describtion: "Add a piping hot cup of soup to your meal!",
//     products: [
//       {
//         id: 1,
//         title: "Grilled Korean BBQ Beef",
//         img: "https://images.pexels.com/photos/1552635/pexels-photo-1552635.jpeg",
//         price: "75.2",
//         describtion: "Boneless short ribs, jasmine rice",
//       },
//       {
//         id: 2,
//         title: "Korean BBQ Beef",
//         img: "https://images.pexels.com/photos/1552635/pexels-photo-1552635.jpeg",
//         price: "75.2",
//         describtion: "Boneless short ribs, jasmine rice",
//       },
//     ],
//   },
//   {
//     id: 4,
//     title: "Drinks",
//     describtion: "Add a piping hot cup of soup to your meal!",
//     products: [
//       {
//         id: 1,
//         title: "Grilled Korean BBQ Beef",
//         img: "https://images.pexels.com/photos/1552635/pexels-photo-1552635.jpeg",
//         price: "75.2",
//         describtion: "Boneless short ribs, jasmine rice",
//       },
//       {
//         id: 2,
//         title: "Korean BBQ Beef",
//         img: "https://images.pexels.com/photos/1552635/pexels-photo-1552635.jpeg",
//         price: "75.2",
//         describtion: "Boneless short ribs, jasmine rice",
//       },
//     ],
//   },
// ];

const BuildMenu = () => {
  let isSubscription = true;
  const subscription = JSON.parse(localStorage.getItem("subscription"));
  if (subscription) {
    isSubscription = subscription?.active === "active" ? false : true;
  }
  const [searchBut, setSearchBut] = useState(false);
  const [collapse, setCollapse] = useState(false);
  const [items, setItems] = useState([]);
  const [menuItems, setMenuItemsBackup] = useState([]);
  const [sectionsNames, setSectionsNames] = useState([]);

  const [addSectionShow, setAddSectionShow] = useState(false);
  const [selectedSection, setSelectedSection] = useState(null);
  const [addItemShow, setAddItemShow] = useState(false);
  const [itemCategory, setItemCategory] = useState([]);
  const [deleteSec, setDeleteSec] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [gallery, setGallery] = useState([]);
  const [pageSize, setPageSize] = useState(0);
  const [selectedItem, setSelectedItem] = useState({});
  const dispatch = useDispatch();
  var MenuId = parseInt(window.location.href.split("?id=")[1]);

  useEffect(() => {
    setLoading(true);
    dispatch(menuActions.onGetSection(MenuId, null)).then((res) => {
      if (res?.results?.length > 0) {
        setSectionsNames(res?.results);
        setItemCategory(res?.results);
        setPageSize(Math.ceil(res?.count / 10));
      }
      setLoading(false);
    });
  }, []);

  const loadSection = (page) => {
    setLoading(true);
    dispatch(menuActions.onGetSection(MenuId, page)).then((res) => {
      if (res?.results?.length > 0) {
        setSectionsNames(res?.results);
        setItemCategory(res?.results);
        setPageSize(Math.ceil(res?.count / 10));
      } else {
        setSectionsNames([]);
        setItemCategory([]);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    dispatch(menuActions.onGetAllProducts()).then((res) => {
      if (res?.results?.length > 0) {
        setItems(res?.results);
        setMenuItemsBackup(res?.results);
        //setTaskStatus(res?.results);
        //settasksItemsBackup(res?.results);
      }
    });
  }, []);

  useEffect(() => {
    dispatch(resturantActions.onGetGalleries("restaurant")).then((res) => {
      if (res?.results?.length > 0) {
        setGallery(res?.results);
      }
    });
  }, []);

  useEffect(() => {
    dispatch(menuActions.onGetItemCategory()).then((res) => {
      if (res?.results?.length > 0) {
        setItemCategory(res?.results);
      }
    });
  }, []);

  const deleteSection = (id) => {
    setLoading(true);
    dispatch(menuActions.onDeleteSection(MenuId, id)).then((res) => {
      setLoading(false);
      if (res?.response?.data?.message) {
        toast.success(res?.response?.data?.message);
      } else {
        loadSection(null);
        setDeleteSec(!deleteSec);
      }
    });
  };

  const updateSectionActivity = (category) => {
    var data = {
      name: category?.name,
      description: category?.discription,
      active: !category?.active,
    };
    dispatch(menuActions.onUpdateSection(MenuId, data, category?.id)).then(
      (res) => {
        loadSection(null);
        setDeleteSec(!deleteSec);
      }
    );
  };

  const addMenuItem = (id, side, cid, itemcategoryindex) => {
    const itemsList = items.filter((item) => id === item.id);
    let getCategory = sectionsNames;
    getCategory[cid]?.items.push(itemsList[0]);

    if (side === "right") {
      getCategory[itemcategoryindex]?.items.filter((catProducts, pindex) => {
        if (catProducts.id === itemsList[0].id) {
          delete getCategory[itemcategoryindex]?.items[pindex];
        }
      });
    }

    setSectionsNames(getCategory);
    if (side === "left") {
      const arr2 = items.filter((item) => id !== item.id);
      setItems(arr2);
      let imag = itemsList[0]?.featured.includes("http");
      let resturantItemData = {
        name: itemsList[0]?.name,
        description: itemsList[0]?.description,
        featured: imag ? itemsList[0]?.featured : itemsList[0]?.featured,
        section: { id: getCategory[cid]?.id },
      };
      setSelectedItem(resturantItemData);
      setAddItemShow(true);
      // dispatch(
      //   menuActions.onCreateSectionItem(getCategory[cid]?.id, resturantItemData)
      // ).then((res) => {
      //   if (res?.id) {
      //   }
      // });
    }
  };

  const filter = (e) => {
    const keyword = e.target.value;
    let link = `?search=${keyword}`;
    debouncedChangeHandler(link);
    // if (keyword !== "") {
    //   // const results = items.filter((task) => {
    //   //   return items.name.toLowerCase().startsWith(keyword.toLowerCase());
    //   // });
    //   const results = items.filter((x) =>
    //     x?.name.toLowerCase()?.includes(keyword.toLowerCase())
    //   );
    //   setItems(results);
    // } else {
    //   setItems(menuItems);
    // }
    // setSearchBut(keyword);
  };

  const filterOrderStatus = (link) => {
    setLoading(true);

    dispatch(menuActions.onGetAllProducts(link)).then((res) => {
      if (res?.results?.length > 0) {
        setItems(res?.results);
        setMenuItemsBackup(res?.results);
      } else {
        setItems([]);
        setMenuItemsBackup([]);
      }
      setLoading(false);
    });
  };

  const debouncedChangeHandler = useCallback(
    debounce(filterOrderStatus, 500),
    []
  );

  function expandAll() {
    setCollapse(false);
  }
  function collapseAll() {
    setCollapse(true);
  }

  const handlePageChange = (v, page) => {
    setPageSize(page);
    loadSection(page);
  };

  return (
    <>
      {isLoading ? <Loader /> : null}
      {addSectionShow ? (
        <AddSection
          setSetcionClose={(e) => {
            loadSection(null);
            setAddSectionShow(e);
          }}
          selectedSection={selectedSection}
        />
      ) : (
        ""
      )}
      {addItemShow ? (
        <AddItem
          setSetcionClose={(e) => {
            loadSection(null);
            setAddItemShow(e);
            setSelectedItem({});
          }}
          itemCategory={itemCategory}
          selectedItem={selectedItem}
          gallery={gallery}
        />
      ) : (
        ""
      )}

      <div id="main-wrapper" class="show">
        <DeshboardLayout />
        <div class="content-body">
          <div className="container">
            <div className="row">
              <div className="col-md-7">
                <h3 className="inner-header">Menu</h3>
              </div>
              <div className="col-md-5 ">
                <div className="menu-title-area">
                  <div className="title-left">
                    <h3 className="inner-header">Items</h3>
                    <p className="title-sub-text">
                      {" "}
                      Find & drag items into your menu.
                    </p>
                  </div>
                  <div className="title-right">
                    <button
                      className="btn-theme-main"
                      onClick={() => setAddItemShow(true)}
                    >
                      <span className="plus-icon">+</span>Add Items
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <section>
              <div className="row">
                <div className="col-md-7">
                  <div className="left-side-menu">
                    <div className="left-header-area">
                      <div className="header-row">
                        <div className="header-left-row">
                          <ul>
                            {collapse ? (
                              <li>
                                <span className="exp-coll" onClick={expandAll}>
                                  Expand all
                                </span>
                              </li>
                            ) : (
                              <li>
                                <span
                                  className="exp-coll"
                                  onClick={collapseAll}
                                >
                                  Collapse all
                                </span>
                              </li>
                            )}
                          </ul>
                        </div>
                        <div className="header-right-row">
                          <button
                            className="btn-theme-main"
                            disabled={isSubscription}
                            onClick={() => setAddSectionShow(true)}
                          >
                            <span className="plus-icon">+</span>Add Section
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="left-side-content-area">
                      <DndProvider backend={HTML5Backend}>
                        {sectionsNames.map((category, index) => (
                          <>
                            <MenuColumnLeft
                              key={"left"}
                              addMenuItem={addMenuItem}
                              categoryIndex={index}
                            >
                              <div className="category-area">
                                <div className="category-left">
                                  <div className="sorting-handle">
                                    <DragIndicatorIcon />
                                  </div>
                                  <div className="category-title">
                                    <h5>{category?.name}</h5>
                                  </div>
                                  <div className="category-description">
                                    <p>{category?.description}</p>
                                  </div>
                                </div>
                                <div className="category-right">
                                  <div className="stiwth-area">
                                    <Switch
                                      onChange={() => {
                                        updateSectionActivity(category);
                                      }}
                                      checked={category?.active}
                                    />
                                  </div>
                                  <div className="dropdown-delete">
                                    <Dropdown className="dropdown">
                                      <Dropdown.Toggle
                                        as="div"
                                        className="btn-link i-false"
                                      >
                                        <div className="del-icon">
                                          <MoreHorizIcon />
                                        </div>
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                                        <Dropdown.Item
                                          className="dropdown-item"
                                          onClick={() => {
                                            deleteSection(category?.id);
                                          }}
                                        >
                                          Delete
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          className="dropdown-item"
                                          onClick={() => {
                                            setSelectedSection(category);
                                            setAddSectionShow(true);
                                          }}
                                        >
                                          Edit
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                              </div>
                              <div
                                className={
                                  collapse
                                    ? `product-areas products-collapse`
                                    : `product-areas`
                                }
                              >
                                {category?.items?.map((item) => (
                                  <LeftMenuItem
                                    key={"right-" + item.id}
                                    id={item.id}
                                    side="right"
                                    category={index}
                                  >
                                    <LeftItemDetail
                                      key={item?.id}
                                      id={item?.id}
                                      img={`${item?.featured}`}
                                      title={item?.name}
                                      describtion={item?.description}
                                      price={item?.price}
                                      currency={item?.currency}
                                      discount={item?.discount}
                                      onDeleteClick={() => {
                                        dispatch(
                                          menuActions.onDeleteSectionItem(
                                            category?.id,
                                            item?.id
                                          )
                                        ).then((res) => {
                                          loadSection(null);
                                        });
                                      }}
                                      onEditClick={() => {
                                        setSelectedItem(item);
                                        setAddItemShow(true);
                                      }}
                                    />
                                  </LeftMenuItem>
                                ))}
                              </div>
                            </MenuColumnLeft>
                          </>
                        ))}
                      </DndProvider>
                    </div>
                  </div>
                </div>
                <DndProvider backend={HTML5Backend}>
                  <div className="col-md-5">
                    <div className="input-group search-area menu-item-search-area">
                      <span
                        className="input-group-text"
                        onClick={() => setSearchBut(!searchBut)}
                      >
                        <i className="flaticon-381-search-2"></i>
                      </span>
                      <input
                        type="text"
                        className={`form-control ${searchBut ? "active" : ""}`}
                        placeholder="Search here..."
                        onChange={filter}
                      />
                    </div>
                    <div>
                      {items.map((item) => (
                        <MenuItem
                          key={"left-" + item.id}
                          id={item.id}
                          side="left"
                          category={null}
                        >
                          <ItemDetail
                            img={item?.featured}
                            title={item?.name}
                            describtion={item?.description}
                            // price={item?.price}
                          />
                        </MenuItem>
                      ))}
                    </div>
                  </div>
                </DndProvider>
              </div>
            </section>
            {pageSize > 1 && (
              <Stack spacing={2}>
                <Pagination count={pageSize} onChange={handlePageChange} />
              </Stack>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default BuildMenu;

const MenuColumnLeft = ({
  addMenuItem,
  onDragEnd,
  children,
  categoryIndex,
}) => {
  const ref = useRef(null);
  const [, drop] = useDrop({
    accept: "card",
    drop(item) {
      addMenuItem(item.id, item.side, categoryIndex, item.category);
    },
  });
  drop(ref);
  return <div ref={ref}> {children}</div>;
};

const MenuItem = ({ id, side, category, children }) => {
  const ref = useRef(null);
  const [{ isDragging }, drag] = useDrag({
    type: "card",
    item: { id, side, category },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(ref);
  return (
    <div ref={ref} style={{ opacity }}>
      {children}
    </div>
  );
};

const LeftMenuItem = ({ id, side, category, children }) => {
  const ref = useRef(null);
  const [{ isDragging }, drag] = useDrag({
    type: "card",
    item: { id, side, category },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(ref);
  return (
    <div ref={ref} style={{ opacity }}>
      {children}
    </div>
  );
};
