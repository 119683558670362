import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { accountActions } from "../../../../Store/Accounts/accountAction";
import Dheader from "./dheaer";
import Loader from "../../../Common/Loader";
import DeshboardLayout from "../DeshboardLayout";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

function DetailNotifications() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [pageSize, setPageSize] = useState(0);
  const [notification, setNotification] = useState([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    loadData(null);
  }, []);

  const loadData = (page) => {
    setLoading(true);
    dispatch(accountActions.onGetAllNotification(page)).then((data) => {
      setLoading(false);
      if (data?.results) {
        setNotification(data?.results);
        setPageSize(Math.ceil(data?.count / 10));
      }
    });
  };
  const handlePageChange = (a, page) => {
    setPageSize(page);
    loadData(page);
  };

  return (
    <>
      {isLoading ? <Loader /> : null}
      <div id="main-wrapper" class="show">
        <DeshboardLayout />
        <div
          class={`content-body dashboard-conainer ${
            isLoading ? "hide-custom" : "show"
          }`}
        >
          <Dheader />
          <div
            className="row"
            style={{
              backgroundColor: "white",
              margin: "20px",
            }}
          >
            {/* <table className="table table-borderless"> */}
            <tr
              style={{ display: "flex", flexDirection: "row", width: "800px" }}
            >
              <td
                style={{
                  width: "550px",
                  fontWeight: "bold",
                }}
              >
                {" "}
                Message{" "}
              </td>
              <td
                style={{
                  width: "150px",
                  fontWeight: "bold",
                }}
              >
                {" "}
                Date{" "}
              </td>
            </tr>

            {/* <tbody> */}
            <ul className="timeline p-l-0">
              {notification?.map((x) => {
                return (
                  <tr
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "800px",
                      margin: "10px",
                    }}
                  >
                    <td
                      style={{
                        width: "550px",
                      }}
                      scope="row"
                    >
                      {x?.message}
                    </td>
                    <td
                      style={{
                        width: "250px",
                      }}
                    >{`${x.created_at.split("T")[0]} ${
                      x.created_at.split("T")[1].split(".")[0]
                    }`}</td>
                  </tr>
                );
              })}
            </ul>
            {/* </tbody>
            </table> */}
          </div>
          {pageSize > 1 && (
            <Stack spacing={2}>
              <Pagination count={pageSize} onChange={handlePageChange} />
            </Stack>
          )}
        </div>
      </div>
    </>
  );
}

export default DetailNotifications;
