import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const Dheader = () => {

const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

    return(<>
            <div className="row mb-2 mx-1">
                <div className="col-md-9">
                    <h2 className="heading">Usermanagement</h2>
                </div>
                <div className="col-md-3 text-right">
                    {/* Modal */}
                    {/* <button className="btn btn-primary">Add New</button> */}
                    <Button variant="primary" onClick={handleShow}>
                        Add New
                    </Button>

                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                        <Modal.Title><h2 className='heading'>Invite User</h2></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="row">
                                <div className="col-md-12">
                                <div className="py-2">
                                    <label for="exampleInputEmail1" className='font-weight-bold'>First Name</label>
                                    <input type="text" className="form-control input-filed" placeholder="First Name" />
                                </div>

                                <div className="py-2">
                                    <label for="exampleInputEmail1" className='font-weight-bold'>Last Name</label>
                                    <input type="text" className="form-control input-filed" placeholder="Last Name" />
                                </div>

                                <div className="py-2">
                                    <label for="exampleInputEmail1" className='font-weight-bold'>Email</label>
                                    <input type="email" className="form-control input-filed" placeholder="Enter email Address" />
                                </div>

                                <div className="py-2">
                                    <label for="exampleInputEmail1" className='font-weight-bold'>Role</label>
                                    <select className="form-control input-filed" placeholder="Enter email Address">
                                        <option>Chef</option>
                                        <option>Waiter</option>
                                        <option>Admin</option>
                                        <option>Staff</option>
                                    </select>
                                </div>
                                </div>

                                <div className="col-md-6 my-3 text-center">
                                <Button variant="info" onClick={handleClose}>
                                    Cancel
                                </Button>
                                </div>

                                <div className="col-md-6 my-3 text-center">
                                <Button variant="primary" onClick={handleClose}>
                                    Save
                                </Button>
                                </div>
                            
                            </div>
                        
                        </Modal.Body>
                    </Modal>
                    {/* end of modal */}
                </div>
            </div>
     </>)
}

export default Dheader;
