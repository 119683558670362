import { accountApi } from "./accountApi";
import { accountTypes } from "./accountTypes";
import { axiosInstance } from "../../Config/axiosInstance";
import { endpoints } from "../../Config/apiConfig";

const onChange = (field, value) => {
  return (dispatch) => {
    dispatch({
      type: accountTypes.ACCOUNT_LOGIN_ONCHANGE,
      payload: { prop: field, value: value },
    });
  };
};

const onSubmit = (userName, password) => async (dispatch) => {
  var response = await accountApi.login(userName, password);
  return response;
};

const onGetProfile = () => async (dispatch) => {
  var response = await axiosInstance.getApi(`${endpoints.accounts.profile}`);
  return response;
};

const onUpdateProfile = (data) => async (dispatch) => {
  var response = await axiosInstance.updateApi(
    data,
    `${endpoints.accounts.updateProfile}`
  );
  return response;
};

const onGetNotification = (isRead) => async (dispatch) => {
  var response = await axiosInstance.getApi(
    `${endpoints.accounts.getNotifications}${isRead}`
  );
  return response;
};

const onGetAllNotification = (page) => async (dispatch) => {
  var response = await axiosInstance.getApi(
    page !== null
      ? `${endpoints.accounts.getNotifications}?page=${page}`
      : endpoints.accounts.getNotifications
  );
  return response;
};

const onSetReadNotifications = (id) => async (dispatch) => {
  var response = await axiosInstance.getApi(
    `${endpoints.accounts.getNotifications}/${id}`
  );
  return response;
};

const onGetBusinessPackages = () => async (dispatch) => {
  var response = await axiosInstance.getApi(`${endpoints.accounts.packages}`);
  return response;
};

const onGetTrialPackages = () => async (dispatch) => {
  var response = await axiosInstance.getApi(
    `${endpoints.accounts.trialPackages}`
  );
  return response;
};

const onGetTransactionsPackages = () => async (dispatch) => {
  var response = await axiosInstance.getApi(
    `${endpoints.accounts.transactionsPackages}`
  );
  return response;
};

const onSubscribePackages = (data) => async (dispatch) => {
  var response = await axiosInstance.postApi(
    data,
    `${endpoints.accounts.packages}/`
  );
  return response;
};

export const accountActions = {
  onChange: onChange,
  onSubmit: onSubmit,
  onGetProfile: onGetProfile,
  onUpdateProfile: onUpdateProfile,
  onGetNotification: onGetNotification,
  onGetAllNotification: onGetAllNotification,
  onSetReadNotifications: onSetReadNotifications,
  onGetBusinessPackages: onGetBusinessPackages,
  onGetTrialPackages: onGetTrialPackages,
  onGetTransactionsPackages: onGetTransactionsPackages,
  onSubscribePackages: onSubscribePackages,
};
