import React, { useState } from "react";
import giveaway from "../../../assets/images/qrscan/giveaway.png";
import cash from "../../../assets/images/qrscan/cash.png";
import "./qrstyle.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Common/Loader";
import { useEffect } from "react";
import { resturantActions } from "../../../Store/Resturant/resturantAction";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import OrderPlaced from "./orderPlaced";
import { formatDate } from "../../Dashboard/partials/status";
import { menuActions } from "../../../Store/Menu/menuAction";
import { catalogActions } from "../../../Store/Catalogs/catalogAction";

function BusinessPay() {
  const { menuData } = useSelector((state) => state.MenuReducer);
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [contact, setContact] = useState("");
  const [payWith, setPaywith] = useState("cash");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [appointmentId, setAppointmentid] = useState();
  const [tip, setTip] = useState(10);
  const [tipArray, setTipArray] = useState([]);
  const [total_tip, setTotalTip] = useState(0);
  const [total_price, setTotalPrice] = useState(0);
  const [total_discount, setTotalDiscount] = useState(0);
  const [cartItems, setCartItems] = useState([]);
  const [orderPlaceOpen, setOrderPlaceOpen] = useState(false);
  var bus = parseInt(window.location.href.split("?bus=")[1]);
  var st = parseInt(
    window.location.href.split("&st=")[1]
      ? window.location.href.split("&st=")[1]
      : 0
  );
  var pr = parseInt(window.location.href.split("&pr=")[1]);
  var orderType = parseInt(window.location.href.split("&ordertype=")[1]);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const ser = urlParams.get("ser");
  const da = urlParams.get("da");
  const ti = urlParams.get("ti");
  var na = window.location.href.split("&na=")[1];

  useEffect(() => {
    dispatch(catalogActions.onGetDefaultBusiness(bus)).then((res) => {
      if (res?.id) {
        const data = {
          name: res?.name,
          description: res?.description,
          menu_type: res?.menu_type,
          home_background_image: res?.home_background_image,
          home_display_logo: res?.home_display_logo,
          home_display_image_background: res?.home_display_image_background,
          home_background_image_file: null,
          home_background_color: res?.home_background_color,
          home_restaurant_name_color: res?.home_restaurant_name_color,
          home_main_heading_color: res?.home_main_heading_color,
          home_sub_heading_color: res?.home_sub_heading_color,
          home_text_color: res?.home_text_color,
          home_dine_in_btn_color: res?.home_dine_in_btn_color,
          home_dine_in_text_color: res?.home_dine_in_text_color,
          home_dine_in_logo_color: res?.home_dine_in_logo_color,
          home_delivery_in_btn_color: res?.home_delivery_in_btn_color,
          home_delivery_in_text_color: res?.home_delivery_in_text_color,
          home_delivery_in_logo_color: res?.home_delivery_in_logo_color,
          menu_header_background_color: res?.menu_header_background_color,
          menu_header_text: res?.menu_header_text,
          menu_product_backgroud_color: res?.menu_product_backgroud_color,
          menu_product_text: res?.menu_product_text,
          menu_product_price: res?.menu_product_price,
          menu_product_icons: res?.menu_product_icons,
          menu_cart_background: res?.menu_cart_background,
          menu_cart_text_color: res?.menu_cart_text_color,
          menu_cart_price_color: res?.menu_cart_price_color,
          menu_category_tab_text_color: res?.menu_category_tab_text_color,
          menu_category_tab_color: res?.menu_category_tab_color,
          menu_delivery_text_background: res?.menu_delivery_text_background,
          menu_delivery_btn_color: res?.menu_delivery_btn_color,
          menu_delivery_icon_color: res?.menu_delivery_icon_color,
          item_product_background_color: res?.item_product_background_color,
          item_product_text_color: res?.item_product_text_color,
          item_product_price: res?.item_product_price,
          item_tip_text_color: res?.item_tip_text_color,
          item_tip_btn_color: res?.item_tip_btn_color,
          item_cart_text_color: res?.item_cart_text_color,
          item_cart_btn_color: res?.item_cart_btn_color,
          item_place_order_text_color: res?.item_place_order_text_color,
          item_place_order_btn_color: res?.item_place_order_btn_color,
          popup_background_color: res?.popup_background_color,
          popup_text_color: res?.popup_text_color,
          popup_product_bg_color: res?.popup_product_bg_color,
          popup_product_text_color: res?.popup_product_text_color,
          popup_cart_btn_color: res?.popup_cart_btn_color,
          popup_cart_text_color: res?.popup_cart_text_color,
        };
        dispatch(menuActions.onChange("menuData", data));
      }
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    dispatch(resturantActions.onGetAssignProfessionalToService(pr)).then(
      (data) => {
        if (data?.results) {
          const arr = JSON.parse(ser);
          let sum = 0;
          let tip = 0;
          let discount = 0;
          const records = data?.results.filter((record) => {
            if (arr.includes(record.id)) {
              discount += record.discount;
              sum += record.price;
            }
            return arr.includes(record.id);
          });
          setTotalDiscount(discount);
          setTotalPrice(sum);
          setTotalTip(tip);
          setCartItems(records);
        }
        setLoading(false);
      }
    );
  }, []);
  useEffect(() => {
    loadTip();
  }, []);

  const loadTip = () => {
    setLoading(true);
    dispatch(resturantActions.onGetTip("business")).then((data) => {
      setLoading(false);
      if (data?.length > 0) {
        setTipArray(data);
      }
    });
  };

  const checkout = () => {
    if (
      (contact !== "") &
      (email !== "") &
      (firstName !== "") &
      (lastName != "")
    ) {
      const arr = JSON.parse(ser);
      const data = {
        customer: {
          // customer_id: 7,
          first_name: firstName,
          last_name: lastName,
          email: email,
          contact_no: contact,
        },
        appointment: {
          payment_mode: payWith,
          total_tip: total_tip,
          booking_slot_from: `${da}`,
          // booking_slot_from: `${da} ${ti}:00`,
        },
        services: arr,
      };
      setLoading(true);
      dispatch(resturantActions.onBusinessCheckout(data, bus)).then((data) => {
        setLoading(false);
        if (data?.id) {
          toast.success("Appointment Reserved");
          setAppointmentid(data?.appointment_id);
          setOrderPlaceOpen(true);
        } else {
          // toast.error("Something went wrong, Please try again");
        }
      });
    } else {
      toast.error("Please enter Frist Name, Last Name, Contact no and Email");
    }
  };
  return (
    <>
      <div className="container-fluid" style={{ paddingBottom: "80px" }}>
        <div className="row services-card pt-5 black-bg px-2">
          <div className="col-md-9 col-3">
            <div
              onClick={() => {
                history.push(
                  `/choose-time?bus=${bus}&st=${st}&pr=${pr}&ordertype=${orderType}&na=${
                    na.split("&ser=")[0]
                  }&ser=${ser}`
                );
              }}
            >
              <i class="fa fa-chevron-left orange-text"></i>
            </div>
          </div>
          <div className="col-6 text-center">
            <p className="text-white">Checkout</p>
          </div>
          <div className="col-md-3 col-3 d-flex text-right"></div>
        </div>
        {isLoading ? <Loader /> : null}
        <div
          className="row pl-4 pr-4"
          style={{
            backgroundColor: `${menuData?.home_background_color}`,
            color: `${menuData?.popup_text_color}`,
          }}
        >
          {cartItems?.map((x) => {
            return (
              <div
                className="services-card shadow-sm my-3 white-bg rounded p-2"
                style={{
                  margin: "5px",
                  border: "1px solid",
                  borderColor: "orange",
                  backgroundColor: menuData?.popup_product_bg_color,
                  color: menuData?.popup_product_text_color,
                }}
              >
                <table
                  width="100%"
                  style={{
                    backgroundColor: menuData?.item_product_background_color,
                  }}
                >
                  <tr
                    style={{
                      color: menuData?.item_product_text_color,
                      backgroundColor: menuData?.item_product_background_color,
                    }}
                  >
                    <td
                      width={"30%"}
                      rowSpan={2}
                      style={{
                        marginRight: "5px",
                        backgroundColor:
                          menuData?.item_product_background_color,
                        color: menuData?.popup_product_text_color,
                      }}
                    >
                      <img
                        src={x?.feature_image}
                        className="card-img img-responsive"
                      />
                    </td>
                    <td
                      width={"60%"}
                      style={{
                        verticalAlign: "top",
                        backgroundColor:
                          menuData?.item_product_background_color,
                        color: menuData?.item_product_text_color,
                      }}
                    >
                      <p className="cad-title">{x?.service?.name}</p>
                      <p className="dim-text">{x?.service?.description}</p>
                    </td>
                    <td
                      width={"20%"}
                      rowSpan={2}
                      style={{
                        backgroundColor:
                          menuData?.item_product_background_color,
                        color: `${menuData?.item_product_price}`,
                      }}
                    >
                      <p
                        className="orange-text font-weight-bold"
                        style={{
                          color: `${menuData?.item_product_price}`,
                        }}
                      >
                        ₦{x?.price}
                      </p>
                    </td>
                  </tr>
                  <tr
                    style={{
                      backgroundColor: menuData?.item_product_background_color,
                    }}
                  >
                    <td
                      style={{
                        backgroundColor:
                          menuData?.item_product_background_color,
                      }}
                    >
                      <p className="dim-text pb-o mb-0">
                        <table width={"100%"}>
                          <tr
                            style={{
                              backgroundColor:
                                menuData?.item_product_background_color,
                            }}
                          >
                            <td width={"33%"}>
                              <i className="fa fa-clock orange-text"></i>{" "}
                              {x?.service_time} min
                            </td>
                            <td width={"33%"}>
                              {x?.discount && x?.discount > 0 ? (
                                <i
                                  style={{ color: "#FF4D4D" }}
                                >{`-₦${x?.discount} off`}</i>
                              ) : (
                                ""
                              )}
                            </td>
                            <td width={"33%"}>
                              {/* <i className="fa fa-star orange-text ml-2"></i> {x?.rating} */}
                            </td>
                          </tr>
                        </table>
                      </p>
                    </td>
                  </tr>
                </table>
              </div>
            );
          })}
          <div className="col-12">
            <lable className="form-label">Contact No</lable>
            <PhoneInput
              country={"ng"}
              onChange={(phone) => {
                setContact(phone);
              }}
            />
          </div>
          <div className="col-12">
            <lable className="form-label">First Name</lable>
            <input
              type="text"
              name="firstName"
              class="form-control"
              style={{ border: "1px solid black !important" }}
              placeholder="First Name"
              value={firstName}
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
            />
          </div>
          <div className="col-12">
            <lable className="form-label">Last Name</lable>
            <input
              type="text"
              name="lastName"
              class="form-control"
              style={{ border: "1px solid black !important" }}
              placeholder="Last Name"
              value={lastName}
              onChange={(e) => {
                setLastName(e.target.value);
              }}
            />
          </div>
          <div className="col-12">
            <lable className="form-label">Email</lable>
            <input
              type="text"
              name="email"
              class="form-control"
              style={{ border: "1px solid black !important" }}
              placeholder="Email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </div>
          <div className="col-12 mt-2">
            <h3 className="mobile-heading">Add a Tip</h3>
            {tipArray.map((x) => {
              return (
                <span
                  className={
                    tip === x?.amount ? "tip-amoun orange-bg" : "tip-amoun"
                  }
                  onClick={() => {
                    setTip(x?.amount);
                    setTotalTip(x?.amount);
                  }}
                >
                  ₦ {x?.amount === 0.0 ? "None" : x?.amount}
                </span>
              );
            })}
          </div>

          <div className="col-12 mt-4">
            <h3 className="mobile-heading">Payment Summary</h3>
          </div>

          <div className="col-12">
            <table className="table table-borderless bill-detail">
              <tr>
                <td className="font-weight-bold">Total Amount</td>
                <td className="text-right">₦{total_price}</td>
              </tr>
              <tr>
                <td className="text-danger">Discount</td>
                <td className="text-danger text-right">-₦{total_discount}</td>
              </tr>
              <tr>
                <td>Tip</td>
                <td className="text-right">₦{total_tip}</td>
              </tr>
              {/* <tr>
              <td>Service Fee</td>
              <td className="text-right">₦5.00</td>
            </tr> */}
              <tr>
                <th>Total Amount</th>
                <th className="text-right">
                  ₦ {total_price + total_tip - total_discount}
                </th>
              </tr>
            </table>
          </div>

          <div className="col-12 mt-4">
            <h3 className="mobile-heading">Pay With</h3>
            <p>
              <input
                type="radio"
                name="paywith"
                value="giveaway"
                checked={payWith === "giveaway"}
                onChange={() => setPaywith("giveaway")}
              />{" "}
              <span>
                {" "}
                <img src={giveaway} /> Giveaway App
              </span>
            </p>
            <p>
              <input
                type="radio"
                name="paywith"
                value="cash"
                checked={payWith === "cash"}
                onChange={() => setPaywith("cash")}
              />{" "}
              <span>
                {" "}
                <img src={cash} /> Cash
              </span>
            </p>
          </div>
        </div>
      </div>
      <div className="row m-0 p-0 py-4 black-bg cart-ribbon">
        <div className="col-12 text-center">
          <button
            className="btn my-btn mobile-btn"
            style={{
              backgroundColor: `${menuData?.item_place_order_btn_color}`,
              color: `${menuData?.item_place_order_text_color}`,
            }}
            onClick={() => {
              checkout();
            }}
          >
            Place an Order
          </button>
        </div>
      </div>
      {orderPlaceOpen && (
        <OrderPlaced
          title={"Appointment Reserved"}
          msg={`Appointment ID ${appointmentId} reserved for ${formatDate(
            da
          )}.`}
          firstName={firstName}
          lastName={lastName}
          contact={contact}
          email={email}
          booking={`${da}`}
          appointmentId={appointmentId}
          link={`/business/${na.split("&ser=")[0]}&st=${st}`}
        />
      )}
    </>
  );
}

export default BusinessPay;
