import React from "react";

function BrandingCustomization() {

  return (
    <div className="animation-area-main">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
            <div className="branding-animation-block-area">
              <div className="branding-left">
              <img
            src={require(`../../assets/images/branding1.png`).default}
            alt=""
            className="branding-up-area"
          />
              </div>
              <div className="branding-middle">
            <img
            src={require(`../../assets/images/branding2.png`).default}
            alt=""
            className="branding-img-main"
          />
          </div>
          <div className="branding-right">
            <img
            src={require(`../../assets/images/branding3.png`).default}
            alt=""
            className="branding-bottom-area"
          />
          </div>

            </div>
          </div>
          </div>
        </div>
        </div>
  );
}

export default BrandingCustomization;
