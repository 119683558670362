import React, { useState } from "react";
import RestaurantProductPage from "./restaurantProductPage";
import Drinks from "./drinks";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resturantActions } from "../../../Store/Resturant/resturantAction";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Loader from "../../Common/Loader";
import BusinessCartArea from "./businessCartArea";
import allMeal from "../../../assets/images/all-meal.jpg";
import { menuActions } from "../../../Store/Menu/menuAction";

function RestaurantCategoriesPage() {
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const [showProduct, setShowProduct] = useState(false);
  const [search, setSearch] = useState("");
  const [showPizza, setPizza] = useState(true);
  const [items, setItem] = useState([]);
  const [itemsBackup, setItemBackup] = useState([]);
  const [selectedItems, setSelectedItems] = useState({});
  const [price, setPrice] = useState(0);
  const [selectedCartItems, setSelectedCartItems] = useState(0);

  const [temptItems, setTempItems] = useState([]);
  const [allItems, setAllItems] = useState([]);
  const dispatch = useDispatch();
  var res = parseInt(window.location.href.split("?res=")[1]);
  var st = parseInt(
    window.location.href.split("&st=")[1]
      ? window.location.href.split("&st=")[1]
      : 0
  );
  var br = parseInt(window.location.href.split("&br=")[1]);
  var orderType = parseInt(window.location.href.split("&ordertype=")[1]);
  var crt = window.location.href.split("&crt=")[1];
  var na = window.location.href.split("&na=")[1];
  const { menuData } = useSelector((state) => state.MenuReducer);
  useEffect(() => {
    dispatch(resturantActions.onGetRestaurants(res)).then((result) => {
      if (result?.id) {
        getMenu(result?.branch?.id);
      }
    });
  }, []);

  const getMenu = (rest) => {
    dispatch(menuActions.onGetMenus(rest)).then((res) => {
      if (res?.id) {
        const data = {
          name: res?.name,
          description: res?.description,
          menu_type: res?.menu_type,
          home_background_image: res?.home_background_image,
          home_display_logo: res?.home_display_logo,
          home_display_image_background: res?.home_display_image_background,
          home_background_image_file: null,
          home_background_color: res?.home_background_color,
          home_restaurant_name_color: res?.home_restaurant_name_color,
          home_main_heading_color: res?.home_main_heading_color,
          home_sub_heading_color: res?.home_sub_heading_color,
          home_text_color: res?.home_text_color,
          home_dine_in_btn_color: res?.home_dine_in_btn_color,
          home_dine_in_text_color: res?.home_dine_in_text_color,
          home_dine_in_logo_color: res?.home_dine_in_logo_color,
          home_delivery_in_btn_color: res?.home_delivery_in_btn_color,
          home_delivery_in_text_color: res?.home_delivery_in_text_color,
          home_delivery_in_logo_color: res?.home_delivery_in_logo_color,
          menu_header_background_color: res?.menu_header_background_color,
          menu_header_text: res?.menu_header_text,
          menu_product_backgroud_color: res?.menu_product_backgroud_color,
          menu_product_text: res?.menu_product_text,
          menu_product_price: res?.menu_product_price,
          menu_product_icons: res?.menu_product_icons,
          menu_cart_background: res?.menu_cart_background,
          menu_cart_text_color: res?.menu_cart_text_color,
          menu_cart_price_color: res?.menu_cart_price_color,
          menu_category_tab_text_color: res?.menu_category_tab_text_color,
          menu_category_tab_color: res?.menu_category_tab_color,
          menu_delivery_text_background: res?.menu_delivery_text_background,
          menu_delivery_btn_color: res?.menu_delivery_btn_color,
          menu_delivery_icon_color: res?.menu_delivery_icon_color,
          item_product_background_color: res?.item_product_background_color,
          item_product_text_color: res?.item_product_text_color,
          item_product_price: res?.item_product_price,
          item_tip_text_color: res?.item_tip_text_color,
          item_tip_btn_color: res?.item_tip_btn_color,
          item_cart_text_color: res?.item_cart_text_color,
          item_cart_btn_color: res?.item_cart_btn_color,
          item_place_order_text_color: res?.item_place_order_text_color,
          item_place_order_btn_color: res?.item_place_order_btn_color,
          popup_background_color: res?.popup_background_color,
          popup_text_color: res?.popup_text_color,
          popup_product_bg_color: res?.popup_product_bg_color,
          popup_product_text_color: res?.popup_product_text_color,
          popup_cart_btn_color: res?.popup_cart_btn_color,
          popup_cart_text_color: res?.popup_cart_text_color,
        };
        dispatch(menuActions.onChange("menuData", data));
      }
    });
  };
  useEffect(() => {
    if (orderType === 121) {
      setLoading(true);
      dispatch(resturantActions.onGetDineIn(br)).then((data) => {
        if (data?.id) {
          let allItems =
            data?.sections?.flatMap((section) => section.items) || [];
          setTempItems(data);
          setItem(allItems);
          setItemBackup(data?.sections[0]?.items);
        }
        setLoading(false);
      });
    }
  }, []);

  useEffect(() => {
    if (orderType === 122) {
      dispatch(resturantActions.onGetDelivery(br)).then((data) => {
        if (data?.id) {
          let allItems =
            data?.sections?.flatMap((section) => section.items) || [];
          setAllItems(allItems);
          setTempItems(data);
          setItem(allItems);
          setItemBackup(data?.sections[0]?.items);
        }
        setLoading(false);
      });
    }
  }, []);

  // const handlePizzaClick = (name) => {
  //   setPizza(!showPizza);
  //   setShowDrinks(!showDrinks);
  // };

  const removeToCart = () => {
    dispatch(
      resturantActions.onResturantRemoveCart(null, res, crt, selectedCartItems)
    ).then((data) => {});
  };
  return (
    <>
      {isLoading ? <Loader /> : null}
      <div
        className="qr-mobile-screen"
        style={{ backgroundColor: `${menuData?.home_background_color}` }}
      >
        <div className="container-fluid">
          <div className="row pt-5 black-bg px-2">
            <div className="col-md-2 col-2">
              <i
                class="fas fa-chevron-left top-arrow orange-text pl-1 pt-0 mt-0"
                onClick={() => {
                  removeToCart();
                  history.push(`/restaurant/${na.split("&crt=")[0]}&&st=${st}`);
                }}
              ></i>
            </div>
            <div className="col-md-8 col-8 text-center">
              <p className="white-text">Choose Item</p>
            </div>
            <div className="col-md-2 col-2 d-flex text-right">
              {/* <i class="fa fa-search orange-text pr-3"></i>
            <Dropdown>
              <Dropdown.Toggle variant="abc" id="dropdown-basic">
                <i class="fa fa-heart orange-text"></i>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="#/action-1">Product 01</Dropdown.Item>
                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown> */}
            </div>
          </div>
        </div>

        <div
          className="inner-screen"
          style={{
            backgroundColor: `${menuData?.menu_header_background_color}`,
          }}
        >
          <div
            className="container-fluid"
            style={{ backgroundColor: `${menuData?.home_background_color}` }}
          >
            <div className="row">
              <div className="col-md-12">
                <div className="screen-search-area">
                  <div className="input-group search-area menu-item-search-area">
                    <span className="input-group-text">
                      <i className="flaticon-381-search-2"></i>
                    </span>
                    <input
                      type="text"
                      className={`form-control`}
                      placeholder="Search here..."
                      value={search}
                      onChange={(e) => {
                        setSearch(e.target.value);
                        if (e.target.value !== "") {
                          setItem(
                            itemsBackup.filter((x) =>
                              x?.name.includes(e.target.value)
                            )
                          );
                        } else {
                          setItem(itemsBackup);
                        }
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div className="qr-scan-description">
                  <h5>Top categories</h5>
                </div>
              </div>
            </div>

            <div className="row py-2">
              <div className="category-container">
                <ul className="category-list">
                  <li
                    onClick={() => {
                      // setItem(x?.items);
                      let allItems =
                        temptItems?.sections?.flatMap(
                          (section) => section.items
                        ) || [];
                      setItem(allItems);
                    }}
                  >
                    <div className="category">
                      <img src={allMeal} />
                      <span>All</span>
                    </div>
                  </li>
                  {temptItems?.sections?.map((x, i) => {
                    return x?.items.length > 0 ? (
                      <li
                        className={showPizza && ""}
                        onClick={() => {
                          setItem(x?.items);
                        }}
                      >
                        <div className="category">
                          <img src={x?.featured} />
                          <span>{x?.name}</span>
                        </div>
                      </li>
                    ) : (
                      ""
                    );
                  })}
                </ul>
              </div>
            </div>

            <Drinks
              items={items}
              setShowProduct={(e) => {
                setShowProduct(true);
              }}
              setSelectedItems={(e) => {
                setSelectedItems(e);
              }}
            />
            <div style={{ height: "80px" }}></div>
            {/* {showPizza && <Pizza items={items} />} */}
            {/* card */}
          </div>
        </div>
        {/* <button className="btn btn-info" onClick={()=>setShowProduct(true)}>Click me</button> */}
        {/* {showProduct ? < Test /> : ""} */}
        {/* <RestaurantProductPage /> */}
        {showProduct ? (
          <RestaurantProductPage
            selectedRecord={selectedItems}
            back={() => {
              setShowProduct(!showProduct);
              //removeToCart(selectedItems?.id);
            }}
            price={(e) => {
              setPrice(e);
            }}
            cartItemId={(e) => {
              setSelectedCartItems(e);
            }}
          />
        ) : (
          ""
        )}
        {price > 0 && !showProduct && (
          <BusinessCartArea
            price={price}
            link={`/pay?res=${res}&st=${st}&br=${br}&ordertype=${orderType}&crtId=${selectedCartItems}&na=${
              na.split("&crt=")[0]
            }&crt=${crt}`}
          />
        )}
      </div>
    </>
  );
}

export default RestaurantCategoriesPage;
