import React from "react";
import CmAnimation from "./CrmAnimation";

function Crm() {
  return (
    <section className="about-2-area about-5-area">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <CmAnimation />
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="about-5-content">
              <h3 className="title">CRM Software for Hair Salon</h3>
              <div className="text">
                <p>
                  Salon management system includes the best CRM software to
                  successfully manage your hairdressers. This tool is a great
                  way to take control of the salon. Online booking and CRM
                  software will prevent double bookings and unhappy clients.
                  Another great benefit of using our system is you no longer
                  have to run to answer phone calls and emails. Follow your
                  schedule and it’s that simple!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Crm;
