import React from "react";
import CommonHero from "../Helper/CommonHero";
import FooterOne from "../Partials/Footers/FooterOne";
import HeaderFive from "../Partials/Headers/HeaderFive";
import FastPayment from "./FastPayment";
import ElectronicTips from "./ElectronicTips";
import RatingReviews from "./RatingReviews";
import BrandPage from "./BrandPage";
// import { useSelector } from "react-redux";

function ScantoPay() {
  // const { errorMessage } = useSelector((state) => state.AccountReducer);
  return (
    <>
      <HeaderFive />
      <CommonHero title="Scan To Pay" />
      <FastPayment />
      <ElectronicTips />
      <RatingReviews />
      <BrandPage />
      <FooterOne />
    </>
  );
}

export default ScantoPay;
