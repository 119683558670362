import { Dropdown } from "react-bootstrap";
import {
  getStatus,
  getFormatedDate,
  getFormatedTime,
} from "../partials/status";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

const Upcoming = ({
  redirectTo,
  upCommingData,
  onOrderStatusChange,
  handleChange,
  upCommingPageSize,
  orderPaywithQRCode,
  orderPaywithCash,
}) => {
  return (
    <>
      <div className="row">
        <table className="table table-borderless">
          <thead>
            <tr>
              <th className="mob-remove" scope="col">
                Date{" "}
              </th>
              <th scope="col" className="order-detail">
                Order ID
              </th>
              <th className="mob-remove" scope="col">
                Order Type
              </th>
              <th className="mob-remove" scope="col">
                Order Status
              </th>
              <th className="mob-remove" scope="col">
                Payment Status
              </th>
              <th className="mob-remove" scope="col">
                Payment Type
              </th>
              <th className="mob-remove" scope="col">
                Amount
              </th>
              <th scope="col" className="text-center order-detail">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {upCommingData.map((x) => {
              return (
                <tr style={{ justifyContent: "center" }}>
                  <td scope="row" className="text-center mob-remove">
                    {getFormatedDate(x.placed_at)}
                    <br></br>
                    {getFormatedTime(x.placed_at)}
                  </td>
                  <td>
                    <span
                      className="form-label order-detail"
                      style={{ cursor: "pointer", color: "#F9A05F" }}
                      onClick={() => {
                        redirectTo(x?.order_id);
                      }}
                    >
                      {x?.order_id}
                    </span>
                  </td>
                  <td className="mob-remove">{getStatus(x?.order_type)}</td>
                  <td className="mob-remove">{getStatus(x?.order_status)}</td>
                  <td className="mob-remove">{getStatus(x?.payment_status)}</td>
                  <td className="mob-remove">{getStatus(x?.payment_mode)}</td>
                  <td className="mob-remove">{`${
                    x?.currency_symbol ? x?.currency_symbol : x?.currency
                  } ${x?.total_bill_amount}`}</td>
                  <td className="text-center">
                    <Dropdown>
                      <Dropdown.Toggle variant="abbs" id="dropdown-basic">
                        <span>
                          <i class="far fa-ellipsis-h"></i>
                        </span>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => {
                            onOrderStatusChange(x?.order_id, "accepted");
                          }}
                          disabled={
                            x?.payment_status === "success" ? true : false
                          }
                          className="order-detail"
                        >
                          Accepted
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            onOrderStatusChange(x?.order_id, "decline");
                          }}
                          disabled={
                            x?.payment_status === "success" ? true : false
                          }
                          className="order-detail"
                        >
                          Decline
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            orderPaywithQRCode(x?.order_id);
                          }}
                          disabled={
                            x?.payment_status === "success" ? true : false
                          }
                          className="order-detail"
                        >
                          Pay Via QR
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            orderPaywithCash(x?.order_id);
                          }}
                          disabled={
                            x?.payment_status === "success" ? true : false
                          }
                          className="order-detail"
                        >
                          Pay Via Cash
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    {/* <button
                      className="btn btn-danger small"
                      onClick={() => {
                        onOrderStatusChange(x?.order_id, "decline");
                      }}
                      disabled={
                        x?.order_status === "accepted" ||
                        x?.order_status === "decline"
                          ? true
                          : false
                      }
                    >
                      Decline
                    </button>
                    <button
                      className="btn btn-success small"
                      onClick={() => {
                        onOrderStatusChange(x?.order_id, "accepted");
                      }}
                      disabled={
                        x?.order_status === "accepted" ||
                        x?.order_status === "decline"
                          ? true
                          : false
                      }
                    >
                      Accept
                    </button> */}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {upCommingData?.length === 0 && (
          <span
            style={{
              display: "flex",
              justifyContent: "center",
              fontSize: "20px",
              marginBottom: "50px",
            }}
          >
            You haven’t any Order.
          </span>
        )}
      </div>
      {upCommingPageSize > 1 && (
        <Stack spacing={2}>
          <Pagination count={upCommingPageSize} onChange={handleChange} />
        </Stack>
      )}
    </>
  );
};

export default Upcoming;
