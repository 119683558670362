import { debounce } from "@mui/material";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resturantActions } from "../../../Store/Resturant/resturantAction";
import PhoneInput from "react-phone-input-2";

const GeneralForm = ({
  profile,
  setProfile,
  feature,
  setFeature,
  businessTypes,
}) => {
  const { resturantSetting } = useSelector((state) => state.ResturantReducer);
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");

  const addLat = () => {
    const location = window.navigator && window.navigator.geolocation;
    if (location) {
      location.getCurrentPosition(
        (position) => {
          dispatch(
            resturantActions.onChange("resturantSetting", {
              ...resturantSetting,
              latitude: {
                lat: position.coords.latitude,
                long: position.coords.longitude,
              },
            })
          );
        },
        (error) => {}
      );
    }
  };
  const addLog = () => {
    const location = window.navigator && window.navigator.geolocation;
    // if (location) {
    //   location.getCurrentPosition(
    //     (position) => {
    //       dispatch(
    //         resturantActions.onChange("resturantSetting", {
    //           ...resturantSetting,
    //           longitude: position.coords.longitude,
    //         })
    //       );
    //     },
    //     (error) => {}
    //   );
    // }
  };
  const dispatch = useDispatch();
  let resturantId = 0;
  const type = localStorage.getItem("type");
  const resId = localStorage.getItem("res");
  if (resId) {
    resturantId = resId;
  } else {
    resturantId = localStorage.getItem("bus");
  }
  const changeHandler = (event) => {
    var data = { name: event.name, link: event.link };
    dispatch(
      resturantActions.onUpdateSocialMedia(data, resturantId, event.id)
    ).then((res) => {});
  };
  const changeHandler1 = (event) => {
    var data = [{ name: event.type, link: event.value }];
    dispatch(
      resturantActions.onCreateSocialMedia(data, resturantId, event.id)
    ).then((res) => {
      resturantSetting?.social_media.push({
        id: res?.id,
        name: event.type,
        link: event.value,
      });
      dispatch(
        resturantActions.onChange("resturantSetting", {
          ...resturantSetting,
          resturantSetting,
        })
      );
    });
  };
  const debouncedChangeHandler = useCallback(debounce(changeHandler, 500), []);
  const debouncedChangeHandler1 = useCallback(
    debounce(changeHandler1, 500),
    []
  );

  const socialLinksUpdate = (value, type) => {
    var res = resturantSetting?.social_media?.filter(
      (x) => x?.name?.toLowerCase() === type
    )[0];
    if (res) {
      res.link = value;
      var updatedLinks = resturantSetting?.social_media?.map((x) => {
        if (x.weekday === type) {
          x.link = value;
        }
        return x;
      });
      dispatch(
        resturantActions.onChange("resturantSetting", {
          ...resturantSetting,
          social_media: updatedLinks,
        })
      );
      debouncedChangeHandler(res);
    } else {
      debouncedChangeHandler1({ value, type });
    }
  };
  return (
    <>
      <div className="row px-2">
        <div className="col-md-12 py-3">
          <div className="col-md-5 py-3">
            <h3>Logo</h3>
            {resturantSetting?.logo && (
              <img
                src={profile ? resturantSetting?.logo : resturantSetting?.logo}
                className="item-setting-preview"
              />
            )}
            <div className="file-upload-wrapper">
              <div className="mb-3">
                {/* <label htmlFor="formFile" className="form-label">
                  Logo
                </label> */}
                <input
                  type="file"
                  className="form-control"
                  name="myImage"
                  accept="image/png,image/jpeg"
                  onChange={(e) => {
                    if (e.target.files && e.target.files[0]) {
                      let img = e.target.files[0];
                      setProfile(e.target.files[0]);
                      const ava = URL.createObjectURL(img);
                      dispatch(
                        resturantActions.onChange("resturantSetting", {
                          ...resturantSetting,
                          logo: ava,
                        })
                      );
                    }
                  }}
                />
              </div>
            </div>
          </div>
          {/* <div className="col-md-5 py-3">
            <h3>Cover Image</h3>
            {resturantSetting?.featured && (
              <img
                src={
                  feature
                    ? resturantSetting?.featured
                    : resturantSetting?.featured
                }
                className="item-setting-preview"
              />
            )}
            <div className="file-upload-wrapper">
              <input
                type="file"
                name="myImage"
                accept="image/png"
                onChange={(e) => {
                  if (e.target.files && e.target.files[0]) {
                    let img = e.target.files[0];
                    setFeature(e.target.files[0]);
                    const ava = URL.createObjectURL(img);
                    dispatch(
                      resturantActions.onChange("resturantSetting", {
                        ...resturantSetting,
                        featured: ava,
                      })
                    );
                  }
                }}
              />
            </div>
          </div> */}
        </div>

        <div className="col-md-6 col-xs-12 py-2">
          <label htmlFor="">Venue Name</label>
          <input
            type="text"
            className="form-control input-filed"
            placeholder="Burger & Pizza Kitchen"
            value={resturantSetting?.name}
            onChange={(e) => {
              dispatch(
                resturantActions.onChange("resturantSetting", {
                  ...resturantSetting,
                  name: e.target.value,
                })
              );
            }}
          />
        </div>
        {/* <div className="col-md-2 col-xs-12 py-2"></div> */}
        <div className="col-md-6 py-2">
          <label htmlFor="">Display Name</label>
          <input
            type="text"
            className="form-control input-filed"
            placeholder="Display Name"
            value={resturantSetting?.display_name}
            onChange={(e) => {
              dispatch(
                resturantActions.onChange("resturantSetting", {
                  ...resturantSetting,
                  display_name: e.target.value,
                })
              );
            }}
          />
        </div>

        <div className="col-md-12 col-xs-12 py-2">
          <label htmlFor="">Venue Description</label>
          <input
            type="text"
            className="form-control input-filed"
            placeholder="Description"
            value={resturantSetting?.description}
            onChange={(e) => {
              dispatch(
                resturantActions.onChange("resturantSetting", {
                  ...resturantSetting,
                  description: e.target.value,
                })
              );
            }}
          />
        </div>

        <div className="col-md-12 col-xs-12 py-2">
          <label htmlFor="">Address</label>
          <input
            type="text"
            className="form-control input-filed"
            placeholder="Address"
            value={resturantSetting?.branch?.address}
            onChange={(e) => {
              dispatch(
                resturantActions.onChange("resturantSetting", {
                  ...resturantSetting,
                  branch: {
                    ...resturantSetting.branch,
                    address: e.target.value,
                  },
                })
              );
            }}
          />
        </div>

        {/* <div className="col-md-5 col-xs-12 py-2">
          <label htmlFor="">City</label>
          <input
            type="text"
            className="form-control input-filed"
            placeholder="City"
            value={resturantSetting?.branch?.city?.name}
            onChange={(e) => {
              dispatch(
                resturantActions.onChange("resturantSetting", {
                  ...resturantSetting,
                  branch: {
                    ...resturantSetting?.branch,
                    city: {
                      ...resturantSetting?.branch.city,
                      name: e.target.value,
                    },
                  },
                })
              );
            }}
          />
        </div>
        <div className="col-md-2 col-xs-12 py-2"></div>
        <div className="col-md-5 col-xs-12 py-2">
          <label htmlFor="">State</label>
          <input
            type="text"
            className="form-control input-filed"
            placeholder="State"
            value={resturantSetting?.name}
            onChange={(e) => {
              dispatch(
                resturantActions.onChange("resturantSetting", {
                  ...resturantSetting,
                  state: e.target.value,
                })
              );
            }}
          />
        </div>

        <div className="col-md-5 col-xs-12 py-2">
          <label htmlFor="">Country</label>
          <input
            type="text"
            className="form-control input-filed"
            placeholder="Country"
            value={resturantSetting?.name}
            onChange={(e) => {
              dispatch(
                resturantActions.onChange("resturantSetting", {
                  ...resturantSetting,
                  country: e.target.value,
                })
              );
            }}
          />
        </div> 
        <div className="col-md-2 col-xs-12 py-2"></div>*/}
        <div className="col-md-6 col-xs-12 py-2">
          <label className="">Contact No</label>
          <PhoneInput
            country={"ng"}
            value={resturantSetting?.primary_contact_no}
            onChange={(phone) => {
              dispatch(
                resturantActions.onChange("resturantSetting", {
                  ...resturantSetting,
                  primary_contact_no: phone,
                })
              );
            }}
          />
        </div>

        <div className="col-md-6 col-xs-12 py-2">
          <label htmlFor="">Email</label>
          <input
            type="text"
            className="form-control input-filed"
            placeholder="Email"
            value={resturantSetting?.email}
            onChange={(e) => {
              dispatch(
                resturantActions.onChange("resturantSetting", {
                  ...resturantSetting,
                  email: e.target.value,
                })
              );
            }}
          />
        </div>
        <div className="col-md-12 col-xs-12 py-2">
          <label htmlFor="">Website</label>
          <input
            type="text"
            className="form-control input-filed"
            placeholder="Website"
            value={
              resturantSetting?.social_media?.filter(
                (x) => x?.name?.toLowerCase() === "website"
              )[0]?.link
            }
            onChange={(e) => {
              let social_media = resturantSetting?.social_media;
              const index = social_media?.findIndex(
                (item) => item.name === "website"
              );
              social_media[index]["link"] = e.target.value;

              dispatch(
                resturantActions.onChange("resturantSetting", {
                  ...resturantSetting,
                  social_media: social_media,
                })
              );
              // socialLinksUpdate(e.target.value, "facebook");
            }}
          />
        </div>

        <div className="col-md-5 col-xs-12 py-2">
          <label htmlFor="">Latitude</label>
          <input
            type="text"
            className="form-control input-filed"
            placeholder="Latitude"
            value={
              resturantSetting?.latitude?.lat
                ? resturantSetting?.latitude?.lat
                : resturantSetting?.latitude
            }
            onChange={(e) => {
              dispatch(
                resturantActions.onChange("resturantSetting", {
                  ...resturantSetting,
                  latitude: {
                    lat: e.target.value,
                    long: resturantSetting?.latitude?.long,
                  },
                })
              );
            }}
          />
        </div>

        <div className="col-md-5 col-xs-12 py-2">
          <label htmlFor="">Longitude</label>
          <input
            type="text"
            className="form-control input-filed"
            placeholder="Longitude"
            value={
              resturantSetting?.latitude?.long
                ? resturantSetting?.latitude?.long
                : resturantSetting?.longitude
            }
            onChange={(e) => {
              dispatch(
                resturantActions.onChange("resturantSetting", {
                  ...resturantSetting,
                  latitude: {
                    lat: resturantSetting?.latitude?.lat,
                    long: e.target.value,
                  },
                })
              );
            }}
          />
        </div>
        <div className="col-md-2 col-xs-12 py-2">
          <button
            type="submit"
            className="btn btn-primary btn-block"
            style={{ width: "100px", height: "50px", alignSelf: "end" }}
            onClick={() => {
              addLat();
            }}
          >
            Add lat/long
          </button>
        </div>

        {/* {type === "business" && ( */}
        {true && (
          <div className="col-md-6 col-xs-12 py-2 mt-5">
            <label htmlFor="">Business Type</label>
            <select
              name="position"
              className="form-control input-filed"
              placeholder="e.g 3142121"
              value={resturantSetting?.business_type?.id}
              onChange={(e) => {
                dispatch(
                  resturantActions.onChange("resturantSetting", {
                    ...resturantSetting,
                    businessType: e.target.value,
                  })
                );
              }}
            >
              <option key={0}>Select Business Type</option>
              {businessTypes?.map((x, i) => {
                return (
                  <option key={i + 1} value={x?.id}>
                    {x?.name}
                  </option>
                );
              })}
            </select>
          </div>
        )}
        <div className="col-md-12 mt-4">
          <h6>Social Profile</h6>
        </div>

        <div className="col-sm-6">
          <label htmlFor="staticEmail">Facebook</label>
          <input
            type="text"
            className="form-control input-filed"
            id="staticEmail"
            value={
              resturantSetting?.social_media?.filter(
                (x) => x?.name?.toLowerCase() === "facebook"
              )[0]?.link
            }
            onChange={(e) => {
              let social_media = resturantSetting?.social_media;
              const index = social_media?.findIndex(
                (item) => item.name === "facebook"
              );
              social_media[index]["link"] = e.target.value;

              dispatch(
                resturantActions.onChange("resturantSetting", {
                  ...resturantSetting,
                  social_media: social_media,
                })
              );
              // socialLinksUpdate(e.target.value, "facebook");
            }}
          />
        </div>

        <div className="col-sm-6">
          <label htmlFor="staticEmail">Tripadvisor</label>
          <input
            type="text"
            className="form-control input-filed"
            id="staticEmail"
            value={
              resturantSetting?.social_media?.filter(
                (x) => x?.name?.toLowerCase() === "tripadvisor"
              )[0]?.link
            }
            onChange={(e) => {
              let social_media = resturantSetting?.social_media;
              const index = social_media?.findIndex(
                (item) => item.name === "tripadvisor"
              );
              social_media[index]["link"] = e.target.value;

              dispatch(
                resturantActions.onChange("resturantSetting", {
                  ...resturantSetting,
                  social_media: social_media,
                })
              );
              // socialLinksUpdate(e.target.value, "facebook");
            }}
          />
        </div>

        <div className="col-sm-6">
          <label htmlFor="staticEmail">Instagram</label>
          <input
            type="text"
            className="form-control input-filed"
            id="staticEmail"
            value={
              resturantSetting?.social_media?.filter(
                (x) => x?.name?.toLowerCase() === "instagram"
              )[0]?.link
            }
            onChange={(e) => {
              let social_media = resturantSetting?.social_media;
              const index = social_media?.findIndex(
                (item) => item.name === "instagram"
              );
              social_media[index]["link"] = e.target.value;

              dispatch(
                resturantActions.onChange("resturantSetting", {
                  ...resturantSetting,
                  social_media: social_media,
                })
              );
              // socialLinksUpdate(e.target.value, "facebook");
            }}
          />
        </div>

        <div className="col-sm-6">
          <label htmlFor="staticEmail">Snapchat</label>
          <input
            type="text"
            className="form-control input-filed"
            id="staticEmail"
            value={
              resturantSetting?.social_media?.filter(
                (x) => x?.name?.toLowerCase() === "snapchat"
              )[0]?.link
            }
            onChange={(e) => {
              let social_media = resturantSetting?.social_media;
              const index = social_media?.findIndex(
                (item) => item.name === "snapchat"
              );
              social_media[index]["link"] = e.target.value;

              dispatch(
                resturantActions.onChange("resturantSetting", {
                  ...resturantSetting,
                  social_media: social_media,
                })
              );
              // socialLinksUpdate(e.target.value, "facebook");
            }}
          />
        </div>

        <div className="col-sm-6">
          <label htmlFor="staticEmail">Twitter</label>
          <input
            type="text"
            className="form-control input-filed"
            id="staticEmail"
            value={
              resturantSetting?.social_media?.filter(
                (x) => x?.name?.toLowerCase() === "twitter"
              )[0]?.link
            }
            onChange={(e) => {
              let social_media = resturantSetting?.social_media;
              const index = social_media?.findIndex(
                (item) => item.name === "twitter"
              );
              social_media[index]["link"] = e.target.value;

              dispatch(
                resturantActions.onChange("resturantSetting", {
                  ...resturantSetting,
                  social_media: social_media,
                })
              );
              // socialLinksUpdate(e.target.value, "facebook");
            }}
          />
        </div>

        <div className="col-sm-6">
          <label htmlFor="staticEmail">Tiktok</label>
          <input
            type="text"
            className="form-control input-filed"
            id="staticEmail"
            value={
              resturantSetting?.social_media?.filter(
                (x) => x?.name?.toLowerCase() === "tiktok"
              )[0]?.link
            }
            onChange={(e) => {
              let social_media = resturantSetting?.social_media;
              const index = social_media?.findIndex(
                (item) => item.name === "tiktok"
              );
              social_media[index]["link"] = e.target.value;

              dispatch(
                resturantActions.onChange("resturantSetting", {
                  ...resturantSetting,
                  social_media: social_media,
                })
              );
              // socialLinksUpdate(e.target.value, "facebook");
            }}
          />
        </div>

        <div className="col-sm-6">
          <label htmlFor="staticEmail">Youtube</label>
          <input
            type="text"
            className="form-control input-filed"
            id="staticEmail"
            value={
              resturantSetting?.social_media?.filter(
                (x) => x?.name?.toLowerCase() === "youtube"
              )[0]?.link
            }
            onChange={(e) => {
              let social_media = resturantSetting?.social_media;
              const index = social_media?.findIndex(
                (item) => item.name === "youtube"
              );
              social_media[index]["link"] = e.target.value;

              dispatch(
                resturantActions.onChange("resturantSetting", {
                  ...resturantSetting,
                  social_media: social_media,
                })
              );
              // socialLinksUpdate(e.target.value, "facebook");
            }}
          />
        </div>

        <div className="col-sm-6">
          <label htmlFor="staticEmail">Website</label>
          <input
            type="text"
            className="form-control input-filed"
            id="staticEmail"
            value={
              resturantSetting?.social_media?.filter(
                (x) => x?.name?.toLowerCase() === "website"
              )[0]?.link
            }
            onChange={(e) => {
              socialLinksUpdate(e.target.value, "website");
            }}
          />
        </div>

        <div className="col-sm-6">
          <label htmlFor="staticEmail">Privacy URL</label>
          <input
            type="text"
            className="form-control input-filed"
            id="staticEmail"
            value={
              resturantSetting?.social_media?.filter(
                (x) => x?.name?.toLowerCase() === "privacyurl"
              )[0]?.link
            }
            onChange={(e) => {
              let social_media = resturantSetting?.social_media;
              const index = social_media?.findIndex(
                (item) => item.name === "privacyURL"
              );
              social_media[index]["link"] = e.target.value;

              dispatch(
                resturantActions.onChange("resturantSetting", {
                  ...resturantSetting,
                  social_media: social_media,
                })
              );
              // socialLinksUpdate(e.target.value, "facebook");
            }}
          />
        </div>

        <div className="col-sm-6">
          <label htmlFor="staticEmail">Linktree</label>
          <input
            type="text"
            className="form-control input-filed"
            id="staticEmail"
            value={
              resturantSetting?.social_media?.filter(
                (x) => x?.name?.toLowerCase() === "linktree"
              )[0]?.link
            }
            onChange={(e) => {
              let social_media = resturantSetting?.social_media;
              const index = social_media?.findIndex(
                (item) => item.name === "Linktree"
              );
              social_media[index]["link"] = e.target.value;

              dispatch(
                resturantActions.onChange("resturantSetting", {
                  ...resturantSetting,
                  social_media: social_media,
                })
              );
              // socialLinksUpdate(e.target.value, "facebook");
            }}
          />
        </div>
      </div>
    </>
  );
};

export default GeneralForm;
