import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { menuActions } from "../../../../Store/Menu/menuAction";
import { catalogActions } from "../../../../Store/Catalogs/catalogAction";
import { toast } from "react-toastify";
const EditMenuheader = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { menuData } = useSelector((state) => state.MenuReducer);
  const onSaveButton = () => {
    let data;
    if (menuData?.home_background_image_file) {
      data = new FormData();
      data.append("name", "Special Menu");
      data.append("description", "Some description of menu");
      menuData?.home_background_image_file &&
        data.append(
          "home_background_image",
          menuData?.home_background_image_file
        );
      data.append("home_display_logo", menuData?.home_display_logo);
      data.append(
        "home_display_image_background",
        menuData?.home_display_image_background
      );
      data.append("home_background_color", menuData?.home_background_color);
      data.append(
        "home_restaurant_name_color",
        menuData?.home_restaurant_name_color
      );
      data.append("home_main_heading_color", menuData?.home_main_heading_color);
      data.append("home_sub_heading_color", menuData?.home_sub_heading_color);
      data.append("home_text_color", menuData?.home_text_color);
      data.append("home_dine_in_btn_color", menuData?.home_dine_in_btn_color);
      data.append("home_dine_in_text_color", menuData?.home_dine_in_text_color);
      data.append("home_dine_in_logo_color", menuData?.home_dine_in_logo_color);
      data.append(
        "home_delivery_in_btn_color",
        menuData?.home_delivery_in_btn_color
      );
      data.append(
        "home_delivery_in_text_color",
        menuData?.home_delivery_in_text_color
      );
      data.append(
        "home_delivery_in_logo_color",
        menuData?.home_delivery_in_logo_color
      );
      data.append(
        "menu_header_background_color",
        menuData?.menu_header_background_color
      );
      data.append("menu_header_text", menuData?.menu_header_text);
      data.append(
        "menu_product_backgroud_color",
        menuData?.menu_product_backgroud_color
      );
      data.append("menu_product_text", menuData?.menu_product_text);
      data.append("menu_product_price", menuData?.menu_product_price);
      data.append("menu_product_icons", menuData?.menu_product_icons);
      data.append("menu_cart_background", menuData?.menu_cart_background);
      data.append("menu_cart_text_color", menuData?.menu_cart_text_color);
      data.append("menu_cart_price_color", menuData?.menu_cart_price_color);
      data.append(
        "menu_category_tab_text_color",
        menuData?.menu_category_tab_text_color
      );
      data.append("menu_category_tab_color", menuData?.menu_category_tab_color);
      data.append(
        "menu_delivery_text_background",
        menuData?.menu_delivery_text_background
      );
      data.append("menu_delivery_btn_color", menuData?.menu_delivery_btn_color);
      data.append(
        "menu_delivery_icon_color",
        menuData?.menu_delivery_icon_color
      );
      data.append(
        "item_product_background_color",
        menuData?.item_product_background_color
      );
      data.append("item_product_text_color", menuData?.item_product_text_color);
      data.append("item_product_price", menuData?.item_product_price);
      data.append("item_tip_text_color", menuData?.item_tip_text_color);
      data.append("item_tip_btn_color", menuData?.item_tip_btn_color);
      data.append("item_cart_text_color", menuData?.item_cart_text_color);
      data.append("item_cart_btn_color", menuData?.item_cart_btn_color);
      data.append(
        "item_place_order_text_color",
        menuData?.item_place_order_text_color
      );
      data.append(
        "item_place_order_btn_color",
        menuData?.item_place_order_btn_color
      );
      data.append("popup_background_color", menuData?.popup_background_color);
      data.append("popup_text_color", menuData?.popup_text_color);
      data.append("popup_product_bg_color", menuData?.popup_product_bg_color);
      data.append(
        "popup_product_text_color",
        menuData?.popup_product_text_color
      );
      data.append("popup_cart_btn_color", menuData?.popup_cart_btn_color);
      data.append("popup_cart_text_color", menuData?.popup_cart_text_color);
    } else {
      data = {
        name: "Special Menu",
        description: "Some description of menu",
        home_background_image: menuData?.home_background_image,
        home_display_logo: menuData?.home_display_logo,
        home_display_image_background: menuData?.home_display_image_background,
        home_background_color: menuData?.home_background_color,
        home_restaurant_name_color: menuData?.home_restaurant_name_color,
        home_main_heading_color: menuData?.home_main_heading_color,
        home_sub_heading_color: menuData?.home_sub_heading_color,
        home_text_color: menuData?.home_text_color,
        home_dine_in_btn_color: menuData?.home_dine_in_btn_color,
        home_dine_in_text_color: menuData?.home_dine_in_text_color,
        home_dine_in_logo_color: menuData?.home_dine_in_logo_color,
        home_delivery_in_btn_color: menuData?.home_delivery_in_btn_color,
        home_delivery_in_text_color: menuData?.home_delivery_in_text_color,
        home_delivery_in_logo_color: menuData?.home_delivery_in_logo_color,
        menu_header_background_color: menuData?.menu_header_background_color,
        menu_header_text: menuData?.menu_header_text,
        menu_product_backgroud_color: menuData?.menu_product_backgroud_color,
        menu_product_text: menuData?.menu_product_text,
        menu_product_price: menuData?.menu_product_price,
        menu_product_icons: menuData?.menu_product_icons,
        menu_cart_background: menuData?.menu_cart_background,
        menu_cart_text_color: menuData?.menu_cart_text_color,
        menu_cart_price_color: menuData?.menu_cart_price_color,
        menu_category_tab_text_color: menuData?.menu_category_tab_text_color,
        menu_category_tab_color: menuData?.menu_category_tab_color,
        menu_delivery_text_background: menuData?.menu_delivery_text_background,
        menu_delivery_btn_color: menuData?.menu_delivery_btn_color,
        menu_delivery_icon_color: menuData?.menu_delivery_icon_color,
        item_product_background_color: menuData?.item_product_background_color,
        item_product_text_color: menuData?.item_product_text_color,
        item_product_price: menuData?.item_product_price,
        item_tip_text_color: menuData?.item_tip_text_color,
        item_tip_btn_color: menuData?.item_tip_btn_color,
        item_cart_text_color: menuData?.item_cart_text_color,
        item_cart_btn_color: menuData?.item_cart_btn_color,
        item_place_order_text_color: menuData?.item_place_order_text_color,
        item_place_order_btn_color: menuData?.item_place_order_btn_color,
        popup_background_color: menuData?.popup_background_color,
        popup_text_color: menuData?.popup_text_color,
        popup_product_bg_color: menuData?.popup_product_bg_color,
        popup_product_text_color: menuData?.popup_product_text_color,
        popup_cart_btn_color: menuData?.popup_cart_btn_color,
        popup_cart_text_color: menuData?.popup_cart_text_color,
      };
    }
    var menuId = window.location.href.split("?id=")[1];
    if (menuId.includes("-u")) {
      dispatch(catalogActions.onUpdateCatalog(data, parseInt(menuId))).then(
        (x) => {
          if (x) {
            history.push(`/cat-all-menu`);
          }
        }
      );
    } else {
      dispatch(catalogActions.onCreateCatalog(data)).then((x) => {
        if (x?.id) {
          history.push(`/cat-all-menu`);
        } else if (x?.response?.data?.message) {
          // toast.error(x?.response?.data?.message);
        } else {
          // toast.error("Some thing went wrong, Please try later.");
        }
      });
    }
  };
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <h3 className="inner-header">Edit Theme</h3>
          </div>
          <div className="col-md-6 text-right">
            <ul className="dashbaord-header-button">
              <li>
                <button
                  className="btn-theme-secondary slim-button-theme "
                  onClick={() => history.push("/cat-home")}
                >
                  Cancel
                </button>
              </li>
              <li>
                <button
                  className="btn-theme-main slim-button-theme"
                  onClick={() => onSaveButton()}
                >
                  Save
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};
export default EditMenuheader;
