import React, { useState } from "react";
import {Link} from 'react-router-dom';
const MsgBox = ({ openMsg, PerfectScrollbar, offMsg }) => {
   const [toggle, setToggle] = useState(false);
   return (
      <div
         className={`card chat dlab-chat-history-box ${openMsg ? "" : "d-none"}`}
      >
         <div className="card-header chat-list-header text-center">
            <Link to={"#"}
               className="dlab-chat-history-back"
               onClick={() => offMsg()}
            >
               <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  width="18px"
                  height="18px"
                  viewBox="0 0 24 24"
                  version="1.1"
               >
                  <g
                     stroke="none"
                     strokeWidth="1"
                     fill="none"
                     fillRule="evenodd"
                  >
                     <polygon points="0 0 24 0 24 24 0 24" />
                     <rect
                        fill="#000000"
                        opacity="0.3"
                        transform="translate(15.000000, 12.000000) scale(-1, 1) rotate(-90.000000) translate(-15.000000, -12.000000) "
                        x="14"
                        y="7"
                        width="2"
                        height="10"
                        rx="1"
                     />
                     <path
                        d="M3.7071045,15.7071045 C3.3165802,16.0976288 2.68341522,16.0976288 2.29289093,15.7071045 C1.90236664,15.3165802 1.90236664,14.6834152 2.29289093,14.2928909 L8.29289093,8.29289093 C8.67146987,7.914312 9.28105631,7.90106637 9.67572234,8.26284357 L15.6757223,13.7628436 C16.0828413,14.136036 16.1103443,14.7686034 15.7371519,15.1757223 C15.3639594,15.5828413 14.7313921,15.6103443 14.3242731,15.2371519 L9.03007346,10.3841355 L3.7071045,15.7071045 Z"
                        fill="#000000"
                        fillRule="nonzero"
                        transform="translate(9.000001, 11.999997) scale(-1, -1) rotate(90.000000) translate(-9.000001, -11.999997) "
                     />
                  </g>
               </svg>
            </Link>
         </div>
         <PerfectScrollbar
            className={`card-body msg_card_body dlab-scroll ${
               openMsg ? "ps ps--active-y" : ""
            } `}
            id="DZ_W_Contacts_Body3"
         >
            <div className="faq-answer-question">
           <h4>How to Upgrate your personal account to business account?</h4>
           <div className="faq-answers">
            <p>"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."</p>
           </div>
           </div>
         </PerfectScrollbar>

      </div>
   );
};

export default MsgBox;
