import React, { useState } from "react";
import TutorialDetail from "./TutorialDetail"

function TutorialsData() {
    const [detailsShow,setDetailsShow] = useState(false);
  return (
		<>
        {detailsShow ? (<TutorialDetail setDetailClose = {setDetailsShow}/>) : ""}
   
		<div className="tutorial-content-area">
            <div className="tutorial-content-block card">
                <div className="tutorial-section-heading">
                    <h4 className="turorial-section-heading">Geting Started</h4>
                    <ul className="tutorial-list">
                       <li>
                        <p className="tutorial-question"
                         onClick={()=>setDetailsShow(true)}
                        >
                            How to Upgrate your personal account to business account?
                        </p>
                       </li>
                       <li>
                       <p className="tutorial-question"
                        onClick={()=>setDetailsShow(true)}>
                            How to Login?
                        </p>
                       </li>
                       <li>
                       <p className="tutorial-question"
                        onClick={()=>setDetailsShow(true)}
                       >
                            How to setup your business details?
                        </p>
                       </li>
                    </ul>
                </div>
            </div>
            <div className="tutorial-content-block card">
                <div className="tutorial-section-heading">
                    <h4 className="turorial-section-heading">Menu</h4>
                    <ul className="tutorial-list">
                       <li>
                        <p className="tutorial-question"
                        onClick={()=>setDetailsShow(true)}
                        >
                            How to Upgrate your personal account to business account?
                        </p>
                       </li>
                       <li>
                       <p className="tutorial-question"
                        onClick={()=>setDetailsShow(true)}
                       >
                            How to Login?
                        </p>
                       </li>
                       <li>
                       <p className="tutorial-question"
                        onClick={()=>setDetailsShow(true)}
                       >
                            How to setup your business details?
                        </p>
                       </li>
                    </ul>
                </div>
            </div>
        </div>
		</>
  );
}

export default TutorialsData;
