import React from "react";

function Benefits() {

  return (
    <section className="about-2-area about-5-area">
      <div className="container">
        <div className="row align-items-center">

          <div className="col-lg-12 col-md-12">
            <div className="about-5-content">
              <h3 className="title">Benefits of QR Menu</h3>
              <div className="text-area">
                <ul className="text-light benefits">
              <li><span className="prmary-brand-color">&#10146;</span> No need for printed menus.</li>
              <li><span className="prmary-brand-color">&#10146;</span> QR Menu that showcases food images and videos to upsell your food.</li>
              <li><span className="prmary-brand-color">&#10146;</span> Increased efficiency as the order and re-orders are placed without the staff approaching the guest.</li>
              <li><span className="prmary-brand-color">&#10146;</span> Simple and easy interface similar to most popular delivery apps making it easy for the guest to use.</li>
              <li><span className="prmary-brand-color">&#10146;</span> No download of any app required. When the guest scans the QR Code a link pops up on the browser</li>
              <li><span className="prmary-brand-color">&#10146;</span> The guest is able to place their order and pay directly through the digital menu.</li>
              </ul>
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Benefits;
