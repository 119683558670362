import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal'

const Users = () =>{
    const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

    return(<>
        <div className="row white-bg rounded m-3 p-2">
            <table className="table table-borderless">
                <thead>
                    <tr>
                        <th scope="col"> First Name </th>
                        <th scope="col"> Last Name </th>
                        <th scope="col"> Email </th>
                        <th scope="col"> Role </th>
                        <th scope="col">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Kumail</td>
                        <td>Ali</td>
                        <td>komail@gmail.com</td>
                        <td><button className="btn btn-danger small-btn">Owner</button></td>
                        <td>
                            <i className="fas fa-edit px-1"></i>
                           
                            {/* MODAL BUTTON */}
                            <Button variant="a" onClick={handleShow}>
                            <i class="far fa-trash-alt px-1"></i>
                            </Button>
                        </td>
                    </tr>

                    <tr>
                        <td>Kumail</td>
                        <td>Ali</td>
                        <td>komail@gmail.com</td>
                        <td><button className="btn btn-success small-btn">Staff</button></td>
                        <td>
                            <i className="fas fa-edit px-1"></i>
                             {/* MODAL BUTTON */}
                             <Button variant="a" onClick={handleShow}>
                            <i class="far fa-trash-alt px-1"></i>
                            </Button>
                        </td>
                    </tr>

                    <tr>
                        <td>Kumail</td>
                        <td>Ali</td>
                        <td>komail@gmail.com</td>
                        <td><button className="btn btn-warning small-btn">Super Admin</button></td>
                        <td>
                            <i className="fas fa-edit px-1"></i>
                             {/* MODAL BUTTON */}
                             <Button variant="a" onClick={handleShow}>
                            <i class="far fa-trash-alt px-1"></i>
                            </Button>
                        </td>
                    </tr>

                    <tr>
                        <td>Kumail</td>
                        <td>Ali</td>
                        <td>komail@gmail.com</td>
                        <td><button className="btn btn-warning small-btn">Admin</button></td>
                        <td>
                            <i className="fas fa-edit px-1"></i>
                             {/* MODAL BUTTON */}
                             <Button variant="a" onClick={handleShow}>
                            <i class="far fa-trash-alt px-1"></i>
                            </Button>
                        </td>
                    </tr>

                    <tr>
                        <td>Kumail</td>
                        <td>Ali</td>
                        <td>komail@gmail.com</td>
                        <td><button className="btn btn-success small-btn">Editor</button></td>
                        <td>
                            <i className="fas fa-edit px-1"></i>
                             {/* MODAL BUTTON */}
                             <Button variant="a" onClick={handleShow}>
                            <i class="far fa-trash-alt px-1"></i>
                            </Button>
                        </td>
                    </tr>

                </tbody>
            </table>
            {/* START OF MODAL */}
            <Modal show={show} onHide={handleClose}>
                        {/* <Modal.Header closeButton>
                        
                        </Modal.Header> */}
                        <Modal.Body>
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <i className='fa fa-exclamation'></i>
                                <h2 className="heading my-3">Are You Sure?</h2>
                                <p className="dim-text">Do you want to delete this table(s) ?</p>
                            </div>
                            <div className="col-md-6 text-center my-3">
                            <Button variant="info" onClick={handleClose}>
                                Cancel
                            </Button>
                            </div>
                            <div className="col-md-6 text-center my-3">
                            <Button variant="delete" onClick={handleClose}>
                                Delete
                            </Button>
                            </div>
                        </div>
                        </Modal.Body>
                        {/* <Modal.Footer> */}
                        
                        {/* <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button> */}
                        
                        
                        {/* </Modal.Footer> */}
                    </Modal>
                    {/* END OF MODAL */}
        </div>
    </>)
}

export default Users;