import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const RestaurantCartArea = ({ selectedCount, totalPrice, link }) => {
  const { menuData } = useSelector((state) => state.MenuReducer);
  const history = useHistory();
  return (
    <div style={{ position: "absolute", bottom: 0, width: "100%" }}>
      <div
        className="row m-0 p-0 py-4 black-bg cart-ribbon"
        style={{
          backgroundColor: `${menuData?.menu_cart_background}`,
          color: `${menuData?.menu_delivery_btn_color}`,
        }}
      >
        <div className="col-6">
          <span className="total-dim-text">Total Bill</span>
          <p className="footer-total-text white-text">₦{totalPrice}</p>
        </div>
        <div className="col-6 text-right">
          <button
            className="preview-button-theme"
            onClick={() => {
              history.push(link);
            }}
          >
            <span className="btn-cart-ison">
              <i
                class="fa fa-shopping-cart"
                aria-hidden="true"
                style={{
                  color: `${menuData?.menu_delivery_icon_color}`,
                }}
              ></i>
            </span>
            {`Checkout(${selectedCount})`}
          </button>
        </div>
      </div>
    </div>
  );
};

export default RestaurantCartArea;
