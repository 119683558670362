import React, { useState } from "react";
import SimpleSlider from "../Helper/SliderCom";
import VideoPopUp from "../Helper/VideoPopUp";
import SliderAnimation from "./SliderAnimation";

function Hero() {
  const [popupvideo, setValue] = useState(false);
  const toggleHandler = (e) => {
    e.preventDefault();
    setValue(!popupvideo);
  };
  const settings = {
    autoplay: true,
    autoplaySpeed: 10000,
    pauseOnHover: false,
    dots: true,
    fade: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          dots: false,
          arrows: false,
        },
      },
    ],
  };
  return (
    <>
      {popupvideo && (
        <VideoPopUp
          videoSrc="//www.youtube.com/embed/EE7NqzhMDms?autoplay=1"
          handler={toggleHandler}
        />
      )}
      <section className="hero-slider hero-5-slide">
        <SimpleSlider settings={settings}>
          <div className="hero-area hero-2-area hero-5-area">
            <div className="section__bg"></div>
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="hero-2-content">
                    <h1
                      className="title mobile-p-t"
                      data-animation="fadeInLeft"
                      data-delay="0.6s"
                    >
                     Grow your Revenue & Increase Profits with Us
                    </h1>
                    <hr></hr>
                  </div>
                </div>
                <div className="col-lg-6">
                <SliderAnimation />
                </div>
              </div>
            </div>


          </div>
        </SimpleSlider>
      </section>
    </>
  );
}

export default Hero;
