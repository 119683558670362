import React from "react";
import { useHistory } from "react-router-dom";

const DheaderMembers = () => {
  const history = useHistory();
  let isSubscription = true;
  const subscription = JSON.parse(localStorage.getItem("subscription"));
  if (subscription) {
    isSubscription = subscription?.active === "active" ? false : true;
  }
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <h3 className="inner-header order-detail">Staff Members</h3>
          </div>
          <div className="col-md-8 text-right">
            {/* <button className="btn btn-primary" data-toggle="modal" data-target="#exampleModal">Schedule</button> */}
            {/* model */}
            {/* <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                  <div class="modal-dialog" role="document">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body">
                        ...
                      </div>
                      <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-primary">Save changes</button>
                      </div>
                    </div>
                  </div>
                </div> */}
            {/* end of modal */}
            {/* <button className="btn btn-primary ml-2">Generate QR Code</button> */}
            <button
              className="btn btn-primary ml-2"
              onClick={() => {
                history.push(`add-professional-member`);
              }}
              disabled={isSubscription}
            >
              Add New Member
            </button>
          </div>
        </div>

        {/* Modal */}
      </div>
    </>
  );
};
export default DheaderMembers;
