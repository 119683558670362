import { catalogTypes } from "./catalogTypes";
const INITIAL_STATE = {
  menuData: {
    name: "Fast Food",
    description: "Life is too short for bad coffee.",
    menu_type: "restaurant",
    home_background_image: "",
    home_display_logo: true,
    home_display_image_background: true,
    home_background_image_file: null,
    home_background_color: "#989898",
    home_restaurant_name_color: "#989898",
    home_main_heading_color: "#989898",
    home_sub_heading_color: "#989898",
    home_text_color: "#1C1C1E",
    home_dine_in_btn_color: "#989898",
    home_dine_in_text_color: "#1C1C1E",
    home_dine_in_logo_color: "#989898",
    home_delivery_in_btn_color: "#989898",
    home_delivery_in_text_color: "#1C1C1E",
    home_delivery_in_logo_color: "#989898",
    menu_header_background_color: "#989898",
    menu_header_text: "#1C1C1E",
    menu_product_backgroud_color: "#989898",
    menu_product_text: "#1C1C1E",
    menu_product_price: "#989898",
    menu_product_icons: "#989898",
    menu_cart_background: "#989898",
    menu_cart_text_color: "#1C1C1E",
    menu_cart_price_color: "#989898",
    menu_category_tab_text_color: "#1C1C1E",
    menu_category_tab_color: "#989898",
    menu_delivery_text_background: "#1C1C1E",
    menu_delivery_btn_color: "#989898",
    menu_delivery_icon_color: "#DADADA",
    item_product_background_color: "#DADADA",
    item_product_text_color: "#1C1C1E",
    item_product_price: "#DADADA",
    item_tip_text_color: "#1C1C1E",
    item_tip_btn_color: "#DADADA",
    item_cart_text_color: "#1C1C1E",
    item_cart_btn_color: "#989898",
    item_place_order_text_color: "#1C1C1E",
    item_place_order_btn_color: "#989898",
    popup_background_color: "#989898",
    popup_text_color: "#1C1C1E",
    popup_product_bg_color: "#989898",
    popup_product_text_color: "#1C1C1E",
    popup_cart_btn_color: "#989898",
    popup_cart_text_color: "#1C1C1E",
  },
  searchTheme: "",
};

const fn = (state, action) => {
  if (!state) state = INITIAL_STATE;
  switch (action.type) {
    case catalogTypes.MENU_ONCHANGE:
      return {
        ...state,
        errorMessage: "",
        [action.payload.prop]: action.payload.value,
      };
    default:
      return state;
  }
};

export default fn;
