import React, { useEffect, useState } from "react";
import DeshboardLayout from "../layouts/DeshboardLayout";
import "./orders.css";
import { useDispatch } from "react-redux";
import Loader from "../../Common/Loader";
import { catalogActions } from "../../../Store/Catalogs/catalogAction";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { formatDate, getStatus, toTitleCase } from "../partials/status";
import BaseModel from "../../Common/BaseModel";
import { toast } from "react-toastify";

function AppointmentDetail() {
  const [isLoading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [professional, setProfessional] = useState(null);
  const [orderData, setOrderData] = useState({});
  const [qrCodeLink, setQrCodeLink] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const orderId = window.location.href.split("?id=")[1];
  useEffect(() => {
    setLoading(true);
    dispatch(catalogActions.onGetAppointmentDetail(orderId)).then((res) => {
      setLoading(false);
      setOrderData(res);
      if (res.services) {
        setItems(res.services);
        setProfessional(res.services[0].professional);
      }
    });
  }, []);

  const distributeTip = () => {
    setLoading(true);
    dispatch(catalogActions.onSetAppointmentTip(orderData?.tip_id)).then(
      (res) => {
        setLoading(false);
        if (res?.link === "completed") {
          toast.warning("Tip already distributed");
        } else {
          const link = res?.link;
          setQrCodeLink(link);
          setIsOpen(true);
        }
      }
    );
  };

  return (
    <>
      {isLoading ? <Loader /> : null}
      <div id="main-wrapper" className="show">
        <DeshboardLayout />
        <div
          className={`content-body dashboard-conainer ${
            isLoading ? "hide-custom" : "show"
          }`}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <h3 className="inner-header">
                  Orders
                  <span className="heading-link">
                    <i className="fa fa-chevron-right bread-icon"></i>
                    <span
                      className="subheading-link"
                      onClick={() => {
                        history.push("/");
                      }}
                    >
                      Back To Order List
                    </span>
                  </span>
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div className="content-body">
          <div className="row order-detail">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h6 className="p-0 m-0">Order Detail</h6>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-4">
                      <strong>Order ID</strong>
                      <p>{orderData?.appointment_id}</p>
                      <hr></hr>
                    </div>
                    <div className="col-md-4">
                      <strong>Order Type</strong>
                      <p>{getStatus(orderData?.appointment_type)}</p>
                      <hr></hr>
                    </div>
                    <div className="col-md-4">
                      <strong>Booking Date</strong>
                      <p>{formatDate(orderData?.booking_slot_from)}</p>
                      <hr></hr>
                    </div>
                    <div className="col-md-4">
                      <strong>Order Status</strong>
                      <p>{getStatus(orderData?.order_status)}</p>
                      <hr></hr>
                    </div>
                    <div className="col-md-4">
                      <strong>Payment Status</strong>
                      <p>{getStatus(orderData?.payment_status)}</p>
                      <hr></hr>
                    </div>
                    <div className="col-md-4">
                      <strong>Payment Mode</strong>
                      <p>{getStatus(orderData?.payment_mode)}</p>
                      <hr></hr>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h6 className="p-0 m-0">Services Detail</h6>
                </div>
                <div className="card-body">
                  <div className="row">
                    {items.length === 0 ? (
                      <div className="col-12"></div>
                    ) : (
                      items.map((value, index) => (
                        <div className="row" key={index}>
                          <div className="col-4">
                            <strong>Name</strong>
                            <p>{value.service}</p>
                          </div>
                          <div className="col-4">
                            <strong>Unit Price</strong>
                            <p>
                              {orderData?.currency} {value.price}
                            </p>
                          </div>
                          <div className="col-4">
                            <strong>Discount</strong>
                            <p>
                              {value.discount
                                ? orderData?.currency + ` ` + value.discount
                                : "None"}
                            </p>
                          </div>
                          <div className="col-12">
                            <hr></hr>
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h6 className="p-0 m-0">Customer Detail</h6>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-4">
                      <strong>Customer ID</strong>
                      <p>{orderData?.customer?.customer_id}</p>
                      <hr></hr>
                    </div>
                    <div className="col-md-4">
                      <strong>Customer Name</strong>
                      <p>
                        {orderData?.customer?.first_name +
                          " " +
                          orderData?.customer?.last_name}
                      </p>
                      <hr></hr>
                    </div>
                    <div className="col-md-4">
                      <strong>Customer contact no</strong>
                      <p>{orderData?.customer?.contact_no}</p>
                      <hr></hr>
                    </div>
                    {orderData?.customer?.email !== "" && (
                      <div className="col-md-4">
                        <strong>Customer Email</strong>
                        <p>{orderData?.customer?.email}</p>
                        <hr></hr>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h6 className="p-0 m-0">Billing Detail</h6>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-4">
                      <strong>Total Items Price:</strong>
                      <p>
                        {orderData?.currency} {orderData?.actual_price}
                      </p>
                    </div>
                    <div className="col-4">
                      <strong>Total Discount:</strong>
                      <p>
                        {orderData?.currency} {orderData?.total_discounted}
                      </p>
                    </div>
                    {/* <div className="col-4">
                    <strong>Service Charges:</strong>
                    <p>
                      {orderData?.currency} {orderData?.service_charges}
                    </p>
                  </div> */}
                    <div className="col-4">
                      <strong>Tip:</strong>
                      <p>
                        {orderData?.currency} {orderData?.total_tip}
                      </p>
                    </div>
                    <div className="col-4">
                      <strong>Items Price with Discount:</strong>
                      <p>
                        {orderData?.currency} {orderData?.total_price}
                      </p>
                    </div>
                    <div className="col-12">
                      <hr></hr>
                    </div>
                    <div className="col-4">
                      <strong>Total Price:</strong>{" "}
                      <strong>
                        {orderData?.currency} {orderData?.total_bill_amount}
                      </strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {professional ? (
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h6 className="p-0 m-0">Professional Detail</h6>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-4">
                        <strong>Name:</strong>
                        <p>{toTitleCase(professional.name)}</p>
                      </div>
                      <div className="col-sm-4">
                        <strong>ID No:</strong>
                        <p>{professional.id_no}</p>
                      </div>
                      <div className="col-sm-4 align-right">
                        {orderData?.total_tip ? (
                          !orderData?.tip_disbursed ? (
                            orderData?.payment_status == "completed" ? (
                              <button
                                onClick={() => {
                                  distributeTip();
                                }}
                                className="btn btn-success"
                              >
                                Distribute Tip (
                                {orderData?.currency +
                                  " " +
                                  orderData?.total_tip}
                                )
                              </button>
                            ) : (
                              "Payment Pending"
                            )
                          ) : (
                            <>
                              Disbursed (
                              {orderData?.currency + " " + orderData?.total_tip}
                              )
                            </>
                          )
                        ) : (
                          "No Tip"
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <BaseModel
        qrCodeLink={qrCodeLink}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </>
  );
}

export default AppointmentDetail;
