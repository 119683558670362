// import { Upcoming } from "@mui/icons-material";
import { debounce } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import DeshboardLayout from "../layouts/DeshboardLayout";
import Dheader from "./dheaer";
import Filter from "./filter";
import Upcoming from "./upcoming";
import Past from "./past";
import "./orders.css";
import { useDispatch } from "react-redux";
import Loader from "../../Common/Loader";
import { catalogActions } from "../../../Store/Catalogs/catalogAction";
import QRCode from "react-qr-code";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { toast } from "react-toastify";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { formatDate } from "../partials/status";
import GuideModel from "../partials/visitModel";
import question from "../../../assets/images/question.png";
import qr_guide from "../../../assets/images/qr_scan_guide.jpg";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  alignItem: "center",
  display: "flex",
  flexDirection: "column",
};

function Appointments() {
  const [toggle, setToggle] = useState(1);
  const [upCommingData, setUpCommingData] = useState([]);
  const [upCommingDataBackup, setUpCommingDataBackup] = useState([]);
  const [pastData, setPastData] = useState([]);
  const [pastDataBackup, setPastDataBackup] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [upCommingPageSize, setUpCommingPageSize] = useState(0);
  const [pastPageSize, setPastPageSize] = useState(0);
  const [qrCodeLink, setQrCodeLink] = useState("");
  const [orderDetail, setOrderDetail] = useState({});
  const [appointmentData, setAppointmentData] = useState([]);
  const [isDetailOpen, setIsDetailOpen] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    loadPastData(null);
  }, []);

  useEffect(() => {
    loadUpcomingData(null);
  }, []);

  const loadPastData = (page) => {
    setLoading(true);
    let date = new Date();
    let pastTime = "";
    if (page) {
      pastTime = `?page=${page}&booking_slot_from__lte=${
        date.toISOString().split("T")[0]
      }`;
    } else {
      pastTime = `?booking_slot_from__lte=${date.toISOString().split("T")[0]}`;
    }

    dispatch(catalogActions.onGetAllAppointments(pastTime)).then((res) => {
      if (res?.results?.length > 0) {
        setPastData(res?.results);
        setPastDataBackup(res?.results);
        setPastPageSize(Math.ceil(res?.count / 10));
      }
      setLoading(false);
    });
  };

  const loadUpcomingData = (page) => {
    setLoading(true);
    let date = new Date();
    let upCommingTime = "";
    if (page) {
      upCommingTime = `?page=${page}&booking_slot_from__gte=${
        date.toISOString().split("T")[0]
      }`;
    } else {
      upCommingTime = `?booking_slot_from__gte=${
        date.toISOString().split("T")[0]
      }`;
    }

    dispatch(catalogActions.onGetAllAppointments(upCommingTime)).then((res) => {
      if (res?.results?.length > 0) {
        setUpCommingData(res?.results);
        setUpCommingDataBackup(res?.results);
        setUpCommingPageSize(Math.ceil(res?.count / 10));
      }
      setLoading(false);
    });
  };

  const filterOrderStatus = (link) => {
    setLoading(true);
    dispatch(catalogActions.onOrderFilter(link)).then((res) => {
      setLoading(false);
      if (res?.results?.length > 0) {
        setUpCommingData(res?.results);
        setUpCommingPageSize(Math.ceil(res?.count / 10));
      }
    });
  };

  function updateToggle(id) {
    setToggle(id);
  }

  const debouncedChangeHandler = useCallback(
    debounce(filterOrderStatus, 500),
    []
  );

  const filter = (keyword) => {
    if (toggle === 1) {
      let link = `?resPerPage=10&search=${keyword}`;
      // filterOrderStatus(link);
      debouncedChangeHandler(link);
      // if (keyword !== "") {
      //   const results = upCommingDataBackup.filter((x) =>
      //     x?.appointment_id.includes(keyword)
      //   );
      //   setUpCommingData(results);
      // } else {
      //   setUpCommingData(upCommingDataBackup);
      // }
    } else {
      let link = `?resPerPage=10&search=${keyword}`;
      filterOrderStatus(link);
      // if (keyword !== "") {
      //   const results = pastDataBackup.filter((x) =>
      //     x?.appointment_id?.includes(keyword)
      //   );
      //   setPastData(results);
      // } else {
      //   setPastData(pastDataBackup);
      // }
    }

    setSearch(keyword);
  };

  const redirectTo = (id) => {
    history.push(`/appointment-detail?id=${id}`);
  };

  const orderStatus = (keyword) => {
    if (toggle === 1) {
      filterOrderStatus(
        `?resPerPage=50&order_status=${keyword.toLowerCase()}&payment_status&total_price`
      );
      // const results = upCommingDataBackup.filter(
      //   (x) => x?.order_status.toLowerCase() == keyword.toLowerCase()
      // );
      // setUpCommingData(results);
    } else {
      const results = pastDataBackup.filter(
        (x) => x?.order_status.toLowerCase() == keyword.toLowerCase()
      );
      setPastData(results);
    }
  };

  const sortBy = (keyword) => {
    if (toggle === 1) {
      if (keyword.toLowerCase() === "orderid") {
        const results = upCommingData.sort((a, b) => {
          let fa = a.appointment_id.toLowerCase(),
            fb = b.appointment_id.toLowerCase();
          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
          return 0;
        });
        const val = results.filter((x) => x);
        setUpCommingData(val);
      }
      if (keyword.toLowerCase() === "date") {
        const results = upCommingData.sort((a, b) => {
          let da = new Date(a.placed_at),
            db = new Date(b.placed_at);
          return da - db;
        });
        const val = results.filter((x) => x);
        setUpCommingData(val);
      }
      if (keyword.toLowerCase() === "amount") {
        const results = upCommingData.sort((a, b) => {
          return a.total_bill_amount - b.total_bill_amount;
        });
        const val = results.filter((x) => x);
        setUpCommingData(val);
      }
      if (keyword.toLowerCase() === "orderstatus") {
        const results = upCommingData.sort((a, b) => {
          let fa = a.order_status.toLowerCase(),
            fb = b.order_status.toLowerCase();
          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
          return 0;
        });
        const val = results.filter((x) => x);
        setUpCommingData(val);
      }
      if (keyword.toLowerCase() === "type") {
        const results = upCommingData.sort((a, b) => {
          let fa = a.order_type.toLowerCase(),
            fb = b.order_type.toLowerCase();
          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
          return 0;
        });
        const val = results.filter((x) => x);
        setUpCommingData(val);
      }
      if (keyword.toLowerCase() === "orderdetails") {
        const results = upCommingData.sort((a, b) => {
          let fa = a.items[0].item.name.toLowerCase(),
            fb = b.items[0].item.name.toLowerCase();
          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
          return 0;
        });
        const val = results.filter((x) => x);
        setUpCommingData(val);
      }
    } else {
      if (keyword.toLowerCase() === "orderid") {
        const results = pastDataBackup.sort((a, b) => {
          let fa = a.appointment_id.toLowerCase(),
            fb = b.appointment_id.toLowerCase();
          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
          return 0;
        });
        const val = results.filter((x) => x);
        setPastData(val);
      }
      if (keyword.toLowerCase() === "date") {
        const results = pastDataBackup.sort((a, b) => {
          let da = new Date(a.placed_at),
            db = new Date(b.placed_at);
          return da - db;
        });
        const val = results.filter((x) => x);
        setPastData(val);
      }
      if (keyword.toLowerCase() === "amount") {
        const results = pastDataBackup.sort((a, b) => {
          return a.total_bill_amount - b.total_bill_amount;
        });
        const val = results.filter((x) => x);
        setPastData(val);
      }
      if (keyword.toLowerCase() === "orderstatus") {
        const results = pastDataBackup.sort((a, b) => {
          let fa = a.order_status.toLowerCase(),
            fb = b.order_status.toLowerCase();
          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
          return 0;
        });
        const val = results.filter((x) => x);
        setPastData(val);
      }
      if (keyword.toLowerCase() === "type") {
        const results = pastDataBackup.sort((a, b) => {
          let fa = a.order_type.toLowerCase(),
            fb = b.order_type.toLowerCase();
          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
          return 0;
        });
        const val = results.filter((x) => x);
        setPastData(val);
      }
      if (keyword.toLowerCase() === "orderdetails") {
        const results = pastDataBackup.sort((a, b) => {
          let fa = a.items[0].item.name.toLowerCase(),
            fb = b.items[0].item.name.toLowerCase();
          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
          return 0;
        });
        const val = results.filter((x) => x);
        setPastData(val);
      }
    }
  };

  const clearFilter = () => {
    setPastData(pastDataBackup);
    setUpCommingData(upCommingDataBackup);
  };

  const orderStatusChange = (orderId, orderStatus) => {
    setLoading(true);
    dispatch(
      catalogActions.onChangeAppointmentsStatus(orderId, orderStatus)
    ).then((res) => {
      loadPastData(null);
      loadUpcomingData(null);
    });
  };

  const orderPaywithQRCode = (orderId) => {
    setLoading(true);
    dispatch(catalogActions.onGetAppointmentQRCode(orderId)).then((res) => {
      setLoading(false);
      if (res?.link != "completed") {
        setIsOpen(true);
        setQrCodeLink(res?.link);
        setOrderDetail(res?.detail);
      } else {
        toast.warn("Payment Completed!");
      }
    });
  };

  const orderPaywithPush = (orderId) => {
    setLoading(true);
    dispatch(
      catalogActions.onPayAppointmentWithPush(orderId)
    ).then((res) => {
      toast.success("Push message for order payment sent successfully");
      loadPastData(null);
      loadUpcomingData(null);
    });
  };

  const getOrderDetail = (orderId) => {
    setLoading(true);
    dispatch(catalogActions.onGetAppointmentDetail(orderId)).then((res) => {
      setLoading(false);
      if (res?.payment_status == "completed") {
        toast.warning("Payment Completed!");
        return;
      }
      setIsDetailOpen(true);
      setAppointmentData(res);
    });
  };

  const payOrder = () => {
    if (
      (appointmentData?.total_tip !== "") &
      (appointmentData?.total_price !== "")
    ) {
      setLoading(true);
      const data = {
        transaction_id: "11111542",
        transaction_type: "purchase",
        payment_status: "success",
        tip: appointmentData?.total_tip,
        amount: appointmentData?.total_price,
        currency: "NGN",
        description: "",
        username: "Munawar",
        customer_id: "123",
        customer: appointmentData?.customer,
        payment_method: "giveaway",
      };
      dispatch(
        catalogActions.onBusinessTransactionWayCash(
          data,
          appointmentData?.appointment_id
        )
      ).then((res) => {
        if (res?.id) {
          setIsDetailOpen(false);
          toast.success("Transaction Succuss");
          loadUpcomingData(null);
        } else {
          toast.error("Transaction Failed");
        }
        setLoading(false);
      });
    } else {
      toast.error("Amount and tip is not null");
    }
  };

  const onCloseModel = () => {
    setOpen(false);
  };

  const upComminghandleChange = (page) => {
    setUpCommingPageSize(page);
    loadUpcomingData(page);
  };

  const pastHandleChange = (page) => {
    setPastPageSize(page);
    loadPastData(page);
  };

  const openPopup = () => {
    setIsOpen(true);
  };

  const closePopup = () => {
    setIsOpen(false);
  };

  return (
    <>
      {isLoading ? <Loader /> : null}
      <div id="main-wrapper" class="show over-flow-block">
        <DeshboardLayout />
        <div
          class={`content-body dashboard-conainer ${
            isLoading ? "hide-custom" : "show"
          }`}
        >
          <Dheader />
          <Filter
            search={search}
            onChange={(e) => {
              filter(e);
            }}
            orderStatus={(e) => orderStatus(e)}
            clearFilter={() => clearFilter()}
            sortBy={(e) => {
              sortBy(e);
            }}
          />

          <div className="row white-bg   mx-3 p-1 mt-3 rounded">
            {/* {isOpen && (
              <div
                style={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  background: "rgba(0, 0, 0, 0.5)",
                  zindex: "100",
                  display: "flex",
                  justifycontent: "center",
                  alignitems: "center",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    maxwidth: "80%",
                    maxheight: "80%",
                    overflow: "auto",
                    backgroundcolor: "white",
                    borderradius: "5px",
                    padding: "20px",
                    boxshadow: "0 0 10px rgba(0, 0, 0, 0.5)",
                  }}
                >
                  <button onClick={closePopup}>Close</button>
                  <div className="col-md-4 p-4 col-xs-12">
                    <div id="qrcode" className="p-2">
                      <QRCode value={qrCodeLink} />
                    </div>
                  </div>
                </div>
              </div>
            )} */}
            {/* demo */}
            <ul className="dashbaord-edit-menu-nav upcoming-past-tab">
              <li
                className={toggle === 1 ? "active-menu-nav" : ""}
                onClick={() => updateToggle(1)}
              >
                <span>Upcoming Appointments</span>
              </li>
              <li
                className={toggle === 2 ? "active-toggle" : ""}
                onClick={() => updateToggle(2)}
              >
                <span>Past Appointments</span>
              </li>
            </ul>
            {/* end demo */}
            {/* <ul className="toggle-list">
              <li
                className={toggle === 1 ? "active-toggle" : ""}
                onClick={() => updateToggle(1)}
              >
                Upcoming Orders
              </li>
              <li
                className={toggle === 2 ? "active-toggle" : ""}
                onClick={() => updateToggle(2)}
              >
                Past Order
              </li>
            </ul> */}

            {toggle === 1 && (
              <div className={toggle === 1 ? "show-content" : "toggle-content"}>
                <Upcoming
                  redirectTo={redirectTo}
                  upCommingData={upCommingData}
                  onOrderStatusChange={(orderId, orderStatus) => {
                    orderStatusChange(orderId, orderStatus);
                  }}
                  handleChange={(a, b) => {
                    upComminghandleChange(b);
                  }}
                  upCommingPageSize={upCommingPageSize}
                  orderPaywithQRCode={(e) => orderPaywithQRCode(e)}
                  orderPaywithCash={(e) => getOrderDetail(e)}
                  orderPaywithPush={(e) => orderPaywithPush(e)}
                />
              </div>
            )}
            {toggle === 2 && (
              <div className={toggle === 2 ? "show-content" : "toggle-content"}>
                <Past
                  redirectTo={redirectTo}
                  pastData={pastData}
                  onOrderStatusChange={(orderId, orderStatus) => {
                    orderStatusChange(orderId, orderStatus);
                  }}
                  pastHandleChange={(a, b) => {
                    pastHandleChange(b);
                  }}
                  pastPageSize={pastPageSize}
                />
              </div>
            )}
          </div>
          <GuideModel
            url={qr_guide}
            open={open}
            onCloseCallback={onCloseModel}
          />
          <Modal
            open={isOpen}
            onClose={() => closePopup()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style} style={{ position: "relative" }}>
              <img
                className="question-mark"
                width={25}
                src={question}
                onClick={() => {
                  setOpen(true);
                }}
                style={{ position: "absolute", right: "8px", top: "8px" }}
              />
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                style={{ alignSelf: "center" }}
              >
                Pay Via Giveaway
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ mt: 2 }}
                style={{ alignSelf: "center" }}
              >
                <QRCode value={qrCodeLink} />
              </Typography>
              <div className="row">
                <div className="col-12">
                  {orderDetail?.booking_slot_from ? (
                    <table width={"250px"} style={{ margin: "auto" }}>
                      <tr>
                        <td colSpan={2} className="text-center">
                          {formatDate(orderDetail?.booking_slot_from)}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <table className="items-table" width={"100%"}>
                            <tr>
                              <th>Service</th>
                              <th>Price</th>
                            </tr>
                            {orderDetail?.services.map((obj) => (
                              <tr>
                                <td>{obj?.service}</td>
                                <td>
                                  {orderDetail?.currency + " " + obj?.price}
                                </td>
                              </tr>
                            ))}
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <th>Order ID : </th>
                        <td>{orderDetail?.appointment_id}</td>
                      </tr>
                      <tr>
                        <th>Total Price : </th>
                        <td>
                          {orderDetail?.currency +
                            " " +
                            orderDetail?.total_price}
                        </td>
                      </tr>
                      <tr>
                        <th>Discount : </th>
                        <td>
                          {orderDetail?.currency +
                            " " +
                            orderDetail?.total_discounted}
                        </td>
                      </tr>
                      <tr>
                        <th>Total Tip : </th>
                        <td>
                          {orderDetail?.currency + " " + orderDetail?.total_tip}
                        </td>
                      </tr>
                      <tr>
                        <th>Total : </th>
                        <th>
                          {orderDetail?.currency +
                            " " +
                            orderDetail?.total_bill_amount}
                        </th>
                      </tr>
                    </table>
                  ) : (
                    <p className="text-center">Loading</p>
                  )}
                </div>
              </div>
              <button
                className="btn btn-primary card-btn"
                style={{ alignSelf: "center", marginTop: "20px" }}
                onClick={() => {
                  closePopup();
                }}
              >
                Close
              </button>
            </Box>
          </Modal>
          <Modal
            open={isDetailOpen}
            onClose={() => {
              setIsDetailOpen(false);
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                style={{ alignSelf: "center" }}
              >
                Payment Detail
              </Typography>
              {/* <div
                // className="col-md-6 col-xs-12 px-5 py-2"
                style={{ width: "100%", marginTop: "20px" }}
              >
                <lable className="form-label">{`Order # ${appointmentData?.appointment_id}`}</lable>
              </div> */}
              <Link
                className="form-label"
                onClick={() => {
                  history.push(
                    `/appointment-detail?id=${appointmentData?.appointment_id}`
                  );
                }}
              >{`Order # ${appointmentData?.appointment_id}`}</Link>
              <div
                // className="col-md-6 col-xs-12 px-5 py-2"
                style={{ width: "100%" }}
              >
                <lable className="form-label">Amount</lable>
                <input
                  type="text"
                  name="Amount"
                  class="form-control input-filed"
                  placeholder="Amount"
                  value={appointmentData?.total_price}
                  onChange={(e) => {
                    setAppointmentData({
                      ...appointmentData,
                      total_price: e.target.value,
                    });
                  }}
                />
              </div>
              <div style={{ width: "100%" }}>
                <lable className="form-label">Tip</lable>
                <input
                  type="text"
                  name="Tip"
                  class="form-control input-filed"
                  placeholder="Tip"
                  value={appointmentData.total_tip}
                  onChange={(e) => {
                    setAppointmentData({
                      ...appointmentData,
                      total_tip: e.target.value,
                    });
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <button
                  className="btn btn-primary"
                  style={{
                    alignSelf: "center",
                    marginTop: "20px",
                    width: "100px",
                  }}
                  onClick={() => {
                    payOrder();
                  }}
                >
                  Submit
                </button>
                <button
                  className="btn btn-primary"
                  style={{
                    alignSelf: "center",
                    marginTop: "20px",
                    width: "100px",
                  }}
                  onClick={() => {
                    setIsDetailOpen(false);
                  }}
                >
                  Close
                </button>
              </div>
            </Box>
          </Modal>
        </div>
      </div>
    </>
  );
}

export default Appointments;
