import React, { useState } from "react";
import giveaway from "../../../assets/images/qrscan/giveaway.png";
import cash from "../../../assets/images/qrscan/cash.png";
import "./qrstyle.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Common/Loader";
import { useEffect } from "react";
import { resturantActions } from "../../../Store/Resturant/resturantAction";
import { toast } from "react-toastify";
import OrderPlaced from "./orderPlaced";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { menuActions } from "../../../Store/Menu/menuAction";

function Pay() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [contact, setContact] = useState("");
  const [tableNo, setTableNo] = useState("");
  const [payWith, setPaywith] = useState("cash");
  const [address, setAddress] = useState("");
  const [tip, setTip] = useState(10);
  const [total_tip, setTotalTip] = useState(0);
  const [cartItems, setCartItems] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [numberProduct, setNumberProduct] = useState(0);
  const [appointmentId, setAppointmentid] = useState();
  const [orderPlaceOpen, setOrderPlaceOpen] = useState(false);
  const [tipArray, setTipArray] = useState([]);
  var res = parseInt(window.location.href.split("?res=")[1]);
  var st = parseInt(
    window.location.href.split("&st=")[1]
      ? window.location.href.split("&st=")[1]
      : 0
  );
  var br = parseInt(window.location.href.split("&br=")[1]);
  var na = window.location.href.split("&na=")[1];
  var orderType = parseInt(window.location.href.split("&ordertype=")[1]);
  var crt = window.location.href.split("&crt=")[1];
  var crtItem = parseInt(window.location.href.split("&crtId=")[1]);
  const { menuData } = useSelector((state) => state.MenuReducer);

  useEffect(() => {
    dispatch(resturantActions.onGetRestaurants(res)).then((result) => {
      if (result?.id) {
        getMenu(result?.branch?.id);
      }
    });
  }, []);

  const getMenu = (rest) => {
    dispatch(menuActions.onGetMenus(rest)).then((res) => {
      if (res?.id) {
        const data = {
          name: res?.name,
          description: res?.description,
          menu_type: res?.menu_type,
          home_background_image: res?.home_background_image,
          home_display_logo: res?.home_display_logo,
          home_display_image_background: res?.home_display_image_background,
          home_background_image_file: null,
          home_background_color: res?.home_background_color,
          home_restaurant_name_color: res?.home_restaurant_name_color,
          home_main_heading_color: res?.home_main_heading_color,
          home_sub_heading_color: res?.home_sub_heading_color,
          home_text_color: res?.home_text_color,
          home_dine_in_btn_color: res?.home_dine_in_btn_color,
          home_dine_in_text_color: res?.home_dine_in_text_color,
          home_dine_in_logo_color: res?.home_dine_in_logo_color,
          home_delivery_in_btn_color: res?.home_delivery_in_btn_color,
          home_delivery_in_text_color: res?.home_delivery_in_text_color,
          home_delivery_in_logo_color: res?.home_delivery_in_logo_color,
          menu_header_background_color: res?.menu_header_background_color,
          menu_header_text: res?.menu_header_text,
          menu_product_backgroud_color: res?.menu_product_backgroud_color,
          menu_product_text: res?.menu_product_text,
          menu_product_price: res?.menu_product_price,
          menu_product_icons: res?.menu_product_icons,
          menu_cart_background: res?.menu_cart_background,
          menu_cart_text_color: res?.menu_cart_text_color,
          menu_cart_price_color: res?.menu_cart_price_color,
          menu_category_tab_text_color: res?.menu_category_tab_text_color,
          menu_category_tab_color: res?.menu_category_tab_color,
          menu_delivery_text_background: res?.menu_delivery_text_background,
          menu_delivery_btn_color: res?.menu_delivery_btn_color,
          menu_delivery_icon_color: res?.menu_delivery_icon_color,
          item_product_background_color: res?.item_product_background_color,
          item_product_text_color: res?.item_product_text_color,
          item_product_price: res?.item_product_price,
          item_tip_text_color: res?.item_tip_text_color,
          item_tip_btn_color: res?.item_tip_btn_color,
          item_cart_text_color: res?.item_cart_text_color,
          item_cart_btn_color: res?.item_cart_btn_color,
          item_place_order_text_color: res?.item_place_order_text_color,
          item_place_order_btn_color: res?.item_place_order_btn_color,
          popup_background_color: res?.popup_background_color,
          popup_text_color: res?.popup_text_color,
          popup_product_bg_color: res?.popup_product_bg_color,
          popup_product_text_color: res?.popup_product_text_color,
          popup_cart_btn_color: res?.popup_cart_btn_color,
          popup_cart_text_color: res?.popup_cart_text_color,
        };
        dispatch(menuActions.onChange("menuData", data));
      }
    });
  };

  useEffect(() => {
    loadData();
    loadTip();
  }, []);
  const loadData = () => {
    setLoading(true);
    dispatch(resturantActions.onGetResturantCart(res, crt)).then((data) => {
      setLoading(false);
      if (data?.id) {
        setCartItems(data);
        setTotalTip(0);
      }
    });
  };
  const loadTip = () => {
    setLoading(true);
    dispatch(resturantActions.onGetTip("restaurant")).then((data) => {
      setLoading(false);
      if (data?.length > 0) {
        setTipArray(data);
      }
    });
  };

  const handleCartplus = (id) => {
    setLoading(true);
    setNumberProduct(numberProduct + 1);
    dispatch(resturantActions.onAddCart(res, crt, id)).then((result) => {
      loadData();
    });
  };
  const handleCartMinus = (id, q) => {
    if (q > 0) {
      setLoading(true);
      setNumberProduct(numberProduct - 1);
      dispatch(resturantActions.onSubCart(res, crt, id)).then((result) => {
        loadData();
      });
    }
  };

  const checkout = () => {
    if (orderType === 121) {
      if ((contact !== "") & (tableNo != "")) {
        const data = {
          customer: {
            contact_no: contact,
          },
          order: {
            total_tip: total_tip,
            payment_mode: payWith,
            order_type: orderType === 121 ? "dine-in" : "Delivery",
            table_no: tableNo,
          },
        };
        dispatch(resturantActions.onResturantCartCheckout(data, res, crt)).then(
          (data) => {
            setLoading(false);
            if (data?.id) {
              toast.success("Order Reserved");
              setAppointmentid(data?.order_id);
              setOrderPlaceOpen(true);
            } else {
              toast.error("Something went wrong, Please try again");
            }
          }
        );
      } else {
        toast.error("Please enter Contact no, and Table No");
      }
    } else {
      if (
        (contact !== "") &
        (address !== "") &
        (cartItems?.total_price > 0) &
        (firstName !== "") &
        (lastName != "")
      ) {
        setLoading(true);
        const data = {
          customer: {
            first_name: firstName,
            last_name: lastName,
            contact_no: contact,
          },
          order: {
            address: address,
            total_tip: total_tip,
            payment_mode: payWith,
            order_type: orderType === 121 ? "dine-in" : "Delivery",
          },
        };

        dispatch(resturantActions.onResturantCartCheckout(data, res, crt)).then(
          (data) => {
            setLoading(false);
            if (data?.id) {
              toast.success("Order Reserved");
              setAppointmentid(data?.order_id);
              setOrderPlaceOpen(true);
            } else {
              toast.error("Something went wrong, Please try again");
            }
          }
        );
      } else {
        toast.error("Please enter Contact no, Address and Item");
      }
    }
  };
  return (
    <>
      {isLoading ? <Loader /> : null}
      <div
        className="container-fluid"
        style={{
          backgroundColor: `${menuData?.home_background_color}`,
          color: `${menuData?.popup_text_color}`,
        }}
      >
        <div className="row pt-5 black-bg px-2">
          <div className="col-md-9 col-3">
            <div
              onClick={() => {
                dispatch(
                  resturantActions.onResturantRemoveCart(
                    null,
                    res,
                    crt,
                    crtItem
                  )
                ).then((data) => {});
                history.push(
                  `/restaurant-menu-categories?res=${res}&st=${st}&br=${br}&ordertype=${orderType}&na=${
                    na.split("&crt=")[0]
                  }&crt=${crt}`
                );
              }}
            >
              <i class="fa fa-chevron-left orange-text"></i>
            </div>
          </div>
          <div className="col-6 text-center">
            <p className="text-white">Checkout</p>
          </div>
          <div className="col-md-3 col-3 d-flex text-right"></div>
        </div>

        {cartItems?.items?.map((x) => {
          return (
            <>
              <div
                className="services-card shadow-sm my-3 white-bg rounded p-2"
                style={{
                  margin: "5px",
                  border: "1px solid",
                  borderColor: "transparent",
                  backgroundColor: menuData?.item_product_background_color,
                  color: menuData?.item_product_text_color,
                }}
              >
                <table width="100%">
                  <tr
                    style={{
                      color: menuData?.item_product_text_color,
                    }}
                  >
                    <td
                      width={"30%"}
                      rowSpan={2}
                      style={{ marginRight: "5px" }}
                    >
                      <img
                        src={x?.item?.featured}
                        className="card-img img-responsive"
                      />
                    </td>
                    <td
                      width={"60%"}
                      style={{
                        verticalAlign: "top",
                        backgroundColor:
                          menuData?.item_product_background_color,
                      }}
                    >
                      <p className="cad-title">{x?.item?.name}</p>
                      <p className="dim-text">{x?.item?.description}</p>
                    </td>
                    <td width={"20%"} rowSpan={2}>
                      <p
                        className="orange-text font-weight-bold"
                        style={{
                          color: `${menuData?.popup_text_color}`,
                        }}
                      >
                        ₦{x?.item?.price * x?.quantity}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className="dim-text pb-o mb-0">
                        <table width={"100%"}>
                          <tr>
                            <td width={"33%"}>
                              <i className="fa fa-clock orange-text"></i>{" "}
                              {x?.preparation_time} Min
                            </td>
                            <td width={"33%"}>
                              {x?.discount && x?.discount > 0 ? (
                                <i
                                  style={{ color: "#FF4D4D" }}
                                >{`-₦${x?.discount} off`}</i>
                              ) : (
                                ""
                              )}
                            </td>
                            <td width={"33%"}>
                              <table>
                                <tr
                                  style={{
                                    backgroundColor:
                                      menuData?.item_product_background_color,
                                  }}
                                >
                                  <td
                                    style={{
                                      backgroundColor:
                                        menuData?.item_product_background_color,
                                    }}
                                  >
                                    <button
                                      className="preview-quality-btn"
                                      onClick={() =>
                                        handleCartMinus(x?.id, x?.quantity)
                                      }
                                    >
                                      -
                                    </button>
                                  </td>
                                  <td>
                                    <span className="preview-quality-number">
                                      {x?.quantity}
                                    </span>
                                  </td>
                                  <td>
                                    <button
                                      className="preview-quality-btn"
                                      onClick={() => handleCartplus(x?.id)}
                                    >
                                      +
                                    </button>
                                  </td>
                                </tr>
                              </table>
                              {/* <i className="fa fa-star orange-text ml-2"></i> {x?.rating} */}
                            </td>
                          </tr>
                        </table>
                      </p>
                    </td>
                  </tr>
                </table>
              </div>
              {/* <div className="row p-2" style={{ margin: "10px" }}>
                <div className="col-2 text-center p-0">
                  <img
                    src={rootURL + x?.item?.featured}
                    className="card-img img-responsive"
                  />
                </div>
                <div className="col-8">
                  <p className="cad-title">{x?.item?.name}</p>
                  <p className="dim-text mb-4">{x?.item?.description}</p>
                  <div className="cart-qualitity-btn">
                    <table>
                      <tr>
                        <td>
                          <button
                            className="preview-quality-btn"
                            onClick={() => handleCartMinus(x?.id, x?.quantity)}
                          >
                            -
                          </button>
                        </td>
                        <td>
                          <span className="preview-quality-number">
                            {x?.quantity}
                          </span>
                        </td>
                        <td>
                          <button
                            className="preview-quality-btn"
                            onClick={() => handleCartplus(x?.id)}
                          >
                            +
                          </button>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div className="col-2 text-right">
                  <p>
                  <i className="far fa-heart"></i>
                  </p>
                  <p className="font-weight-bold orange-text">
                    ₦{x?.item?.price * x?.quantity}
                  </p>
                </div>
              </div> */}
            </>
          );
        })}
        {orderType === 121 ? (
          <>
            <div className="col-md-10">
              <lable className="form-label">Contact No</lable>
              <PhoneInput
                country={"ng"}
                value={contact}
                onChange={(phone) => {
                  setContact(phone);
                }}
              />
            </div>

            <div className="col-md-10">
              <lable className="form-label">Table No</lable>
              <input
                type="text"
                name="table_no"
                class="form-control"
                style={{ border: "1px solid black !important" }}
                placeholder="Table No"
                value={tableNo}
                onChange={(e) => {
                  setTableNo(e.target.value);
                }}
              />
            </div>
          </>
        ) : (
          <>
            <div className="col-12">
              <lable className="form-label">First Name</lable>
              <input
                type="text"
                name="firstName"
                class="form-control"
                style={{ border: "1px solid black !important" }}
                placeholder="First Name"
                value={firstName}
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
              />
            </div>
            <div className="col-12">
              <lable className="form-label">Last Name</lable>
              <input
                type="text"
                name="lastName"
                class="form-control"
                style={{ border: "1px solid black !important" }}
                placeholder="Last Name"
                value={lastName}
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
              />
            </div>
            <div className="col-md-10">
              <lable className="form-label">Contact No</lable>
              <PhoneInput
                country={"ng"}
                value={contact}
                onChange={(phone) => {
                  setContact(phone);
                }}
              />
            </div>
            <div className="col-md-10">
              <lable className="form-label">Address</lable>
              <input
                type="text"
                name="address"
                class="form-control"
                style={{ border: "1px solid black !important" }}
                placeholder="Address"
                value={address}
                onChange={(e) => {
                  setAddress(e.target.value);
                }}
              />
            </div>
          </>
        )}
        <div className="col-12 mt-2">
          <h3 className="mobile-heading">Add a Tip</h3>
          {tipArray.map((x) => {
            return (
              <span
                className={
                  tip === x?.amount ? "tip-amoun orange-bg" : "tip-amoun"
                }
                onClick={() => {
                  setTip(x?.amount);
                  setTotalTip(x?.amount);
                }}
              >
                ₦ {x?.amount === 0.0 ? "None" : x?.amount}
              </span>
            );
          })}
        </div>

        <div className="col-12 mt-4">
          <h3 className="mobile-heading">Payment Summary</h3>
        </div>

        <div className="col-12">
          <table className="table table-borderless bill-detail">
            <tr>
              <td className="">Total Amount</td>
              <td className="text-right">₦{cartItems?.total_price}</td>
            </tr>
            <tr>
              <td className="text-danger">Discount</td>
              <td className="text-danger text-right">
                -₦{cartItems?.total_discounted}
              </td>
            </tr>
            <tr>
              <td>Tip</td>
              <td className="text-right">₦{total_tip}</td>
            </tr>
            <tr>
              <td>Service Fee</td>
              <td className="text-right">₦0</td>
            </tr>
            <tr>
              <th className="font-weight-bold">Total Amount</th>
              <th className="text-right">₦{cartItems?.total_bill_amount}</th>
            </tr>
          </table>
        </div>

        <div className="col-12 mt-4">
          <h3 className="mobile-heading">Pay With</h3>
          <p>
            <input
              type="radio"
              name="paywith"
              value="giveaway"
              checked={payWith === "giveaway"}
              onChange={() => setPaywith("giveaway")}
            />{" "}
            <span>
              {" "}
              <img src={giveaway} /> Giveaway App
            </span>
          </p>
          <p>
            <input
              type="radio"
              name="paywith"
              value="cash"
              checked={payWith === "cash"}
              onChange={() => setPaywith("cash")}
            />{" "}
            <span>
              {" "}
              <img src={cash} /> Cash
            </span>
          </p>
        </div>

        <div className="checkout-cart-area-paynow">
          <div className="row">
            <div className="col-12 text-center">
              <div className="text-center">
                <button
                  className="preview-button-theme"
                  style={{
                    backgroundColor: `${menuData?.item_place_order_btn_color}`,
                    color: `${menuData?.item_place_order_text_color}`,
                  }}
                  onClick={() => {
                    checkout();
                  }}
                >
                  Place an Order
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {orderPlaceOpen && (
        <OrderPlaced
          title={"Order Reserved"}
          msg={`Order Id ${appointmentId} is reserved.`}
          firstName={firstName}
          lastName={lastName}
          contact={contact}
          email={address}
          appointmentId={appointmentId}
          link={`/restaurant/${na.split("&crt=")[0]}&&st=${st}`}
        />
      )}

      {/* <div className="row fixed text-center">
            <div className="col-12 text-center">
                <div className="main-wrapper">
                    <div className="inner-wrapper white-bg">
                        <div className="top">
                            <p className="dim-text">Pay ByDubai Now</p>
                            <hr />
                            <img src={gpay} width={100}/>
                            <h6>Giveway < br /> App</h6>
                            <div className="row">
                                <div className="col-6 text-left">
                                    <p className="pl-2 my-0"> Total Amount</p>
                                    <p className="pl-2 my-0 font-weight-bold"> 100 AED</p>
                                </div>
                                <div className="col-6 text-right">
                                    <button className="btn btn-blue mr-2"><Link to="/giveaway">Next</Link></button>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div className="bottom-wrapper white-bg">
                        <p className="blue-text"> Cancel </p>
                    </div>
                </div>
            </div>
        </div> */}
    </>
  );
}

export default Pay;
