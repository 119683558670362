import React, { useState } from "react";
import DeshboardLayout from "../layouts/DeshboardLayout";
import Dheader from "./dheader";
import { useHistory } from "react-router-dom";
import Table from "./table";
import "./trans.css";

function CatTransactions() {
  const history = useHistory();
  const redirectTo = (id) => {
    history.push(`/appointment-detail?id=${id}`);
  };
  return (
    <>
      <div id="main-wrapper" class="show over-flow-block">
        <DeshboardLayout />
        <div class="content-body">
          <Table redirectTo={(id) => redirectTo(id)} />
        </div>
      </div>
    </>
  );
}

export default CatTransactions;
