import React, { useState } from "react";
import { Link } from "react-router-dom";
import memberImg from "../../../assets/images/dashboard/staff/memberimg.png";

function Member() {
  return (
    <>
      <div className="text-center py-4 pt-4 bg-white rounded">
        <div className="mt-5">
          <img src={memberImg} alt="" />
          <p className="py-3 orange-text">Upload Photo</p>
          <div className="row px-4 text-left">
            <div className="col-md-6 col-xs-12 px-5 py-2">
              <lable className="form-label">First Name</lable>
              <input
                type="text"
                name="firstname"
                class="form-control input-filed"
                placeholder="Jason"
              />
            </div>
            <div className="col-md-6 col-xs-12 px-5 py-2">
              <lable className="form-label">Last Name</lable>
              <input
                type="text"
                name="lastname"
                class="form-control input-filed"
                placeholder="Reese"
              />
            </div>
            <div className="col-md-6 col-xs-12 px-5 py-2">
              <lable className="form-label">Email</lable>
              <input
                type="email"
                name="email"
                class="form-control input-filed"
                placeholder="Jansoreese21@gmail.com"
              />
            </div>
            <div className="col-md-6 col-xs-12 px-5 py-2">
              <lable className="form-label">ID Number</lable>
              <input
                type="text"
                name="idnumber"
                class="form-control input-filed"
                placeholder="100231"
              />
            </div>
            <div className="col-md-6 col-xs-12 px-5 py-2">
              <lable className="form-label">ID Number</lable>
              <select
                name="position"
                class="form-control input-filed"
                placeholder="e.g 3142121"
              >
                <option value="Value 10">Waiter</option>
                <option value="Value 10">Value 01</option>
              </select>
            </div>
            <div className="col-md-6 col-xs-12 px-5 py-2">
              <lable className="form-label">Wallet Address</lable>
              <input
                type="text"
                name="walletaddress"
                class="form-control input-filed"
                placeholder="12124523453mefhjew23"
              />
            </div>
            <div className="col-md-6 col-xs-12 px-5 py-2">
              <lable className="form-label">D</lable>
              <select
                name="position"
                class="form-control input-filed"
                placeholder="e.g 3142121"
              >
                <option value="Value 10"> </option>
                <option value="Waiter">Waiter</option>
              </select>
            </div>

            <div className="col-md-6 col-xs-12 px-5 py-2"></div>
            <div className="text-right col-md-6 col-xs-12 px-5 py-5">
              <a class="btn btn-info" href-="" type="button">
                <Link to="/allstaffmembers">Cancel</Link>
              </a>
            </div>
            <div className="col-md-6 col-xs-12 px-5 py-5">
              <button class="btn btn-primary">
                <Link to="/allstaffmembers">Add Member</Link>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Member;
