import React, { useState } from "react";
import DeshboardLayout from "../layouts/DeshboardLayout";


function Reports() {
  return (
		<>
		    <div id="main-wrapper" class="show">
		    <DeshboardLayout />
			 <div class="content-body">
        <h1>Reports</h1>			  
			  </div>	
			 </div>
		</>
  );
}

export default Reports;
