import React, { useEffect, useState } from "react";
import DeshboardLayout from "../layouts/DeshboardLayout";
import "./orders.css";
import { useDispatch } from "react-redux";
import Loader from "../../Common/Loader";
import { catalogActions } from "../../../Store/Catalogs/catalogAction";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getStatus, toTitleCase } from "../partials/status";
import BaseModel from "../../Common/BaseModel";

const style = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  width: "400px",
};
const p = {
  fontSize: "16px",
  fontWeight: "bold",
  alignSelf: "center",
  width: "250px",
};

function OrderDetail() {
  const [total_price, setTotalPrice] = useState(0);
  const [total_discount, setDiscount] = useState(0);
  const [items, setItems] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [orderData, setOrderData] = useState({});
  const [qrCodeLink, setQrCodeLink] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const orderId = window.location.href.split("?id=")[1];
  useEffect(() => {
    setLoading(true);
    dispatch(catalogActions.onGetOrderDetail(orderId)).then((res) => {
      setLoading(false);
      setOrderData(res);
      console.log(res);
      if (res.items) setItems(res.items);
      let price = res.items.reduce((sum, item) => {
        let temp = item.unit_price * item.quantity;
        return sum + temp;
      }, 0);
      setTotalPrice(price);
      let discount = res.items.reduce((sum, item) => {
        let temp = item.discount * item.quantity;
        return sum + temp;
      }, 0);
      setDiscount(discount);
    });
  }, []);

  const distributeTip = () => {
    setLoading(true);
    dispatch(catalogActions.onSetOrderTip(orderData?.tip_id)).then((res) => {
      setLoading(false);
      if (res?.link === "completed") {
        toast.warning("Tip already distributed");
      } else {
        const link = res?.link;
        setQrCodeLink(link);
        setIsOpen(true);
      }
    });
  };

  return (
    <>
      {isLoading ? <Loader /> : null}
      <div id="main-wrapper" className="show">
        <DeshboardLayout />
        <div
          className={`content-body dashboard-conainer ${
            isLoading ? "hide-custom" : "show"
          }`}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <h3 className="inner-header">
                  Orders
                  <span className="heading-link">
                    <i className="fa fa-chevron-right bread-icon"></i>
                    <span
                      className="subheading-link"
                      onClick={() => {
                        history.push("/");
                      }}
                    >
                      Back To Order List
                    </span>
                  </span>
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div class="content-body">
          <div className="row order-detail">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h6 className="p-0 m-0">Order Detail</h6>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-4">
                      <strong>Order ID</strong>
                      <p>{orderData?.order_id}</p>
                      <hr></hr>
                    </div>
                    <div className="col-md-4">
                      <strong>Order Type</strong>
                      <p>{getStatus(orderData?.order_type)}</p>
                      <hr></hr>
                    </div>
                    <div className="col-md-4">
                      <strong>Table No</strong>
                      <p>
                        {orderData?.table_no
                          ? orderData?.table_no
                          : orderData?.table_no}
                      </p>
                      <hr></hr>
                    </div>
                    <div className="col-md-4">
                      <strong>Order Status</strong>
                      <p>{getStatus(orderData?.order_status)}</p>
                      <hr></hr>
                    </div>
                    <div className="col-md-4">
                      <strong>Payment Status</strong>
                      <p>{getStatus(orderData?.payment_status)}</p>
                      <hr></hr>
                    </div>
                    <div className="col-md-4">
                      <strong>Payment Mode</strong>
                      <p>{getStatus(orderData?.payment_mode)}</p>
                      <hr></hr>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h6 className="p-0 m-0">Items Detail</h6>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-12">
                      {items.length == 0 ? (
                        <div className="col-12"></div>
                      ) : (
                        items.map((value, i) => (
                          <div className="row" key={i}>
                            <div className="col-sm-4">
                              <strong>Item Name</strong>
                              <p>{value.item.name}</p>
                            </div>
                            <div className="col-sm-2">
                              <strong>Unit Price</strong>
                              <p>
                                {orderData?.currency} {value.unit_price}
                              </p>
                            </div>
                            <div className="col-sm-2">
                              <strong>Discount</strong>
                              <p>
                                {value.discount
                                  ? orderData?.currency + ` ` + value.discount
                                  : "None"}
                              </p>
                            </div>
                            <div className="col-sm-2">
                              <strong>Quantity</strong>
                              <p>{value.quantity}</p>
                            </div>
                            <div className="col-sm-2">
                              <strong>Total Price</strong>
                              <p>
                                {orderData?.currency}{" "}
                                {value.unit_price * value.quantity -
                                  value.discount * value.quantity}
                              </p>
                            </div>
                            <div className="col-sm-12">
                              <hr></hr>
                            </div>
                          </div>
                        ))
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h6 className="p-0 m-0">Customer Detail</h6>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-4">
                      <strong>Customer ID</strong>
                      <p>{orderData?.customer?.id}</p>
                      <hr></hr>
                    </div>
                    <div className="col-md-4">
                      <strong>Customer Name</strong>
                      <p>
                        {orderData?.customer?.first_name +
                          " " +
                          orderData?.customer?.last_name}
                      </p>
                      <hr></hr>
                    </div>
                    <div className="col-md-4">
                      <strong>Customer contact no</strong>
                      <p>{orderData?.customer?.contact_no}</p>
                      <hr></hr>
                    </div>
                    {!!orderData?.address && (
                      <div className="col-md-4">
                        <strong>Customer address</strong>
                        <p>{orderData.address}</p>
                        <hr></hr>
                      </div>
                    )}
                    {orderData?.customer?.email &&
                      orderData?.customer?.email !== "" && (
                        <div className="col-md-4">
                          <strong>Customer Email</strong>
                          <p>{orderData?.customer?.email}</p>
                          <hr></hr>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h6 className="p-0 m-0">Billing Detail</h6>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-4">
                      <strong>Total Items Price:</strong>
                      <p>
                        {orderData?.currency} {total_price}
                      </p>
                    </div>
                    <div className="col-4">
                      <strong>Total Discount:</strong>
                      <p>
                        {orderData?.currency} {total_discount}
                      </p>
                    </div>
                    <div className="col-4">
                      <strong>Service Charges:</strong>
                      <p>
                        {orderData?.currency} {orderData?.service_charges}
                      </p>
                    </div>
                    <div className="col-4">
                      <strong>Tip:</strong>
                      <p>
                        {orderData?.currency} {orderData?.total_tip}
                      </p>
                    </div>
                    <div className="col-4">
                      <strong>Items Price with Discount:</strong>
                      <p>
                        {orderData?.currency} {orderData?.actual_price}
                      </p>
                    </div>
                    <div className="col-12">
                      <hr></hr>
                    </div>
                    <div className="col-4">
                      <strong>Total Price:</strong>{" "}
                      <strong>
                        {orderData?.currency} {orderData?.total_bill_amount}
                      </strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {orderData?.worker ? (
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h6 className="p-0 m-0">Worker Detail</h6>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-4">
                        <strong>Name:</strong>
                        <p>{toTitleCase(orderData?.worker.name)}</p>
                      </div>
                      <div className="col-sm-4">
                        <strong>ID No:</strong>
                        <p>{orderData?.worker.id_no}</p>
                      </div>
                      <div className="col-sm-4 align-right">
                        {orderData?.total_tip ? (
                          !orderData?.tip_disbursed ? (
                            orderData?.payment_status == "completed" ? (
                              <button
                                onClick={() => {
                                  distributeTip();
                                }}
                                className="btn btn-success"
                              >
                                Distribute Tip (
                                {orderData?.currency +
                                  " " +
                                  orderData?.total_tip}
                                )
                              </button>
                            ) : (
                              "Payment Pending"
                            )
                          ) : (
                            "Tip Distributed"
                          )
                        ) : (
                          "No Tip"
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <BaseModel
        qrCodeLink={qrCodeLink}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </>
  );
}

export default OrderDetail;
