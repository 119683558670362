import { catalogTypes } from "./catalogTypes";
import { endpoints } from "../../Config/apiConfig";
import { axiosInstance } from "../../Config/axiosInstance";

const onChange = (field, value) => {
  return (dispatch) => {
    dispatch({
      type: catalogTypes.MENU_ONCHANGE,
      payload: { prop: field, value: value },
    });
  };
};

// const getLocalStorageValue = (type) => async (dispatch) => {
//   return await localStorage.getItem(type);
// };

const onGetCatalog = () => async (dispatch) => {
  var bus = await localStorage.getItem("bus");
  var response = await axiosInstance.getApi(
    endpoints.businessApi.getAllCatalogs(bus)
  );
  return response;
};

const onGetBusinesses = (id) => async (dispatch) => {
  var response = await axiosInstance.getApi(
    `${endpoints.businessApi.getBusinesses}${id}`
  );
  return response;
};

const onGetDefaultBusinesses = (id, pr, type) => async (dispatch) => {
  var response = await axiosInstance.getApiWithoutToken(
    `${endpoints.businessApi.getDefaultCatalog(
      id
    )}/default?professional_id=${pr}&type=${type}`
  );
  return response;
};

const onGetDefaultBusiness = (id) => async (dispatch) => {
  var response = await axiosInstance.getApiWithoutToken(
    `${endpoints.businessApi.getDefaultCatalog(id)}/default`
  );
  return response;
};

const onUpdateCatalog = (data, id) => async (dispatch) => {
  var bus = await localStorage.getItem("bus");
  var response = await axiosInstance.updateApi(
    data,
    `${endpoints.businessApi.UpdateCatalogs(id, bus)}`
  );
  return response;
};

const onGetCatalogSection = (sectionId, page) => async (dispatch) => {
  var response = await axiosInstance.getApi(
    page !== null
      ? `${endpoints.businessApi.getSectionCatalogs(sectionId)}?page=${page}`
      : endpoints.businessApi.getSectionCatalogs(sectionId)
  );
  return response;
};

const onCreateCatalogSection = (sectionId, data) => async (dispatch) => {
  var response = await axiosInstance.postApi(
    data,
    `${endpoints.businessApi.section(sectionId)}`
  );
  return response;
};
const onUpdateCatalogSection = (sectionId, data, id) => async (dispatch) => {
  var response = await axiosInstance.updateApi(
    data,
    `${endpoints.businessApi.section(sectionId)}${id}/`
  );
  return response;
};

const onDeleteCatalogSection = (sectionId, id) => async (dispatch) => {
  var response = await axiosInstance.deleteApi(
    null,
    `${endpoints.businessApi.section(sectionId)}${id}/`
  );
  return response;
};

const onGetCatalogWorkers = (page) => async (dispatch) => {
  var bus = await localStorage.getItem("bus");
  var response = await axiosInstance.getApi(
    page !== null
      ? `${endpoints.businessApi.getWorkers(bus)}${page}`
      : endpoints.businessApi.getWorkers(bus)
  );
  return response;
};
const onGetCatalogWorkersById = (id) => async (dispatch) => {
  var bus = await localStorage.getItem("bus");
  var response = await axiosInstance.getApi(
    `${endpoints.businessApi.getWorkers(bus)}${id}`
  );
  return response;
};
const onCreateCatalogWorkers = (data) => async (dispatch) => {
  var bus = await localStorage.getItem("bus");
  var response = await axiosInstance.postApi(
    data,
    endpoints.businessApi.getWorkers(bus)
  );
  return response;
};
const onUpdateCatalogWorkers = (data, id) => async (dispatch) => {
  var bus = await localStorage.getItem("bus");
  var response = await axiosInstance.updateApi(
    data,
    `${endpoints.businessApi.getWorkers(bus)}${id}/`
  );
  return response;
};
const onDeleteCatalogWorkers = (data, id) => async (dispatch) => {
  var bus = await localStorage.getItem("bus");
  var response = await axiosInstance.deleteApi(
    data,
    `${endpoints.businessApi.getWorkers(bus)}${id}/`
  );
  return response;
};

const onCreateScheduleCatalogWorkers =
  (data, workerid, id) => async (dispatch) => {
    var response = await axiosInstance.updateApi(
      data,
      `${endpoints.resturantApi.scheduleWorkers}${workerid}/schedules/${id}/`
    );
    return response;
  };

const onGetCatalogWorkersPosition = () => async (dispatch) => {
  var response = await axiosInstance.getApi(
    `${endpoints.resturantApi.workersPositions}`
  );
  return response;
};

const onGetAllServices =
  (data = "") =>
  async (dispatch) => {
    var response = await axiosInstance.getApi(
      endpoints.businessApi.getAllServices + data
    );
    return response;
  };

const onCreateSectionServices = (sectionId, data) => async (dispatch) => {
  var response = await axiosInstance.postApi(
    data,
    `${endpoints.businessApi.services(sectionId)}/`
  );
  return response;
};

const onUpdateSectionServices =
  (sectionId, data, itemid) => async (dispatch) => {
    var response = await axiosInstance.updateApi(
      data,
      `${endpoints.businessApi.services(sectionId)}/${itemid}/`
    );
    return response;
  };

const onDeleteSectionServices = (sectionId, itemid) => async (dispatch) => {
  var response = await axiosInstance.deleteApi(
    null,
    `${endpoints.businessApi.services(sectionId)}/${itemid}/`
  );
  return response;
};

// const onCreateScheduleServices = (data, workerid, id) => async (dispatch) => {
//   var response = await axiosInstance.updateApi(
//     data,
//     `${endpoints.businessApi.scheduleWorkers}${workerid}/schedules/${id}/`
//   );
//   return response;
// };

const onGetAllAppointments = (date) => async (dispatch) => {
  var bus = await localStorage.getItem("bus");
  var response = await axiosInstance.getApi(
    `${endpoints.businessApi.appointments(bus)}${date}`
  );
  return response;
};

const onChangeAppointmentsStatus =
  (orderId, orderStatus) => async (dispatch) => {
    var response = await axiosInstance.postApiWithoutData(
      `${endpoints.businessApi.appointmentsStatus}${orderId}/status/${orderStatus}/`
    );
    return response;
  };

const onPayAppointmentWithPush = (orderId) => async (dispatch) => {
  var response = await axiosInstance.postApiWithoutData(
    `${endpoints.orderApi.appointmentPayPush(orderId)}`
  );
  return response;
};

const onGetCatTransaction = (filter) => async (dispatch) => {
  var bus = await localStorage.getItem("bus");
  var response = await axiosInstance.getApi(
    filter !== null
      ? `${endpoints.businessApi.getBusinessesTransactions(bus)}${filter}`
      : endpoints.businessApi.getBusinessesTransactions(bus)
  );
  return response;
};

const onCreateCatalog = (data) => async (dispatch) => {
  var bus = await localStorage.getItem("bus");
  var response = await axiosInstance.postApi(
    data,
    `${endpoints.businessApi.createCatalog(bus)}`
  );
  return response;
};

const onGetCatalogById = (id) => async (dispatch) => {
  var bus = await localStorage.getItem("bus");
  var response = await axiosInstance.getApi(
    endpoints.businessApi.getCatalog(id, bus)
  );
  return response;
};

const onDeleteCatalog = (menuId) => async (dispatch) => {
  var bus = await localStorage.getItem("bus");
  var response = await axiosInstance.deleteApi(
    null,
    `${endpoints.businessApi.getCatalog(menuId, bus)}`
  );
  return response;
};

const onProfessionGenerateQRCode = (data, id) => async (dispatch) => {
  var response = await axiosInstance.postApi(
    data,
    `${endpoints.qrCode.professionalQRCode}${id}/qrcode`
  );
  return response;
};

const onProfessionUpdateQRCode = (data, id) => async (dispatch) => {
  var response = await axiosInstance.updateApi(
    data,
    `${endpoints.qrCode.professionalQRCode}${id}/qrcode/update`
  );
  return response;
};

const onGetMobileCatalogWorkers = (bus) => async (dispatch) => {
  var response = await axiosInstance.getApiWithoutToken(
    endpoints.businessApi.getWorkers(bus)
  );
  return response;
};

const onGetAssignProfessionalSchedule = (id, date) => async (dispatch) => {
  var response = await axiosInstance.getApiWithoutToken(
    `${endpoints.businessApi.getAssignProfessionalSchedule(id)}${date}`
  );
  return response;
};

const onGetProfessionalLeaves = (id, date) => async (dispatch) => {
  var response = await axiosInstance.getApi(
    `${endpoints.businessApi.getProfessionalLeaves(id)}${date}`
  );
  return response;
};

const onGetAppointmentQRCode = (orderNo) => async (dispatch) => {
  var response = await axiosInstance.getApi(
    `${endpoints.qrCode.appointmentQRCode}${orderNo}`
  );
  return response;
};

const onGetOrderQRCode = (orderNo) => async (dispatch) => {
  var response = await axiosInstance.getApi(
    `${endpoints.qrCode.orderQRCode}${orderNo}`
  );
  return response;
};

const onBusinessTransactionWayCash = (data, id) => async (dispatch) => {
  var response = await axiosInstance.postApi(
    data,
    `${endpoints.orderApi.appointmentWayCash}${id}`
  );
  return response;
};
const onRestaurantTransactionWayCash = (data, id) => async (dispatch) => {
  var response = await axiosInstance.postApi(
    data,
    `${endpoints.orderApi.orderWayCash}${id}`
  );
  return response;
};

const onGetOrderDetail = (orderNo) => async (dispatch) => {
  var response = await axiosInstance.getApi(
    `${endpoints.orderApi.getOrderDetail}${orderNo}`
  );
  return response;
};

const onSetOrderTip = (orderNo) => async (dispatch) => {
  var response = await axiosInstance.getApi(
    `${endpoints.orderApi.setOrderTip}${orderNo}`
  );
  return response;
};

const onSetAppointmentTip = (orderNo) => async (dispatch) => {
  var response = await axiosInstance.getApi(
    `${endpoints.orderApi.setAppointmentTip}${orderNo}`
  );
  return response;
};

const onGetAppointmentDetail = (orderNo) => async (dispatch) => {
  var response = await axiosInstance.getApi(
    `${endpoints.orderApi.getAppointmentDetail}${orderNo}`
  );
  return response;
};

const onAddProfessionLeave = (data, id) => async (dispatch) => {
  var response = await axiosInstance.postApi(
    data,
    `${endpoints.businessApi.addProfessionalLeaves(id)}`
  );
  return response;
};

const onOrderFilter = (link) => async (dispatch) => {
  var bus = await localStorage.getItem("bus");
  var response = await axiosInstance.getApi(
    `${endpoints.businessApi.appointments(bus)}${link}`
  );
  return response;
};

export const catalogActions = {
  onChange: onChange,
  onGetCatalog: onGetCatalog,
  onGetDefaultBusinesses: onGetDefaultBusinesses,
  onUpdateCatalog: onUpdateCatalog,
  onGetCatalogSection: onGetCatalogSection,
  onCreateCatalogSection: onCreateCatalogSection,
  onUpdateCatalogSection: onUpdateCatalogSection,
  onDeleteCatalogSection: onDeleteCatalogSection,
  onGetCatalogWorkers: onGetCatalogWorkers,
  onGetCatalogWorkersById: onGetCatalogWorkersById,
  onCreateCatalogWorkers: onCreateCatalogWorkers,
  onUpdateCatalogWorkers: onUpdateCatalogWorkers,
  onDeleteCatalogWorkers: onDeleteCatalogWorkers,
  onCreateScheduleCatalogWorkers: onCreateScheduleCatalogWorkers,
  onGetCatalogWorkersPosition: onGetCatalogWorkersPosition,
  onGetAllServices: onGetAllServices,
  onCreateSectionServices: onCreateSectionServices,
  onUpdateSectionServices: onUpdateSectionServices,
  onDeleteSectionServices: onDeleteSectionServices,
  onGetAllAppointments: onGetAllAppointments,
  onChangeAppointmentsStatus: onChangeAppointmentsStatus,
  onPayAppointmentWithPush: onPayAppointmentWithPush,
  onGetCatTransaction: onGetCatTransaction,
  onCreateCatalog: onCreateCatalog,
  onGetCatalogById: onGetCatalogById,
  onDeleteCatalog: onDeleteCatalog,
  onProfessionGenerateQRCode: onProfessionGenerateQRCode,
  onProfessionUpdateQRCode: onProfessionUpdateQRCode,
  onGetBusinesses: onGetBusinesses,
  onGetMobileCatalogWorkers: onGetMobileCatalogWorkers,
  onGetAssignProfessionalSchedule: onGetAssignProfessionalSchedule,
  onGetAppointmentQRCode: onGetAppointmentQRCode,
  onGetOrderQRCode: onGetOrderQRCode,
  onBusinessTransactionWayCash: onBusinessTransactionWayCash,
  onRestaurantTransactionWayCash: onRestaurantTransactionWayCash,
  onGetOrderDetail: onGetOrderDetail,
  onGetAppointmentDetail: onGetAppointmentDetail,
  onGetProfessionalLeaves: onGetProfessionalLeaves,
  onAddProfessionLeave: onAddProfessionLeave,
  onOrderFilter: onOrderFilter,
  onGetDefaultBusiness: onGetDefaultBusiness,
  onSetOrderTip: onSetOrderTip,
  onSetAppointmentTip: onSetAppointmentTip,
};
