import React from "react";
import HeaderFive from "../Partials/Headers/HeaderFive";
import Hero from "./Hero";

function HomeFive() {
  return (
    <>
      <HeaderFive />
      <Hero />
    </>
  );
}

export default HomeFive;
