import React, { useState } from "react";
import DeshboardLayout from "../../layouts/DeshboardLayout";

import MenuTemplates from "./MenuTemplates"
function MenuHome() {
  return (
		<>
		    <div id="main-wrapper" class="show">
		    <DeshboardLayout />
			 <div class="content-body">
			 <MenuTemplates />
			  </div>	
			 </div>
		</>
  );
}

export default MenuHome;
