import React, { useEffect, useState } from "react";
import DeshboardLayout from "../layouts/DeshboardLayout";
import Dheader from "./DheaderMembers";
import Step01 from "./step01";
import Members from "./Members";
import { useDispatch } from "react-redux";
import Loader from "../../Common/Loader";
import { catalogActions } from "../../../Store/Catalogs/catalogAction";

const DashboardHome = () => {
  const [haveData, setHaveData] = useState(false);
  const [results, setResults] = useState([]);
  const [refreshData, setRefreshData] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(0);
  const dispatch = useDispatch();
  useEffect(() => {
    loadData(null);
  }, [refreshData]);

  const loadData = (page) => {
    setLoading(true);
    dispatch(catalogActions.onGetCatalogWorkers(page)).then((data) => {
      if (data?.results?.length > 0) {
        setResults(data?.results);
        setHaveData(true);
        setPageSize(Math.ceil(data?.count / 10));
      }
      setLoading(false);
    });
  };

  const handlePageChange = (a, page) => {
    setPageSize(page);
    loadData(`?page=${page}`, null);
  };

  return (
    <>
      {isLoading ? <Loader /> : null}
      <div id="main-wrapper" class="show">
        <DeshboardLayout />

        <div
          class={`content-body dashboard-conainer ${
            isLoading ? "hide-custom" : "show"
          }`}
        >
          {haveData ? <Dheader /> : null}
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                {!haveData ? (
                  <Step01 />
                ) : (
                  <Members
                    results={results}
                    isReload={(e) => {
                      setRefreshData(!refreshData);
                    }}
                    pageSize={pageSize}
                    handlePageChange={(a, p) => handlePageChange(a, p)}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default DashboardHome;
