import { combineReducers, createStore, applyMiddleware } from "redux";
import AccountReducer from "./Accounts/accountReducer";
import ReduxThunk from "redux-thunk";
import MenuReducer from "./Menu/menuReducer";
import ResturantReducer from "./Resturant/resturantReducer";
import CatalogReducer from "./Catalogs/catalogReducer";

const reducers = combineReducers({
  AccountReducer: AccountReducer,
  MenuReducer: MenuReducer,
  ResturantReducer: ResturantReducer,
  CatalogReducer: CatalogReducer,
});

const store = createStore(reducers, {}, applyMiddleware(ReduxThunk));
export default store;
