import React, { useState } from "react";
import arrow from "../../../assets/images/arrow.png";

const InfoBlocks = ({ summary }) => {
  return (
    <>
      <div className="row dashbord-media">
        <div className="col-3 mt-3">
          <div className="infoblocks income-block">
            <span className="info-number">
              {summary?.total_income ? `₦ ${summary?.total_income}` : 0}
            </span>
            <span className="info-text">Total Income</span>
            <div className="week-progress">
              <span className="week">This Week</span>
              <span className="figure">
                <i className="fa fa-calender"></i>
                <img src={arrow} width={20} />
                {"  "}
                {`${summary?.week_income?.percentage}`}%
              </span>
            </div>
          </div>
        </div>
        <div className="col-3 mt-3">
          <div className="infoblocks">
            <span className="info-number">
              {" "}
              {summary?.tip_received ? `₦ ${summary?.tip_received}` : 0}
            </span>
            <span className="info-text">Tip Recieved</span>
          </div>
        </div>
        <div className="col-3 mt-3">
          <div className="infoblocks">
            <span className="info-number">
              {summary?.total_orders ? summary?.total_orders : 0}
            </span>
            <span className="info-text">Total Orders</span>
          </div>
        </div>
        <div className="col-3 mt-3">
          <div className="infoblocks">
            <span className="info-number">
              {summary?.cancel_orders ? summary?.cancel_orders : 0}
            </span>
            <span className="info-text">Cancel Orders</span>
          </div>
        </div>
        <div className="col-3 mt-3">
          <div className="infoblocks">
            <span className="info-number">
              {summary?.total_customer ? summary?.total_customer : 0}
            </span>
            <span className="info-text">Total Customers</span>
          </div>
        </div>
        <div className="col-3 mt-3">
          <div className="infoblocks">
            <span className="info-number">
              {summary?.new_customer ? summary?.new_customer : 0}
            </span>
            <span className="info-text">New Customers</span>
          </div>
        </div>
        <div className="col-3 mt-3">
          <div className="infoblocks">
            <span className="info-number">
              {summary?.loyal_customer ? summary?.loyal_customer : 0}
            </span>
            <span className="info-text">Loyal Customers</span>
          </div>
        </div>
        <div className="col-3 mt-3">
          <div className="infoblocks">
            <span className="info-number">
              {summary?.total_visits ? summary?.total_visits : 0}
            </span>
            <span className="info-text">Total Visits</span>
          </div>
        </div>
      </div>
    </>
  );
};
export default InfoBlocks;
