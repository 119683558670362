import React, { useEffect, useState } from "react";
import DeshboardLayout from "../../layouts/DeshboardLayout";
import EditMenuheader from "./EditMenuheader";
import EditMenuNav from "./EditMenuNav";
import MenuHomePage from "./MenuHomePage";
import MenuSectionPage from "./MenuSectionPage";
import MenuItemPage from "./MenuItemPage";
import PopupPage from "./PopupPage";
import { useDispatch } from "react-redux";
import { menuActions } from "../../../../Store/Menu/menuAction";
import { catalogActions } from "../../../../Store/Catalogs/catalogAction";

function EditCatTemplate() {
  var templateId = parseInt(window.location.href.split("?id=")[1]);
  var menuId = window.location.href.split("?id=")[1];
  const [activeScreen, setActiveScreen] = useState(0);
  const [templeteDetail, setTemplateDetail] = useState();
  const dispatch = useDispatch();
  useEffect(() => {
    if (menuId.includes("-u"))
      dispatch(catalogActions.onGetCatalogById(parseInt(menuId))).then(
        (res) => {
          if (res) {
            loadData(res);
          }
        }
      );
    else {
      dispatch(menuActions.onGetThemeById(templateId)).then((res) => {
        if (res) {
          loadData(res);
        }
      });
    }
  }, []);

  const loadData = (res) => {
    setTemplateDetail(res);
    const data = {
      name: res?.name,
      description: res?.description,
      menu_type: res?.menu_type,
      home_background_image: res?.home_background_image,
      home_display_logo: res?.home_display_logo,
      home_display_image_background: res?.home_display_image_background,
      home_background_image_file: null,
      logo: res?.logo,
      home_background_color: res?.home_background_color,
      home_restaurant_name_color: res?.home_restaurant_name_color,
      home_main_heading_color: res?.home_main_heading_color,
      home_sub_heading_color: res?.home_sub_heading_color,
      home_text_color: res?.home_text_color,
      home_dine_in_btn_color: res?.home_dine_in_btn_color,
      home_dine_in_text_color: res?.home_dine_in_text_color,
      home_dine_in_logo_color: res?.home_dine_in_logo_color,
      home_delivery_in_btn_color: res?.home_delivery_in_btn_color,
      home_delivery_in_text_color: res?.home_delivery_in_text_color,
      home_delivery_in_logo_color: res?.home_delivery_in_logo_color,
      menu_header_background_color: res?.menu_header_background_color,
      menu_header_text: res?.menu_header_text,
      menu_product_backgroud_color: res?.menu_product_backgroud_color,
      menu_product_text: res?.menu_product_text,
      menu_product_price: res?.menu_product_price,
      menu_product_icons: res?.menu_product_icons,
      menu_cart_background: res?.menu_cart_background,
      menu_cart_text_color: res?.menu_cart_text_color,
      menu_cart_price_color: res?.menu_cart_price_color,
      menu_category_tab_text_color: res?.menu_category_tab_text_color,
      menu_category_tab_color: res?.menu_category_tab_color,
      menu_delivery_text_background: res?.menu_delivery_text_background,
      menu_delivery_btn_color: res?.menu_delivery_btn_color,
      menu_delivery_icon_color: res?.menu_delivery_icon_color,
      item_product_background_color: res?.item_product_background_color,
      item_product_text_color: res?.item_product_text_color,
      item_product_price: res?.item_product_price,
      item_tip_text_color: res?.item_tip_text_color,
      item_tip_btn_color: res?.item_tip_btn_color,
      item_cart_text_color: res?.item_cart_text_color,
      item_cart_btn_color: res?.item_cart_btn_color,
      item_place_order_text_color: res?.item_place_order_text_color,
      item_place_order_btn_color: res?.item_place_order_btn_color,
      popup_background_color: res?.popup_background_color,
      popup_text_color: res?.popup_text_color,
      popup_product_bg_color: res?.popup_product_bg_color,
      popup_product_text_color: res?.popup_product_text_color,
      popup_cart_btn_color: res?.popup_cart_btn_color,
      popup_cart_text_color: res?.popup_cart_text_color,
    };
    dispatch(menuActions.onChange("menuData", data));
  };
  return (
    <>
      <div id="main-wrapper" className="show">
        <DeshboardLayout />
        <div className="content-body">
          <EditMenuheader />
          <div className="edit-menu-area">
            <EditMenuNav
              activeOption={activeScreen}
              setactive={setActiveScreen}
            />
            {activeScreen == 0 ? (
              <MenuHomePage templeteDetail={templeteDetail} />
            ) : (
              ""
            )}
            {activeScreen == 1 ? (
              <MenuSectionPage templeteDetail={templeteDetail} />
            ) : (
              ""
            )}
            {activeScreen == 2 ? (
              <MenuItemPage templeteDetail={templeteDetail} />
            ) : (
              ""
            )}
            {activeScreen == 3 ? (
              <PopupPage templeteDetail={templeteDetail} />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default EditCatTemplate;
