import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resturantActions } from "../../../Store/Resturant/resturantAction";
import Loader from "../../Common/Loader";
import "./staff.css";
import { rootURL } from "../../../Config/apiConfig";
import { catalogActions } from "../../../Store/Catalogs/catalogAction";
import { toast } from "react-toastify";
import QRCode from "react-qr-code";
import html2canvas from "html2canvas";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getTime } from "../partials/status";
import { MdShoppingCartCheckout } from "react-icons/md";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  alignItem: "center",
  display: "flex",
  flexDirection: "column",
};

const Step01 = () => {
  let isSubscription = true;
  const subscription = JSON.parse(localStorage.getItem("subscription"));
  if (subscription) {
    isSubscription = subscription?.active === "active" ? true : false;
  }
  const [value, setValue] = useState(new Date());
  const [isOpen, setIsOpen] = useState(false);
  const [selectedObjects, setSelectedObjects] = useState([]);
  const [memberData, setMemberData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    id: 0,
    idNo: 0,
    position: "",
    walletaddress: "",
    avatar: "",
    country: 1,
    status: "",
    available: "",
    profile_image: "",
  });
  const [profile, setProfile] = useState("");
  const [monday, setMonday] = useState({
    id: 0,
    start_time: "09:00:00",
    end_time: "17:00:00",
    active: false,
  });
  const [tuesday, setTuesday] = useState({
    id: 0,
    start_time: "09:00:00",
    end_time: "17:00:00",
    active: false,
  });
  const [wednesday, setWednesday] = useState({
    id: 0,
    start_time: "09:00:00",
    end_time: "17:00:00",
    active: false,
  });
  const [thursday, setThursday] = useState({
    id: 0,
    start_time: "09:00:00",
    end_time: "17:00:00",
    active: false,
  });
  const [friday, setFriday] = useState({
    id: 0,
    start_time: "09:00:00",
    end_time: "17:00:00",
    active: false,
  });
  const [saturday, setSaturday] = useState({
    id: 0,
    start_time: "09:00:00",
    end_time: "17:00:00",
    active: false,
  });
  const [sunday, setSunday] = useState({
    id: 0,
    start_time: "09:00:00",
    end_time: "17:00:00",
    active: false,
  });
  const [isLoading, setLoading] = useState(false);
  const [isFirstTimeLoading, setIsFirstTimeLoading] = useState(false);
  const [worketPosition, setWorketPosition] = useState([]);
  const [businessService, setBusinessService] = useState([]);
  const [professionalService, setProfessionalService] = useState([]);
  const [serviceExist, setServiceExist] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [qrLink, setQrLink] = useState("");
  const [qrCodeLink, setQrCodeLink] = useState("");
  const [sechedule, setSchedule] = useState([]);
  const [sechedulebk, setSchedulebk] = useState([]);
  const [selectedMemberId, setSelectedMemberId] = useState(0);
  const dispatch = useDispatch();
  const history = useHistory();
  var memberId = parseInt(window.location.href.split("?id=")[1]);

  const closePopup = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    dispatch(resturantActions.onGetWorkersPosition("business")).then((data) => {
      if (data?.results) {
        setWorketPosition(data?.results);
      }
    });
  }, []);

  useEffect(() => {
    dispatch(resturantActions.onGetProfessionalServices()).then((data) => {
      if (data?.results) {
        let rec = data?.results;
        rec = data?.results.map((x) => {
          return { ...x, checked: false };
        });
        setIsFirstTimeLoading(true);
        setBusinessService(rec);
      }
    });
  }, []);

  useEffect(
    (x) => {
      if (memberId) {
        setLoading(true);
        loadEditData();
      }
    },
    [memberId]
  );

  const loadEditData = () => {
    dispatch(catalogActions.onGetCatalogWorkersById(memberId)).then((data) => {
      setLoading(false);
      if (data?.id) {
        let imag = "";
        if (data?.profile_image) {
          const isExit = data?.profile_image?.includes("http");
          imag = isExit ? data?.profile_image : `${data?.profile_image}`;
        }

        setMemberData({
          firstName: data?.name?.split(" ")[0],
          lastName: data?.name?.split(" ")[1],
          email: data?.email,
          id: data?.id,
          idNo: data?.id_no,
          position: data?.position?.id,
          walletaddress: data?.address,
          country: data?.country?.id,
          status: data?.status,
          available: data?.available,
          avatar: imag,
        });
        if (data?.qr?.qr_code_link) {
          setQrCodeLink(data?.qr?.qr_data);
        }
        if (data?.schedule?.length > 0) {
          var sun = data?.schedule.filter((x) => x.weekday == "sunday")?.[0];
          var mon = data?.schedule.filter((x) => x.weekday == "monday")?.[0];
          var tue = data?.schedule.filter((x) => x.weekday == "tuesday")?.[0];
          var wed = data?.schedule.filter((x) => x.weekday == "wednesday")?.[0];
          var thur = data?.schedule.filter((x) => x.weekday == "thursday")?.[0];
          var fri = data?.schedule.filter((x) => x.weekday == "friday")?.[0];
          var sat = data?.schedule.filter((x) => x.weekday == "saturday")?.[0];
          setMonday({
            id: mon.id,
            start_time: mon.start_time,
            end_time: mon.end_time,
            active: mon.active,
          });
          setTuesday({
            id: tue.id,
            start_time: tue.start_time,
            end_time: tue.end_time,
            active: tue.active,
          });
          setWednesday({
            id: wed.id,
            start_time: wed.start_time,
            end_time: wed.end_time,
            active: wed.active,
          });
          setThursday({
            id: thur.id,
            start_time: thur.start_time,
            end_time: thur.end_time,
            active: thur.active,
          });
          setFriday({
            id: fri.id,
            start_time: fri.start_time,
            end_time: fri.end_time,
            active: fri.active,
          });
          setSaturday({
            id: sat.id,
            start_time: sat.start_time,
            end_time: sat.end_time,
            active: sat.active,
          });
          setSunday({
            id: sun.id,
            start_time: sun.start_time,
            end_time: sun.end_time,
            active: sun.active,
          });
        }
        if (data?.services?.length > 0) {
          setProfessionalService(data?.services);
        }
      }
    });
  };

  useEffect(() => {
    if (
      isFirstTimeLoading &&
      businessService.length > 0 &&
      professionalService.length > 0
    ) {
      var arr = [];
      businessService?.map((x) => {
        var res = professionalService.filter(
          (y) => y.service.id === x.service.id
        );
        if (res.length > 0) {
          x.checked = true;
          arr.push(x.service.id);
        }
        return x;
      });
      setServiceExist(arr);
      setIsFirstTimeLoading(false);
      setBusinessService(businessService);
    }
  }, [professionalService, isFirstTimeLoading]);

  const addMember = () => {
    if (
      memberData.firstName !== "" &&
      memberData.lastName !== "" &&
      memberData.email !== "" &&
      memberData.idNo !== ""
    ) {
      setLoading(true);
      let data = new FormData();
      data.append(
        "professional[name]",
        `${memberData.firstName} ${memberData.lastName}`
      );
      data.append("professional[contact_no]", "+923336569854");
      data.append("professional[email]", memberData.email);
      data.append("professional[id_no]", memberData.idNo);
      data.append("professional[user_app_id]", memberData.idNo);
      data.append("professional[address]", memberData.walletaddress);
      data.append("professional[status]", "Approved");
      data.append(
        "professional[position]",
        memberData?.position && memberData?.position != ""
          ? memberData?.position
          : worketPosition[0]?.id
      );
      profile && data.append("professional[profile_image]", profile);
      data.append("schedule[0][weekday]", "monday");
      data.append("schedule[0][start_time]", monday.start_time);
      data.append("schedule[0][end_time]", monday.end_time);
      data.append("schedule[0][active]", monday.active);
      data.append("schedule[1][weekday]", "tuesday");
      data.append("schedule[1][start_time]", tuesday.start_time);
      data.append("schedule[1][end_time]", tuesday.end_time);
      data.append("schedule[1][active]", tuesday.active);
      data.append("schedule[2][weekday]", "wednesday");
      data.append("schedule[2][start_time]", wednesday.start_time);
      data.append("schedule[2][end_time]", wednesday.end_time);
      data.append("schedule[2][active]", wednesday.active);
      data.append("schedule[3][weekday]", "thursday");
      data.append("schedule[3][start_time]", thursday.start_time);
      data.append("schedule[3][end_time]", thursday.end_time);
      data.append("schedule[3][active]", thursday.active);
      data.append("schedule[4][weekday]", "friday");
      data.append("schedule[4][start_time]", friday.start_time);
      data.append("schedule[4][end_time]", friday.end_time);
      data.append("schedule[4][active]", friday.active);
      data.append("schedule[5][weekday]", "saturday");
      data.append("schedule[5][start_time]", saturday.start_time);
      data.append("schedule[5][end_time]", saturday.end_time);
      data.append("schedule[5][active]", saturday.active);
      data.append("schedule[6][weekday]", "sunday");
      data.append("schedule[6][start_time]", sunday.start_time);
      data.append("schedule[6][end_time]", sunday.end_time);
      data.append("schedule[6][active]", sunday.active);

      professionalService.map((it, i) =>
        data.append(`services[${i}]`, it.service.id)
      );

      if (memberId) {
        dispatch(
          catalogActions.onUpdateCatalogWorkers(data, memberData.id)
        ).then((data) => {
          setLoading(false);
          history.push("/professional");
        });
      } else {
        dispatch(catalogActions.onCreateCatalogWorkers(data)).then((res) => {
          if (res?.id) {
            setSelectedMemberId(res?.id);
            history.push(`/add-professional-member?id=${res?.id}`);
            window.location.reload();
          }
        });
        setLoading(false);
      }
    } else {
      toast.error("Please add all information");
    }
  };

  const updateSchedule = (modal) => {
    // dispatch(
    //   resturantActions.onCreateScheduleWorkers(
    //     {
    //       start_time: modal.start_time,
    //       end_time: modal.end_time,
    //       active: modal.active,
    //     },
    //     memberId,
    //     modal.id
    //   )
    // ).then((data) => {});
  };

  const generateQRCode = async () => {
    var bus = await localStorage.getItem("bus");
    setLoading(true);
    let vanue = localStorage.getItem("venu_name");
    var data = {
      link: `https://yawaevig.com/business/${vanue}&st=${memberId}&pr=${memberId}`,
    };
    if (qrCodeLink) {
      dispatch(catalogActions.onProfessionUpdateQRCode(data, memberId)).then(
        (res) => {
          if (res?.id) {
            setQrLink(res?.qr_data);
            let imag = res?.qr_code_link.includes("http");
            setQrCodeLink(res?.qr_data);
          }
          setLoading(false);
        }
      );
    } else {
      dispatch(catalogActions.onProfessionGenerateQRCode(data, memberId)).then(
        (res) => {
          if (res?.id) {
            setQrLink(res?.qr_data);
            setQrCodeLink(res?.qr_data);
          }
          setLoading(false);
        }
      );
    }
  };

  const DownloadQr = (e) => {
    html2canvas(document.getElementById("qrcode")).then(function (canvas) {
      // document.body.appendChild(canvas);
      var a = document.createElement("a");
      a.href = canvas
        .toDataURL("..assets/image/jpeg")
        .replace("image/jpeg", "image/octet-stream");
      a.download = "QRCode.jpeg";
      a.click();
    });
  };

  const handleChange = (e) => {
    // setDate(e.target.value);
  };

  useEffect(() => {
    loadAvailability(true);
  }, [startDate]);

  const loadAvailability = (isBackup) => {
    if (memberId) {
      //   const year = startDate.getFullYear();
      //   const date = startDate.getDate();
      //   const month = startDate.getMonth() + 1;
      //   const selectedDate = `${year}-${month}-${date}`;

      var currentDatetime = new Date(startDate);
      var year = currentDatetime.getFullYear();
      var month = (currentDatetime.getMonth() + 1).toString().padStart(2, "0");
      var day = currentDatetime.getDate().toString().padStart(2, "0");

      var selectedDate = year + "-" + month + "-" + day;

      setLoading(true);
      dispatch(
        catalogActions.onGetAssignProfessionalSchedule(memberId, selectedDate)
      ).then((data) => {
        if (data?.length > 0) {
          dispatch(
            catalogActions.onGetProfessionalLeaves(memberId, selectedDate)
          ).then((rec) => {
            setLoading(false);
            if (rec?.results?.length > 0) {
              const recStartTimes = rec?.results.map((item) =>
                item.start_time.slice(0, 5)
              );
              const updatedFirstArray = data.map((item) => ({
                ...item,
                // available: !recStartTimes.includes(item.start_time),
                leave: recStartTimes.includes(item.start_time),
              }));
              setSchedule(updatedFirstArray);
              if (isBackup) {
                setSchedulebk(updatedFirstArray);
              }
            } else {
              setSchedule(data);
              if (isBackup) {
                setSchedulebk(data);
              }
            }
          });
        }
      });
    }
  };

  const handleChangeAvailability = (modal) => {
    setLoading(true);
    dispatch(catalogActions.onAddProfessionLeave(modal, memberId)).then(
      (data) => {
        setLoading(false);
        loadAvailability(false);
      }
    );
  };
  return (
    <>
      {isLoading ? <Loader /> : null}
      <div className={`${isLoading ? "hide-custom" : "show"}`}>
        <div className="text-center py-4 pt-4 bg-white rounded">
          {memberId > 0 && isSubscription ? (
            <div className="text-right" style={{ alignItems: "center" }}>
              {qrCodeLink && (
                <button
                  className="btn btn-primary mr-4"
                  onClick={() => {
                    setIsOpen(true);
                  }}
                >
                  View QR Code
                </button>
              )}
              <button
                className="btn btn-primary mr-4"
                onClick={() => {
                  generateQRCode();
                }}
              >
                Generate QR Code
              </button>
            </div>
          ) : (
            <>
              {!isSubscription && (
                <p>
                  You cannot use Generate QR Code in the trial and expired
                  package. To Generate QR Code please subscribe{" "}
                  <span
                    style={{ cursor: "pointer", color: "#f9a05f" }}
                    onClick={() => {
                      history.push("/subscription");
                    }}
                  >
                    package
                  </span>
                  .{" "}
                </p>
              )}
            </>
          )}
          <div className="mt-5">
            <label>
              <input
                style={{ display: "none" }}
                type="file"
                name="myImage"
                accept="image/png,image/jpeg"
                onChange={(e) => {
                  if (e.target.files && e.target.files[0]) {
                    let img = e.target.files[0];
                    setProfile(e.target.files[0]);
                    const ava = URL.createObjectURL(img);
                    setMemberData({
                      ...memberData,
                      avatar: ava,
                    });
                  }
                }}
              />
              {memberData?.avatar ? (
                <img
                  className="mouse-pointer"
                  style={{
                    width: "100px",
                    height: "100px",
                    borderRadius: "100px",
                  }}
                  src={memberData.avatar}
                />
              ) : (
                <span className="fa fa-camera staff-img" />
              )}
            </label>
            <p className="py-3 orange-text mouse-pointer">Upload Photo</p>
            <div className="row px-4 text-left addstaff-mob">
              <div className="col-md-6 col-xs-12 py-2 addstaff-mob">
                <lable className="form-label order-detail">First Name</lable>
                <input
                  type="text"
                  name="firstname"
                  class="form-control input-filed order-detail"
                  placeholder="e.g Jhon"
                  value={memberData.firstName}
                  onChange={(e) => {
                    setMemberData({
                      ...memberData,
                      firstName: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="col-md-6 col-xs-12 py-2 addstaff-mob">
                <lable className="form-label order-detail">Last Name</lable>
                <input
                  type="text"
                  name="lastname"
                  class="form-control input-filed order-detail"
                  placeholder="e.g Wick"
                  value={memberData.lastName}
                  onChange={(e) => {
                    setMemberData({
                      ...memberData,
                      lastName: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="col-md-6 col-xs-12 py-2 addstaff-mob">
                <lable className="form-label order-detail">Email</lable>
                <input
                  type="email"
                  name="email"
                  class="form-control input-filed order-detail"
                  placeholder="e.g johnwick@email.com"
                  value={memberData.email}
                  onChange={(e) => {
                    setMemberData({
                      ...memberData,
                      email: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="col-md-6 col-xs-12 py-2 addstaff-mob">
                <lable className="form-label order-detail">Wallet</lable>
                <input
                  type="text"
                  name="idnumber"
                  class="form-control input-filed order-detail"
                  placeholder="e.g 3142121"
                  value={memberData.idNo}
                  onChange={(e) => {
                    setMemberData({
                      ...memberData,
                      idNo: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="col-md-6 col-xs-12 py-2 addstaff-mob">
                <lable className="form-label order-detail">Position</lable>
                <select
                  name="position"
                  class="form-control input-filed order-detail"
                  placeholder="e.g 3142121"
                  value={memberData.position}
                  onChange={(e) => {
                    setMemberData({
                      ...memberData,
                      position: e.target.value,
                    });
                  }}
                >
                  {worketPosition?.map((x, i) => {
                    return (
                      <option key={i} value={x.id}>
                        {x.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="col-md-6 col-xs-12 px-5 py-2">
                {/* <lable className="form-label">Wallet Address</lable>
                <input
                  type="text"
                  name="walletaddress"
                  class="form-control input-filed"
                  placeholder="Add giveaway wallet Address"
                  value={memberData.walletaddress}
                  onChange={(e) => {
                    setMemberData({
                      ...memberData,
                      walletaddress: e.target.value,
                    });
                  }}
                /> */}
              </div>
              {memberId > 0 && (
                <div className="col-md-6">
                  <p className="font-weight-bold order-detail">Availablility</p>
                </div>
              )}
              {memberId > 0 && (
                <div className="col-md-6">
                  <div
                    class="input-group date date-calander"
                    style={{ display: "flex", flexFlow: "row-reverse" }}
                  >
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="fa fa-calendar date-calendar"></i>
                      </span>
                      <DatePicker
                        class="form-control input-filed"
                        selected={startDate}
                        onChange={(date) => {
                          console.log(date);
                          setStartDate(date);
                        }}
                      />
                    </div>
                    {/* <input
                    type="date"
                    class="form-control input-filed"
                    placeholder="Select a date"
                    onChange={(e) => {
                      setValue(e.target.value);
                      loadData(e.target.value);
                    }}
                  /> */}
                  </div>
                </div>
              )}
              <div className="col-md-12 col-xs-12 py-2">
                <p>
                  {sechedule?.map((x, i) => {
                    return (
                      <span
                        className={`time-slot ${
                          x?.available
                            ? ""
                            : x?.type == "appointment" ||
                              x?.type == "passed" ||
                              x?.type == "lock"
                            ? "lock"
                            : "leave"
                        } avail`}
                        onClick={() => {
                          if (
                            x.available ||
                            (!x.available &&
                              x?.type != "appointment" &&
                              x?.type != "passed")
                          ) {
                            let modal = {
                              start_datetime: x?.start_datetime,
                              end_datetime: x?.end_datetime,
                            };
                            console.log(modal);
                            handleChangeAvailability(modal);
                          }
                        }}
                      >
                        {getTime(x?.start_time)}
                      </span>
                    );
                  })}
                  {/* <span className="time-slot">09:00 am</span>{" "}
                  <span className="time-slot active">10:00 am</span>
                  <span className="time-slot">11:00 am</span>{" "}
                  <span className="time-slot">12:00 am</span>{" "}
                  <span className="time-slot">01:00 pm</span>
                  <span className="time-slot">02:00 pm</span>{" "}
                  <span className="time-slot">03:00 pm</span>{" "}
                  <span className="time-slot">04:00 pm</span>
                  <span className="time-slot active">05:00 pm</span>{" "}
                  <span className="time-slot">06:00 pm</span>{" "}
                  <span className="time-slot active">07:00 pm</span>
                  <span className="time-slot">08:00 pm</span>{" "}
                  <span className="time-slot">09:00 pm</span>{" "}
                  <span className="time-slot">10:00 pm</span>
                  <span className="time-slot">11:00 pm</span>{" "}
                  <span className="time-slot">12:00 pm</span> */}
                </p>
              </div>
              {memberId > 0 && (
                <>
                  <div className="col-md-6 col-xs-12 ">
                    <p className="font-weight-bold order-detail">
                      Working Hours
                    </p>
                  </div>

                  <div className="col-md-3 col-xs-12 px-5">
                    <p className="font-weight-bold mob-remove">Start Time</p>
                  </div>
                  <div className="col-md-3 col-xs-12 px-5">
                    <p className="font-weight-bold mob-remove">End Time</p>
                  </div>

                  <div className="col-md-6 aling-fix col-xs-12">
                    <input
                      type="checkbox"
                      name="sunday"
                      checked={sunday.active}
                      onChange={() => {
                        setSunday({
                          ...sunday,
                          active: !sunday.active,
                        });
                        updateSchedule({
                          ...sunday,
                          active: !sunday.active,
                        });
                      }}
                    />{" "}
                    <span>Sunday</span>
                  </div>

                  <div className="col-md-3 col-xs-12 ">
                    <p className="font-weight-bold">
                      <input
                        type="time"
                        name="starttime"
                        value={sunday.start_time}
                        onChange={(ev) => {
                          setSunday({
                            ...sunday,
                            start_time: ev.target.value,
                          });
                          updateSchedule({
                            ...sunday,
                            start_time: ev.target.value,
                          });
                        }}
                        className="form-control input-filed"
                      />
                    </p>
                  </div>
                  <div className="col-md-3 col-xs-12">
                    <input
                      type="time"
                      name="endtime"
                      value={sunday.end_time}
                      onChange={(ev) => {
                        setSunday({
                          ...sunday,
                          end_time: ev.target.value,
                        });
                        updateSchedule({
                          ...sunday,
                          end_time: ev.target.value,
                        });
                      }}
                      className="form-control input-filed"
                    />
                  </div>

                  <div className="col-md-6 aling-fix col-xs-12">
                    <input
                      type="checkbox"
                      name="monday"
                      checked={monday?.active}
                      onChange={() => {
                        setMonday({
                          ...monday,
                          active: !monday.active,
                        });
                        updateSchedule({
                          ...monday,
                          active: !monday.active,
                        });
                      }}
                    />{" "}
                    <span>Monday</span>
                  </div>

                  <div className="col-md-3 col-xs-12 ">
                    <p className="font-weight-bold">
                      <input
                        type="time"
                        name="starttime"
                        value={monday.start_time}
                        className="form-control input-filed"
                        onChange={(ev) => {
                          setMonday({
                            ...monday,
                            start_time: ev.target.value,
                          });
                          updateSchedule({
                            ...monday,
                            start_time: ev.target.value,
                          });
                        }}
                      />
                    </p>
                  </div>
                  <div className="col-md-3 col-xs-12 ">
                    <input
                      type="time"
                      name="starttime"
                      value={monday.end_time}
                      onChange={(ev) => {
                        setMonday({
                          ...monday,
                          end_time: ev.target.value,
                        });
                        updateSchedule({
                          ...monday,
                          end_time: ev.target.value,
                        });
                      }}
                      className="form-control input-filed"
                    />
                  </div>

                  <div className="col-md-6 aling-fix col-xs-12">
                    <input
                      type="checkbox"
                      name="tuesday"
                      checked={tuesday.active}
                      onChange={() => {
                        setTuesday({
                          ...tuesday,
                          active: !tuesday.active,
                        });
                        updateSchedule({
                          ...tuesday,
                          active: !tuesday.active,
                        });
                      }}
                    />{" "}
                    <span>Tuesday</span>
                  </div>

                  <div className="col-md-3 col-xs-12 ">
                    <p className="font-weight-bold">
                      <input
                        type="time"
                        name="starttime"
                        className="form-control input-filed"
                        value={tuesday.start_time}
                        onChange={(ev) => {
                          setTuesday({
                            ...tuesday,
                            start_time: ev.target.value,
                          });
                          updateSchedule({
                            ...tuesday,
                            start_time: ev.target.value,
                          });
                        }}
                      />
                    </p>
                  </div>
                  <div className="col-md-3 col-xs-12 ">
                    <input
                      type="time"
                      name="end_time"
                      value={tuesday.end_time}
                      className="form-control input-filed"
                      onChange={(ev) => {
                        setTuesday({
                          ...tuesday,
                          end_time: ev.target.value,
                        });
                        updateSchedule({
                          ...tuesday,
                          end_time: ev.target.value,
                        });
                      }}
                    />
                  </div>

                  <div className="col-md-6 aling-fix col-xs-12 ">
                    <input
                      type="checkbox"
                      name="sunday"
                      checked={wednesday.active}
                      onChange={() => {
                        setWednesday({
                          ...wednesday,
                          active: !wednesday.active,
                        });
                        updateSchedule({
                          ...wednesday,
                          active: !wednesday.active,
                        });
                      }}
                    />{" "}
                    <span>Wednesday</span>
                  </div>

                  <div className="col-md-3 col-xs-12 ">
                    <p className="font-weight-bold">
                      <input
                        type="time"
                        name="starttime"
                        className="form-control input-filed"
                        value={wednesday.start_time}
                        onChange={(ev) => {
                          setWednesday({
                            ...wednesday,
                            start_time: ev.target.value,
                          });
                          updateSchedule({
                            ...wednesday,
                            start_time: ev.target.value,
                          });
                        }}
                      />
                    </p>
                  </div>
                  <div className="col-md-3 col-xs-12">
                    <input
                      type="time"
                      name="endtime"
                      value={wednesday.end_time}
                      onChange={(ev) => {
                        setWednesday({
                          ...wednesday,
                          end_time: ev.target.value,
                        });
                        updateSchedule({
                          ...wednesday,
                          end_time: ev.target.value,
                        });
                      }}
                      className="form-control input-filed"
                    />
                  </div>

                  <div className="col-md-6 aling-fix col-xs-12 ">
                    <input
                      type="checkbox"
                      name="thursday"
                      checked={thursday.active}
                      onChange={() => {
                        setThursday({
                          ...thursday,
                          active: !thursday.active,
                        });
                        updateSchedule({
                          ...thursday,
                          active: !thursday.active,
                        });
                      }}
                    />{" "}
                    <span>Thursday</span>
                  </div>

                  <div className="col-md-3 col-xs-12 ">
                    <p className="font-weight-bold">
                      <input
                        type="time"
                        name="starttime"
                        className="form-control input-filed"
                        value={thursday.start_time}
                        onChange={(ev) => {
                          setThursday({
                            ...thursday,
                            start_time: ev.target.value,
                          });
                          updateSchedule({
                            ...thursday,
                            start_time: ev.target.value,
                          });
                        }}
                      />
                    </p>
                  </div>
                  <div className="col-md-3 col-xs-12 ">
                    <input
                      type="time"
                      name="endtime"
                      className="form-control input-filed"
                      value={thursday.end_time}
                      onChange={(ev) => {
                        setThursday({
                          ...thursday,
                          end_time: ev.target.value,
                        });
                        updateSchedule({
                          ...thursday,
                          end_time: ev.target.value,
                        });
                      }}
                    />
                  </div>

                  <div className="col-md-6 aling-fix col-xs-12 ">
                    <input
                      type="checkbox"
                      name="friday"
                      checked={friday.active}
                      onChange={() => {
                        setFriday({
                          ...friday,
                          active: !friday.active,
                        });
                        updateSchedule({
                          ...friday,
                          active: !friday.active,
                        });
                      }}
                    />{" "}
                    <span>Friday</span>
                  </div>

                  <div className="col-md-3 col-xs-12 ">
                    <p className="font-weight-bold">
                      <input
                        type="time"
                        name="starttime"
                        className="form-control input-filed"
                        value={friday.start_time}
                        onChange={(ev) => {
                          setFriday({
                            ...friday,
                            start_time: ev.target.value,
                          });
                          updateSchedule({
                            ...friday,
                            start_time: ev.target.value,
                          });
                        }}
                      />
                    </p>
                  </div>
                  <div className="col-md-3 col-xs-12 ">
                    <input
                      type="time"
                      name="endtime"
                      className="form-control input-filed"
                      value={friday.end_time}
                      onChange={(ev) => {
                        setFriday({
                          ...friday,
                          end_time: ev.target.value,
                        });
                        updateSchedule({
                          ...friday,
                          end_time: ev.target.value,
                        });
                      }}
                    />
                  </div>

                  <div className="col-md-6 aling-fix col-xs-12 ">
                    <input
                      type="checkbox"
                      name="saturday"
                      checked={saturday.active}
                      onChange={() => {
                        setSaturday({
                          ...saturday,
                          active: !saturday.active,
                        });
                        updateSchedule({
                          ...saturday,
                          active: !saturday.active,
                        });
                      }}
                    />{" "}
                    <span>Saturday</span>
                  </div>

                  <div className="col-md-3 col-xs-12 ">
                    <p className="font-weight-bold">
                      <input
                        type="time"
                        name="starttime"
                        className="form-control input-filed"
                        value={saturday.start_time}
                        onChange={(ev) => {
                          setSaturday({
                            ...saturday,
                            start_time: ev.target.value,
                          });
                          updateSchedule({
                            ...saturday,
                            start_time: ev.target.value,
                          });
                        }}
                      />
                    </p>
                  </div>
                  <div className="col-md-3 col-xs-12 ">
                    <input
                      type="time"
                      name="endtime"
                      className="form-control input-filed"
                      value={saturday.end_time}
                      onChange={(ev) => {
                        setSaturday({
                          ...saturday,
                          end_time: ev.target.value,
                        });
                        updateSchedule({
                          ...saturday,
                          end_time: ev.target.value,
                        });
                      }}
                    />
                  </div>

                  {memberId > 0 ? (
                    <>
                      <>
                        <div className="col-md-6 col-xs-12 ">
                          <p className="font-weight-bold order-detail">
                            Services
                          </p>
                        </div>
                      </>
                      {businessService?.map((x) => {
                        return (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginLeft: "35px",
                              marginTop: "10px",
                            }}
                            className="serv order-detail"
                          >
                            <input
                              className="serv order-detail"
                              type="checkbox"
                              name={`${x.service.name}`}
                              checked={
                                professionalService.filter(
                                  (it) => it.service.id === x.service.id
                                )?.length > 0
                              }
                              onChange={() => {
                                // console.log(x);
                                let exist = professionalService.filter(
                                  (it) => it.service.id === x.service.id
                                );
                                if (exist.length > 0) {
                                  let temp = professionalService.filter(
                                    (it) => it.service.id !== x.service.id
                                  );
                                  setProfessionalService(temp);
                                } else {
                                  console.log("Not Exits");
                                  setProfessionalService([
                                    ...professionalService,
                                    x,
                                  ]);
                                }

                                // var exist = serviceExist.filter(
                                //   (it) => it === x.service.id
                                // );
                                // if (exist > 0) {
                                //   var unCheck = serviceExist.filter(
                                //     (it) => it !== x.service.id
                                //   );
                                //   setServiceExist(unCheck);
                                //   dispatch(
                                //     resturantActions.onDeAssignProfessionalToService(
                                //       x.id,
                                //       memberId
                                //     )
                                //   ).then((res) => {});
                                // } else {
                                //   var re = serviceExist;
                                //   re.push(x.service.id);
                                //   setServiceExist(re);
                                //   const data = [
                                //     {
                                //       professional_id: memberId,
                                //     },
                                //   ];
                                //   setLoading(true);
                                //   dispatch(
                                //     resturantActions.onAssignProfessionalToService(
                                //       x.id,
                                //       data
                                //     )
                                //   ).then((res) => {
                                //     loadEditData();
                                //   });
                                // }
                              }}
                            />{" "}
                            <span className="order-detail">
                              {x.service.name}
                            </span>
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <></>
                  )}
                </>
              )}

              {/* end of new content */}
              <div className="text-right col-md-6 col-xs-12 py-3">
                <a class="btn btn-info" href-="" type="button">
                  <Link to="/professional">Cancel</Link>
                </a>
              </div>
              <div className="col-md-6 col-xs-12 py-3">
                <button
                  class="btn btn-primary"
                  onClick={() => {
                    addMember();
                  }}
                >
                  {memberId ? "Update" : "Save"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={isOpen}
        onClose={() => closePopup()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{ alignSelf: "center", position: "relative" }}
          >
            QR Code
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2 }}
            style={{ alignSelf: "center" }}
          >
            {qrCodeLink && (
              <>
                <div id="qrcode" style={{ width: "200px", height: "200px" }}>
                  <QRCode size={200} value={qrCodeLink} />
                </div>
              </>
            )}
          </Typography>
          <div className="row mt-2">
            <div className="col">
              {qrCodeLink && (
                <button
                  id="button"
                  onClick={DownloadQr}
                  class="btn btn-primary small"
                >
                  Download
                </button>
              )}
            </div>
            <div className="col">
              <button
                className="btn btn-warning small"
                style={{ width: "150px" }}
                onClick={() => {
                  closePopup();
                }}
              >
                Close
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};
export default Step01;
