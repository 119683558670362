import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import { menuActions } from "../../../../Store/Menu/menuAction";
import Loader from "../../../Common/Loader";
import Switch from "react-switch";

function TutorialDetail({ setDetailClose }) {
  const [show, setShow] = useState(true);
  const [isLoading, setLoading] = useState(false);

  const handleClose = () => {
    setShow(false);
    setDetailClose(false);
  };

  return (
    <>
      {isLoading ? <Loader /> : null}
      
      <div className="modalAreaCentered text-center">
        <Modal show={show} onHide={handleClose} size="lg" centered>
          <Modal.Header closeButton>
            <Modal.Title> How to Upgrate your personal account to business account?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <iframe width="100%" height="400px" src="https://www.youtube.com/embed/oxti1Tr08zQ" title="Giveaway App Explainer Video" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

export default TutorialDetail;
