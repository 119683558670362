import React, { useState } from 'react'

function DiffrentWays() {

  const [value, setValue] = useState(0)

  /* 
  useEffect(() => {
    const interval = setInterval(() => {
      setValue((state) => {
        if (state < 2) {
          return state + 1
        } else {
          return 0
        }
      })
    }, 5000);
  
    return () => clearInterval(interval);
  }, []);*/
  return (
    <>
    <section className="slider-5-area">
      <div className="container">
      <div className="row align-items-center">
          <div className="col-lg-12">
          <h3 className="title">Different ways to use QR Menu</h3>
            </div>
            </div>
        <div className="row align-items-center">
          <div className="col-lg-6">
          <div className='slider-left-side'>
            <div className='lef-side-content'>
            <div className={`slider-image ${value === 0 ? 'active-left' : 'hide'}`} id="image-1">
            <img
                src={require(`../../assets/images/qrcodemenu/slider1.png`).default}
                alt=""
              />
            </div>
            <div className={`slider-image ${value === 1 ? 'active-left' : 'hide'}`} id="image-2">
            <img
                src={require(`../../assets/images/showcase1.png`).default}
                alt=""
              />
            </div>
            <div className={`slider-image ${value === 2 ? 'active-left' : 'hide'}`} id="image-3">
            <img
                src={require(`../../assets/images/showcase1.png`).default}
                alt=""
              />
            </div>
            </div>
            <div className='left-side-footer'>
            <ul>
              <li><span 
              onClick={ () => setValue(0) }
              className={`slider-btn-left ${value === 0 ? 'bullet-active' : 'bullet-inactive'}`} id="btn-1"></span></li>
              <li><span 
              onClick={ () => setValue(1) }
              className={`slider-btn-left ${value === 1 ? 'bullet-active' : 'bullet-inactive'}`} id="btn-2"></span></li>
              <li><span 
              onClick={ () => setValue(2) }
              className={`slider-btn-left ${value === 2 ? 'bullet-active' : 'bullet-inactive'}`} id="btn-3"></span></li>

            </ul>

            </div>
          </div>
    
          </div>
          <div className="col-lg-6 col-md-12">
          <div className='slider-right-side'>
          <div className='right-side-content'>
          <div className={`slider-content ${value === 0 ? 'active-right' : 'hide'}`} id="slider-1">
            <h4>1. Just show the menu</h4>
           <p>You can just simply show your menu digitally in the best possible way, by showcasing your food and beverages with beautiful photos and videos. Take advantage of our other features that will guarantee an increase in sales and guest satisfaction while being more personalized with your guests by still providing physical table service.</p>
            </div>
            <div className={`slider-content ${value === 1 ? 'active-right' : 'hide'}`} id="slider-2">
            <h4>2. Enable ordering</h4>
            <p>You can just simply show your menu digitally in the best possible way, by showcasing your food and beverages with beautiful photos and videos. Take advantage of our other features that will guarantee an increase in sales and guest satisfaction while being more personalized with your guests by still providing physical table service.</p>
            </div>
            <div className={`slider-content ${value === 2 ? 'active-right' : 'hide'}`} id="slider-3">
            <h4>3. Enable ordering with direct payment</h4>
<p>Apart from receiving the order from your guests, you can use our platform to collect the payment from your guests directly into your Stripe, 2Checkout or Checkout account.</p>
<p>If the above services are unavailable in your location, you can always use our internal My Menu Pay to accept online payments!</p>
            </div>
            </div>
            <div className='right-side-footer'>
              <ul>
                <li>
                  <span
                                onClick={() =>
                                  setValue((state) => {
                                    if (state > 0) {
                                      return state - 1
                                    } else {
                                      return 2
                                    }
                                  })
                                  
                                }
                  
                  ><i class="fa fa-chevron-left"></i></span>
                </li>
                <li>
                  <span
                                onClick={() =>
                                  setValue((state) => {
                                    if (state < 2) {
                                      return state + 1
                                    } else {
                                      return 0
                                    }
                                  })
                                }
                  ><i class="fa fa-chevron-right"></i></span>
                </li>

              </ul>
            </div>
            </div>
            </div>
          </div>
        </div>
    </section>
  </>
  );
}

export default DiffrentWays;
