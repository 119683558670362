import React from "react";

function RatingReviewsAnimations() {

  return (
    <div className="animation-area-main">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
            <div className="rating-animation-block-area">
              <div className="rating-left">
              <img
            src={require(`../../assets/images/rating1.png`).default}
            alt=""
            className="rating-main-img"
          />
              </div>

          <div className="rating-right">
            <img
            src={require(`../../assets/images/rating3.png`).default}
            alt=""
            className="rating-rating-badge"
          />
          </div>
          <div className="rating-middle">
            <img
            src={require(`../../assets/images/rating2.png`).default}
            alt=""
            className="rating-star-image"
          />
          </div>
            </div>
          </div>
          </div>
        </div>
        </div>
  );
}

export default RatingReviewsAnimations;
