import axios from "axios";
import { toast } from "react-toastify";

const apiKey = process.env.REACT_APP_API_KEY;
var api_key = apiKey;

const messageHandler = (response) => {
  console.log(response);
  if (response.status == 500) {
    toast.error("Something went wrong, please try later.");
  }
  if (
    (typeof response === "string" && response.includes("<!DOCTYPE html>")) ||
    typeof response === undefined
  ) {
    toast.error("Something went wrong, please try later.");
    return;
  }

  let errorMessages = [];

  // Check if response contains any form validation errors
  for (const key in response) {
    if (response.hasOwnProperty(key)) {
      const errorList = response[key];
      let formattedErrors = [];
      if (Array.isArray(errorList)) {
        formattedErrors = errorList.map(
          (errorMessage) =>
            errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1)
        );
        const errorMessageString = formattedErrors.join("\n");
        errorMessages.push(`${errorMessageString}`);
      } else if (typeof errorList === "string") {
        errorMessages.push(`${errorList}`);
      } else {
        Object.keys(errorList).forEach((key) => {
          console.log(key, errorList[key]);
        });
        return;
      }
      // errorMessages.push(`${key}: ${errorMessageString}`);
    }
  }

  if (errorMessages.length > 0) {
    // Handle form validation errors
    errorMessages.forEach((errorMessage) => {
      toast.error(errorMessage);
    });
  } else if (response?.message) {
    // Handle general error
    toast.error(response.message);
  } else {
    // Handle unknown error
    toast.error("Something went wrong, please try later.");
  }
};

function getApi(endpoint) {
  var config = {
    method: "get",
    url: endpoint,
    headers: {
      Authorization: `JWT ${localStorage.getItem("access_token")}`,
      "Content-Type": "application/json",
      "X-Api-Key": api_key,
    },
  };
  var response = axios(config)
    .then(function (response) {
      if (response.status !== 200) {
        messageHandler(response);
      }
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }
      return error;
    });
  return response;
}

function getApiWithoutToken(endpoint) {
  var config = {
    method: "get",
    url: endpoint,
    headers: {
      "Content-Type": "application/json",
      "X-Api-Key": api_key,
    },
  };
  var response = axios(config)
    .then(function (response) {
      if (response.status !== 200) {
        messageHandler(response);
      }
      return response.data;
    })
    .catch(function (error) {
      if (response.status !== 200) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          messageHandler(error);
        }
      }
      console.log(error);
      return error;
    });
  return response;
}

function postApi(data, endpoint) {
  var config = {
    method: "post",
    url: endpoint,
    headers: {
      Authorization: `JWT ${localStorage.getItem("access_token")}`,
      "Content-Type": "application/json",
      "X-Api-Key": api_key,
    },
    data: data,
  };
  var response = axios(config)
    .then(function (response) {
      if (response.status !== 200) {
        console.log("Request with Token");
        messageHandler(response);
      }
      return response.data;
    })
    .catch(function (error) {
      if (response.status !== 200) {
        messageHandler(error.response.data);
        // toast.error(
        //   error.response.data.message
        //     ? error.response.data.message
        //     : "Something went wrong, please try later."
        // );
      }
      console.log(error);
      return error;
    });
  return response;
}

function postApiWithoutToken(data, endpoint) {
  var config = {
    method: "post",
    url: endpoint,
    headers: {
      "Content-Type": "application/json",
      "X-Api-Key": api_key,
    },
    data: data,
  };
  var response = axios(config)
    .then(function (response) {
      if (response.status !== 200) {
        messageHandler(response);
      }
      return response.data;
    })
    .catch(function (error) {
      if (response.status !== 200) {
        messageHandler(error.response.data);
        // toast.error(
        //   error.response.data.message
        //     ? error.response.data.message
        //     : "Something went wrong, please try later."
        // );
      }
      console.log(error);
      return error;
    });
  return response;
}

function postApiWithoutData(endpoint) {
  var config = {
    method: "post",
    url: endpoint,
    headers: {
      Authorization: `JWT ${localStorage.getItem("access_token")}`,
      "Content-Type": "application/json",
      "X-Api-Key": api_key,
    },
  };
  var response = axios(config)
    .then(function (response) {
      if (response.status !== 200) {
        messageHandler(response);
      }
      return response.data;
    })
    .catch(function (error) {
      if (response.status !== 200) {
        messageHandler(error.response.data);
        // toast.error(
        //   error.response.data.message
        //     ? error.response.data.message
        //     : "Something went wrong, please try later."
        // );
      }
      console.log(error);
      return error;
    });
  return response;
}

function updateApi(data, endpoint) {
  var config = {
    method: "put",
    url: endpoint,
    headers: {
      Authorization: `JWT ${localStorage.getItem("access_token")}`,
      "X-Api-Key": api_key,
      "Content-Type": "application/json",
    },
    data: data,
  };
  var response = axios(config)
    .then(function (response) {
      if (response.status !== 200) {
        messageHandler(response);
      }
      return response.data;
    })
    .catch(function (error) {
      if (error.response && error.response.status !== 200) {
        messageHandler(error.response.data);
      }
      console.log(error);
      return error;
    });
  return response;
}

function updateApiWithoutToken(data, endpoint) {
  var config = {
    method: "put",
    url: endpoint,
    headers: {
      "X-Api-Key": api_key,
      "Content-Type": "application/json",
    },
    data: data,
  };
  var response = axios(config)
    .then(function (response) {
      if (response.status !== 200) {
        messageHandler(response);
      }
      return response.data;
    })
    .catch(function (error) {
      if (response.status !== 200) {
        toast.error(
          error.response.data.message
            ? error.response.data.message
            : "Something went wrong, please try later."
        );
      }
      console.log(error);
      return error;
    });
  return response;
}

function deleteApi(data, endpoint) {
  var config = {
    method: "delete",
    maxBodyLength: Infinity,
    url: endpoint,
    headers: {
      Authorization: `JWT ${localStorage.getItem("access_token")}`,
      "X-Api-Key": api_key,
      "Content-Type": "application/json",
    },
    data: data,
  };
  var response = axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return error;
    });
  return response;
}

function deleteApiWithoutToken(data, endpoint) {
  var config = {
    method: "delete",
    maxBodyLength: Infinity,
    url: endpoint,
    headers: {
      "X-Api-Key": api_key,
      "Content-Type": "application/json",
    },
    data: data,
  };
  var response = axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return error;
    });
  return response;
}

function deleteApiWithoutData(endpoint) {
  var config = {
    method: "delete",
    maxBodyLength: Infinity,
    url: endpoint,
    headers: {
      Authorization: `JWT ${localStorage.getItem("access_token")}`,
      "X-Api-Key": api_key,
      "Content-Type": "application/json",
    },
  };
  var response = axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return error;
    });
  return response;
}

export const axiosInstance = {
  getApi: getApi,
  postApi: postApi,
  postApiWithoutData: postApiWithoutData,
  updateApi: updateApi,
  deleteApi: deleteApi,
  deleteApiWithoutData: deleteApiWithoutData,
  getApiWithoutToken: getApiWithoutToken,
  postApiWithoutToken: postApiWithoutToken,
  updateApiWithoutToken: updateApiWithoutToken,
  deleteApiWithoutToken: deleteApiWithoutToken,
};
