import React, { useState } from "react";
import { Link } from "react-router-dom";
import navItem from "../Data/Navigation";

function Navigation() {
  const [tab, setTab] = useState();
  const tabHandler = (e, value) => {
    e.preventDefault();
    setTab(value);
  };
  return (
    <ul className="navbar-nav ml-auto">
      {navItem &&
        navItem.length > 0 &&
        navItem.map((item) => (
          <li key={item.id} className="nav-item">
            {item.submenu ? (
              <a onClick={(e) => tabHandler(e, item.id)} className="nav-link" href="/#">
                {item.title}
              </a>
            ) : (
              <Link className="nav-link" to={item.link}>
                {item.title}
              </Link>
            )}
            {item.submenu && (
              <ul
                className="sub-menu"
                style={{ display: tab === item.id ? "block" : "" }}
              >
                {item.items.map((subItem) => (
                  <li key={subItem.id}>
                    <Link
                      to={subItem.link}
                      style={{ textTransform: "capitalize" }}
                    >
                      {subItem.title}
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
    </ul>
  );
}

export default Navigation;
