import React from "react";
import BrandingCustomization from "./BrandingCustomization";
function Branding() {

  return (
    <section className="about-2-area about-5-area">
      <div className="container">
        <div className="row align-items-center">

          <div className="col-lg-6 col-md-12">
            <div className="about-5-content">
              <h3 className="title">Branding Customization</h3>
              <div className="text">
                <p>
                Being hoteliers we understand the importance of showcasing your brand. Hence all our channels - Tablet Menu, QR Menu, Online Ordering Menu - the guest is first taken to a landing page that represents your brand.</p>
                <p>Your digital menu can be similar as how your printed menu is. However, with our platform, your landing page can be so much more than just a plain menu. You can add info page, guest feedback, call for service button, and option to change languages!</p>
              </div>

            </div>
          </div>
          <div className="col-lg-6">
          <BrandingCustomization />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Branding;
