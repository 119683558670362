import React, { useState } from "react";
import reactCSS from "reactcss";
import { SketchPicker, ColorResult } from "react-color";

const ColorPickerFont = ({ color, setColor }) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [selectedColor, setSelectedColor] = useState(color);

  const styles = reactCSS({
    default: {
      container: {
        height: "28px",
      },
      color: {
        width: "30px",
        height: "18px",
        borderRadius: "2px",
        color: `${color}`,
      },
      swatch: {
        padding: "5px",
        background: "#fff",
        borderRadius: "1px",
        boxShadow: "0 0 0 1px rgba(0,0,0,.15)",
        display: "inline-block",
        cursor: "pointer",
        transform: "translate(2px, 2px)",
        marginLeft: "4px",
        height: 28,
        lineHeight: "20px",
        fontWeight: "bold",
        fontSize: "16px",
        textAlign: "center",
      },
      popover: {
        position: "absolute",
        zIndex: "2",
      },
      cover: {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
      },
    },
  });

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };
  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const handleChange = (color) => {
    //setSelectedColor(color.hex);
    setColor(color.hex);
  };

  const handleComplete = (color) => {
    //setSelectedColor(color.hex);
    setColor(color.hex);
  };
  return (
    <div style={styles.container}>
      <div style={styles.swatch} onClick={handleClick}>
        <div style={styles.color}>A</div>
      </div>
      {displayColorPicker ? (
        <div style={styles.popover}>
          <div style={styles.cover} onClick={handleClose} />
          <SketchPicker
            color={color}
            onChange={handleChange}
            onChangeComplete={handleComplete}
          />
        </div>
      ) : null}
    </div>
  );
};

export default ColorPickerFont;
