import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { menuActions } from "../../../../Store/Menu/menuAction";

const MenuTemplateheader = () => {
  const dispatch = useDispatch();
  const { searchTheme } = useSelector((state) => state.MenuReducer);
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-8">
            <h3 className="inner-header">Design studio Theme</h3>
          </div>
          <div className="col-md-4 text-right">
            <div className="input-group search-area menu-item-search-area">
              <span className="input-group-text">
                <i className="flaticon-381-search-2"></i>
              </span>
              <input
                type="text"
                value={searchTheme}
                className={`form-control active}`}
                placeholder="Search theme"
                onChange={(e) => {
                  dispatch(menuActions.onChange("searchTheme", e.target.value));
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default MenuTemplateheader;
