import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dineinicon from "../../../assets/images/dashboard/dineinicon.png";
import deliveryicon from "../../../assets/images/dashboard/deliveryicon.png";
import "./qrstyle.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Loader from "../../Common/Loader";
import { menuActions } from "../../../Store/Menu/menuAction";
import { resturantActions } from "../../../Store/Resturant/resturantAction";
import { useParams } from "react-router-dom";

function RestaurantHomePage() {
  const { slug } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [facebook, setFacebook] = useState("");
  const [instgram, setInstgram] = useState("");
  const [temptItems, setTempItems] = useState([]);
  const [rest, setRest] = useState(null);

  // const [branchId, setBranchId] = useState(0);
  // var rest = parseInt(window.location.href.split("?res=")[1]);
  const { menuData } = useSelector((state) => state.MenuReducer);
  var st = parseInt(
    window.location.href.split("&st=")[1]
      ? window.location.href.split("&st=")[1]
      : 0
  );
  useEffect(() => {
    setLoading(true);
    dispatch(
      resturantActions.onGetRestaurantsByVenu(slug.split("&&st=")[0])
    ).then((result) => {
      if (result?.id) {
        setRest(result?.id);
        setTempItems(result);
        // setBranchId(result?.branch?.id);
        getMenu(result?.branch?.id);
        setFacebook(
          result?.social_media.filter((x) => x.name === "facebook")[0]?.link
        );
        setInstgram(
          result?.social_media.filter((x) => x.name === "instagram")[0]?.link
        );
      }
      setLoading(false);
    });
  }, []);

  const getMenu = (rest) => {
    setLoading(true);
    dispatch(menuActions.onGetMenus(rest)).then((res) => {
      if (res?.id) {
        const data = {
          name: res?.name,
          description: res?.description,
          menu_type: res?.menu_type,
          home_background_image: res?.home_background_image,
          home_display_logo: res?.home_display_logo,
          home_display_image_background: res?.home_display_image_background,
          home_background_image_file: null,
          home_background_color: res?.home_background_color,
          home_restaurant_name_color: res?.home_restaurant_name_color,
          home_main_heading_color: res?.home_main_heading_color,
          home_sub_heading_color: res?.home_sub_heading_color,
          home_text_color: res?.home_text_color,
          home_dine_in_btn_color: res?.home_dine_in_btn_color,
          home_dine_in_text_color: res?.home_dine_in_text_color,
          home_dine_in_logo_color: res?.home_dine_in_logo_color,
          home_delivery_in_btn_color: res?.home_delivery_in_btn_color,
          home_delivery_in_text_color: res?.home_delivery_in_text_color,
          home_delivery_in_logo_color: res?.home_delivery_in_logo_color,
          menu_header_background_color: res?.menu_header_background_color,
          menu_header_text: res?.menu_header_text,
          menu_product_backgroud_color: res?.menu_product_backgroud_color,
          menu_product_text: res?.menu_product_text,
          menu_product_price: res?.menu_product_price,
          menu_product_icons: res?.menu_product_icons,
          menu_cart_background: res?.menu_cart_background,
          menu_cart_text_color: res?.menu_cart_text_color,
          menu_cart_price_color: res?.menu_cart_price_color,
          menu_category_tab_text_color: res?.menu_category_tab_text_color,
          menu_category_tab_color: res?.menu_category_tab_color,
          menu_delivery_text_background: res?.menu_delivery_text_background,
          menu_delivery_btn_color: res?.menu_delivery_btn_color,
          menu_delivery_icon_color: res?.menu_delivery_icon_color,
          item_product_background_color: res?.item_product_background_color,
          item_product_text_color: res?.item_product_text_color,
          item_product_price: res?.item_product_price,
          item_tip_text_color: res?.item_tip_text_color,
          item_tip_btn_color: res?.item_tip_btn_color,
          item_cart_text_color: res?.item_cart_text_color,
          item_cart_btn_color: res?.item_cart_btn_color,
          item_place_order_text_color: res?.item_place_order_text_color,
          item_place_order_btn_color: res?.item_place_order_btn_color,
          popup_background_color: res?.popup_background_color,
          popup_text_color: res?.popup_text_color,
          popup_product_bg_color: res?.popup_product_bg_color,
          popup_product_text_color: res?.popup_product_text_color,
          popup_cart_btn_color: res?.popup_cart_btn_color,
          popup_cart_text_color: res?.popup_cart_text_color,
        };
        dispatch(menuActions.onChange("menuData", data));
      }
      setLoading(false);
    });
  };

  const onCreateDineInButtonPress = async () => {
    setLoading(true);
    dispatch(resturantActions.onCreateStaffDineIn(rest, st)).then((data) => {
      if (data?.id) {
        history.push(
          `/restaurant-menu-categories?res=${rest}&st=${st}&br=${
            temptItems?.branch?.id
          }&ordertype=121&na=${slug.split("&&st=")[0]}&crt=${data?.id}`
        );
      }
      setLoading(false);
    });
  };

  const onCreateDeliveryButtonPress = async () => {
    setLoading(true);
    dispatch(resturantActions.onCreateStaffDelivery(rest, st)).then((data) => {
      if (data?.id) {
        history.push(
          `/restaurant-menu-categories?res=${rest}&st=${st}&br=${
            temptItems?.branch?.id
          }&ordertype=122&na=${slug.split("&&st=")[0]}&crt=${data?.id}`
        );
      }
      setLoading(false);
    });
  };
  return (
    <>
      {isLoading ? <Loader /> : null}
      <div
        className="qr-mobile-screen"
        style={{ backgroundColor: `${menuData?.home_background_color}` }}
      >
        <div className="container-fluid">
          <div className="row">
            {/* <div className="col-md-12 p-0">
					<div className="qr-header-area">
						<div className="qr-header-image">
						<img src={burgerbg} className="topimg"/>
						</div>
						<div className="qr-after-header px-3 top-radius">
							<div className="qr-logo-place">
							<img src="https://png.pngtree.com/png-vector/20191030/ourmid/pngtree-burger-vector-illustration-with-filled-line-design-burger-clip-art-png-image_1923564.jpg" className='qr-logo-main'/>
							</div>
							<div className="qr-social-media">
							<div className='social-media-icons'>
								<ul>
								<li><i className="fab fa-facebook-f"></i></li>
								<li><i className="fab fa-instagram"></i></li>
								</ul>
							</div>
							</div>
						</div>
					</div>
				</div> */}

            <div className="col-md-12 p-0">
              <div
                className="head-img"
                style={{ backgroundImage: `url(${temptItems.featured})` }}
              >
                {/* <img
                  src={temptItems.featured}
                  className="topimg"
                  style={{ width: "100%" }}
                /> */}
              </div>
              <div
                className="header2"
                style={{
                  backgroundColor: `${menuData?.home_background_color}`,
                }}
              >
                {temptItems?.logo && (
                  <img
                    alt="logo"
                    src={temptItems?.logo}
                    className="qr-logo-main"
                  />
                )}
                <div className="qr-social-media icon-social">
                  <div className="social-media-icons">
                    <ul>
                      {facebook !== "" && (
                        <li>
                          <i
                            className="fab fa-facebook-f"
                            onClick={() => {
                              if (
                                temptItems?.social_media.filter(
                                  (x) => x.name === "facebook"
                                )[0]?.link
                              ) {
                                window.open(
                                  temptItems?.social_media.filter(
                                    (x) => x.name === "facebook"
                                  )[0]?.link
                                );
                              }
                            }}
                          ></i>
                        </li>
                      )}
                      <li>
                        {" "}
                        {instgram !== "" && (
                          <i
                            className="fab fa-instagram"
                            onClick={() => {
                              if (
                                temptItems?.social_media.filter(
                                  (x) => x.name === "instagram"
                                )[0]?.link
                              ) {
                                window.open(
                                  temptItems?.social_media.filter(
                                    (x) => x.name === "instagram"
                                  )[0]?.link
                                );
                              }
                            }}
                          ></i>
                        )}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {/* <div className="col-md-7">
					<div className="qr-scan-title">
						<h3>Burger & Pizza kitchen</h3>
					</div>
					<div className="qr-scan-location">
						<span>
						<i className="fa fa-map-marker" aria-hidden="true"></i> </span>
						<span className='preview-address'>
						1001 Street, Nigera
						</span>
					</div>						  
				</div>
					  <div className="col-md-5">
						  <span>Open</span>
				</div> */}

            <div className="">
              <div className="qr-scan-title">
                <h3 style={{ color: `${menuData?.home_main_heading_color}` }}>
                  {temptItems?.name}
                </h3>
                <div className="qr-scan-location p-0">
                  <table width="100%">
                    <tbody>
                      <tr>
                        <td width={"70%"}>
                          <span className="orange-text">
                            <i className="fas fa-map-marker-alt"></i>{" "}
                          </span>
                          <span
                            className="preview-address"
                            style={{ color: `${menuData?.home_text_color}` }}
                          >
                            {temptItems?.branch?.address}
                          </span>
                        </td>
                        <td className="text-right">
                          <span
                            align="right"
                            valign="top"
                            style={{ marginLeft: "20px" }}
                          >
                            {temptItems?.branch?.open ? (
                              <span className="hour-btn open-hour">OPEN</span>
                            ) : (
                              <span className="hour-btn close-hour">CLOSE</span>
                            )}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="">
                {/* <p className="green-text">
                  <i className="far fa-scrubber"></i> Open
                </p> */}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="qr-scan-description">
              <h5 style={{ color: `${menuData?.home_sub_heading_color}` }}>
                Description
              </h5>
              <p>{temptItems?.description}</p>
            </div>
          </div>
          <div className="row">
            <div className="qr-ordering-area">
              {/* <Link
                className="qr-scan-ordering-btn"
                to="restaurant-menu-categories"
              > */}

              <div
                className="qr-scan-ordering-btn"
                style={{
                  backgroundColor: menuData?.home_dine_in_btn_color,
                }}
                onClick={() => {
                  onCreateDineInButtonPress();
                }}
              >
                <div className="left-btn-content">
                  <img
                    alt="logo_color"
                    src={dineinicon}
                    className="pl-2"
                    style={{ color: `${menuData?.home_dine_in_logo_color}` }}
                  />
                  <span
                    className="pl-3"
                    style={{ color: `${menuData?.home_dine_in_text_color}` }}
                  >
                    Dine in
                  </span>
                </div>
                <div className="right-side-btn-content">
                  <i
                    className="fa fa fa-chevron-right pr-3"
                    style={{ color: `${menuData?.home_dine_in_logo_color}` }}
                  ></i>
                </div>
              </div>

              {/* </Link>
              <Link
                className="qr-scan-ordering-btn"
                to="restaurant-menu-categories"
              > */}
              <div
                className="qr-scan-ordering-btn"
                style={{
                  backgroundColor: menuData?.home_dine_in_btn_color,
                }}
                onClick={() => {
                  onCreateDeliveryButtonPress();
                }}
              >
                <div className="left-btn-content">
                  <img
                    alt="img"
                    src={deliveryicon}
                    className="pl-2"
                    style={{ color: `${menuData?.home_dine_in_logo_color}` }}
                  />
                  <span
                    className="pl-3"
                    style={{ color: `${menuData?.home_dine_in_text_color}` }}
                  >
                    Delivery
                  </span>
                </div>
                <div className="right-side-btn-content">
                  <i
                    className="fa fa fa-chevron-right pr-3"
                    style={{ color: `${menuData?.home_dine_in_logo_color}` }}
                  ></i>
                </div>
              </div>
              {/* </Link> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RestaurantHomePage;
