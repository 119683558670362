import { useState, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { transactionAction } from "../../../Store/Transaction/transactionAction";
import Loader from "../../Common/Loader";
import Dheader from "./dheader";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { formatDate, getStatus } from "../partials/status";

const Table = ({ redirectTo }) => {
  const [transaction, setTransaction] = useState([]);
  const [transactionBackup, setTransactionBackup] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [pageSize, setPageSize] = useState(0);

  const dispatch = useDispatch();
  useEffect(() => {
    loadData(null, null);
  }, []);

  const loadData = (page, search) => {
    setLoading(true);
    let link = null;
    if (page !== null) {
      link = `?${page}`;
    }
    if (search !== null) {
      link = page !== null ? `?${page}&&${search}` : `?${search}`;
    }
    dispatch(transactionAction.onGetTransaction(link)).then((res) => {
      if (res?.results) {
        setTransaction(res?.results);
        setTransactionBackup(res?.results);
        setPageSize(Math.ceil(res?.count / 10));
      }
      setLoading(false);
    });
  };

  const filter = (keyword) => {
    // if (keyword !== "") {
    //   const results = transactionBackup.filter((x) =>
    //     x?.order_id.includes(keyword)
    //   );
    //   setTransaction(results);
    // } else {
    //   setTransaction(transactionBackup);
    // }
    loadData(null, `search=${keyword}`);
    setSearch(keyword);
  };

  const handlePageChange = (a, page) => {
    setPageSize(page);
    loadData(`page=${page}`, null);
  };

  return (
    <>
      {isLoading ? <Loader /> : null}
      <Dheader
        search={search}
        setSearch={(e) => {
          filter(e);
        }}
      />
      <div className="row p-4 mx-2 white-bg rounded">
        {/* tbale */}
        <table className="table table-borderless">
          <thead>
            <tr>
              <th scope="col" className="mob-remove">
                {" "}
                Date{" "}
              </th>
              <th scope="col" className="mob-remove">
                Order ID
              </th>
              {/* <th scope="col">Table</th> */}
              {/* <th scope="col">Customer</th> */}
              <th scope="col">Transaction ID</th>
              <th scope="col" className="mob-remove">
                Amount
              </th>
              <th scope="col">Status Order</th>
              {/* <th scope="col">Action</th> */}
            </tr>
          </thead>
          <tbody>
            {transaction?.map((x, i) => {
              return (
                <tr key={i}>
                  <td scope="row" className="mob-remove">
                    {formatDate(x.timestamp)}
                  </td>
                  <td className="mob-remove">{x.transaction_id}</td>
                  <td>
                    <span
                      className="form-label"
                      style={{ cursor: "pointer", color: "#F9A05F" }}
                      onClick={() => {
                        redirectTo(x?.order_id);
                      }}
                    >
                      {x?.order_id}
                    </span>
                  </td>
                  {/* <td>023</td> */}
                  {/* <td>{x?.customer?.name}</td> */}
                  <td className="mob-remove">
                    {x?.currency}
                    {x?.amount}
                  </td>
                  <td>{getStatus(x.payment_status)}</td>
                  <td hidden>
                    <Dropdown>
                      <Dropdown.Toggle variant="filter" id="dropdown-basic">
                        <span className="filteroption">
                          <i class="far fa-ellipsis-h"></i>
                        </span>
                      </Dropdown.Toggle>

                      {/* <Dropdown.Menu>
                        <Dropdown.Item href="#/action-1">
                          Action 01
                        </Dropdown.Item>
                        <Dropdown.Item href="#/action-2">
                          Action 02
                        </Dropdown.Item>
                      </Dropdown.Menu> */}
                    </Dropdown>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {transaction?.length === 0 && (
          <span
            style={{
              display: "flex",
              justifyContent: "center",
              fontSize: "20px",
              marginBottom: "50px",
            }}
          >
            You haven’t any Transaction.
          </span>
        )}
        {pageSize > 1 && (
          <Stack spacing={2}>
            <Pagination count={pageSize} onChange={handlePageChange} />
          </Stack>
        )}
      </div>
    </>
  );
};

export default Table;
