import React from "react";
import { Link } from "react-router-dom";

const EditMenuNav = ({activeOption,setactive}) => {
	return(
		<>
        <div className="container">
            <div className="row">
            <div className="col-md-12">
            <ul className="dashbaord-edit-menu-nav">
                <li className={`${activeOption===0 ? "active-menu-nav" : ""}`}><span
                onClick={()=>setactive(0)}
                >Home Page</span></li>
                 <li className={`${activeOption===1 ? "active-menu-nav" : ""}`}><span
                onClick={()=>setactive(1)}
                >Menu Page & Section Pages</span></li>
                  <li className={`${activeOption===2 ? "active-menu-nav" : ""}`}><span
                onClick={()=>setactive(2)}                
                >Item Pages</span></li>
                 <li className={`${activeOption===3 ? "active-menu-nav" : ""}`}><span
                onClick={()=>setactive(3)}                
                >Pop-up Pages</span></li>
            </ul>
            </div>
            </div>
        </div>
		</>
	)
}
export default EditMenuNav;