import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Switch from "react-switch";
import { resturantActions } from "../../../Store/Resturant/resturantAction";
import { MdOutlinePlayCircleOutline } from "react-icons/md";

// export default Notification;
const Notification = () => {
  const { resturantSettingNotification, sounds } = useSelector(
    (state) => state.ResturantReducer
  );
  const dispatch = useDispatch();
  const playSound = (id, repeat) => {
    const selectedSound = sounds?.filter((r) => r.id == id)[0];
    const audio = new Audio(selectedSound.audio_file);
    audio.play();
  };
  return (
    <>
      <div className="row">
        <div className="col-md-8">
          <table className="table table-borderless">
            <thead>
              <tr>
                <th scope="col">Notification Sound</th>
                <th scope="col"></th>
                <th scope="col"></th>
                <th scope="col">Repeat</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Order Notification </td>
                <td>
                  <select
                    className="form-control input-filed"
                    value={
                      resturantSettingNotification?.order_notification_sound?.id
                    }
                    onChange={(e) => {
                      // const selectedSound = sounds?.filter(
                      //   (r) => r.id == e.target.value
                      // )[0];
                      // const audio = new Audio(selectedSound.audio_file);
                      // audio.play();
                      dispatch(
                        resturantActions.onChange(
                          "resturantSettingNotification",
                          {
                            ...resturantSettingNotification,
                            order_notification_sound: {
                              ...resturantSettingNotification.order_notification_sound,
                              id: parseInt(e.target.value),
                            },
                          }
                        )
                      );
                    }}
                  >
                    {sounds?.map((x, i) => {
                      return (
                        <option key={i} value={x?.id}>
                          {x?.name}
                        </option>
                      );
                    })}
                  </select>
                </td>
                <td>
                  <Switch
                    className="react-switch"
                    onChange={() => {
                      dispatch(
                        resturantActions.onChange(
                          "resturantSettingNotification",
                          {
                            ...resturantSettingNotification,
                            order_notification_sound_on:
                              !resturantSettingNotification?.order_notification_sound_on,
                          }
                        )
                      );
                    }}
                    checked={
                      resturantSettingNotification?.order_notification_sound_on
                    }
                    aria-label="super secret label that is not visible"
                  />
                </td>
                <td>
                  <input
                    type="number"
                    placeholder="0"
                    className="form-control input-filed number-field"
                    value={
                      resturantSettingNotification?.order_notification_sound_repeat
                    }
                    onChange={(e) => {
                      dispatch(
                        resturantActions.onChange(
                          "resturantSettingNotification",
                          {
                            ...resturantSettingNotification,
                            order_notification_sound_repeat: e.target.value,
                          }
                        )
                      );
                    }}
                  />
                </td>
                <td>
                  <MdOutlinePlayCircleOutline
                    style={{
                      color: "#00000",
                      fontSize: "30px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      playSound(
                        resturantSettingNotification?.order_notification_sound
                          ?.id,
                        resturantSettingNotification?.order_notification_sound_repeat
                      );
                    }}
                  />
                </td>
              </tr>

              <tr>
                <td>Service Notification </td>
                <td>
                  <select
                    className="form-control input-filed"
                    value={
                      resturantSettingNotification?.service_notification_sound
                        ?.id
                    }
                    onChange={(e) => {
                      dispatch(
                        resturantActions.onChange(
                          "resturantSettingNotification",
                          {
                            ...resturantSettingNotification,
                            service_notification_sound: {
                              ...resturantSettingNotification.service_notification_sound,
                              id: parseInt(e.target.value),
                            },
                          }
                        )
                      );
                    }}
                  >
                    {sounds?.map((x, i) => {
                      return (
                        <option key={i} value={x?.id}>
                          {x?.name}
                        </option>
                      );
                    })}
                  </select>
                </td>
                <td>
                  <Switch
                    className="react-switch"
                    onChange={() => {
                      dispatch(
                        resturantActions.onChange(
                          "resturantSettingNotification",
                          {
                            ...resturantSettingNotification,
                            service_notification_sound_on:
                              !resturantSettingNotification?.service_notification_sound_on,
                          }
                        )
                      );
                    }}
                    checked={
                      resturantSettingNotification?.service_notification_sound_on
                    }
                    aria-label="super secret label that is not visible"
                  />
                </td>
                <td>
                  <input
                    type="number"
                    placeholder="0"
                    className="form-control input-filed number-field"
                    value={
                      resturantSettingNotification?.service_notification_sound_repeat
                    }
                    onChange={(e) => {
                      dispatch(
                        resturantActions.onChange(
                          "resturantSettingNotification",
                          {
                            ...resturantSettingNotification,
                            service_notification_sound_repeat: e.target.value,
                          }
                        )
                      );
                    }}
                  />
                </td>
                <td>
                  <MdOutlinePlayCircleOutline
                    style={{
                      color: "#00000",
                      fontSize: "30px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      playSound(
                        resturantSettingNotification?.service_notification_sound
                          ?.id,
                        resturantSettingNotification?.service_notification_sound_repeat
                      );
                    }}
                  />
                </td>
              </tr>

              {/* <tr>
                <td>Panel Notification </td>
                <td>
                  <select
                    className="form-control input-filed"
                    value={
                      resturantSettingNotification?.panel_notification_sound?.id
                    }
                    onChange={(e) => {
                      const selectedSound = sounds?.filter(
                        (r) => r.id == e.target.value
                      )[0];
                      const audio = new Audio(selectedSound.audio_file);
                      audio.play();
                      dispatch(
                        resturantActions.onChange(
                          "resturantSettingNotification",
                          {
                            ...resturantSettingNotification,
                            panel_notification_sound: {
                              ...resturantSettingNotification.panel_notification_sound,
                              id: parseInt(e.target.value),
                            },
                          }
                        )
                      );
                    }}
                  >
                    {sounds?.map((x, i) => {
                      return (
                        <option key={i} value={x?.id}>
                          {x?.name}
                        </option>
                      );
                    })}
                  </select>
                </td>
                <td>
                  <Switch
                    className="react-switch"
                    onChange={() => {
                      dispatch(
                        resturantActions.onChange(
                          "resturantSettingNotification",
                          {
                            ...resturantSettingNotification,
                            panel_notification_sound_on:
                              !resturantSettingNotification?.panel_notification_sound_on,
                          }
                        )
                      );
                    }}
                    checked={
                      resturantSettingNotification?.panel_notification_sound_on
                    }
                    aria-label="super secret label that is not visible"
                  />
                </td>
                <td>
                  <input
                    type="number"
                    placeholder="0"
                    className="form-control input-filed number-field"
                    value={
                      resturantSettingNotification?.panel_notification_sound_repeat
                    }
                    onChange={(e) => {
                      dispatch(
                        resturantActions.onChange(
                          "resturantSettingNotification",
                          {
                            ...resturantSettingNotification,
                            panel_notification_sound_repeat: e.target.value,
                          }
                        )
                      );
                    }}
                  />
                </td>
              </tr>

              <tr>
                <td>Chat Notification</td>
                <td>
                  <select
                    className="form-control input-filed"
                    value={
                      resturantSettingNotification?.chat_notification_sound?.id
                    }
                    onChange={(e) => {
                      const selectedSound = sounds?.filter(
                        (r) => r.id == e.target.value
                      )[0];
                      const audio = new Audio(selectedSound.audio_file);
                      audio.play();
                      dispatch(
                        resturantActions.onChange(
                          "resturantSettingNotification",
                          {
                            ...resturantSettingNotification,
                            chat_notification_sound: {
                              ...resturantSettingNotification.chat_notification_sound,
                              id: parseInt(e.target.value),
                            },
                          }
                        )
                      );
                    }}
                  >
                    {sounds?.map((x, i) => {
                      return (
                        <option key={i} value={x?.id}>
                          {x?.name}
                        </option>
                      );
                    })}
                  </select>
                </td>
                <td>
                  <Switch
                    className="react-switch"
                    onChange={() => {
                      dispatch(
                        resturantActions.onChange(
                          "resturantSettingNotification",
                          {
                            ...resturantSettingNotification,
                            chat_notification_sound_on:
                              !resturantSettingNotification?.chat_notification_sound_on,
                          }
                        )
                      );
                    }}
                    checked={
                      resturantSettingNotification?.chat_notification_sound_on
                    }
                    aria-label="super secret label that is not visible"
                  />
                </td>
                <td>
                  <input
                    type="number"
                    placeholder="0"
                    className="form-control input-filed number-field"
                    value={
                      resturantSettingNotification?.chat_notification_sound_repeat
                    }
                    onChange={(e) => {
                      dispatch(
                        resturantActions.onChange(
                          "resturantSettingNotification",
                          {
                            ...resturantSettingNotification,
                            chat_notification_sound_repeat: e.target.value,
                          }
                        )
                      );
                    }}
                  />
                </td>
              </tr> */}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Notification;
