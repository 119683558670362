import React from "react";
import CommonHero from "../Helper/CommonHero";
import FooterOne from "../Partials/Footers/FooterOne";
import HeaderFive from "../Partials/Headers/HeaderFive";
import CommingSoon from "../Partials/CommingSoon";

function PrivacyPolicy() {
  return (
    <>
      <HeaderFive />
      <CommonHero title="Privacy Policy" breadcrumb={["Home", "Privacy Policy"]} />
      <CommingSoon />
      <FooterOne />
    </>
  );
}

export default PrivacyPolicy;
