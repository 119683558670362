import React, { useState } from "react";
import { useSelector } from "react-redux";

function Screen3({ template }) {
  const { menuData } = useSelector((state) => state.MenuReducer);
  return (
    <>
      <div className="screen-content">
        <div className="screen2-header">
          <div className="back-icon-area">
            <span>
              <i className="fa fa-chevron-left"></i>
            </span>
          </div>
          <div className="back-icon-area">
            <span>
              <i className="fa fa-heart"></i>
            </span>
          </div>
        </div>
        <div className="screen-search-area">
          <div className="input-group search-area menu-item-search-area">
            <span className="input-group-text">
              <i className="flaticon-381-search-2"></i>
            </span>
            <input
              type="text"
              className={`form-control`}
              placeholder="Search here..."
              disabled
            />
          </div>
        </div>
        <div
          className="popup-product-area-preview"
          style={{
            backgroundColor: `${menuData?.popup_background_color}`,
            color: `${menuData?.popup_text_color}`,
          }}
        >
          <div className="popup-header">
            <i className="fa fa-chevron-left"></i>
          </div>
          <div className="products-area-screen-3">
            <div
              className="product-block"
              style={{
                backgroundColor: menuData?.popup_product_bg_color,
                color: menuData?.popup_product_text_color,
              }}
            >
              <div className="product-block-left">
                <img
                  src="https://images.pexels.com/photos/291528/pexels-photo-291528.jpeg?auto=compress&cs=tinysrgb&w=800"
                  className="preview-product-image"
                ></img>
              </div>
              <div className="product-block-right">
                <div className="product-block-row1">
                  <div className="produtc-block-title-area">
                    <p className="product-name-block">Pizaa</p>
                    <p className="product-description-preview-block">
                      Onion, Capsicum, Corn, Cheese ( 100gm ), Spanish tomato,
                      Coriander
                    </p>
                  </div>
                  <div className="product-block-fav-area">
                    <div className="fav-btn-product">
                      <i className=""></i>
                    </div>
                  </div>
                </div>
                <div className="product-block-row2 product-time0-areea">
                  <div className="row2-left-about-product">
                    <ul>
                      <li>
                        <span className="product-time-icon">
                          <i className="fa fa-clock" aria-hidden="true"></i>
                        </span>
                        <span className="product-time-number">40min</span>
                      </li>
                      <li>
                        <span className="discount-product-info">20%</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="quality-area">
            <div className="quilaity-lable">
              <span
                style={{
                  color: `${menuData?.popup_text_color}`,
                }}
              >
                Quantity
              </span>
            </div>
            <div className="cart-qualitity-btn">
              <table>
                <tr>
                  <td>
                    <button className="preview-quality-btn">-</button>
                  </td>
                  <td>
                    <span className="preview-quality-number">1</span>
                  </td>
                  <td>
                    <button className="preview-quality-btn">+</button>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          {/* <div className="comment-area">
            <div className="comment-form-group">
              <lable
                style={{
                  color: `${menuData?.popup_text_color}`,
                }}
              >
                Any Special Requests?
              </lable>
              <textarea className="form-control"></textarea>
            </div>
          </div> */}
        </div>
        <div className="checkout-cart-area-preview">
          <div className="left-total-area">
            <span className="total-dim-text">Total Bill</span>
            <p className="footer-total-text">₦75.12</p>
          </div>
          <div className="right-side-btn">
            <button
              className="preview-button-theme"
              disabled
              style={{
                backgroundColor: `${menuData?.popup_cart_btn_color}`,
                color: `${menuData?.popup_cart_text_color}`,
              }}
            >
              <span className="btn-cart-ison">
                <i className="fa fa-shopping-cart" aria-hidden="true"></i>
              </span>
              Checkout(3)
            </button>
          </div>
        </div>
        <div className="preview-overlay-area-s3"></div>
      </div>
    </>
  );
}

export default Screen3;
