import { endpoints } from "../../Config/apiConfig";
import { axiosInstance } from "../../Config/axiosInstance";

const onGetTransaction = (filter) => async (dispatch) => {
  var res = await localStorage.getItem("res");
  var response = await axiosInstance.getApi(
    filter !== null
      ? `${endpoints.transaction.getRestaurantTransactions(res)}${filter}`
      : endpoints.transaction.getRestaurantTransactions(res)
  );
  return response;
};
export const transactionAction = {
  onGetTransaction: onGetTransaction,
};
