import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import QRCode from "react-qr-code";
import html2canvas from "html2canvas";
import GuideModel from "../partials/visitModel";
import question from "../../../assets/images/question.png";
import qr_guide from "../../../assets/images/qr_scan_guide.jpg";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
  alignItem: "center",
  display: "flex",
  flexDirection: "column",
};

const BaseModel = ({ isOpen, qrCodeLink = null, setIsOpen }) => {
  // const [isOpen, setIsOpen] = useState(false);

  const [open, setOpen] = useState(false);

  const closePopup = () => {
    setIsOpen(false);
  };

  const DownloadQr = (e) => {
    html2canvas(document.getElementById("qrcode")).then(function (canvas) {
      // document.body.appendChild(canvas);
      var a = document.createElement("a");
      a.href = canvas
        .toDataURL("..assets/image/jpeg")
        .replace("image/jpeg", "image/octet-stream");
      a.download = "QRCode.jpeg";
      a.click();
    });
  };

  const onCloseModel = () => {
    setOpen(false);
  };

  return (
    <>
      <GuideModel url={qr_guide} open={open} onCloseCallback={onCloseModel} />
      <Modal
        open={isOpen}
        onClose={() => closePopup()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} style={{ position: "relative" }}>
          <img
            className="question-mark"
            width={25}
            src={question}
            onClick={() => {
              setOpen(true);
            }}
            style={{ position: "absolute", right: "8px", top: "8px" }}
          />
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{ alignSelf: "center" }}
          >
            QR Code
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2 }}
            style={{ alignSelf: "center" }}
          >
            {qrCodeLink && (
              <>
                <div id="qrcode" style={{ width: "250px", height: "250px" }}>
                  <QRCode size={250} value={qrCodeLink} />
                </div>
              </>
            )}
          </Typography>
          <div className="row mt-2">
            <div className="col text-center">
              <button
                className="btn btn-warning small"
                style={{ width: "150px" }}
                onClick={() => {
                  closePopup();
                }}
              >
                Close
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default BaseModel;
