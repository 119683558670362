import React, { useState } from "react";
import addIcon from "../../../assets/images/dashboard/staff/addmember.png";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Button } from "bootstrap";

const Step01 = () => {
  const history = useHistory();
  let isSubscription = true;
  const subscription = JSON.parse(localStorage.getItem("subscription"));
  if (subscription) {
    isSubscription = subscription?.active === "active" ? false : true;
  }
  return (
    <>
      <div className="text-center y-4">
        <div className="mt-5">
          <img src={addIcon} />
        </div>
        <div className="py-5">
          <button
            className="btn-theme-main"
            disabled={isSubscription}
            onClick={() => {
              history.push("/add-professional-member");
            }}
          >
            Add Member
          </button>
        </div>
      </div>
    </>
  );
};
export default Step01;
