import React, { useState } from "react";
import Screen1 from "../../previewscreenscat/Screen1";
import Screen2 from "../../previewscreenscat/Screen2";
import Screen3 from "../../previewscreenscat/Screen3";
import Screen4 from "../../previewscreenscat/Screen4";
import { useDispatch } from "react-redux";
import { menuActions } from "../../../../../Store/Menu/menuAction";
import { useEffect } from "react";
import { catalogActions } from "../../../../../Store/Catalogs/catalogAction";

/// Images

const TemplatePreview = ({ toggle, closePreview, template, templateId }) => {
  const [currentScreen, setCurrentScreen] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(catalogActions.onGetCatalogById(parseInt(templateId))).then(
      (res) => {
        if (res) {
          loadData(res);
        }
      }
    );
  }, []);

  const loadData = (res) => {
    const data = {
      name: res?.name,
      description: res?.description,
      menu_type: res?.menu_type,
      home_background_image: res?.home_background_image,
      home_display_logo: res?.home_display_logo,
      home_display_image_background: res?.home_display_image_background,
      home_background_image_file: null,
      home_background_color: res?.home_background_color,
      home_restaurant_name_color: res?.home_restaurant_name_color,
      home_main_heading_color: res?.home_main_heading_color,
      home_sub_heading_color: res?.home_sub_heading_color,
      home_text_color: res?.home_text_color,
      home_dine_in_btn_color: res?.home_dine_in_btn_color,
      home_dine_in_text_color: res?.home_dine_in_text_color,
      home_dine_in_logo_color: res?.home_dine_in_logo_color,
      home_delivery_in_btn_color: res?.home_delivery_in_btn_color,
      home_delivery_in_text_color: res?.home_delivery_in_text_color,
      home_delivery_in_logo_color: res?.home_delivery_in_logo_color,
      menu_header_background_color: res?.menu_header_background_color,
      menu_header_text: res?.menu_header_text,
      menu_product_backgroud_color: res?.menu_product_backgroud_color,
      menu_product_text: res?.menu_product_text,
      menu_product_price: res?.menu_product_price,
      menu_product_icons: res?.menu_product_icons,
      menu_cart_background: res?.menu_cart_background,
      menu_cart_text_color: res?.menu_cart_text_color,
      menu_cart_price_color: res?.menu_cart_price_color,
      menu_category_tab_text_color: res?.menu_category_tab_text_color,
      menu_category_tab_color: res?.menu_category_tab_color,
      menu_delivery_text_background: res?.menu_delivery_text_background,
      menu_delivery_btn_color: res?.menu_delivery_btn_color,
      menu_delivery_icon_color: res?.menu_delivery_icon_color,
      item_product_background_color: res?.item_product_background_color,
      item_product_text_color: res?.item_product_text_color,
      item_product_price: res?.item_product_price,
      item_tip_text_color: res?.item_tip_text_color,
      item_tip_btn_color: res?.item_tip_btn_color,
      item_cart_text_color: res?.item_cart_text_color,
      item_cart_btn_color: res?.item_cart_btn_color,
      item_place_order_text_color: res?.item_place_order_text_color,
      item_place_order_btn_color: res?.item_place_order_btn_color,
      popup_background_color: res?.popup_background_color,
      popup_text_color: res?.popup_text_color,
      popup_product_bg_color: res?.popup_product_bg_color,
      popup_product_text_color: res?.popup_product_text_color,
      popup_cart_btn_color: res?.popup_cart_btn_color,
      popup_cart_text_color: res?.popup_cart_text_color,
    };
    dispatch(menuActions.onChange("menuData", data));
    setLoading(true);
  };

  const handlebackbtn = () => {
    if (currentScreen > 0) {
      setCurrentScreen(currentScreen - 1);
    }
  };
  const handlenextbtn = () => {
    if (currentScreen < 3) {
      setCurrentScreen(currentScreen + 1);
    }
  };
  return (
    <>
      {isLoading && (
        <div className={`chatbox ${toggle ? "active" : ""}`}>
          <div
            className="chatbox-close"
            onClick={() => closePreview(false)}
          ></div>
          <div className="preview-area">
            <div className="main-content">
              <div className="left-area" onClick={handlebackbtn}>
                <i class="fa fa-chevron-left"></i>
              </div>
              <div className="screen-area">
                {currentScreen === 0 ? <Screen1 template={template} /> : ""}
                {currentScreen === 1 ? <Screen2 template={template} /> : ""}
                {currentScreen === 2 ? <Screen3 template={template} /> : ""}
                {currentScreen === 3 ? <Screen4 template={template} /> : ""}
              </div>
              <div className="right-area" onClick={handlenextbtn}>
                <i class="fa fa-chevron-right"></i>
              </div>
            </div>
            <div className="content-bottom">
              <div className="template-detail-name">
                <p>{template?.title}</p>
                <p>
                  {template?.number} - {template?.date}
                </p>
              </div>
              <ul className="dots-list">
                <li>
                  <span
                    className={`dot-area ${
                      currentScreen === 0 ? "active-dot" : ""
                    }`}
                    onClick={() => setCurrentScreen(0)}
                  ></span>
                </li>
                <li>
                  <span
                    className={`dot-area ${
                      currentScreen === 1 ? "active-dot" : ""
                    }`}
                    onClick={() => setCurrentScreen(1)}
                  ></span>
                </li>
                <li>
                  <span
                    className={`dot-area ${
                      currentScreen === 2 ? "active-dot" : ""
                    }`}
                    onClick={() => setCurrentScreen(2)}
                  ></span>
                </li>
                <li>
                  <span
                    className={`dot-area ${
                      currentScreen === 3 ? "active-dot" : ""
                    }`}
                    onClick={() => setCurrentScreen(3)}
                  ></span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TemplatePreview;
