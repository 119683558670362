import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { RoutesObj } from "../Routers";

function Routers() {
  const authComponent = (componentObj) => {
    const isAccessToken = localStorage.getItem("access_token");
    if (isAccessToken) {
      return componentObj;
    } else {
      return <Redirect to="/" />;
    }
  };
  return (
    <Router>
      <Switch>
        <Route
          exact
          path={RoutesObj.HomeFive.path}
          render={() => RoutesObj.HomeFive.component}
        />
        {/* <Route
          exact
          path={RoutesObj.Login.path}
          render={() => RoutesObj.Login.component}
        /> */}
        <Route
          exact
          path={RoutesObj.QrCodeMenu.path}
          render={() => RoutesObj.QrCodeMenu.component}
        />
        <Route
          exact
          path={RoutesObj.ScantoPay.path}
          render={() => RoutesObj.ScantoPay.component}
        />
        <Route
          exact
          path={RoutesObj.PrivacyPolicy.path}
          render={() => RoutesObj.PrivacyPolicy.component}
        />
        <Route
          exact
          path={RoutesObj.TermsConditions.path}
          render={() => RoutesObj.TermsConditions.component}
        />
        <Route
          exact
          path={RoutesObj.Contact.path}
          render={() => RoutesObj.Contact.component}
        />
        <Route
          exact
          path={RoutesObj.Dashboard.path}
          render={() => authComponent(RoutesObj.Dashboard.component)}
        />
        <Route
          exact
          path={RoutesObj.BuildMenu.path}
          element={RoutesObj.BuildMenu.component}
          render={() => authComponent(RoutesObj.BuildMenu.component)}
        />
        <Route
          exact
          path={RoutesObj.BuildCatalog.path}
          element={RoutesObj.BuildCatalog.component}
          render={() => authComponent(RoutesObj.BuildCatalog.component)}
        />
        <Route
          exact
          path={RoutesObj.MenuHome.path}
          element={RoutesObj.MenuHome.component}
          render={() => authComponent(RoutesObj.MenuHome.component)}
        />
        <Route
          exact
          path={RoutesObj.CatHome.path}
          element={RoutesObj.CatHome.component}
          render={() => authComponent(RoutesObj.CatHome.component)}
        />
        <Route
          exact
          path={RoutesObj.AllMenu.path}
          element={RoutesObj.AllMenu.component}
          render={() => authComponent(RoutesObj.AllMenu.component)}
        />
        <Route
          exact
          path={RoutesObj.CatAllMenu.path}
          element={RoutesObj.CatAllMenu.component}
          render={() => authComponent(RoutesObj.CatAllMenu.component)}
        />
        <Route
          exact
          path={RoutesObj.EditTemplate.path}
          element={RoutesObj.EditTemplate.component}
          render={() => authComponent(RoutesObj.EditTemplate.component)}
        />

        <Route
          exact
          path={RoutesObj.EditCatTemplate.path}
          element={RoutesObj.EditCatTemplate.component}
          render={() => authComponent(RoutesObj.EditCatTemplate.component)}
        />

        <Route
          exact
          path={RoutesObj.Staff.path}
          element={RoutesObj.Staff.component}
          render={() => authComponent(RoutesObj.Staff.component)}
        />
        <Route
          exact
          path={RoutesObj.AddMember.path}
          element={RoutesObj.AddMember.component}
          render={() => authComponent(RoutesObj.AddMember.component)}
        />
        <Route
          exact
          path={RoutesObj.ViewMember.path}
          element={RoutesObj.ViewMember.component}
          render={() => authComponent(RoutesObj.ViewMember.component)}
        />
        <Route
          exact
          path={RoutesObj.StaffMember.path}
          element={RoutesObj.StaffMember.component}
          render={() => authComponent(RoutesObj.StaffMember.component)}
        />
        <Route
          exact
          path={RoutesObj.AllStaffMembers.path}
          element={RoutesObj.AllStaffMembers.component}
          render={() => authComponent(RoutesObj.AllStaffMembers.component)}
        />

        <Route
          exact
          path={RoutesObj.Professional.path}
          element={RoutesObj.Professional.component}
          render={() => authComponent(RoutesObj.Professional.component)}
        />
        <Route
          exact
          path={RoutesObj.ProfessionalAddMember.path}
          element={RoutesObj.ProfessionalAddMember.component}
          render={() =>
            authComponent(RoutesObj.ProfessionalAddMember.component)
          }
        />
        <Route
          exact
          path={RoutesObj.ProfessionalViewMember.path}
          element={RoutesObj.ProfessionalViewMember.component}
          render={() =>
            authComponent(RoutesObj.ProfessionalViewMember.component)
          }
        />
        <Route
          exact
          path={RoutesObj.ProfessionalMember.path}
          element={RoutesObj.ProfessionalMember.component}
          render={() => authComponent(RoutesObj.ProfessionalMember.component)}
        />
        <Route
          exact
          path={RoutesObj.AllProfessionalMembers.path}
          element={RoutesObj.AllProfessionalMembers.component}
          render={() =>
            authComponent(RoutesObj.AllProfessionalMembers.component)
          }
        />

        <Route
          exact
          path={RoutesObj.Tables.path}
          element={RoutesObj.Tables.component}
          render={() => authComponent(RoutesObj.Tables.component)}
        />
        <Route
          exact
          path={RoutesObj.Orders.path}
          element={RoutesObj.Orders.component}
          render={() => authComponent(RoutesObj.Orders.component)}
        />
        <Route
          exact
          path={RoutesObj.Appointments.path}
          element={RoutesObj.Appointments.component}
          render={() => authComponent(RoutesObj.Appointments.component)}
        />
        <Route
          exact
          path={RoutesObj.Transactions.path}
          element={RoutesObj.Transactions.component}
          render={() => authComponent(RoutesObj.Transactions.component)}
        />
        <Route
          exact
          path={RoutesObj.CatTransactions.path}
          element={RoutesObj.CatTransactions.component}
          render={() => authComponent(RoutesObj.CatTransactions.component)}
        />
        <Route
          exact
          path={RoutesObj.Reports.path}
          element={RoutesObj.Reports.component}
          render={() => authComponent(RoutesObj.Reports.component)}
        />
        <Route
          exact
          path={RoutesObj.Settings.path}
          element={RoutesObj.Settings.component}
          render={() => authComponent(RoutesObj.Settings.component)}
        />
        <Route
          exact
          path={RoutesObj.QrCode.path}
          element={RoutesObj.QrCode.component}
          render={() => authComponent(RoutesObj.QrCode.component)}
        />
        <Route
          exact
          path={RoutesObj.UserManagement.path}
          element={RoutesObj.UserManagement.component}
          render={() => authComponent(RoutesObj.UserManagement.component)}
        />
        <Route
          exact
          path={RoutesObj.MyAccount.path}
          element={RoutesObj.MyAccount.component}
          render={() => authComponent(RoutesObj.MyAccount.component)}
        />
        <Route
          exact
          path={RoutesObj.Subscription.path}
          element={RoutesObj.Subscription.component}
          render={() => authComponent(RoutesObj.Subscription.component)}
        />
        <Route
          exact
          path={[
            RoutesObj.RestaurantHomePage.path,
            `${RoutesObj.RestaurantHomePage.path}/:slug`,
          ]}
          render={() => RoutesObj.RestaurantHomePage.component}
        />
        <Route
          exact
          path={[
            RoutesObj.RestaurantCategoriesPage.path,
            `${RoutesObj.RestaurantCategoriesPage.path}/:slug`,
          ]}
          //element={RoutesObj.RestaurantCategoriesPage.component}
          render={() => RoutesObj.RestaurantCategoriesPage.component}
        />
        <Route
          exact
          path={RoutesObj.Tip.path}
          element={RoutesObj.Tip.component}
          render={() => RoutesObj.Tip.component}
        />
        <Route
          exact
          path={RoutesObj.Pricing.path}
          element={RoutesObj.Pricing.component}
          render={() => RoutesObj.Pricing.component}
        />
        <Route
          exact
          path={RoutesObj.Pay.path}
          element={RoutesObj.Pay.component}
          render={() => RoutesObj.Pay.component}
        />
        <Route
          exact
          path={RoutesObj.Giveaway.path}
          element={RoutesObj.Giveaway.component}
          render={() => RoutesObj.Giveaway.component}
        />
        <Route
          exact
          path={RoutesObj.Calendar.path}
          element={RoutesObj.Calendar.component}
          render={() => RoutesObj.Calendar.component}
        />
        <Route
          exact
          path={RoutesObj.BookAppointment.path}
          element={RoutesObj.BookAppointment.component}
          render={() => RoutesObj.BookAppointment.component}
        />
        <Route
          exact
          path={RoutesObj.DetailNotification.path}
          element={RoutesObj.DetailNotification.component}
          render={() => RoutesObj.DetailNotification.component}
        />
        <Route
          exact
          path={[
            RoutesObj.BusinessHomePage.path,
            `${RoutesObj.BusinessHomePage.path}/:slug`,
          ]}
          render={() => RoutesObj.BusinessHomePage.component}
        />
        <Route
          exact
          path={RoutesObj.BusinessCategoriesPage.path}
          element={RoutesObj.BusinessCategoriesPage.component}
          render={() => RoutesObj.BusinessCategoriesPage.component}
        />
        <Route
          exact
          path={RoutesObj.BusinessProfessionalPage.path}
          element={RoutesObj.BusinessProfessionalPage.component}
          render={() => RoutesObj.BusinessProfessionalPage.component}
        />
        <Route
          exact
          path={RoutesObj.BusinessPay.path}
          element={RoutesObj.BusinessPay.component}
          render={() => RoutesObj.BusinessPay.component}
        />
        <Route
          exact
          path={RoutesObj.BusinessOrderDetail.path}
          element={RoutesObj.BusinessOrderDetail.component}
          render={() => RoutesObj.BusinessOrderDetail.component}
        />
        <Route
          exact
          path={RoutesObj.AppointmentDetail.path}
          element={RoutesObj.AppointmentDetail.component}
          render={() => RoutesObj.AppointmentDetail.component}
        />
      </Switch>
    </Router>
  );
}
export default Routers;
