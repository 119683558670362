import React, { useEffect, useState } from "react";
import { LineChart, Line, XAxis, YAxis } from "recharts";

const SalesChart = ({ salesGraph }) => {
  const data = [];
  salesGraph?.sales_graph?.current?.map((x) => {
    data.push({ name: x?.date, uv: x?.value, pv: x?.value, amt: x?.value });
  });

  return (
    <>
      <div className="continaer">
        <div className="row">
          <div className="col-md-12">
            <h3
              className="inner-header"
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >
              Sales
            </h3>
            <div className="chart-block">
              <div className="row">
                <div className="col-md-4">
                  <h5>Overview</h5>
                </div>
                <div className="col-md-8">
                  {/* <ul className="graph-indication">
                    <li>
                      <span className="color-code-red">Red</span> This Month
                    </li>
                    <li>
                      <span className="color-code-blue">Blue</span> Last Month
                    </li>
                  </ul> */}
                </div>
              </div>
              <div style={{ width: "100%" }}>
                <LineChart
                  width={500}
                  height={300}
                  data={data}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Line
                    type="monotone"
                    dataKey="pv"
                    stroke="#8884d8"
                    activeDot={{ r: 8 }}
                  />
                  <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
                </LineChart>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SalesChart;
