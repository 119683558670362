import React, { useState } from "react";
import "./qrstyle.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useEffect } from "react";
import Loader from "../../Common/Loader";
import { catalogActions } from "../../../Store/Catalogs/catalogAction";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { menuActions } from "../../../Store/Menu/menuAction";

const Calendar1 = () => {
  const [value, setValue] = useState(new Date());
  const [isLoading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);
  const [sechedule, setSchedule] = useState([]);
  const [sechedulebk, setSchedulebk] = useState([]);
  const [selectedObjects, setSelectedObjects] = useState();
  const history = useHistory();
  const dispatch = useDispatch();
  const { menuData } = useSelector((state) => state.MenuReducer);
  var bus = parseInt(window.location.href.split("?bus=")[1]);
  var na = window.location.href.split("&na=")[1];
  var st = parseInt(
    window.location.href.split("&st=")[1]
      ? window.location.href.split("&st=")[1]
      : 0
  );
  var pr = parseInt(window.location.href.split("&pr=")[1]);
  var orderType = parseInt(window.location.href.split("&ordertype=")[1]);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const ser = urlParams.get("ser");
  useEffect(() => {
    let d = new Date();
    const year = d.getFullYear();
    const month = d.getMonth() + 1;
    const date = d.getDate();
    // const year = d.getUTCFullYear();
    // const date = d.getUTCDate();
    // const month = d.getUTCMonth() + 1;
    loadData(`${year}-${month}-${date}`);
  }, []);

  useEffect(() => {
    dispatch(catalogActions.onGetDefaultBusiness(bus)).then((res) => {
      if (res?.id) {
        const data = {
          name: res?.name,
          description: res?.description,
          menu_type: res?.menu_type,
          home_background_image: res?.home_background_image,
          home_display_logo: res?.home_display_logo,
          home_display_image_background: res?.home_display_image_background,
          home_background_image_file: null,
          home_background_color: res?.home_background_color,
          home_restaurant_name_color: res?.home_restaurant_name_color,
          home_main_heading_color: res?.home_main_heading_color,
          home_sub_heading_color: res?.home_sub_heading_color,
          home_text_color: res?.home_text_color,
          home_dine_in_btn_color: res?.home_dine_in_btn_color,
          home_dine_in_text_color: res?.home_dine_in_text_color,
          home_dine_in_logo_color: res?.home_dine_in_logo_color,
          home_delivery_in_btn_color: res?.home_delivery_in_btn_color,
          home_delivery_in_text_color: res?.home_delivery_in_text_color,
          home_delivery_in_logo_color: res?.home_delivery_in_logo_color,
          menu_header_background_color: res?.menu_header_background_color,
          menu_header_text: res?.menu_header_text,
          menu_product_backgroud_color: res?.menu_product_backgroud_color,
          menu_product_text: res?.menu_product_text,
          menu_product_price: res?.menu_product_price,
          menu_product_icons: res?.menu_product_icons,
          menu_cart_background: res?.menu_cart_background,
          menu_cart_text_color: res?.menu_cart_text_color,
          menu_cart_price_color: res?.menu_cart_price_color,
          menu_category_tab_text_color: res?.menu_category_tab_text_color,
          menu_category_tab_color: res?.menu_category_tab_color,
          menu_delivery_text_background: res?.menu_delivery_text_background,
          menu_delivery_btn_color: res?.menu_delivery_btn_color,
          menu_delivery_icon_color: res?.menu_delivery_icon_color,
          item_product_background_color: res?.item_product_background_color,
          item_product_text_color: res?.item_product_text_color,
          item_product_price: res?.item_product_price,
          item_tip_text_color: res?.item_tip_text_color,
          item_tip_btn_color: res?.item_tip_btn_color,
          item_cart_text_color: res?.item_cart_text_color,
          item_cart_btn_color: res?.item_cart_btn_color,
          item_place_order_text_color: res?.item_place_order_text_color,
          item_place_order_btn_color: res?.item_place_order_btn_color,
          popup_background_color: res?.popup_background_color,
          popup_text_color: res?.popup_text_color,
          popup_product_bg_color: res?.popup_product_bg_color,
          popup_product_text_color: res?.popup_product_text_color,
          popup_cart_btn_color: res?.popup_cart_btn_color,
          popup_cart_text_color: res?.popup_cart_text_color,
        };
        dispatch(menuActions.onChange("menuData", data));
      }
    });
  }, []);

  const getTime = (timeString) => {
    const [hour, minute] = timeString.split(":").map(Number);
    const period = hour < 12 ? "AM" : "PM";

    let hourIn12Hour = hour % 12;
    if (hourIn12Hour === 0) {
      hourIn12Hour = 12;
    }

    const timeIn12Hour = `${hourIn12Hour}:${
      minute < 10 ? "0" + minute : minute
    } ${period}`;

    return timeIn12Hour;
  };

  const loadData = (date) => {
    setLoading(true);
    dispatch(catalogActions.onGetAssignProfessionalSchedule(pr, date)).then(
      (data) => {
        if (data?.length > 0) {
          dispatch(catalogActions.onGetProfessionalLeaves(pr, date)).then(
            (rec) => {
              setLoading(false);
              if (rec?.results?.length > 0) {
                const recStartTimes = rec?.results.map((item) =>
                  item.start_time.slice(0, 5)
                );
                const updatedFirstArray = data.map((item) => ({
                  ...item,
                  // available: !recStartTimes.includes(item.start_time),
                  leave: !recStartTimes.includes(item.start_time),
                }));
                setSchedule(updatedFirstArray);
                setSchedulebk(updatedFirstArray);
                // if (isBackup) {
                // }
              } else {
                setSchedule(data);
                setSchedulebk(data);
                // if (isBackup) {
                // }
              }
            }
          );
        }
      }
    );
    // setLoading(true);
    // dispatch(catalogActions.onGetAssignProfessionalSchedule(pr, date)).then(
    //   (data) => {
    //     if (data?.length > 0) {
    //       setSchedule(data);
    //       setSchedulebk(data);
    //     }
    //     setLoading(false);
    //   }
    // );
  };
  return (
    <>
      {isLoading ? <Loader /> : null}
      <div
        className="qr-mobile-screen"
        style={{ backgroundColor: `${menuData?.home_background_color}` }}
      >
        <div className="container-fluid">
          <div className="row pt-5 black-bg px-2">
            <div className="col-md-4 col-2">
              <i
                class="fas fa-chevron-left top-arrow orange-text pl-1 pt-0 mt-0"
                onClick={() => {
                  history.push(
                    `/business-menu-categories?bus=${bus}&st=${st}&pr=${pr}&ordertype=${orderType}&na=${
                      na.split("&ser=")[0]
                    }&ser=${ser}`
                  );
                }}
              ></i>
            </div>
            <div className="col-md-8 col-8 text-center">
              <p className="white-text">Choose a Time</p>
            </div>
            <div className="col-md-2 col-2"></div>
          </div>
        </div>

        <div
          className="white-bg custom-calander"
          style={{ backgroundColor: `${menuData?.home_background_color}` }}
        >
          <DatePicker
            selected={value}
            onChange={(d) => {
              // const year = d.getUTCFullYear();
              // const date = d.getUTCDate();
              // const month = d.getUTCMonth() + 1;
              const year = d.getFullYear();
              const month = d.getMonth() + 1;
              const date = d.getDate();
              setValue(d);
              loadData(`${year}-${month}-${date}`);
            }}
            minDate={new Date()}
            inline
          />
          {/* <Calendar
          value={value}
          onChange={(d) => {
            const year = d.getFullYear();
            const date = d.getDate();
            const month = d.getUTCMonth();
            setValue(d);
            loadData(`${year}-${month}-${date}`);
          }}
          minDate={new Date()}
        /> */}
        </div>

        <div
          className="slots"
          style={{ backgroundColor: `${menuData?.home_background_color}` }}
        >
          <p className="font-weight-bold pl-3">Availale slots</p>
          <p cassName="px-3" style={{ textAlign: "center" }}>
            {sechedule?.map((x, i) => {
              return (
                <span
                  className={`time ${x?.available ? "active" : ""} ${
                    i == selected ? "selected" : ""
                  }`}
                  style={{ paddingLeft: "20px", paddingRight: "20px" }}
                  onClick={() => {
                    var slot = sechedulebk.filter(
                      (y) => y.start_time === x?.start_time
                    )[0];
                    if (
                      slot.available &&
                      (slot.leave || typeof x?.leave === "undefined")
                    ) {
                      setSelectedObjects(x?.start_time);
                      const newArray = sechedulebk.map((item) => ({
                        ...item,
                      }));
                      setSelected(i);
                      newArray[i].available = !x?.available;
                      setSchedule(newArray);
                    }
                  }}
                >
                  {getTime(x?.start_time)}
                </span>
              );
            })}
            {/* <span className="time">08:00 am</span>
            <span className="time">09:00 am</span>
            <span className="time">10:00 am</span>
            <span className="time">11:00 am</span>
            <span className="time">12:00 am</span>
            <span className="time">01:00 pm</span>
            <span className="time">02:00 pm</span>
            <span className="time">03:00 pm</span>
            <span className={`time  `}>04:00 pm</span>
            <span className="time active">05:00 pm</span>
            <span className="time">06:00 pm</span>
            <span className="time ">07:00 pm</span>
            <span className="time">08:00 pm</span>
            <span className="time">09:00 pm</span>
            <span className="time">10:00 pm</span>
            <span className="time">11:00 pm</span> */}
          </p>
        </div>

        <div className="row m-0 p-0 py-4 black-bg cart-ribbon">
          <div className="col-12 text-center">
            <button
              className="btn my-btn mobile-btn"
              onClick={() => {
                if (selectedObjects) {
                  const year = value.getFullYear();
                  const date = String(value.getDate()).padStart(2, "0");
                  const month = String(value.getMonth() + 1).padStart(2, "0");

                  // var date1 = `${year}-${month}-${date}`;
                  var date1 = `${year}-${month}-${date}`;
                  let dateToCheck = new Date(date1);
                  dateToCheck.setHours(selectedObjects.split(":")[0]);
                  dateToCheck.setMinutes(0);
                  dateToCheck.setSeconds(0);
                  const now = new Date();
                  //  if (dateToCheck.getTime() > now.getTime()) {

                  const dateTimeStr = `${year}-${month}-${date}T${selectedObjects}Z`;
                  // console.log("Date String:", dateTimeStr);

                  history.push(
                    `/business-pay?bus=${bus}&st=${st}&pr=${pr}&ordertype=${orderType}&na=${
                      na.split("&ser=")[0]
                    }&ser=${ser}&da=${dateTimeStr}&ti=${selectedObjects}`
                  );
                  // } else {
                  //   toast.error("Please select the valid date");
                  // }
                } else {
                  toast.error("Please select time");
                }
              }}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Calendar1;
