
import React, { useState } from "react";
import DeshboardLayout from "../layouts/DeshboardLayout";
import Dheader from "../staff/DheaderAdd";
// import InfoBlocks from "./main/InfoBlocks";
import Member from "../staff/Member";

 import "../../../assets/admin//vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "../../../assets/admin/css/style.css";


const DashboardHome = () => {
	const [toggle, setToggle] = useState("");
	const onClick = (name) => setToggle(toggle === name ? "" : name);
	return(
		<>
		    <div id="main-wrapper" class="show">
			 <DeshboardLayout />
			 <div class="content-body dashboard-conainer">
			 <Dheader />
             
            {/* page content     */}
            <div className="container">
				<div className="row">
					<div className="col-md-12">
						<Member />
						{/* <SalesChart /> */}
					</div>
				</div>
			</div>
			 {/* page content     */}
			  </div>	
			 </div>
		</>
	)
}
export default DashboardHome;