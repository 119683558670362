import React from "react";
import RatingReviewsAnimations from "./RatingReviewsAnimations";

function RatingReviews() {

  return (
    <section className="about-2-area about-5-area">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
          <RatingReviewsAnimations />
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="about-5-content">
              <h3 className="title">Rating and Reviews</h3>
              <div className="text">
                <p>
                Now your customers will be able to rate your employees and your brand immediately after payment. The client can rate and write a comment. You will instantly receive feedback from your customers and will be able to respond to your customer just as instantly.
                </p>
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default RatingReviews;
