import React from "react";
import HairSaloonAnimation from "./HairSaloonAnimation";

function HairSaloon() {
  return (
    <section className="about-2-area about-5-area">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="about-5-content">
              <h3 className="title">Hair Salon Online Booking</h3>
              <div className="text">
                <p>
                  Using Salon Management App will allow you to pick a time with
                  your preferred staff at your convenience. You no longer need
                  to wait for someone to respond. Our hair salon management
                  software allows you to see who is working that day and if they
                  are available to serve. Put your time to better use with us
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <HairSaloonAnimation />
          </div>
        </div>
      </div>
    </section>
  );
}

export default HairSaloon;
