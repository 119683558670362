import React, { useEffect } from "react";
import { accountActions } from "../../../../Store/Accounts/accountAction";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";

function Notifications({ recrods }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const isRead = (x) => {
    dispatch(accountActions.onSetReadNotifications(x?.id)).then((data) => {
      if (
        x?.metadata?.type === "appointment_tip" ||
        x?.metadata?.type === "appointment" ||
        x?.metadata?.type === "appointment_transaction"
      ) {
        history.push(`/appointment-detail?id=${x?.metadata?.order_id}`);
      }
      if (
        x?.metadata?.type === "order_tip" ||
        x?.metadata?.type === "order" ||
        x?.metadata?.type === "order_transaction"
      ) {
        history.push(`/order-detail?id=${x?.metadata?.order_id}`);
      }
      if (
        x?.metadata?.type === "business_subscription" ||
        x?.metadata?.type === "restaurant_subscription"
      ) {
        history.push(`/subscription`);
      }
    });
  };
  return (
    <>
      <ul className="timeline p-l-0">
        {recrods?.map((x) => {
          return (
            <li>
              <div className="timeline-panel" style={{ cursor: "pointer" }}>
                <div
                  className="notification-link"
                  onClick={() => {
                    isRead(x);
                  }}
                >
                  <div className="media-body">
                    <h6 className="mb-1">{x?.message}</h6>
                    <small className="d-block">{`${
                      x.created_at.split("T")[0]
                    } ${x.created_at.split("T")[1].split(".")[0]}`}</small>
                  </div>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </>
  );
}

export default Notifications;
