//Production api link:
// const rootURL = "";
//Dev URL
//export const rootURL = "http://ec2-54-64-215-232.ap-northeast-1.compute.amazonaws.com";
const host = process.env.REACT_APP_API_URL;
const protocole = process.env.REACT_APP_API_PROTOCOL;
const socketProtocole = "wss";
//Local
// const socketProtocole = "ws";
// const protocole = "http";
// const host = "localhost:9000";

export const rootURL = `${protocole}://${host}`;
export const loginSocket = `${socketProtocole}://${host}/ws/qr_login/`;
export const notificationSocket = `${socketProtocole}://${host}/ws/notification/?user_id=${localStorage.getItem(
  "user_id"
)}`;

// export const rootURL = "http://localhost:9000";
export const endpoints = {
  accounts: {
    login: `${rootURL}/auth/jwt/create`,
    //profile: `${rootURL}/auth/users/me`,
    profile: `${rootURL}/api/v1/userprofile/`,
    updateProfile: `${rootURL}/api/v1/userprofile/`,
    getNotifications: `${rootURL}/api/v1/notifications`,
    setReadNotifications: `${rootURL}/api/v1/set_read_notifications`,
    packages: `${rootURL}/api/v1/packages`,
    trialPackages: `${rootURL}/api/v1/packages/trial`,
    transactionsPackages: `${rootURL}/api/v1/package/transactions`,
  },
  resturantApi: {
    createRestaurant: `${rootURL}/api/v1/restaurants/`,
    restaurantByVenu: `${rootURL}/api/v1/business/venu/`,
    getLoggedUserResturant: `${rootURL}/api/v1/user/restaurant`,
    getLoggedUserDetail: `${rootURL}/api/v1/user/business`,
    getWorkers: (bran) => `${rootURL}/api/v1/branches/${bran}/workers`,
    createWorkers: (bran) => `${rootURL}/api/v1/branches/${bran}/workers/`,
    updateWorkers: (bran) => `${rootURL}/api/v1/branches/${bran}/workers/`,
    deleteWorkers: (bran) => `${rootURL}/api/v1/branches/${bran}/workers/`,
    scheduleWorkers: `${rootURL}/api/v1/workers/`,
    workersPositions: `${rootURL}/api/v1/worker-positions`,
    restaurantItem: (res) => `${rootURL}/api/v1/restaurants/${res}/items/`,
    updateRestaurant: `${rootURL}/api/v1/restaurants/update/`,
    /// updateRestaurant: `${rootURL}api/v1/user/business/update/`,
    dineIn: (bran) =>
      `${rootURL}/api/v1/branches/${bran}/menus/default/dine_in`,
    delivery: (bran) =>
      `${rootURL}/api/v1/branches/${bran}/menus/default/delivery`,
    createStaffDineIn: (res) =>
      `${rootURL}/api/v1/restaurant/${res}/cart/create/`,
    createStaffDelivery: (res) =>
      `${rootURL}/api/v1/restaurant/${res}/cart/create/`,
  },
  menuTemplateApi: {
    getAllTheme: (type) => `${rootURL}/api/v1/menu_themes?menu_type=${type}`,
    getThemeById: `${rootURL}/api/v1/menu_themes`,
    menuThemesCategory: `${rootURL}/api/v1/menu_themes_category/?menu_type=`,
    getBranchMenu: (bran) => `${rootURL}/api/v1/branches/${bran}/menus`,
  },
  itemCategoryApi: {
    getAllItemCategory: `${rootURL}/api/v1/item-categories`,
    getItemCategoryById: `${rootURL}/api/v1/item-categories/`,
  },
  MenuApi: {
    getAllMenu: (bran) =>
      `${rootURL}/api/v1/branches/${localStorage.getItem(
        "bran"
      )}/menus/?format=json`,
    getMenuById: (id, bran) =>
      `${rootURL}/api/v1/branches/${bran}/menus/${id}/`,
    createMenu: (bran) => `${rootURL}/api/v1/branches/${bran}/menus/`,
    UpdateMenu: (id, bran) => `${rootURL}/api/v1/branches/${bran}/menus/${id}/`,
    section: (sec) => `${rootURL}/api/v1/menus/${sec}/sections/`,
    item: (sec) => `${rootURL}/api/v1/sections/${sec}/items`,
    itemCategory: `${rootURL}/api/v1/item-categories`,
    getAllProducts: `${rootURL}/api/v1/products`,
    deleteMenu: (id, bran) => `${rootURL}/api/v1/branches/${bran}/menus/${id}/`,
    isFirstTimeMenuOpen: (acroynm) => `${rootURL}/api/v1/branches/${acroynm}/`,
  },
  orderApi: {
    getAllOrder: (res) => `${rootURL}/api/v1/restaurants/${res}/orders/`,
    orderStatus: `${rootURL}/api/v1/orders/`,
    setOrderTip: `${rootURL}/api/v1/order/tip/`,
    setAppointmentTip: `${rootURL}/api/v1/appointment/tip/`,
    orderWayCash: `${rootURL}/api/v1/restaurant/cash/pay/`,
    appointmentWayCash: `${rootURL}/api/v1/business/cash/pay/`,
    appointmentPayPush: (orderId) => `${rootURL}/api/v1/business/pay_via_push/${orderId}`,
    getAppointmentDetail: `${rootURL}/api/v1/appointment/`,
    getOrderDetail: `${rootURL}/api/v1/order/`,
  },
  settingApi: {
    getRestaurant: `${rootURL}/api/v1/user/restaurant`,
    getBusinessTypes: `${rootURL}/api/v1/business-types`,
    notificationRestaurant: (id) =>
      `${rootURL}/api/v1/restaurant/${id}/settings/`,
    soundClips: `${rootURL}/api/v1/sound-clips`,
    createSocialMedia: (restaurantId) =>
      `${rootURL}/api/v1/restaurants/${restaurantId}/social-medias/`,
    updateSocialMedia: (restaurantId, updateId) =>
      `${rootURL}/api/v1/restaurants/${restaurantId}/social-medias/${updateId}/`,
    schedules: (restaurantId) =>
      `${rootURL}/api/v1/restaurant/${restaurantId}/schedule/`,
  },
  transaction: {
    getRestaurantTransactions: (res) =>
      `${rootURL}/api/v1/restaurants/${res}/transactions/`,
  },
  qrCode: {
    generateQRCode: `${rootURL}/api/v1/restaurants/${localStorage.getItem(
      "res"
    )}/qrcode`,
    getQRCode: `${rootURL}/api/v1/business/qrcode`,
    updateQRCode: `${rootURL}/api/v1/restaurants/${localStorage.getItem(
      "res"
    )}/qrcode/update`,
    staffQRCode: (id) => `${rootURL}/api/v1/workers/${id}/qrcode`,
    professionalQRCode: `${rootURL}/api/v1/professionals/`,
    appointmentQRCode: `${rootURL}/api/v1/appointment/pay/`,
    orderQRCode: `${rootURL}/api/v1/order/pay/`,
  },
  businessApi: {
    getBusinesses: `${rootURL}/api/v1/businesses/`,
    getAllCatalogs: (bus) => `${rootURL}/api/v1/businesses/${bus}/catalogs`,
    getDefaultCatalog: (bus) => `${rootURL}/api/v1/businesses/${bus}/catalog`,
    UpdateCatalogs: (id, bus) =>
      `${rootURL}/api/v1/businesses/${bus}/catalogs/${id}/`,
    getSectionCatalogs: (sec) => `${rootURL}/api/v1/catalogs/${sec}/sections/`,
    section: (sec) => `${rootURL}/api/v1/catalogs/${sec}/sections/`,
    getWorkers: (bus) => `${rootURL}/api/v1/businesses/${bus}/professionals/`,
    getAllServices: `${rootURL}/api/v1/services`,
    services: (sec) => `${rootURL}/api/v1/sections/${sec}/services`,
    appointments: (bus) => `${rootURL}/api/v1/businesses/${bus}/appointments/`,
    appointmentsStatus: `${rootURL}/api/v1/appointments/`,
    getBusinessesTransactions: (bus) =>
      `${rootURL}/api/v1/businesses/${bus}/transactions/`,
    createCatalog: (bus) => `${rootURL}/api/v1/businesses/${bus}/catalogs/`,
    getCatalog: (id, bus) =>
      `${rootURL}/api/v1/businesses/${bus}/catalogs/${id}`,
    getCatalogServices: (bus) =>
      `${rootURL}/api/v1/businesses/${bus}/services/?resPerPage=1000`,
    assignProfessionalToService: (id) =>
      `${rootURL}/api/v1/services/${id}/professionals/`,
    getAssignProfessionalToService: (id) =>
      `${rootURL}/api/v1/professionals/${id}/services/`,
    getAssignProfessionalSchedule: (id) =>
      `${rootURL}/api/v1/professional/${id}/schedule?date=`,
    getProfessionalLeaves: (id) =>
      `${rootURL}/api/v1/professionals/${id}/leaves/?start_datetime_date=`,
    addProfessionalLeaves: (id) =>
      `${rootURL}/api/v1/professionals/${id}/leaves/`,
  },
  dashboard: {
    getSummary: (id) => `${rootURL}/api/v1/business/summary/`,
  },
  cart: {
    getCart: (id, customer) =>
      `${rootURL}/api/v1/restaurant/${id}/customer/${customer}/cart`,
    addCart: (id, cart) =>
      `${rootURL}/api/v1/restaurant/${id}/cart/${cart}/item/`,
    subCart: (id, customer, cart) =>
      `${rootURL}/api/v1/restaurant/${id}/customer/${customer}/cart/${cart}/sub`,
    getRemove: (id, customer, cart) =>
      `${rootURL}/api/v1//restaurant/${id}/customer/${customer}/cart/remove/2`,
    itemCheckout: (id, customer) =>
      `${rootURL}/api/v1/restaurant/${id}/customer/${customer}/cart/checkout`,
    returentAddCart: (id, cart) =>
      `${rootURL}/api/v1/restaurant/${id}/cart/${cart}/add`,
    returentRemoveCart: (id, cart) =>
      `${rootURL}/api/v1/restaurant/${id}/cart/${cart}/remove/`,
    returentItemCheckout: (id, cart) =>
      `${rootURL}/api/v1/restaurant/${id}/cart/${cart}/checkout`,
    returentGetCart: (id, cart) =>
      `${rootURL}/api/v1/restaurant/${id}/cart/${cart}`,
    retusrentGetTip: (type) => `${rootURL}/api/v1/tip?type=${type}`,
  },
  gallery: {
    getGallery: (type) =>
      `${rootURL}/api/v1/galleries?type=${type}&&resPerPage=5`,
  },
};
