import { useSelector } from "react-redux";
import { rootURL } from "../../../Config/apiConfig";
import pizza from "../../../assets/images/qrscan/pizza.png";
import RestaurantProductPage from "./restaurantProductPage";
import { useState } from "react";
const Drinks = ({ items, setShowProduct, setSelectedItems }) => {
  // const [showProduct, setShowProduct] = useState(false);
  const { menuData } = useSelector((state) => state.MenuReducer);
  return (
    <>
      {/* card */}
      {items?.map((x) => {
        return (
          <>
            <div
              className="services-card shadow-sm my-3 white-bg rounded p-2"
              style={{
                margin: "5px",
                border: "1px solid",
                borderColor: "transparent",
                backgroundColor: `${menuData?.menu_product_backgroud_color}`,
                color: `${menuData?.menu_product_text}`,
              }}
              onClick={() => {
                setShowProduct(true);
                setSelectedItems(x);
              }}
            >
              <table width="100%">
                <tr>
                  <td width={"30%"} rowSpan={2} style={{ marginRight: "5px" }}>
                    <img
                      src={x?.featured}
                      className="card-img img-responsive"
                    />
                  </td>
                  <td width={"60%"} style={{ verticalAlign: "top" }}>
                    <p className="cad-title">{x?.name}</p>
                    <p className="dim-text">{x?.description}</p>
                  </td>
                  <td width={"20%"} rowSpan={2}>
                    <p
                      className="orange-text font-weight-bold"
                      style={{
                        color: `${menuData?.menu_product_price}`,
                      }}
                    >
                      ₦{x?.price}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="dim-text pb-o mb-0">
                      <table width={"100%"}>
                        <tr>
                          <td width={"33%"}>
                            <i className="fa fa-clock orange-text"></i>{" "}
                            {x?.preparation_time} Min
                          </td>
                          <td width={"33%"}>
                            {x?.discount && x?.discount > 0 ? (
                              <i
                                style={{ color: "#FF4D4D" }}
                              >{`-₦${x?.discount} off`}</i>
                            ) : (
                              ""
                            )}
                          </td>
                          <td width={"33%"}>
                            {/* <i className="fa fa-star orange-text ml-2"></i> {x?.rating} */}
                          </td>
                        </tr>
                      </table>
                    </p>
                  </td>
                </tr>
              </table>
            </div>
            {/* <div
              className="shadow-sm row my-3 white-bg rounded p-2"
              onClick={() => {
                setShowProduct(true);
                setSelectedItems(x);
              }}
            >
              <div className="col-3 p-0">
                <img
                  src={rootURL + x?.featured}
                  className="card-img img-responsive"
                />
              </div>
              <div className="col-6">
                <p className="cad-title">{x?.name} </p>
                <p className="dim-text mb-4">{x?.description}</p>
                <p className="dim-text pb-o mb-0">
                  <i className="fa fa-clock orange-text"></i>{" "}
                  {x?.preparation_time} min{" "}
                  <i className="fa fa-star orange-text ml-2"></i> {x?.rating}
                </p>
              </div>
              <div className="col-3 text-right">
                <p className="orange-text font-weight-bold">₦{x?.price}</p>
              </div>
            </div> */}
          </>
        );
      })}

      {/* /Card */}

      {/* <div
        className="shadow-sm row my-3 white-bg rounded p-2"
        onClick={() => setShowProduct(true)}
      >
        <div className="col-3 p-0">
          <img src={pizza} className="card-img img-responsive" />
        </div>
        <div className="col-7">
          <p className="cad-title">Drink</p>
          <p className="dim-text mb-4">Soft Drink</p>
          <p className="dim-text pb-o mb-0">
            <i className="fa fa-clock orange-text"></i> 40 min{" "}
            <i className="fa fa-star orange-text ml-2"></i> 4.7
            <span className="text-danger ml-3">20% off</span>
          </p>
        </div>
        <div className="col-2 text-right">
          <p>
            <i className="far fa-heart"></i>
          </p>
          <p className="orange-text font-weight-bold">₦75.12</p>
        </div>
      </div> */}
      {/* {showProduct ? <RestaurantProductPage /> : ""} */}
    </>
  );
};

export default Drinks;
