import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import {
  getFormatedDate,
  getFormatedTime,
  getStatus,
} from "../partials/status";

const past = ({
  redirectTo,
  pastData,
  onOrderStatusChange,
  pastHandleChange,
  pastPageSize,
}) => {
  return (
    <>
      <div className="row">
        <table className="table table-borderless">
          <thead>
            <tr>
              <th className="mob-remove" scope="col">
                Appointment
              </th>
              <th className="mob-remove" scope="col">
                Professional
              </th>
              <th scope="col">Order ID</th>
              {/* <th scope="col">Order Detail</th> */}
              <th className="mob-remove" scope="col">
                Order Status
              </th>
              <th className="mob-remove" scope="col">
                Payment Status
              </th>
              <th className="mob-remove" scope="col">
                Payment Type
              </th>
              <th className="mob-remove" scope="col">
                Amount
              </th>
              <th scope="col" className="text-center">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {pastData.map((x) => {
              return (
                <tr>
                  <td scope="row" className="text-center mob-remove">
                    {getFormatedDate(x?.placed_at)}
                    <br></br>
                    {getFormatedTime(x?.placed_at)}
                  </td>
                  <td className="mob-remove">
                    {x?.services[0]?.professional?.name}
                  </td>
                  <td>
                    <Link
                      className="form-label order-detail"
                      style={{ cursor: "pointer", color: "#F9A05F" }}
                      onClick={() => {
                        redirectTo(x?.appointment_id);
                      }}
                    >
                      {x?.appointment_id}
                    </Link>
                  </td>
                  {/* <td>{x?.services.map((x) => x.service)}</td> */}
                  <td className="mob-remove">{getStatus(x?.order_status)}</td>
                  <td className="mob-remove">{getStatus(x?.payment_status)}</td>
                  <td className="mob-remove">{getStatus(x?.payment_mode)}</td>
                  <td className="mob-remove">{`${x?.currency} ${x?.total_bill_amount}`}</td>
                  <td className="text-center">
                    {/* drop down */}
                    <Dropdown>
                      <Dropdown.Toggle variant="abbs" id="dropdown-basic">
                        <span>
                          <i class="far fa-ellipsis-h"></i>
                        </span>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => {
                            onOrderStatusChange(x?.id, "complete");
                          }}
                        >
                          Mark As Delivered
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            onOrderStatusChange(x?.id, "complete");
                          }}
                        >
                          Mark As Complete
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            onOrderStatusChange(x?.id, "cancel");
                          }}
                        >
                          Remove Order
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    {/* end of dropdown */}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {pastData?.length === 0 && (
          <span
            style={{
              display: "flex",
              justifyContent: "center",
              fontSize: "20px",
              marginBottom: "50px",
            }}
          >
            You haven’t any Appointment.
          </span>
        )}
      </div>
      {pastPageSize > 1 && (
        <Stack spacing={2}>
          <Pagination count={pastPageSize} onChange={pastHandleChange} />
        </Stack>
      )}
    </>
  );
};

export default past;
