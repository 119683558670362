import React from "react";

function ItemDetail({ img, title, describtion, price }) {
  return (
    <>
      <div className="card mb-10">
        <div className="main-block">
          <table className="menu-block-table">
            <tr>
              <td style={{ width: "120px" }}>
                <img
                  src={img}
                  className="meni-image"
                  style={{
                    width: "90px",
                    objectFit: "cover",
                    overflow: "hidden",
                  }}
                />
              </td>
              <td>
                <p className="menu-item-title">{title}</p>
                <p className="menu-item-description">{describtion}</p>
              </td>
              <td>{/* <p className="menu-item-price">₦{price}</p> */}</td>
            </tr>
          </table>
        </div>
      </div>
    </>
  );
}

export default ItemDetail;
