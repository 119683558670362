import React from "react";

const Menuheader = () => {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <h3 className="inner-header">Menu</h3>
          </div>
          {/*
            <div className="col-md-6 text-right">
            <ul className="dashbaord-header-button">
                <li><button  className="btn-theme-secondary slim-button-theme ">Preview</button></li>
                <li><button  className="btn-theme-main slim-button-theme">Save</button></li>
            </ul>
    </div> */}
        </div>
      </div>
    </>
  );
};
export default Menuheader;
