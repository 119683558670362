import React, { useState } from "react";
import RestaurantProductPage from "./restaurantProductPage";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Loader from "../../Common/Loader";
import RestaurantCartArea from "./restaurantCartArea";
import { catalogActions } from "../../../Store/Catalogs/catalogAction";
import allServices from "../../../assets/images/services.jpg";
import { menuActions } from "../../../Store/Menu/menuAction";

function BusinessCategoriesPage() {
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const [showProduct, setShowProduct] = useState(false);
  const [search, setSearch] = useState("");
  const [showPizza, setPizza] = useState(true);
  const [items, setItem] = useState([]);
  const [allItems, setAllItem] = useState([]);
  const { menuData } = useSelector((state) => state.MenuReducer);
  const [selectedItems, setSelectedItems] = useState([]);
  const [price, setPrice] = useState(0);
  const [selectedCartItems, setSelectedCartItems] = useState(0);
  const [select, setSelect] = useState([]);
  const [temptItems, setTempItems] = useState([]);
  const [itemsBackup, setItemBackup] = useState();
  const [selectedObjects, setSelectedObjects] = useState([]);
  const [selectedCount, setSelectedCount] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [selectedIds, setSelectedIds] = useState([]);

  const dispatch = useDispatch();
  var bus = parseInt(window.location.href.split("?bus=")[1]);
  var st = parseInt(
    window.location.href.split("&st=")[1]
      ? window.location.href.split("&st=")[1]
      : 0
  );
  var pr = parseInt(window.location.href.split("&pr=")[1]);
  var orderType = parseInt(window.location.href.split("&ordertype=")[1]);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const ser = urlParams.get("ser");
  var na = window.location.href.split("&na=")[1];
  useEffect(() => {
    setSelectedCount(selectedObjects.length);
    setTotalPrice(selectedObjects.reduce((total, obj) => total + obj.price, 0));
    setSelectedIds(selectedObjects.map((obj) => obj.id));
  }, [selectedObjects]);

  useEffect(() => {
    dispatch(catalogActions.onGetDefaultBusiness(bus)).then((res) => {
      if (res?.id) {
        const data = {
          name: res?.name,
          description: res?.description,
          menu_type: res?.menu_type,
          home_background_image: res?.home_background_image,
          home_display_logo: res?.home_display_logo,
          home_display_image_background: res?.home_display_image_background,
          home_background_image_file: null,
          home_background_color: res?.home_background_color,
          home_restaurant_name_color: res?.home_restaurant_name_color,
          home_main_heading_color: res?.home_main_heading_color,
          home_sub_heading_color: res?.home_sub_heading_color,
          home_text_color: res?.home_text_color,
          home_dine_in_btn_color: res?.home_dine_in_btn_color,
          home_dine_in_text_color: res?.home_dine_in_text_color,
          home_dine_in_logo_color: res?.home_dine_in_logo_color,
          home_delivery_in_btn_color: res?.home_delivery_in_btn_color,
          home_delivery_in_text_color: res?.home_delivery_in_text_color,
          home_delivery_in_logo_color: res?.home_delivery_in_logo_color,
          menu_header_background_color: res?.menu_header_background_color,
          menu_header_text: res?.menu_header_text,
          menu_product_backgroud_color: res?.menu_product_backgroud_color,
          menu_product_text: res?.menu_product_text,
          menu_product_price: res?.menu_product_price,
          menu_product_icons: res?.menu_product_icons,
          menu_cart_background: res?.menu_cart_background,
          menu_cart_text_color: res?.menu_cart_text_color,
          menu_cart_price_color: res?.menu_cart_price_color,
          menu_category_tab_text_color: res?.menu_category_tab_text_color,
          menu_category_tab_color: res?.menu_category_tab_color,
          menu_delivery_text_background: res?.menu_delivery_text_background,
          menu_delivery_btn_color: res?.menu_delivery_btn_color,
          menu_delivery_icon_color: res?.menu_delivery_icon_color,
          item_product_background_color: res?.item_product_background_color,
          item_product_text_color: res?.item_product_text_color,
          item_product_price: res?.item_product_price,
          item_tip_text_color: res?.item_tip_text_color,
          item_tip_btn_color: res?.item_tip_btn_color,
          item_cart_text_color: res?.item_cart_text_color,
          item_cart_btn_color: res?.item_cart_btn_color,
          item_place_order_text_color: res?.item_place_order_text_color,
          item_place_order_btn_color: res?.item_place_order_btn_color,
          popup_background_color: res?.popup_background_color,
          popup_text_color: res?.popup_text_color,
          popup_product_bg_color: res?.popup_product_bg_color,
          popup_product_text_color: res?.popup_product_text_color,
          popup_cart_btn_color: res?.popup_cart_btn_color,
          popup_cart_text_color: res?.popup_cart_text_color,
        };
        dispatch(menuActions.onChange("menuData", data));
      }
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    let orderTypes = "walk_in";
    if (orderType === 122) {
      orderTypes = "call_out";
    }
    dispatch(catalogActions.onGetDefaultBusinesses(bus, pr, orderTypes)).then(
      (data) => {
        if (data?.id) {
          let filterData = data?.sections.filter((x) => x.items?.length > 0);
          let allItems =
            data?.sections?.flatMap((section) => section.items) || [];
          setTempItems(filterData);
          setItem(allItems);
          setItemBackup(allItems);
          setAllItem(allItems);
          const arr = JSON.parse(ser);
          // if (arr && arr?.length > 0) {
          //   let da = [];
          //   filterData.map((it) => {
          //
          //     var f = it.items.filter((ite) => arr.includes(ite?.id));
          //     if (f?.length > 0) {
          //       da.push(f);
          //     }
          //   });
          //   if (da?.length > 0) {
          //     setSelectedObjects(da);
          //   }
          // }
        }
        setLoading(false);
      }
    );
  }, []);

  // useEffect(() => {
  //   setLoading(true);
  //   dispatch(resturantActions.onGetAssignProfessionalToService(pr)).then(
  //     (data) => {
  //       if (data?.results) {
  //         setItem(data?.results);
  //         setItemBackup(data?.results);
  //       }
  //       setLoading(false);
  //     }
  //   );
  // }, []);

  const handleObjectClick = (object) => {
    if (selectedObjects.some((o) => o.id === object.id)) {
      setSelectedObjects(selectedObjects.filter((o) => o.id !== object.id));
    } else {
      setSelectedObjects([...selectedObjects, object]);
    }
  };
  return (
    <>
      {isLoading ? <Loader /> : null}
      <div
        className="qr-mobile-screen"
        style={{ backgroundColor: `${menuData?.home_background_color}` }}
      >
        <div className="container-fluid">
          <div className="row pt-5 black-bg px-2">
            <div
              className="col-md-2 col-2"
              onClick={() => {
                history.push(
                  `/business-menu-professional?bus=${bus}&st=${st}&ordertype=${orderType}&na=${
                    na.split("&ser=")[0]
                  }`
                );
              }}
            >
              <i class="fas fa-chevron-left top-arrow orange-text pl-1 pt-0 mt-0"></i>
            </div>
            <div className="col-md-8 col-8 text-center">
              <p className="white-text">Choose a Service</p>
            </div>
            <div className="col-md-2 col-2"></div>
          </div>
        </div>

        <div
          className="inner-screen"
          style={{
            backgroundColor: `${menuData?.menu_header_background_color}`,
          }}
        >
          <div
            className="container-fluid"
            style={{ backgroundColor: `${menuData?.home_background_color}` }}
          >
            <div className="screen-search-area">
              <div className="input-group search-area menu-item-search-area">
                {/* <span className="input-group-text">
                    <i className="flaticon-381-search-2"></i>
                  </span> */}
                <input
                  type="text"
                  className={`form-control`}
                  placeholder="Search here..."
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                    if (e.target.value !== "") {
                      setItem(
                        itemsBackup.filter((x) =>
                          x?.service?.name
                            .toUpperCase()
                            .includes(e.target.value.toUpperCase())
                        )
                      );
                    } else {
                      setItem(itemsBackup);
                    }
                  }}
                />
              </div>
            </div>

            <div className="row py-2">
              <div
                className="category-container"
                style={{
                  marginBottom: 0,
                  color: `${menuData?.menu_category_tab_text_color} !important`,
                }}
              >
                <ul className="category-list">
                  <li
                    onClick={() => {
                      // setItem(x?.items);
                      // let allItems =
                      //   temptItems?.sections?.flatMap(
                      //     (section) => section.items
                      //   ) || [];
                      setItem(allItems);
                    }}
                  >
                    <div className="category">
                      <img src={allServices} />
                      <span>All</span>
                    </div>
                  </li>
                  {temptItems?.map((x, i) => {
                    return (
                      <li
                        className={showPizza && ""}
                        onClick={() => {
                          setItem(x?.items);
                        }}
                      >
                        <div className="category">
                          <img src={x?.featured} />
                          <span>{x?.name}</span>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>

            {/* <BusinessCat
            items={items}
            setShowProduct={(e) => {
              setShowProduct(true);
            }}
            selectedItems={selectedItems}
            setSelectedItems={(e) => {
              var arr = selectedItems;
              var exist = selectedItems?.filter((x) => x?.id === e.id);
              if (exist?.length > 0) {
                var arr1 = selectedItems?.filter((x) => x?.id !== e.id);
                setSelectedCartItems(arr1);
              } else {
                arr.push(e);
                setSelectedItems(arr);
              }
            }}
          /> */}
            {items?.map((x) => {
              return (
                <div
                  className="services-card shadow-sm my-3 white-bg rounded p-2"
                  style={{
                    margin: "5px",
                    border: "1px solid",
                    borderColor: selectedObjects.some((o) => o.id === x.id)
                      ? "orange"
                      : "transparent",
                    backgroundColor: `${menuData?.menu_product_backgroud_color}`,
                  }}
                  onClick={() => handleObjectClick(x)}
                >
                  <table width="100%">
                    <tr
                      style={{
                        backgroundColor: `${menuData?.menu_product_backgroud_color}`,
                      }}
                    >
                      <td
                        width={"30%"}
                        rowSpan={2}
                        style={{
                          marginRight: "5px",
                          backgroundColor: `${menuData?.menu_product_backgroud_color}`,
                        }}
                      >
                        <img
                          src={x?.feature_image}
                          className="card-img img-responsive"
                        />
                      </td>
                      <td width={"60%"} style={{ verticalAlign: "top" }}>
                        <p
                          className="cad-title"
                          style={{
                            color: `${menuData?.menu_product_text}`,
                          }}
                        >
                          {x?.service?.name}
                        </p>
                        <p
                          className="dim-text"
                          style={{
                            color: `${menuData?.menu_product_text}`,
                          }}
                        >
                          {x?.service?.description}
                        </p>
                      </td>
                      <td width={"20%"} rowSpan={2}>
                        <p
                          className="orange-text font-weight-bold"
                          style={{
                            color: `${menuData?.menu_product_price}`,
                          }}
                        >
                          ₦{x?.price}
                        </p>
                      </td>
                    </tr>
                    <tr
                      style={{
                        backgroundColor: `${menuData?.menu_product_backgroud_color}`,
                      }}
                    >
                      <td>
                        <p className="dim-text pb-o mb-0">
                          <table width={"100%"}>
                            <tr>
                              <td width={"33%"}>
                                <i
                                  className="fa fa-clock orange-text"
                                  style={{
                                    color: `${menuData?.menu_product_text}`,
                                  }}
                                ></i>{" "}
                                {x?.service_time} min
                              </td>
                              <td width={"33%"}>
                                {x?.discount && x?.discount > 0 ? (
                                  <i
                                    style={{
                                      color: "#FF4D4D",
                                      color: `${menuData?.menu_product_text}`,
                                    }}
                                  >{`-₦${x?.discount} off`}</i>
                                ) : (
                                  ""
                                )}
                              </td>
                              <td width={"33%"}>
                                {/* <i className="fa fa-star orange-text ml-2"></i> {x?.rating} */}
                              </td>
                            </tr>
                          </table>
                        </p>
                      </td>
                    </tr>
                  </table>
                </div>
              );
            })}
          </div>
        </div>
        {showProduct ? (
          <RestaurantProductPage
            selectedRecord={selectedItems}
            back={() => {
              setShowProduct(!showProduct);
            }}
            price={(e) => {
              setPrice(e);
            }}
            cartItemId={(e) => {
              setSelectedCartItems(e);
            }}
          />
        ) : (
          ""
        )}
        {selectedObjects?.length > 0 && (
          <RestaurantCartArea
            selectedCount={selectedCount}
            totalPrice={totalPrice}
            link={`/choose-time?bus=${bus}&st=${st}&pr=${pr}&ordertype=${orderType}&na=${
              na.split("&ser=")[0]
            }&ser=[${selectedIds.join(",")}]`}
          />
        )}
      </div>
    </>
  );
}

export default BusinessCategoriesPage;
