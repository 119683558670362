import { resturantTypes } from "./resturantTypes";
const INITIAL_STATE = {
  resturantSetting: {},
  resturantSettingNotification: {},
  sounds: {},
  resturantId: 0,
  orderDetail: {},
};

const fn = (state, action) => {
  if (!state) state = INITIAL_STATE;
  switch (action.type) {
    case resturantTypes.RESTURANT_ONCHANGE:
      return {
        ...state,
        errorMessage: "",
        [action.payload.prop]: action.payload.value,
      };
    default:
      return state;
  }
};

export default fn;
