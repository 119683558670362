import React from "react";
import ScantoPay from "../Components/ScantoPay";
import QrCodeMenu from "../Components/QrCodeMenu";
import PrivacyPolicy from "../Components/PrivacyPolicy";
import TermsConditions from "../Components/TermsConditions";
import Dashboard from "../Components/Dashboard";
import Contact from "../Components/Contact/index";
import HomeFive from "../Components/HomeFive/index";
import BookAppointment from "../Components/BookAppointment";

import BuildMenu from "../Components/Dashboard/menu/buildmenu";
import BuildCatalog from "../Components/Dashboard/menu/buildCatalog";
import MenuHome from "../Components/Dashboard/menu/menuhome";
import CatHome from "../Components/Dashboard/menu/cathome";
import AllMenu from "../Components/Dashboard/menu/allmenus";
import CatAllHome from "../Components/Dashboard/menu/catallmenus";
import EditTemplate from "../Components/Dashboard/menu/edittemplate";
import EditCatTemplate from "../Components/Dashboard/menu/editcattemplate";

import Staff from "../Components/Dashboard/staff";
import AddMember from "../Components/Dashboard/staff/AddStaff";
import ViewMember from "../Components/Dashboard/staff/ViewStaff";
import StaffMember from "../Components/Dashboard/staff/StaffMember";
import AllStaffMembers from "../Components/Dashboard/staff/AllStaffMembers";

import Professional from "../Components/Dashboard/professional";
import ProfessionalAddMember from "../Components/Dashboard/professional/AddStaff";
import ProfessionalViewMember from "../Components/Dashboard/professional/ViewStaff";
import ProfessionalMember from "../Components/Dashboard/professional/StaffMember";
import AllProfessionalMembers from "../Components/Dashboard/professional/AllStaffMembers";

import Tables from "../Components/Dashboard/tables";
import Orders from "../Components/Dashboard/orders";
import Appointments from "../Components/Dashboard/appointments";
import Transactions from "../Components/Dashboard/transactions";
import CatTransactions from "../Components/Dashboard/cattransactions";
import Reports from "../Components/Dashboard/reports";
import Settings from "../Components/Dashboard/settings";
import QrCode from "../Components/Dashboard/qrcode";
import UserManagement from "../Components/Dashboard/usermanagment";
import MyAccount from "../Components/Dashboard/myaccount";
import Subscription from "../Components/Dashboard/subscription";

import RestaurantHomePage from "../Components/QrScan/restaurent/restaurantHomePage";
import RestaurantCategoriesPage from "../Components/QrScan/restaurent/restaurantCategoriesPage";
import Tip from "../Components/QrScan/restaurent/tip";
import Pricing from "../Components/Dashboard/pricing";
import Pay from "../Components/QrScan/restaurent/pay";
import Calendar from "../Components/QrScan/restaurent/calendar";
import Giveaway from "../Components/QrScan/restaurent/giveaway";
import DetailNotifications from "../Components/Dashboard/layouts/notifications/DetailNotifications";
import BusinessHomePage from "../Components/QrScan/restaurent/businessHomePage";
import BusinessCategoriesPage from "../Components/QrScan/restaurent/businessCategoriesPage";
import BusinessProfessionalPage from "../Components/QrScan/restaurent/businessProfessionalPage";
import BusinessPay from "../Components/QrScan/restaurent/businessPay";
import OrderDetail from "../Components/Dashboard/orders/orderDetail";
import AppointmentDetail from "../Components/Dashboard/appointments/appointmentDetail";

const RoutesObj = {
  HomeFive: {
    path: "/",
    component: <HomeFive />,
  },
  // Login: {
  //   path: "/login",
  //   component: <Login />,
  // },
  ScantoPay: {
    path: "/scan-to-pay",
    component: <ScantoPay />,
  },
  QrCodeMenu: {
    path: "/qr-code-menu",
    component: <QrCodeMenu />,
  },
  PrivacyPolicy: {
    path: "/privacy-policy",
    component: <PrivacyPolicy />,
  },
  TermsConditions: {
    path: "/terms-and-conditions",
    component: <TermsConditions />,
  },
  Dashboard: {
    path: "/dashboard",
    component: <Dashboard />,
  },
  BuildMenu: {
    path: "/menu",
    component: <BuildMenu />,
  },
  BuildCatalog: {
    path: "/buildCatalog",
    component: <BuildCatalog />,
  },
  AllMenu: {
    path: "/all-menu",
    component: <AllMenu />,
  },
  CatAllMenu: {
    path: "/cat-all-menu",
    component: <CatAllHome />,
  },
  MenuHome: {
    path: "/menu-home",
    component: <MenuHome />,
  },
  CatHome: {
    path: "/cat-home",
    component: <CatHome />,
  },
  EditTemplate: {
    path: "/edit-menu-template",
    component: <EditTemplate />,
  },
  EditCatTemplate: {
    path: "/edit-cat-template",
    component: <EditCatTemplate />,
  },
  Contact: {
    path: "/contact",
    component: <Contact />,
  },
  Staff: {
    path: "/staff",
    component: <Staff />,
  },
  AddMember: {
    path: "/add-staff-member",
    component: <AddMember />,
  },
  ViewMember: {
    path: "/view-staff-member",
    component: <ViewMember />,
  },
  StaffMember: {
    path: "/staffmember",
    component: <StaffMember />,
  },
  AllStaffMembers: {
    path: "/allstaffmembers",
    component: <AllStaffMembers />,
  },

  Professional: {
    path: "/professional",
    component: <Professional />,
  },
  ProfessionalAddMember: {
    path: "/add-professional-member",
    component: <ProfessionalAddMember />,
  },
  ProfessionalViewMember: {
    path: "/view-professional-member",
    component: <ProfessionalViewMember />,
  },
  ProfessionalMember: {
    path: "/professionalmember",
    component: <ProfessionalMember />,
  },
  AllProfessionalMembers: {
    path: "/allprofessionalmembers",
    component: <AllProfessionalMembers />,
  },

  Tables: {
    path: "/tables",
    component: <Tables />,
  },
  Orders: {
    path: "/orders",
    component: <Orders />,
  },
  Appointments: {
    path: "/appointments",
    component: <Appointments />,
  },
  Transactions: {
    path: "/transactions",
    component: <Transactions />,
  },
  CatTransactions: {
    path: "/cat-transactions",
    component: <CatTransactions />,
  },
  Reports: {
    path: "/reports",
    component: <Reports />,
  },
  Settings: {
    path: "/settings",
    component: <Settings />,
  },
  QrCode: {
    path: "/qr-codes",
    component: <QrCode />,
  },
  UserManagement: {
    path: "/user-management",
    component: <UserManagement />,
  },
  MyAccount: {
    path: "/my-account",
    component: <MyAccount />,
  },

  Subscription: {
    path: "/subscription",
    component: <Subscription />,
  },
  // RestaurantHomePage: {
  //   path: "/restaurant-menu-homepage",
  //   component: <RestaurantHomePage />,
  // },
  RestaurantCategoriesPage: {
    path: "/restaurant-menu-categories",
    component: <RestaurantCategoriesPage />,
  },
  Tip: {
    path: "/tip-page",
    component: <Tip />,
  },
  Pricing: {
    path: "/pricing",
    component: <Pricing />,
  },
  Pay: {
    path: "/pay",
    component: <Pay />,
  },
  BusinessPay: {
    path: "/business-pay",
    component: <BusinessPay />,
  },
  Giveaway: {
    path: "/giveaway",
    component: <Giveaway />,
  },
  Calendar: {
    path: "/choose-time",
    component: <Calendar />,
  },
  BookAppointment: {
    path: "/book-appointment",
    component: <BookAppointment />,
  },
  DetailNotification: {
    path: "/detail-notification",
    component: <DetailNotifications />,
  },
  BusinessHomePage: {
    path: "/business",
    component: <BusinessHomePage />,
  },
  BusinessCategoriesPage: {
    path: "/business-menu-categories",
    component: <BusinessCategoriesPage />,
  },
  BusinessProfessionalPage: {
    path: "/business-menu-professional",
    component: <BusinessProfessionalPage />,
  },
  BusinessOrderDetail: {
    path: "/order-detail",
    component: <OrderDetail />,
  },
  AppointmentDetail: {
    path: "/appointment-detail",
    component: <AppointmentDetail />,
  },
  RestaurantHomePage: {
    path: "/restaurant",
    component: <RestaurantHomePage />,
  },
};
export default RoutesObj;
