import React from "react";

const Dheader = () => {
  return (
    <>
      <div className="container">
        <div className="row mb-3">
          <div className="col-md-6">
            <h3 className="inner-header order-detail">
              Staff Members{" "}
              <span class="heading-link">
                <i class="fa fa-chevron-right bread-icon"></i>
                <span class="subheading-link">Add New Member</span>
              </span>
            </h3>
          </div>
          {/* <div className="col-md-6 text-right">
            <button className="btn btn-info mx-2">Cancel</button>
            <button className="btn btn-primary">Save</button>
          </div> */}
        </div>
      </div>
    </>
  );
};
export default Dheader;
