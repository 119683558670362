/// Menu
import Metismenu from "metismenujs";
import { BrowserView } from "react-device-detect";
import React, { Component, useEffect, useState } from "react";

import PerfectScrollbar from "react-perfect-scrollbar";
import { Link } from "react-router-dom";
// import { useScrollPosition } from "@n8tb1t/use-scroll-position";

import imgdashboard from "../../../../assets/images/dashboard/dashboard.png";
import imgmenu from "../../../../assets/images/dashboard/menu.png";
import imgstaff from "../../../../assets/images/dashboard/staff.png";
import imgorder from "../../../../assets/images/dashboard/order.png";
import imgtransactions from "../../../../assets/images/dashboard/transactions.png";
import imgqrcode from "../../../../assets/images/dashboard/qrcode.png";
import imgreport from "../../../../assets/images/dashboard/reports.png";
import imgsettings from "../../../../assets/images/dashboard/settings.png";
import { useDispatch, useSelector } from "react-redux";
import { accountActions } from "../../../../Store/Accounts/accountAction";
import { getType } from "@reduxjs/toolkit";

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new Metismenu(this.$el);
  }
  componentWillUnmount() {}
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const SideBar = () => {
  const [toggle, setToggle] = useState(false);
  const dispatch = useDispatch();
  const { type } = useSelector((state) => state.AccountReducer);
  if (type === "") {
    let getType = localStorage.getItem("type");
    dispatch(accountActions.onChange("type", getType));
  }
  let subscription = localStorage.getItem("subscription");
  useEffect(() => {
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);
  }, []);
  // let scrollPosition = useScrollPosition();
  /// Path
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  /// Active menu
  let deshBoard = [
      "",
      "dashboard-dark",
      "wallet",
      "invoices-list",
      "create-invoices",
      "card-center",
      "transaction-details",
      "task",
    ],
    app = [
      "app-profile",
      "post-details",
      "app-calender",
      "email-compose",
      "email-inbox",
      "email-read",
      "ecom-product-grid",
      "ecom-product-list",
      "ecom-product-order",
      "ecom-checkout",
      "ecom-invoice",
      "ecom-customers",
      "post-details",
      "ecom-product-detail",
    ];
  return (
    <div className={`dlabnav`}>
      <BrowserView>
        <div
          className="nav-control browser-toggle"
          onClick={() => {
            setToggle(!toggle);
          }}
        >
          <div className={`hamburger ${toggle ? "is-active" : ""}`}>
            <i className="fa fa-angle-left menu-hum" aria-hidden="true"></i>
          </div>
        </div>
      </BrowserView>
      {subscription !== "null" && (
        <PerfectScrollbar className="dlabnav-scroll">
          <MM className="metismenu" id="menu">
            <li className={`${deshBoard.includes(path) ? "mm-active" : ""}`}>
              <Link
                className={`${path === "dashboard" ? "active-border" : ""}`}
                to="/dashboard"
              >
                {/* <i className="fas fa-home"></i> */}
                <img src={imgdashboard} alt="" />
                <span className="nav-text dash-menu"> Dashboard</span>
              </Link>
            </li>
            {type === "restaurant" && (
              <>
                <li
                  className={`${
                    app.includes(path) ? "mm-active mob-remove" : "mob-remove"
                  }`}
                >
                  <Link
                    className={`has-arrow ai-icon ${
                      path === "all-menu" || path === "menu-home"
                        ? "active-border"
                        : ""
                    }`}
                    to="#"
                  >
                    <img src={imgmenu} alt="" />
                    <span className="nav-text dash-menu">Menu</span>
                  </Link>
                  <ul>
                    <li>
                      <Link
                        className={`${
                          path === "app-profile" ? "mm-active" : ""
                        }`}
                        to="/menu-home"
                      >
                        Add Menu
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={`${
                          path === "app-profile" ? "mm-active" : ""
                        }`}
                        to="/all-menu"
                      >
                        View Menus
                      </Link>
                    </li>
                  </ul>
                </li>

                <li
                  className={`${deshBoard.includes(path) ? "mm-active" : ""}`}
                >
                  <Link
                    className={`${
                      path === "staff" || path === "add-staff-member"
                        ? "active-border"
                        : ""
                    }`}
                    to="/staff"
                  >
                    <img src={imgstaff} alt="" />
                    <span className="nav-text dash-menu">Staff</span>
                  </Link>
                </li>

                <li
                  className={`${deshBoard.includes(path) ? "mm-active" : ""}`}
                >
                  <Link
                    className={`${
                      path === "orders" || path === "order-detail"
                        ? "active-border"
                        : ""
                    }`}
                    to="/orders"
                  >
                    <img src={imgorder} alt="" />
                    <span className="nav-text dash-menu">Orders</span>
                  </Link>
                </li>

                <li
                  className={`${deshBoard.includes(path) ? "mm-active" : ""}`}
                >
                  <Link
                    className={`${
                      path === "transactions" ? "active-border" : ""
                    }`}
                    to="/transactions"
                  >
                    <img src={imgtransactions} alt="" />
                    <span className="nav-text dash-menu">Transactions </span>
                  </Link>
                </li>
              </>
            )}
            {type === "business" && (
              <>
                <li
                  className={`${
                    app.includes(path) ? "mm-active mob-remove" : "mob-remove"
                  }`}
                >
                  <Link
                    className={`has-arrow ai-icon ${
                      path === "cat-home" || path === "cat-all-menu"
                        ? "active-border"
                        : ""
                    }`}
                    to="#"
                  >
                    <img src={imgmenu} alt="" />
                    <span className="nav-text dash-menu">Design studio</span>
                  </Link>
                  <ul>
                    <li>
                      <Link
                        className={`${
                          path === "app-profile" ? "mm-active" : ""
                        }`}
                        to="/cat-home"
                      >
                        Add Catalog
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={`${
                          path === "app-profile" ? "mm-active" : ""
                        }`}
                        to="/cat-all-menu"
                      >
                        View Catalogs
                      </Link>
                    </li>
                  </ul>
                </li>

                <li
                  className={`${deshBoard.includes(path) ? "mm-active" : ""}`}
                >
                  <Link
                    className={`${
                      path === "professional" ||
                      path === "add-professional-member"
                        ? "active-border"
                        : ""
                    }`}
                    to="/professional"
                  >
                    <img src={imgstaff} alt="" />
                    <span className="nav-text dash-menu">Professional</span>
                  </Link>
                </li>

                <li
                  className={`${deshBoard.includes(path) ? "mm-active" : ""}`}
                >
                  <Link
                    className={`${
                      path === "appointments" ? "active-border" : ""
                    }`}
                    to="/appointments"
                  >
                    <img src={imgorder} alt="" />
                    <span className="nav-text dash-menu">Appointments</span>
                  </Link>
                </li>

                <li
                  className={`${deshBoard.includes(path) ? "mm-active" : ""}`}
                >
                  <Link
                    className={`${
                      path === "cat-transactions" ? "active-border" : ""
                    }`}
                    to="/cat-transactions"
                  >
                    <img src={imgtransactions} alt="" />
                    <span className="nav-text dash-menu">Transactions </span>
                  </Link>
                </li>
              </>
            )}

            <li className={`${deshBoard.includes(path) ? "mm-active" : ""}`}>
              <Link
                className={`${path === "qr-codes" ? "active-border" : ""}`}
                to="/qr-codes"
              >
                <img src={imgqrcode} alt="" />
                <span className="nav-text dash-menu">QR Code</span>
              </Link>
            </li>
            <li
              className={`${
                deshBoard.includes(path) ? "mm-active mob-remove" : "mob-remove"
              }`}
            >
              <Link
                className={`${path === "settings" ? "active-border" : ""}`}
                to="/settings"
              >
                <img src={imgsettings} alt="" />
                <span className="nav-text dash-menu">Settings</span>
              </Link>
            </li>
          </MM>
        </PerfectScrollbar>
      )}
    </div>
  );
};

export default SideBar;
