import React, { useState } from "react";
import ColorPicker from "./ColorPicker";
import ColorPickerFont from "./ColorPickerFont";
import Screen4 from "../previewscreenscat/Screen4";

import { useDispatch, useSelector } from "react-redux";
import { menuActions } from "../../../../Store/Menu/menuAction";
function MenuItemPage({ templeteDetail }) {
  const { menuData } = useSelector((state) => state.MenuReducer);
  const dispatch = useDispatch();
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="form-area-menu">
              <div className="form-group-grid">
                <label>Service Background Color</label>
                <div className="form-right">
                  <div className="color-picker-bk">
                    <ColorPicker
                      color={menuData?.item_product_background_color}
                      setColor={(e) => {
                        dispatch(
                          menuActions.onChange("menuData", {
                            ...menuData,
                            item_product_background_color: e,
                          })
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group-grid">
                <label>Service Text</label>
                <div className="form-right">
                  <div className="color-picker-font">
                    <ColorPickerFont
                      color={menuData?.item_product_text_color}
                      setColor={(e) => {
                        dispatch(
                          menuActions.onChange("menuData", {
                            ...menuData,
                            item_product_text_color: e,
                          })
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group-grid">
                <label>Service Price</label>
                <div className="form-right">
                  <div className="color-picker-font">
                    <ColorPickerFont
                      color={menuData?.item_product_price}
                      setColor={(e) => {
                        dispatch(
                          menuActions.onChange("menuData", {
                            ...menuData,
                            item_product_price: e,
                          })
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group-grid">
                <label>Tip Button</label>
                <div className="form-right">
                  <div className="color-picker-bk">
                    <ColorPicker
                      color={menuData?.item_tip_btn_color}
                      setColor={(e) => {
                        dispatch(
                          menuActions.onChange("menuData", {
                            ...menuData,
                            item_tip_btn_color: e,
                          })
                        );
                      }}
                    />
                  </div>
                  <div className="color-picker-font">
                    <ColorPickerFont
                      color={menuData?.item_tip_text_color}
                      setColor={(e) => {
                        dispatch(
                          menuActions.onChange("menuData", {
                            ...menuData,
                            item_tip_text_color: e,
                          })
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
              {/* <div className="form-group-grid">
                <label>Cart Button</label>
                <div className="form-right">
                  <div className="color-picker-bk">
                    <ColorPicker
                      color={menuData?.info_button_color}
                      setColor={(e) => {
                        dispatch(
                          menuActions.onChange("menuData", {
                            ...menuData,
                            info_button_color: e,
                          })
                        );
                      }}
                    />
                  </div>
                  <div className="color-picker-font">
                    <ColorPickerFont
                      color={menuData?.info_button_text_color}
                      setColor={(e) => {
                        dispatch(
                          menuActions.onChange("menuData", {
                            ...menuData,
                            info_button_text_color: e,
                          })
                        );
                      }}
                    />
                  </div>
                </div>
              </div> */}

              <div className="form-group-grid">
                <label>Place Order Button</label>
                <div className="form-right">
                  <div className="color-picker-bk">
                    <ColorPicker
                      color={menuData?.item_place_order_btn_color}
                      setColor={(e) => {
                        dispatch(
                          menuActions.onChange("menuData", {
                            ...menuData,
                            item_place_order_btn_color: e,
                          })
                        );
                      }}
                    />
                  </div>
                  <div className="color-picker-font">
                    <ColorPickerFont
                      color={menuData?.item_place_order_text_color}
                      setColor={(e) => {
                        dispatch(
                          menuActions.onChange("menuData", {
                            ...menuData,
                            item_place_order_text_color: e,
                          })
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="screen-area inner-screen-area">
              <Screen4 />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MenuItemPage;
