import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./qrstyle.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Loader from "../../Common/Loader";
import { menuActions } from "../../../Store/Menu/menuAction";
import image from "../../../assets/images/avatar.jpg";
import { catalogActions } from "../../../Store/Catalogs/catalogAction";

function BusinessProfessionalPage() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [professional, setProfessional] = useState([]);
  const { menuData } = useSelector((state) => state.MenuReducer);
  var bus = parseInt(window.location.href.split("?bus=")[1]);
  var st = parseInt(
    window.location.href.split("&st=")[1]
      ? window.location.href.split("&st=")[1]
      : 0
  );
  var orderType = parseInt(window.location.href.split("&ordertype=")[1]);
  var na = window.location.href.split("&na=")[1];

  useEffect(() => {
    dispatch(catalogActions.onGetDefaultBusiness(bus)).then((res) => {
      if (res?.id) {
        const data = {
          name: res?.name,
          description: res?.description,
          menu_type: res?.menu_type,
          home_background_image: res?.home_background_image,
          home_display_logo: res?.home_display_logo,
          home_display_image_background: res?.home_display_image_background,
          home_background_image_file: null,
          home_background_color: res?.home_background_color,
          home_restaurant_name_color: res?.home_restaurant_name_color,
          home_main_heading_color: res?.home_main_heading_color,
          home_sub_heading_color: res?.home_sub_heading_color,
          home_text_color: res?.home_text_color,
          home_dine_in_btn_color: res?.home_dine_in_btn_color,
          home_dine_in_text_color: res?.home_dine_in_text_color,
          home_dine_in_logo_color: res?.home_dine_in_logo_color,
          home_delivery_in_btn_color: res?.home_delivery_in_btn_color,
          home_delivery_in_text_color: res?.home_delivery_in_text_color,
          home_delivery_in_logo_color: res?.home_delivery_in_logo_color,
          menu_header_background_color: res?.menu_header_background_color,
          menu_header_text: res?.menu_header_text,
          menu_product_backgroud_color: res?.menu_product_backgroud_color,
          menu_product_text: res?.menu_product_text,
          menu_product_price: res?.menu_product_price,
          menu_product_icons: res?.menu_product_icons,
          menu_cart_background: res?.menu_cart_background,
          menu_cart_text_color: res?.menu_cart_text_color,
          menu_cart_price_color: res?.menu_cart_price_color,
          menu_category_tab_text_color: res?.menu_category_tab_text_color,
          menu_category_tab_color: res?.menu_category_tab_color,
          menu_delivery_text_background: res?.menu_delivery_text_background,
          menu_delivery_btn_color: res?.menu_delivery_btn_color,
          menu_delivery_icon_color: res?.menu_delivery_icon_color,
          item_product_background_color: res?.item_product_background_color,
          item_product_text_color: res?.item_product_text_color,
          item_product_price: res?.item_product_price,
          item_tip_text_color: res?.item_tip_text_color,
          item_tip_btn_color: res?.item_tip_btn_color,
          item_cart_text_color: res?.item_cart_text_color,
          item_cart_btn_color: res?.item_cart_btn_color,
          item_place_order_text_color: res?.item_place_order_text_color,
          item_place_order_btn_color: res?.item_place_order_btn_color,
          popup_background_color: res?.popup_background_color,
          popup_text_color: res?.popup_text_color,
          popup_product_bg_color: res?.popup_product_bg_color,
          popup_product_text_color: res?.popup_product_text_color,
          popup_cart_btn_color: res?.popup_cart_btn_color,
          popup_cart_text_color: res?.popup_cart_text_color,
        };
        dispatch(menuActions.onChange("menuData", data));
      }
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    dispatch(catalogActions.onGetMobileCatalogWorkers(bus)).then((res) => {
      if (res?.results) {
        setProfessional(res?.results);
      }
      setLoading(false);
    });
  }, []);

  return (
    <>
      {isLoading ? <Loader /> : null}
      <div
        className="qr-mobile-screen"
        style={{ backgroundColor: `${menuData?.home_background_color}` }}
      >
        <div className="container-fluid">
          <div className="row pt-5 black-bg px-2">
            <div
              className="col-md-2 col-2"
              onClick={() => {
                // history.push(`/business-menu-homepage?bus=${bus}&st=${st}`);
                history.push(`/business/${na.split("&crt=")[0]}&st=${st}`);
              }}
            >
              <i class="fas fa-chevron-left top-arrow orange-text pl-1 pt-0 mt-0"></i>
            </div>
            <div className="col-md-8 col-8 text-center">
              <p className="white-text">Choose a Professional</p>
            </div>
            <div className="col-md-2 col-2"></div>
          </div>
        </div>
        {/* <div className="row pt-5 black-bg px-2">
          <div className="col-md-9 col-9">
            <i
              class="fas fa-chevron-left top-arrow orange-text pl-1 pt-0 mt-0"
              onClick={() => {
                history.push(`/business-menu-homepage?bus=${bus}&&st=${st}`);
              }}
            ></i>
          </div>
          <div className="col-md-8 col-8 text-center">
            <p className="white-text">Professional</p>
          </div>
        </div> */}
        <div className="container-fluid">
          {/* <div className="row">
            <div className="inline-content">
              <div className="qr-scan-title">
                <h3 style={{ marginTop: "10px" }}>Choose a Professional</h3>
              </div>
            </div>
          </div> */}
          <div className="row" style={{ padding: "10px" }}>
            {professional?.map((x) => {
              return (
                <div style={{ width: "45%", margin: "6px" }}>
                  <div className="text-center pb-15 card radius-12">
                    <div className="pb-3">
                      <img
                        style={{
                          width: "65px",
                          height: "65px",
                          borderRadius: "100px",
                        }}
                        src={x.profile_image ? `${x?.profile_image}` : image}
                        alt=""
                        width={"40px"}
                        height={"40px"}
                      />
                    </div>
                    <div className="">
                      <h5
                        style={{
                          color: "#282A2C",
                          fontSize: "14px",
                          margin: 0,
                        }}
                      >
                        {x.name}
                      </h5>
                    </div>
                    <div className="">
                      <h6 style={{ color: "#8E8E93", fontSize: "12px" }}>
                        {/* {x?.available === "n"
                          ? "Not Available"
                          : "Available Today"} */}
                      </h6>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        alignSelf: "center",
                        borderRadius: "10px",
                        width: "100%",
                      }}
                    >
                      <button
                        className="preview-button-theme"
                        style={{ borderRadius: "5px", width: "100%" }}
                        onClick={() => {
                          history.push(
                            `/business-menu-categories?bus=${bus}&st=${st}&pr=${x.id}&ordertype=${orderType}&na=${na}`
                          );
                        }}
                      >
                        Select
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default BusinessProfessionalPage;
