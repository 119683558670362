import React from "react";
import { useSortable } from "@dnd-kit/sortable";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { Dropdown } from "react-bootstrap";
import Switch from "react-switch";
function LeftItemDetail(props) {
  const { setNodeRef } = useSortable({ id: props.id });
  return (
    <>
      <div ref={setNodeRef}>
        <div className="card mb-10 left-menu-item">
          <div className="main-block ">
            <table className="menu-block-table">
              <tr>
                <td>
                  <div className="sorting-handle">
                    <DragIndicatorIcon />
                  </div>
                </td>
                <td style={{ width: "120px" }}>
                  <img
                    src={props.img}
                    className="meni-image"
                    style={{
                      width: "100px",
                      objectFit: "cover",
                      overflow: "hidden",
                    }}
                  />
                </td>
                <td>
                  <p className="menu-item-title">{props.title}</p>
                  <p className="menu-item-description">{props.describtion}</p>
                </td>
                <td>
                  {props.discount > 0 ? (
                    <p className="menu-item-price">
                      {props.currency} {props.price - props.discount}
                      {/* <br></br> */}
                      <span className="discount-price">
                        <strike>
                          {props.currency} {props.price}
                        </strike>
                      </span>
                    </p>
                  ) : (
                    <p className="menu-item-price">
                      {props.currency} {props.price}
                    </p>
                  )}
                </td>
                {/* <td>
                  <Switch />
                </td> */}
                <td>
                  <Dropdown className="dropdown">
                    <Dropdown.Toggle as="div" className="btn-link i-false">
                      <div className="del-icon">
                        <MoreHorizIcon />
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                      <Dropdown.Item
                        className="dropdown-item"
                        onClick={() => {
                          props.onDeleteClick();
                        }}
                      >
                        Delete
                      </Dropdown.Item>
                      <Dropdown.Item
                        className="dropdown-item"
                        onClick={() => {
                          props.onEditClick();
                        }}
                      >
                        Edit
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default LeftItemDetail;
